import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import Button from "react-bootstrap/Button";
import Modal1 from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { RxCrossCircled } from "react-icons/rx";
import { Modal } from "antd";
import ReactPaginate from "react-paginate";
import axios from "axios";
function VchicleModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const [etitdata, seteditdata] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [modal2Open, setModal2Open] = useState(false);

  const [errorData, setErrordata] = useState("");
  const erorShow = (data) => {
    setErrordata(data);
    setModal2Open(true);
  };

  const [dataSource, setdataSource] = useState([]);
  const [category, setcategory] = useState("");
  const [VchicleName, setVehicleName] = useState("");
  const AllCategory = async () => {
    let res = await axios.get(
      "https://goeasycab.info/api/admin/getAllCategory"
    );
    if (res.status === 200) {
      console.log(res);
      setdataSource(res.data.carList);
    }
  };
  const [allData, setAllData] = useState([]);
  const allVehicleName = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllVehicleName"
      );
      if (res.status == 200) {
        setAllData(res.data.carList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllBrandData,setAllBrand]=useState([]);
  const getAllbrand= async ()=>{
    try {
      let res=await axios.get("https://goeasycab.info/api/admin/getAllVehicleBrand");
      if(res.status==200){
        setAllBrand(res?.data?.carList)
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    AllCategory();
    allVehicleName();
    getAllbrand();
  }, []);
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  const [brand,setbrand]=useState("");
  const addVehicleBrand = async () => {
    try {
      if (!category) return erorShow("Please select category");
      if (!VchicleName) return erorShow("Please select vehicle name");
      if(!brand) return erorShow("Please enter vehicle brand")
      const config = {
        url: "/admin/addVehicleBrand",
        method: "post",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          authId: admin1._id,
          category: category,
          vehicleName: VchicleName,
          vehicleBrand:brand
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
       window.location.reload();
      }
    } catch (error) {
      erorShow(error.response.data.error);
      console.log(error);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllBrandData.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const editVehicle = async () => {
    try {
      const config = {
        url: "/admin/editVehicleBrand",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          authId: admin1._id,
          id: etitdata?._id,
          category: category,
          vehicleName: VchicleName,
          vehicleBrand:brand
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully update");
        window.location.reload();
      }
    } catch (error) {
      erorShow(error.response.data.error);
      console.log(error);
    }
  };
  const deleteVehicleBrand = async (id) => {
    try {
      const config = {
        url: "/admin/deleteVehicleBrand/" + id + "/" + admin1._id,
        method: "delete",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");
        getAllbrand();
      }
    } catch (error) {
      erorShow(error.response.data.error);
      console.log(error);
    }
  };
  return (
    <>
      <div className="accountt mb-3">
        
        <Button
          style={{
            borderRadius: "50px",
            border: "1px solid #fdd12d",
          }}
          onClick={handleShow}
        >
          Create
        </Button>
      </div>

      <div className="vwv">
        <div style={{ paddingLeft: "0px" }}>
          <div style={{ backgroundColor: "#f0f0f0" }}>
            <div class="row" style={{ padding: "10px 20px" }}>
              <div class="table-responsive">
                <div class="react-bootstrap-table">
                  <table
                    class="table table align-middle table-nowrap"
                    style={{ width: "90%", margin: "2%" }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#fdd12d",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      <tr style={{ padding: "10px" }}>
                        <th
                          tabindex="0"
                          aria-label="Username sortable"
                          class="sortable"
                        >
                          S.No
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Vchicle Category
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Vchicle Name
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Brand
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Action
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {AllBrandData?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                        return (
                          <tr style={{ textAlign: "center" }}>
                          <td>{i+1}</td>
  
                          <td>{item?.category}</td>
                          <td> {item?.vehicleName}</td>
                          <td> {item?.vehicleBrand}</td>
  
                          <td>
                            <Button type="button" variant="danger" onClick={()=>deleteVehicleBrand(item?._id)}>
                              <span>
                                <RiDeleteBin6Line />
                              </span>
                            </Button>
                            <Button
                              type="button"
                              variant="success"
                              className="mx-2"
                              onClick={()=>{
                                seteditdata(item)
                                handleShow1()
                              }}
                            >
                              <span>
                                <RiEditLine />
                              </span>
                            </Button>
                          </td>
                        </tr>
                        )
                      })}
                     
                    </tbody>
                  </table>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal */}

      <Modal1 show={show} onHide={handleClose}>
        <Modal1.Header closeButton></Modal1.Header>
        <Modal1.Body>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e)=>setcategory(e.target.value)}
            >
              <option>Select Category</option>
              {dataSource?.map((item) => {
                return <option value={item?.category}>{item?.category}</option>;
              })}
            </Form.Select>
          </div>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e)=>setVehicleName(e.target.value)}
            >
              <option>Select Name</option>
              {allData?.filter((ele)=>ele?.category==category)?.map((item) => {
                return <option value={item?.vehicleName}>{item?.vehicleName}</option>;
              })}
            </Form.Select>
          </div>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="text"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder="Enter Brand Name"
              onChange={(e)=>setbrand(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
        </Modal1.Body>
        <Modal1.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cencle
          </Button>
          <Button variant="primary" onClick={addVehicleBrand}>
            Submit
          </Button>
        </Modal1.Footer>
      </Modal1>

      {/* Edit Modal1 */}

      <Modal1 show={show1} onHide={handleClose1}>
        <Modal1.Header closeButton></Modal1.Header>
        <Modal1.Body>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e)=>setcategory(e.target.value)}
            >
               {etitdata?.category ? (
                <option>{etitdata?.category}</option>
              ) : (
                <option>Select Category</option>
              )}
              {dataSource?.map((item) => {
                return <option value={item?.category}>{item?.category}</option>;
              })}
            </Form.Select>
          </div>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e)=>setVehicleName(e.target.value)}
            >
              {etitdata?.vehicleName ? (  <option>{etitdata?.vehicleName}</option>):(  <option>Select Name</option>)}
            
              {allData?.filter((ele)=>ele?.category==category)?.map((item) => {
                return <option value={item?.vehicleName}>{item?.vehicleName}</option>;
              })}
            </Form.Select>
          </div>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="text"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder={etitdata?.vehicleBrand}
              onChange={(e)=>setbrand(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
        </Modal1.Body>
        <Modal1.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cencle
          </Button>
          <Button variant="primary" onClick={editVehicle}>
            Submit
          </Button>
        </Modal1.Footer>
      </Modal1>
      {/* error handler model */}
      <Modal
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <div className="" style={{ display: "flex", justifyContent: "center" }}>
          <div className="">
            <div
              className="wrong"
              style={{
                fontSize: " 100px",
                color: "#ff000087",
                textAlign: "center",
              }}
            >
              <RxCrossCircled />
            </div>
            <div className="nf_0">{errorData}</div>
            <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30" }}
                onClick={() => setModal2Open(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default VchicleModal;
