import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as XLSX from "xlsx";
import exportFromJSON from "export-from-json";
import { AiFillEye } from "react-icons/ai";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import Autocomplete from "react-google-autocomplete";
import ReactPaginate from "react-paginate";
import moment from 'moment';
function SoldOut() {
  let [cityList,setcitylist]=useState(false)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show1, setShow1] = useState(false);
  
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show3, setShow3] = useState(false);
  
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [show4, setshow4] = useState(false);
  
    const handleClose4 = () => setshow4(false);
    const handleshow4 = () => setshow4(true);
  const [city,setcity]=useState("");
  const [lat,setlat]=useState("");
  const [lang,setlang]=useState("");
  const [Airportname,setAirportname]=useState("");
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
const [noAllCity,setnoAllcity]=useState([])
  const [allCity,setAllcity]=useState([]);
  const [data,setdata]=useState([])
  const getAllCity=async()=>{
    try {
      let res=await axios.get("https://goeasycab.info/api/admin/getAllAirportCity");
      if(res.status===200){
        setAllcity(res.data.success);
        setnoAllcity(res.data.success);
       
      }
    } catch (error) {
      console.log(error);
    }
  }
  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("AirportRoutes");
  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };
  const AddAirportCity= async()=>{
    try {
      if(!city) return alert("Please enter city");
      const config = {
        url: "/admin/addCityAirport",
        method: "post",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {authId:admin1?._id,city:city,lat:lat,lng:lang}
      };
      let res=await axios(config);
      if(res.status===200){
        alert("Successfully added city");
        handleClose()
        getAllCity()
        setcity("")
        setlang("");
        setlat("");
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }
  const handlePlaceChanged1 = (place) => {
    if (place) {
      console.log("check it", place.name);

      console.log("Lat", place.geometry.location.lat());
      console.log("Long", place.geometry.location.lng());
      setlat(place.geometry.location.lat());
      setlang(place.geometry.location.lng());
      // distanceInKm(13.1989,77.7068,place.geometry.location.lat(),place.geometry.location.lng())
      
      setcity(place.name);
    }
  };
  const handlePlaceChanged = (place) => {
    if (place) {
      console.log("check it", place.name);

      console.log("Lat", place.geometry.location.lat());
      console.log("Long", place.geometry.location.lng());
      setlat(place.geometry.location.lat());
      setlang(place.geometry.location.lng());
      // distanceInKm(13.1989,77.7068,place.geometry.location.lat(),place.geometry.location.lng())
      
      setAirportname(place.name);
    }
  };
useEffect(()=>{
  getAllCity();
  getAirportDetails();
},[])
const [deleteId,setDeleteId]=useState("");

const DeleteCity=async()=>{
  try {
    const config = {
      url: "/admin/deleteAirportCity/"+deleteId+"/"+admin1?._id,
      method: "delete",
      baseURL: "https://goeasycab.info/api",
      headers: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
      // data: {authId:admin1?._id,city:city,lat:lat,lng:lang}
    };
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully deleted");
      getAllCity("");
      handleClose3()
      setDeleteId("")
    }
  } catch (error) {
    alert(error.response.dada.error)
    console.log(error);
  }
}
const DeleteAirport=async()=>{
  try {
    const config = {
      url: "/admin/deleteAirportDetails/"+deleteId+"/"+admin1?._id,
      method: "delete",
      baseURL: "https://goeasycab.info/api",
      headers: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
      // data: {authId:admin1?._id,city:city,lat:lat,lng:lang}
    };
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully deleted");
     getAirportDetails()
      handleClose4()
      setDeleteId("")
    }
  } catch (error) {
    alert(error.response.dada.error)
    console.log(error);
  }
}
const [noAirportData,setAirportData]=useState([])
const [allAirpots,setAllAirports]=useState([]);
const getAirportDetails=async()=>{
  try {
    let res=await axios.get("https://goeasycab.info/api/admin/getAllAirportsDetails");
    if(res.status==200){
      setAllAirports(res.data.success);
      setAirportData(res.data.success);
      setdata(res.data.success);
    }
  } catch (error) {
    console.log(error);
  }
}

const AddAirportDetails=async()=>{
  try {
    if(!city) return alert("Please select city");
    if(!Airportname) return alert("Please enter airport name")
    const config = {
      url: "/admin/AddAirportDetails",
      method: "post",
      baseURL: "https://goeasycab.info/api",
      headers: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
      data: {authId:admin1?._id,city:city,lat:lat,lng:lang,airportName:Airportname}
    };
    let res=await axios(config);
    if(res.status===200){
      alert("Successfully added airport")
      handleClose1()
      getAirportDetails()
      setcity("")
      setlang("");
      setlat("");
    }
  } catch (error) {
    alert(error.response.dada.error)
    console.log(error);
  }
}
const [pageNumber, setPageNumber] = useState(0);
const usersPerPage = 10;
const pagesVisited = pageNumber * usersPerPage;
const pageCount = Math.ceil(allAirpots.length / usersPerPage);
const changePage = ({ selected }) => {
  setPageNumber(selected);
};
const [pageNumber1, setPageNumber1] = useState(0);

const pagesVisited1 = pageNumber1 * usersPerPage;
const pageCount1 = Math.ceil(allCity.length / usersPerPage);
const changePage1 = ({ selected }) => {
  setPageNumber1(selected);
};
const [search,setSearch]=useState("");

const handleFilter = (e) => {
  if(cityList){
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = noAllCity.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllcity([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAllcity([...noAllCity]);
    }
    setAllAirports(noAirportData)
  }else{
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = noAirportData.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllAirports([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAllAirports([...noAirportData]);
    }
    setAllcity(noAllCity)
  }
};
const [allSold,setAllsold]=useState(false);
const [from, setfrom] = useState("");
const [to, setto] = useState("");
const [slodid, setsoldid] = useState("");
const [showSold, setShowSold] = useState(false);
const handeleShowld = () => setShowSold(true);
const handleCloseShold = () => setShowSold(false);

const [airportType,setAirportType]=useState("");


const SoldUnsoldAirportDetails=async()=>{
  try {
    if(!airportType) return alert("Please select Sold Out Type");
    if(!from) return alert("Please select start date");
    if(!to) return alert("Please select end date")
    const config = {
      url: "/admin/soudAndunSouldAirportRoute",
      method: "put",
      baseURL: "https://goeasycab.info/api",
      headers: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
      data: {authId:admin1?._id,id:slodid,isSould:true,from:from,to:to,airportType:airportType}
    };
    let res=await axios(config);
    if(res.status==200){
      alert(res.data.success);
      handleCloseShold();
      getAirportDetails();
      setfrom("");
      setto("");
      setAirportType("")
    }
  } catch (error) {
    console.log(error);
  }
}
const SoldUnsold=async(id,isSould)=>{
  try {
    const config = {
      url: "/admin/soudAndunSouldAirportRoute",
      method: "put",
      baseURL: "https://goeasycab.info/api",
      headers: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
      data: {authId:admin1?._id,id:id,isSould:isSould,from:"",to:"",airportType:""}
    };
    let res=await axios(config);
    if(res.status==200){
      alert(res.data.success);
      getAirportDetails()
    }
  } catch (error) {
    console.log(error);
  }
}
//AllsoudAndunSouldAirportRoute
const allSoldOutUnsoldOut=async(isSould)=>{
  try {
    let start=""
    let end=""
    let airport=""
    if(isSould==true){
      if(!airportType) return alert("Please select Sold Out Type");
      if(!from) return alert("Please select start date");
      if(!to) return alert("Please select end date")
      start=from
      end=to
      airport=airportType
    }
    const config = {
      url: "/admin/AllsoudAndunSouldAirportRoute",
      method: "put",
      baseURL: "https://goeasycab.info/api",
      headers: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
      data: {authId:admin1?._id,isSould:isSould,from:start,to:end,airportType:airport}
    };
    let res=await axios(config);
    if(res.status==200){
      alert(res.data.success);
      
      handleCloseShold();
      setAllsold(false)
      setAirportType("");
      setfrom("");
      setto("");
      getAirportDetails();
    }
  } catch (error) {
    console.log(error);
  }
}


const [excel,setexcel] = useState()
const [exceldata, setexceldata] = useState([]);
console.log(excel);
function readFile() {
  var name = excel.name;
  const reader = new FileReader();
  reader.onload = (evt) => {
    // evt = on_file_select event
    /* Parse data */
    const bstr = evt.target.result;
    const wb = XLSX.read(bstr, { type: "binary" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
    /* Update state */
    console.log("Data>>>" + data); // shows that excel data is read
    console.log(convertToJson(data)); // shows data in json format
    setexceldata(JSON.parse(convertToJson(data)));
  };
  reader.readAsBinaryString(excel);
}
function convertToJson(csv) {
  var lines = csv.split("\n");

  var result = [];

  var headers = lines[0].split(",");

  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split(",");

    for (var j = 0; j < headers.length; j++) {
      // console.log(currentline[j]?.replace(/_/g, ","));
      obj[headers[j]] = currentline[j]?.replace(/_/g, ",");
    }

    result.push(obj);
  }

  //return result; //JavaScript object
  return JSON.stringify(result); //JSON
}

const postRentalBulk = async (e) => {
  e.preventDefault();
 
  if(excel){
  try {
    const config = {
      url: "/admin/bulkUploadAirportDetails",
      method: "post",
      baseURL: "https://goeasycab.info/api",
      headers: { "content-type": "application/json",Authorization: `Bearer ${admin}` },
      data: {
        authId: admin1._id,
        data: exceldata?.filter((ele)=>ele.City).map((datas) => ({
          city: datas.City,
          airportName: datas.AirportName,
          lat: Number(datas.Latitude),
          lng: Number(datas.Longitude),
        })),
      },

    };
    let res = await axios(config);
    if (res.status === 200) {
      console.log(res.data);
      alert("Successfully added");
      window.location.reload()
      setexceldata([])
      setexcel()
      getAirportDetails()
      return res;
    }
    
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  
 }
}else {
  alert("Please import excel  file")
}
};
console.log("amit",exceldata.filter((ele)=>ele.City))


if (excel && exceldata.length === 0) {
  readFile();
}
  return (
   <>
   <div className='jvkcjd'>
   <div style={{ paddingLeft: "0px" }}>
    <div className="ssiu" style={{display:"flex",justifyContent:"space-between"}}>
    <Button
          style={{
            color: "white",
            backgroundColor: "#fdd12d",
            border: "#fdd12d",
          }}
        >
          {" "}
          All Airport Routes
        </Button>
 
    <div className="d-flex" style={{ justifyContent: "end",gap:"5px",padding:"5px",marginRight:"78px" }}>
      <a
                 
                 href={
                   "https://goeasycab.info/AirportUpload.xls"
                 }
               >
             <button
               style={{
                 padding: "2px 8px 7px 8px",
                 color: "black",
                 backgroundColor: "#fdd12d",
                 border: "none",
                 fontWeight: "600",
                 fontSize: "14px",
                 borderRadius: "20px",
                 margin: "0px 10px 0px 0px",
               }}
               // onClick={() => {
               //   setEnterfilenameModal(true);
               // }}
             >
               <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
               Download Excel
             </button></a>
          <input
                 accept=".xlsx,.xls"
                 style={{
                   padding: "2px 8px 7px 8px",
                   color: "black",
                   backgroundColor: "#fdd12d",
                   border: "none",
                   fontWeight: "600",
                   fontSize: "14px",
                   borderRadius: "20px",
                   margin: "0px 10px 0px 0px",
                  display:"none"

                 }}
                 id="icon-button-file"
                 type="file"
                 onChange={(e) => setexcel(e.target.files[0])}
               />
               <label className="btn-c btn-orange " htmlFor="icon-button-file" style={{
                     padding: "7px 8px 7px 8px",
                     color: "black",
                     backgroundColor: "#fdd12d",
                     border: "none",
                     fontWeight: "600",
                     fontSize: "14px",
                     borderRadius: "20px",
                     margin: "0px 10px 0px 0px",
               }} >
                 {" "}
                 Import Excel
               </label>
               <button
               style={{
                 padding: "2px 8px 7px 8px",
                 color: "black",
                 backgroundColor: "#fdd12d",
                 border: "none",
                 fontWeight: "600",
                 fontSize: "14px",
                 borderRadius: "20px",
                 margin: "0px 10px 0px 0px",
               }}
               onClick={postRentalBulk}
             >
               <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
               Upload Excel
             </button>

      </div>
    </div>
    
      <br></br>
      </div>
     
      <div className="d-flex" style={{ justifyContent: "space-between", }}>
        {allAirpots?.filter((ele)=>ele?.isSould==false).length==0 ? (   <Button className='mx-2'
            style={{
              color: "white",
              backgroundColor: "#fdd12d",
              border: "#fdd12d",
              color:"green"
            }}
            onClick={()=>allSoldOutUnsoldOut(false)}
          >
            {" "}
            All Un-Sold-Out
          </Button>):(   <Button className='mx-2'
            style={{
              color: "white",
              backgroundColor: "#fdd12d",
              border: "#fdd12d",
              color:"red"
            }}
            onClick={()=>{
              setAllsold(true);
              handeleShowld()
            }}
          >
            {" "}
            All Sold-Out
         
          </Button>)}
   

          <Button className='mx-2'
            style={{
              color: "white",
              backgroundColor: "#fdd12d",
              border: "#fdd12d",
            }}
            onClick={handleShow}
          >
            {" "}
         +Add City
          </Button>
          <Button className='mx-2'
            style={{
              color: "black",
              backgroundColor: "#fdd12d",
              border: "#fdd12d",
            }}
            onClick={()=>setcitylist(!cityList)}
          >
            {" "}
            {cityList ? ("Ariport List"):("City List")}
         
          </Button>
          <Button
            style={{
              color: "white",
              backgroundColor: "#fdd12d",
              border: "#fdd12d",
            }}
           onClick={handleShow1}
          >
            {" "}
           +Airport Details
          </Button>{" "}
          
          <Button
            style={{
              color: "white",
              backgroundColor: "#fdd12d",
              border: "#fdd12d",
            }}
           onClick={ExportToExcel}
          >
            {" "}
           ExportToExcel
          </Button>{" "}
          <div class="col-sm-4">
            <div class="search-box  d-inline-block">
              <div class="position-relative" style={{ display: "flex" }}>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  onChange={handleFilter}
                  placeholder="Search  Routes"
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

            {cityList ? (    <div class="table-responsive">
                    <div class="react-bootstrap-table">
                      <table
                        class="table table align-middle table-nowrap"
                        style={{ width: "90%", margin: "2%" }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#fdd12d",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          <tr style={{ padding: "10px" }}>
                            <th
                              tabindex="0"
                              aria-label="Username sortable"
                              class="sortable"
                            >
                              S.No
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              City
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            {/* <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Latitude
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th> */}
                            {/* <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Longitude
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th> */}
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Action
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {allCity?.slice(pagesVisited1, pagesVisited1 + usersPerPage)?.map((ele,i)=>{
                            return (
                              <tr style={{ textAlign: "center" }}>
                              <td>{i+1}</td>
  
                              <td>{ele?.city}</td>
                              {/* <td>{ele?.lat}</td>
                              <td>{ele.lng}</td> */}
                              
                              <td>
                                <Button type="button" variant="danger" onClick={()=>{
                                  setDeleteId(ele?._id);
                                  handleShow3()
                                }}>
                                  <span>
                                   Delete
                                  </span>
                                </Button>
                                {/* <Button
                                  type="button"
                                  variant="success"
                                  className="mx-2"
                                >
                                  <span>
                                   Active 
                                  </span>
                                </Button> */}
                              </td>
                            </tr>
                            )
                          })}
                         
                        </tbody>
                      </table>
                      <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
                    </div>
                  </div>):(    <div class="table-responsive">
                    <div class="react-bootstrap-table">
                      <table
                        class="table table align-middle table-nowrap"
                        style={{ width: "90%", margin: "2%" }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#fdd12d",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          <tr style={{ padding: "10px" }}>
                            <th
                              tabindex="0"
                              aria-label="Username sortable"
                              class="sortable"
                            >
                              S.No
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              City
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Airport
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                              <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Latitude
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Longitude
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Sold Start Date
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Sold End Date
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Sold Out Type
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Action
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                         {allAirpots?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((ele,i)=>{
                          return ( <tr style={{ textAlign: "center" }}>
                          <td>{i+1}</td>

                          <td>{ele?.city}</td>
                          <td>{ele?.airportName}</td>
                          <td>{ele?.lat}</td>
                          <td>{ele?.lng}</td>
                          <td>{ele?.from ? (moment(ele?.from).format("DD/MM/YYYY")):(<></>)}
                              </td>
                              <td>{ele?.to ? (moment(ele?.to).format("DD/MM/YYYY")):(<></>)}
                              </td>
                              <td>{ele?.airportType}</td>
                          <td style={{display:"flex",gap:"2px"}}>
                            {ele?.isSould==false ? (  <Button
                              type="button"
                              variant="success"
                              className="mx-2"
                              onClick={()=>{
                                setAllsold(false)
                                setsoldid(ele?._id)
                                handeleShowld()
                              }}
                            >
                              <span>
                               SoldOut 
                              </span>
                            </Button>):(  <Button
                              type="button"
                              variant="danger"
                              className="mx-2"
                              onClick={()=>SoldUnsold(ele?._id,false)}
                            >
                              <span>
                               UnSoldOut 
                              </span>
                            </Button>)}
                        
                            <Button type="button" variant="danger" onClick={()=>{
                              setDeleteId(ele?._id);
                              handleshow4()
                            }}>
                              <span>
                               Delete
                              </span>
                            </Button>
                         
                          </td>
                        </tr>)
                         })}
                        </tbody>
                      </table>
                      <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
                    </div>
                  </div>)}
      
   </div>
   <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add City</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <div className="dgier mt-4">
          <div className="grse mt-2" >
            
                    
          <Autocomplete
            apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
            placeholder={
                "Enter city name"
            }
            style={{
              padding: "10px",
              width: "100%",
              border: "1px solid #fdd12d",
              height: "50px",
              borderRadius: "20px",
            
            }}
           
            onPlaceSelected={(place) => {
              handlePlaceChanged1(place);
            }}
            options={{
              types: ["(cities)"],
              componentRestrictions: { country: "in"},
              fields: [ "geometry", "icon", "name"],
              // types: ["establishment"],
            }}
          />
             
      
        </div>
          
          </div>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={AddAirportCity}>
           Submit
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Airport Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <div className="dgier mt-4">
          
            <div className="grse" >
            <Form.Select
                  aria-label="Default select example"
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                 onChange={(e)=>setcity(e.target.value)}
                >
                  <option>Select City</option>
                  {allCity?.map((ele)=>{
                    return (
                      <option value={ele?.cit}>{ele?.city}</option>
                    )
                  })}

                </Form.Select>
               
            </div>
            <div className="grse mt-2" >
            
         
            <Autocomplete
            apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
            placeholder={
                "Enter airport name"
            }
            style={{
              padding: "10px",
              width: "100%",
              border: "1px solid #fdd12d",
              height: "50px",
              borderRadius: "20px",
            
            }}
           
            onPlaceSelected={(place) => {
              handlePlaceChanged(place);
            }}
            options={{
              // types: ["(resoins)"],
              componentRestrictions: { country: "in"},
              fields: ["address_components", "geometry", "icon", "name"],
              types: ["establishment"],
            }}
          />
      
            </div>
                 
          
         
          </div>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={AddAirportDetails}>
           Submit
          </Button>
        </Modal.Footer>
      </Modal>
          {/* delete city model */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete??</Modal.Title>
        </Modal.Header>
      
        <Modal.Footer>
         
          <Button variant="danger" onClick={handleClose3}>
           Cancel
          </Button>
          <Button variant="success" onClick={DeleteCity}>
           Ok
          </Button>
        </Modal.Footer>
      </Modal>
     {/* delete city model */}
     <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete?</Modal.Title>
        </Modal.Header>
      
        <Modal.Footer>
         
          <Button variant="danger" onClick={handleClose4}>
           Cancel
          </Button>
          <Button variant="success" onClick={DeleteAirport}>
           Ok
          </Button>
        </Modal.Footer>
      </Modal>
        {/* sold out */}
        <Modal
        show={showSold}
        onHide={handleCloseShold}
        animation={true}
        size="lg"
      >
        <Modal.Header style={{ color: "black" }}>Add sold out date</Modal.Header>
        <Modal.Body>
          <div style={{ margin: "1% 2%" }}>
            <label
              style={{
                textAlign: "left",
                paddingBottom: "3px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Select Sold Out Type
            </label>
            <br />
          <select      style={{
                 padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }} onChange={(e)=>setAirportType(e.target.value)}>
            <option>Select Type</option>
            <option value="Airport Pickup">Airport Pickup</option>
            <option value="Airport Drop">Airport Drop</option>
            <option value="Both">Both Airport Pickup/Airport Drop</option>
          </select>
            <br/>
              <label
              style={{
                textAlign: "left",
                paddingBottom: "3px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Start Date
            </label>
            <br />
            <input
              type="date"
              min={Date.now()}
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder="40"
              //   value={basePrice}
              onChange={(e) => {
                setfrom(e.target.value);
              }}
            />
            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              End Date
            </label>
            <br />
            <input
              type="date"
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder="4"
              //   value={basePrice}
              onChange={(e) => {
                setto(e.target.value);
              }}
            />
            <br />
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ justifyContent: "space-between", padding: "10px 20px" }}
        >
          <Button
            style={{
              backgroundColor: "#ffcc33",
              border: "none",
              width: "120px",
            }}
            onClick={handleCloseShold}
          >
            CANCEL
          </Button>
            {allSold==true ? ( <Button
            style={{
              backgroundColor: "#fdd12d",
              border: "none",
              width: "180px",
            }}
            onClick={()=>allSoldOutUnsoldOut(true)}
          >
          All Sold Now
          </Button>):( <Button
            style={{
              backgroundColor: "#fdd12d",
              border: "none",
              width: "180px",
            }}
            onClick={SoldUnsoldAirportDetails}
          >
            Sold Now
          </Button>)}
         
        </Modal.Footer>
      </Modal>
   </>
  )
}

export default SoldOut