import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "../Admin/Admin.css";
import Button from "react-bootstrap/Button";
function GST() {

  const [cgst, setcgst] = useState("");
  const [sgst, setsgst] = useState("");
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  console.log("admin", admin1._id);
  let obj={authId:admin1._id,Sgst:sgst,Cgst:cgst}
 
  const AddGST = async () => {
    if (!sgst) {
      alert("sgst missing")
    }
    else if(!cgst){
      alert("cgst missing")
    }
    else{
    try {
     const config = {
       url: "/admin/addGst",
       method: "post",
       baseURL: "https://goeasycab.info/api",
       headers: { "content-type": "application/json" },
       headers: { Authorization: `Bearer ${admin}` },
       data: obj,
     };
     await axios(config).then(function (res) {
       if (res.status === 200) {
         console.log(res.data);
         window.location.reload();
        
       }
     });
   } catch (error) {
     console.log(error.res);
     alert(error.response.data.error);
   }
  }
 };
  const [dataSource, setdataSource] = useState({});



  const Gst = async () => {
    let res = await axios.get("https://goeasycab.info/api/admin/getGst");
    if (res.status === 200) {
      setdataSource(res.data.success);

    }
  };
  useEffect(() => {
    Gst();
  }, []);

 


 
  return (
    <div style={{ paddingLeft: "30px" }}>
       <Button
        style={{
          color: "white",
          backgroundColor: "#fdd12d",
          border: "#fdd12d",
        }}
      >
        {" "}
        GST
      </Button>
    
      <br></br>
      <div className="" style={{display:"flex",alignItems: "center"}}>
      <textarea
              placeholder="Cgst"
              type="number"
              style={{
                padding: "10px",
                width: 100,height:50,
                border: "1px solid #fdd12d",
                borderRadius:10,margin:10
              }}
              //   value={basePrice}
              onChange={(e) => {
                setcgst(e.target.value);
              }}
            />
     <textarea
              placeholder="Sgst"
              type="number"
              style={{
                padding: "10px",
                width: 100,height:50,
                border: "1px solid #fdd12d",
                borderRadius:10,margin:10
              }}
              //   value={basePrice}
              onChange={(e) => {
                setsgst(e.target.value);
              }}
            />
             <Button
            className="commonupload text-black"
            variant="primary"
            onClick={()=>AddGST()}
           style={{height:50,width:80,backgroundColor:"#fdd12d"}}
          >
            Add
          </Button>
      </div>
      <div class="table-responsive">
            <div class="react-bootstrap-table">
              <table
                class="table table align-middle table-nowrap"
                style={{ width: "90%", margin: "2%" }}
              >
                <thead style={{ backgroundColor: "#fdd12d", color: "white" }}>
                  <tr style={{ padding: "10px" }}>
                  
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      CGST
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                     SGST
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      TOTAL
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                   
                  </tr>
                </thead>
                <tbody>
                
                      <tr>
                       
                        <td>{dataSource.Sgst} %</td>
                        <td>{dataSource.Cgst} %</td>
                        <td>{dataSource.Cgst+dataSource.Sgst} %</td>
                       
                      </tr>
                    
                </tbody>
              </table>
            </div>
          </div>
        
      
     
    </div>
  );
}

export default GST;

