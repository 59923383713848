import React, { useEffect, useRef } from "react";
import { Button, Radio } from "antd";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DatePicker from "react-multi-date-picker";
import { TimePicker } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import axios from "axios";
import { Modal } from "antd";
import { RxCrossCircled } from "react-icons/rx";
import moment from "moment";


function OutStation() {
  const [distance, setdistance] = useState(0);
  const [value, setValue] = useState(2);
  const [lat1, setlat1] = useState();
  const [lon1, setlon1] = useState();
  const [lat2, setlat2] = useState();
  const [long2, setlong2] = useState();
  const [modal2Open, setModal2Open] = useState(false);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const [oneWay, setoneWay] = useState("");
  const [roundTrip, setroundTrip] = useState(true);

  const [allroutesdata, setAllroutes] = useState([]);
  const getAllroutes = async () => {
    try {
      let res = await axios.get("https://goeasycab.info/api/admin/getRoute");
      if (res.status === 200) {
        setAllroutes(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [modal2Open2, setModal2Open2] = useState(false);

  const [errorData,setErrordata]=useState("")
  const erorShow=(data)=>{
    setErrordata(data)
    setModal2Open2(true)
  }
  const history = useNavigate();
  const [from, setfrom] = useState("");
  const [to, setto] = useState("");
  const [pickupdate, setpickupdate] = useState(moment().format("DD/MM/YYYY"));
  const [retundate, setretundate] = useState(moment().format("DD/MM/YYYY"));
// const [today,settoday]=useState(new Date())
 
  // console.log("check date",moment(today).format("DD/MM/YYYY"));

  const alldate = [
    { id: 0, time: "00:00" },
    { id: 15, time: "00:15" },
    { id: 30, time: "00:30" },
    { id: 45, time: "00:45" },
    { id: 100, time: "01:00" },
    { id: 115, time: "01:15" },
    { id: 130, time: "01:30" },
    { id: 145, time: "01:45" },
    { id: 200, time: "02:00" },
    { id: 215, time: "02:15" },
    { id: 230, time: "02:30" },
    { id: 245, time: "02:45" },
    { id: 300, time: "03:00" },
    { id: 315, time: "03:15" },
    { id: 330, time: "03:30" },
    { id: 345, time: "03:45" },
    { id: 400, time: "04:00" },
    { id: 415, time: "04:15" },
    { id: 430, time: "04:30" },
    { id: 445, time: "04:45" },
    { id: 500, time: "05:00" },
    { id: 515, time: "05:15" },
    { id: 530, time: "05:30" },
    { id: 545, time: "05:45" },
    { id: 600, time: "06:00" },
    { id: 615, time: "06:15" },
    { id: 630, time: "06:30" },
    { id: 645, time: "06:45" },
    { id: 700, time: "07:00" },
    { id: 715, time: "07:15" },
    { id: 730, time: "07:30" },
    { id: 745, time: "07:45" },
    { id: 800, time: "08:00" },
    { id: 815, time: "08:15" },
    { id: 830, time: "08:30" },
    { id: 845, time: "08:45" },
    { id: 900, time: "09:00" },
    { id: 915, time: "09:15" },
    { id: 930, time: "09:30" },
    { id: 945, time: "09:45" },
    { id: 1000, time: "10:00" },
    { id: 1015, time: "10:15" },
    { id: 1030, time: "10:30" },
    { id: 1045, time: "10:45" },
    { id: 1100, time: "11:00" },
    { id: 1115, time: "11:15" },
    { id: 1130, time: "11:30" },
    { id: 1145, time: "11:45" },
    { id: 1200, time: "12:00" },
    { id: 1215, time: "12:15" },
    { id: 1230, time: "12:30" },
    { id: 1245, time: "12:45" },
    { id: 1300, time: "13:00" },
    { id: 1315, time: "13:15" },
    { id: 1330, time: "13:30" },
    { id: 1345, time: "13:45" },
    { id: 1400, time: "14:00" },
    { id: 1415, time: "14:15" },
    { id: 1430, time: "14:30" },
    { id: 1445, time: "14:45" },
    { id: 1500, time: "15:00" },
    { id: 1515, time: "15:15" },
    { id: 1530, time: "15:30" },
    { id: 1545, time: "15:45" },
    { id: 1600, time: "16:00" },
    { id: 1615, time: "16:15" },
    { id: 1630, time: "16:30" },
    { id: 1645, time: "16:45" },
    { id: 1700, time: "17:00" },
    { id: 1715, time: "17:15" },
    { id: 1730, time: "17:30" },
    { id: 1745, time: "17:45" },
    { id: 1800, time: "18:00" },
    { id: 1815, time: "18:15" },
    { id: 1830, time: "18:30" },
    { id: 1845, time: "18:45" },
    { id: 1900, time: "19:00" },
    { id: 1915, time: "19:15" },
    { id: 1930, time: "19:30" },
    { id: 1945, time: "19:45" },
    { id: 2000, time: "20:00" },
    { id: 2015, time: "20:15" },
    { id: 2030, time: "20:30" },
    { id: 2045, time: "20:45" },
    { id: 2100, time: "21:00" },
    { id: 2115, time: "21:15" },
    { id: 2130, time: "21:30" },
    { id: 2145, time: "21:45" },
    { id: 2200, time: "22:00" },
    { id: 2215, time: "22:15" },
    { id: 2230, time: "22:30" },
    { id: 2245, time: "22:45" },
    { id: 2300, time: "23:00" },
    { id: 2315, time: "23:15" },
    { id: 2330, time: "23:30" },
    { id: 2345, time: "23:45" },
  
  ];
  const [am1,setam1]=useState([])
  const [am2,setam2]=useState([])
  const [pickuptime, setpickuptime] = useState("");

  const [returntime, setreturntime] = useState("");
  useEffect(()=>{
    let today = new Date();
    if(moment(today).format("DD/MM/YYYY")==pickupdate){
      const later = new Date(today.getTime() + 2 * 60 * 60 * 1000);
      let mint=0
      if(later.getMinutes()<10){
        mint=`0${later.getMinutes()}`
      }else{
        mint=later.getMinutes()
      }
      let abc = `${later.getHours()}${mint}`;
    setam1(alldate.filter((ele) => ele.id > parseInt(abc)));
    setpickuptime(alldate.filter((ele) => ele.id > parseInt(abc))[0].time)
    // setreturntime(alldate.filter((ele) => ele.id > parseInt(abc))[0].time)
    }else{
      setam1(alldate)
      setpickuptime("")
      setreturntime("")
    }
  },[pickupdate])

  useEffect(()=>{
    let today = new Date();
    if(moment(today).format("DD/MM/YYYY")==pickupdate){
      const later = new Date(today.getTime() + 2 * 60 * 60 * 1000);
      let mint=0
      if(later.getMinutes()<10){
        mint=`0${later.getMinutes()}`
      }else{
        mint=later.getMinutes()
      }
      let abc = `${later.getHours()}${mint}`;
    setam1(alldate.filter((ele) => ele.id > parseInt(abc)));
    setpickuptime(alldate.filter((ele) => ele.id > parseInt(abc))[0].time)
    // setreturntime(alldate.filter((ele) => ele.id > parseInt(abc))[0].time)
    }else{
      setam1(alldate)
      setpickuptime("")
      setreturntime("")
    }
  },[])

  useEffect(()=>{
    let today = new Date();
    if(moment(today).format("DD/MM/YYYY")==retundate){
      const later = new Date(today.getTime() + 3 * 60 * 60 * 1000);
      let mint=0
      if(later.getMinutes()<10){
        mint=`0${later.getMinutes()}`
      }else{
        mint=later.getMinutes()
      }
      let abc = `${later.getHours()}${mint}`;
    setam2(alldate.filter((ele) => ele.id > parseInt(abc)));
    // setpickuptime(alldate.filter((ele) => ele.id > parseInt(abc))[0].time)
    setreturntime(alldate.filter((ele) => ele.id > parseInt(abc))[0].time)
    }else{
      setam2(alldate)
      // setpickuptime("")
      setreturntime("")
    }
  },[retundate])

  
 
 
  var date1 =pickupdate?.split("/")
  var date2 = retundate?.split("/");
  // 
  var da1=new Date(date1[2],date1[1],date1[0])
  var da2=new Date(date2[2],date2[1],date2[0])
var md1=moment(da1)
var md2=moment(da2)
  var days = md2.diff(md1, 'days',false)




  const SearchcabsOneway = async () => {
    try {
     
      if (!from) {
        return erorShow("Please enter your source location");
      }
      if (!to) {
        return erorShow("Please enter your detstination location");
      }
      if (!pickupdate) {
        return erorShow("Please select your pick up date");
      }
      if (!pickuptime) {
        return erorShow("Please select your pick up time");
      }
      let am = allroutesdata?.filter(
        (ele) =>
        ele?.from?.toLowerCase() === from?.toLowerCase() &&
        ele?.to?.toLowerCase() === to?.toLowerCase()&& ele.roundTrip=="oneway" && ele.isActive==true
      );

      if (oneWay) {
        if (am.length == 0) {
          return setModal2Open(true);
        }
        if (am[0].isActive == false) {
          return setModal2Open(true);
        }
        let km=am[0]?.totalKm 
     
        return history("/search", {
          state: {
            from: from,
            to: to,
            pickupdate: pickupdate,
            pickuptime: pickuptime,
            way: "oneway",
            km: km,
            packagedata: am[0],
            am:am 
          },
        });
      } else {
        
        am = allroutesdata?.filter(
          (ele) =>
            ele?.from?.toLowerCase() === from?.toLowerCase() &&
            ele?.to?.toLowerCase() === to?.toLowerCase() && ele.roundTrip=="Round Trip" && ele.isActive==true
        );
    
      
        if (am.length == 0) {
          return setModal2Open(true);
        }
        if (!retundate) return erorShow("Please select return date!");
        if (!returntime) return erorShow("Please select time");
     
        if (am.length == 0) {
          return setModal2Open(true);
        }
        if (am[0].isActive == false) {
          return setModal2Open(true);
        }
      
        let km=am[0]?.totalKm * 2
        let km2=250
        
          if(days>0){
            km2=250*(days+1)
            if(km>km2){
              km=km
            }else if(km2>km){
              km=km2
            }
            days= days+1
          }else{
          
            if(km<250){
              km=250
            }
            days= days+1
          }
      
        return history("/search", {
          state: {
            from: from,
            to: to,
            pickupdate: pickupdate,
            pickuptime: pickuptime,
            way: "Round Trip",
            retundate: retundate,
            returntime: returntime,
            km: km,
            packagedata: am[0],
            days:days+1,
            am:am            
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllroutes();
  }, []);
  // console.log("check it ", allroutesdata);
  const top100Films = [];
  const top100Films2 = [];
  if (allroutesdata.length != 0) {
    for (let i = 0; i < allroutesdata.length; i++) {
      if(top100Films[i-1]?.label!==allroutesdata[i]?.from){
        top100Films.push({
          label: allroutesdata[i]?.from,
          // year: allroutesdata[i]?.totalKm,
        });
      }
     if(top100Films2[i-1]?.label!==allroutesdata[i]?.to){
        top100Films2.push({
        label: allroutesdata[i]?.to,
        // year: allroutesdata[i]?.totalKm,
      });
     }
    
    }
  }

  return (
    <>
      <div className="current_type">
        <div className="outstation_09">
          <div className="tab-he">
            <ul className="one-tab text-center">
              <Radio.Group onChange={onChange} value={value}>
                <Radio
                  value={1}
                  onClick={() => {
                    setoneWay(true);
                    setroundTrip(false);
                  }}
                >
                  {" "}
                  One-Way{" "}
                </Radio>
                <Radio
                  value={2}
                  onClick={() => {
                    setoneWay(false);
                    setroundTrip(true);
                  }}
                >
                  {" "}
                  Round trip{" "}
                </Radio>
              </Radio.Group>
            </ul>
           <div className="">
           <div className="auto-tads">
              {oneWay ? (
                <>
                 <div className="row me-0">
                  <div className="col-md-6">
                  <div className="js-example pb-2">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={[
                        ...new Map(
                          top100Films.map((item) => [item?.label, item])
                        ).values(),
                      ]}
                      style={{ width: "100%" }}
                      onChange={(event, value) => {
                        console.log("event", event, value);
                        setfrom(value?.label);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Source" />
                      )}
                      // onChange={(e)=>setfrom(e.target.value)}
                    />
                  </div>
                  </div>
                  <div className="col-md-6">
                  <div className="js-example pb-2">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={[
                        ...new Map(
                          top100Films2.map((item) => [item?.label, item])
                        ).values(),
                      ]}
                      style={{ width: "100%" }}
                      onChange={(event, value) => setto(value?.label)}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Destination" />
                      )}
                    />
                  </div>
                  </div>
                 </div>
                
                  
                

                  <div className="feature-list mb-4">
                    <div className="hourly mb-3">
                      <div className="row me-0">
                        <div className="col-md-6">
                        <div className="hourly_hero">
                        <label className="eiggyI pb-2">Pickup Date</label>
                        <div className="">
                          <DatePicker
                            style={{ height: "38px" }}
                            format="DD/MM/YYYY"
                            type="input-icon"
                            minDate={new Date()}
                            value={pickupdate}
                            onChange={(date) =>
                              setpickupdate(date.format("DD/MM/YYYY"))
                            }
                          />
                        </div>
                      </div>
                        </div>
                        <div className="col-md-6">
                        <div className="hourly_hero">
                        <label className="eiggyI pb-2">Pickup Time</label>
                        <div>
                          <select
                            name="txtStartTime"
                            className="select_hero-0"
                            onChange={(e) => setpickuptime(e.target.value)}
                          >
                            {pickuptime ? (<option> {moment(pickuptime, ["h:mm A"]).format(
                                    "hh:mm a"
                                  )}</option>):(<option>Select Time</option>)}
                            {am1?.map((item, i) => {
                              return (
                                <option value={item?.time} >
                                  {moment(item?.time, ["h:mm A"]).format(
                                    "hh:mm a"
                                  )}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                        </div>
                      </div>
                     
                     
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {roundTrip ? (
                    <>
                     <div className="row me-0">
                      <div className="col-md-6">
                      <div className="js-example pb-2">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={[
                            ...new Map(
                              top100Films.map((item) => [item?.label, item])
                            ).values(),
                          ]}
                          style={{ width: "100%" }}
                          onChange={(event, value) => {
                            console.log("event", event, value);
                            setfrom(value?.label);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Source" />
                          )}
                        />
                      </div>
                      </div>
                      <div className="col-md-6">
                      <div className="js-example pb-2">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={[
                            ...new Map(
                              top100Films2.map((item) => [item?.label, item])
                            ).values(),
                          ]}
                          style={{ width: "100%" }}
                          onChange={(event, value) => {
                            console.log("event", event, value);
                            setto(value?.label);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Destination" />
                          )}
                        />
                      </div>
                      </div>
                  
                      
                     </div>

                      <div className="feature-list mb-4">
                        <div className="hourly mb-3">
                          <div className="row me-0">
                            <div className="col-md-3">
                            <div className="hourly_hero">
                            <label className="eiggyI pb-2">Pickup Date</label>
                            <div className="">
                              <DatePicker
                                style={{ height: "38px" }}
                                format="DD/MM/YYYY"
                                type="input-icon"
                                minDate={new Date()}
                                value={pickupdate}
                                onChange={(date) =>
                                  setpickupdate(date.format("DD/MM/YYYY"))
                                }
                              />
                              {/* <input type="date" className="date-pick_0" onChange={(e)=>setpickupdate(e.target.value)}/> */}
                            </div>
                          </div>
                            </div>
                            <div className="col-md-3">
                            <div className="hourly_hero">
                            <label className="eiggyI pb-2">Pickup Time</label>
                            <div>
                              <select
                                name="txtStartTime"
                                className="select_hero-0"
                                onChange={(e) => setpickuptime(e.target.value)}
                              >
                                  {pickuptime ? (<option>  {moment(pickuptime, ["h:mm A"]).format(
                                        "hh:mm a"
                                      )}</option>):(<option>Select Time</option>)}
                                {am1?.map((item, i) => {
                                  return (
                                    <option
                                      value={item?.time}
                                     
                                    >
                                      {moment(item?.time, ["h:mm A"]).format(
                                        "hh:mm a"
                                      )}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                            </div>
                            <div className="col-md-3">
                            <div className="hourly_hero">
                          <label className="eiggyI pb-2">Return Date</label>
                          <div className="">
                            <DatePicker
                              style={{ height: "38px" }}
                              format="DD/MM/YYYY"
                              type="input-icon"
                              minDate={new Date()}
                              value={retundate}
                              onChange={(date) =>
                                setretundate(date.format("DD/MM/YYYY"))
                              }
                            />
                          </div>
                        </div>
                            </div>
                            <div className="col-md-3">
                            <div className="hourly_hero">
                          <label className="eiggyI pb-2">Return Time</label>
                          <div>
                            <select
                              name="txtStartTime"
                              className="select_hero-0"
                              onChange={(e) => setreturntime(e.target.value)}
                            >
                                {returntime ? (<option>   {moment(returntime, ["h:mm A"]).format(
                                      "hh:mm a"
                                    )}</option>):(<option>Select Time</option>)}
                              {am2?.map((item, i) => {
                                return (
                                  <option value={item?.time} >
                                    {moment(item?.time, ["h:mm A"]).format(
                                      "hh:mm a"
                                    )}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                            </div>
                          </div>
                         
                        
                        </div>
                      </div>

                      {/* Return */}
                      <div className="hourly mb-3">
                       
                       
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
           </div>
          </div>
          <div className=" text-center mb-4">
            {/* <Link to="/search"> */}
            <Button
              className="hvr-shutter-out-horizontal"
              onClick={() => SearchcabsOneway()}
            >
              SEARCH CABS
            </Button>
            {/* </Link> */}
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <div
          className=""
          style={{ display: "flex", justifyContent: "center", padding: "25px" }}
        >
          <div className="">
            <div
              className="wrong"
              style={{
                fontSize: " 100px",
                color: "#ff000087",
                textAlign: "center",
              }}
            >
              <RxCrossCircled />
            </div>
            <div className="nf_0">No service provides this route</div>
            <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30" }}
                onClick={() => setModal2Open(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={modal2Open2}
        onOk={() => setModal2Open2(false)}
        onCancel={() => setModal2Open2(false)}
      >
        <div
          className=""
          style={{ display: "flex", justifyContent: "center", }}
        >
          <div className="">
            <div
              className="wrong"
              style={{
                fontSize: " 100px",
                color: "#ff000087",
                textAlign: "center",
              }}
            >
              <RxCrossCircled />
            </div>
            <div className="nf_0">{errorData}</div>
            <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30" }}
                onClick={() => setModal2Open2(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default OutStation;
