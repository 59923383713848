import axios from "axios";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import "../Admin/Admin.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaImages } from "react-icons/fa";
import exportFromJSON from "export-from-json";
import { ImFolderDownload } from "react-icons/im";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { Form } from "react-bootstrap";
import moment from "moment";
function Packages() {
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const formdata = new FormData();
  const [PackageKm, setPackageKm] = useState();
  const [PackageHr, setPackageHr] = useState("");
  const [extraHrLimit, setextraHrLimit] = useState("");
  const [extraHrLimetPrice, setextraHrLimetPrice] = useState("");
  const [price, setprice] = useState("");
  const [city, setcity] = useState("");
  const [driverBata,setdriverBata]=useState(0)
  const [total,settotal]=useState(0)
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  console.log("admin", admin1._id);
  let obj = {
    authId: admin1._id,
    PackageHr: PackageHr,
    PackageKm: PackageKm,
   
    packegePrice:price,
    driverBata:driverBata,
    price: Number(price)+Number(driverBata),
    city: city,
  };
  const Addpackages = async () => {
    if (!city) return alert("Please select city");
    if (!PackageHr) {
      alert("PackageHr missing");
    } else if (!PackageKm) {
      alert("PackageKm missing");
    } else  if (!price) {
      alert("Price missing");
    } else {
      try {
        const config = {
          url: "/admin/addpackage",
          method: "post",
          baseURL: "https://goeasycab.info/api",
          headers: { "content-type": "application/json" },
          headers: { Authorization: `Bearer ${admin}` },

          data: obj,
        };
        await axios(config).then(function (res) {
          if (res.status === 200) {
            console.log(res.data);
            // alert("success");

            window.location.reload();
            Allpackages();
          }
        });
      } catch (error) {
        console.log(error.res);
        alert(error.response.data.error);
      }
    }
  };
  const [data, setdata] = useState();
  const [dataSource, setdataSource] = useState([]);
  useEffect(() => {
    Allpackages();
  }, []);

  const Allpackages = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllPackage"
      );
      if (res.status === 200) {
        setdataSource(res.data.success);
        setdata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Export to Excel

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(dataSource.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };
  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = dataSource.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setdataSource([...dataSource]);
    }
  };
  const [showdelete, setshowdelete] = useState(false);
  const handleClose4 = () => setshowdelete(false);
  const [deleteId, setdeleteId] = useState("");
  const Removepackages = async () => {
    try {
      const config = {
        url: "/admin/deletePackage/" + deleteId + "/" + admin1?._id,
        method: "delete",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);

          alert("Deleted Successfully");
          setshowdelete(false);
          Allpackages();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const [editorPopup, seteditorPopup] = useState(false);
  const [editorShow, seteditorShow] = useState({});

  const openEditor = (data) => {
    seteditorShow(data);

    seteditorPopup(true);
  };
  const CloseEditor = () => seteditorPopup(false);

  const [blogPopup, setblogPopup] = useState(false);
  const [blogShow, setblogShow] = useState();

  const openBlog = (data) => {
    // setFullscreen(breakpoint);
    setblogShow(data);
    setblogPopup(true);
  };
  const CloseBlog = () => setblogPopup(false);
  const [editvehiclename, seteditvehiclename] = useState(false);
  const [isShowneditvehiclename, setIsShowneditvehiclename] = useState(false);
  const [editbaseprice, seteditbaseprice] = useState(false);
  const [isbaseprice, setisbaseprice] = useState(false);
  const [editvehicletype, seteditvehicletype] = useState(false);
  const [isShownvehicletype, setIsShownvehicletype] = useState(false);

  const [editextraHrLimit, seteditextraHrLimit] = useState(false);
  const [isShownextraHrLimit, setIsShownextraHrLimit] = useState(false);
  const [editluagge, seteditluagge] = useState(false);
  const [isshownluagge, setisshownluagge] = useState(false);
  const [iscity, setisCity] = useState(false);
  const [iscity1, setisCity1] = useState(false);
  let obj1 = {
    authId: admin1._id,
    packageId: editorShow._id,
    PackageHr: PackageHr ? PackageHr : editorShow?.PackageHr,
    PackageKm: PackageKm ? PackageKm : editorShow?.PackageKm,
    packegePrice:price,
    driverBata:driverBata,
    price: Number(price)+Number(driverBata),
    city: city,
  };
  const Updatepackages = async () => {
    try {
      const config = {
        url: "/admin/updatePackage",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: obj1,
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert("updated Success");
          console.log(res.data);
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error.res);
      alert("Something went wrong!!! try again");
    }
  };
  const [noAllCity, setnoAllcity] = useState([]);
  const [allCity, setAllcity] = useState([]);
  const getAllCity = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllAirportCity"
      );
      if (res.status === 200) {
        setAllcity(res.data.success);
        setnoAllcity(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCity();
  }, []);

  const [from, setfrom] = useState("");
  const [to, setto] = useState("");
  const [slodid, setsoldid] = useState("");
  const [showSold, setShowSold] = useState(false);
  const handeleShowld = () => setShowSold(true);
  const handleCloseShold = () => setShowSold(false);
const soldOutRental=async()=>{
  try {
    if(!from) return alert("Please select start date");
    if(!to) return alert("Please select end date")
    const config = {
      url: "/admin/MakeSoldoutUnsoldOutRental",
      method: "put",
      baseURL: "https://goeasycab.info/api",

      headers: { Authorization: `Bearer ${admin}`,"content-type": "application/json" },
      data: {id:slodid,from:from,to:to,authId:admin1?._id,isSold:true}
    };
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully sold-out");
      handleCloseShold()
      Allpackages();
    }
  } catch (error) {
    console.log(error);
  }
}
const UnsoldOutRental=async(id)=>{
  try {
    
    const config = {
      url: "/admin/MakeSoldoutUnsoldOutRental",
      method: "put",
      baseURL: "https://goeasycab.info/api",

      headers: { Authorization: `Bearer ${admin}`,"content-type": "application/json" },
      data: {id:id,from:"",to:"",authId:admin1?._id,isSold:false}
    };
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully Un-Sold-Out");
      Allpackages();
    }
  } catch (error) {
    console.log(error);
  }
}
const [allSold,setAllsold]=useState(false);

const allSoldOutAndUnsoldOut=async(isSold)=>{
  try {
    let start=""
    let end=""
    if(isSold==true){
      if(!from) return alert("Please select start date");
      if(!to) return alert("Please select end date")
      start=from
      end=to
    }
    const config = {
      url: "/admin/AllSoldoutUnsoldOutRental",
      method: "put",
      baseURL: "https://goeasycab.info/api",
      headers: { Authorization: `Bearer ${admin}`,"content-type": "application/json" },
      data: {from:start,to:end,authId:admin1?._id,isSold:isSold}
    };
    let res=await axios(config);
    if(res.status==200){
      alert(res.data.success);
      handleCloseShold()
      setAllsold(false)
      Allpackages();
    }
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}


const [excel,setexcel] = useState()
const [exceldata, setexceldata] = useState([]);
console.log(excel);
function readFile() {
  var name = excel.name;
  const reader = new FileReader();
  reader.onload = (evt) => {
    // evt = on_file_select event
    /* Parse data */
    const bstr = evt.target.result;
    const wb = XLSX.read(bstr, { type: "binary" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
    /* Update state */
    console.log("Data>>>" + data); // shows that excel data is read
    console.log(convertToJson(data)); // shows data in json format
    setexceldata(JSON.parse(convertToJson(data)));
  };
  reader.readAsBinaryString(excel);
}
function convertToJson(csv) {
  var lines = csv.split("\n");

  var result = [];

  var headers = lines[0].split(",");

  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split(",");

    for (var j = 0; j < headers.length; j++) {
      // console.log(currentline[j]?.replace(/_/g, ","));
      obj[headers[j]] = currentline[j]?.replace(/_/g, ",");
    }

    result.push(obj);
  }

  //return result; //JavaScript object
  return JSON.stringify(result); //JSON
}

const postRentalBulk = async (e) => {
  e.preventDefault();
 
  if(excel){
  try {
    const config = {
      url: "/admin/bulkUploadPackageRental",
      method: "post",
      baseURL: "https://goeasycab.info/api",
      headers: { "content-type": "application/json",Authorization: `Bearer ${admin}` },
      data: {
        authId: admin1._id,
        data: exceldata?.filter((ele)=>ele.City).map((datas) => ({
          city: datas.City,
          PackageHr: Number(datas.TotalHour),
          PackageKm: Number(datas.TotalKm),
          packegePrice:Number(datas?.Package_Price),
          driverBata:Number(datas?.Driver_Allowance),
          price: Number(datas.TotalPrice),
        })),
      },

    };
    let res = await axios(config);
    if (res.status === 200) {
      console.log(res.data);
      alert("Successfully Uploaded");
      setexceldata([])
      setexcel()
    Allpackages()
      return res;
    }
    
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  
 }
}else {
  alert("Please import excel  file")
}
};
console.log("amit",exceldata.filter((ele)=>ele.City))


if (excel && exceldata.length === 0) {
  readFile();
}
  return (
    <div style={{ paddingLeft: "0px" }}>
       <Button
        style={{
          color: "white",
          backgroundColor: "#fdd12d",
          border: "#fdd12d",
        }}
      >
        {" "}
        Packages
      </Button>
  
      <br></br>
      <div style={{ backgroundColor: "#f0f0f0" }}>
        <div class="row" style={{ padding: "10px 20px" }}>
          <div class="col-sm-4">
            <div class="search-box  d-inline-block">
              <div class="position-relative" style={{ display: "flex" }}>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  placeholder="Search Packages"
                  onChange={handleFilter}
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                />
              </div>
            </div>
          </div>

          <div class="col-sm-8">
            <div class="text-sm-end">
            {dataSource?.filter((ele)=>ele?.isSold==false).length==0 ? (   <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "green",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                  margin: "0px 10px 0px 0px",
                }}
                onClick={() => {
                  allSoldOutAndUnsoldOut(false)
                }}
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                All Un-Sold-Out
              </button>):(   <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "red",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                  margin: "0px 10px 0px 0px",
                }}
                onClick={() => {
                  setAllsold(true)
                  handeleShowld();
                }}
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                All Sold-Out
              </button>)}{" "}
            <a
                 
                 href={
                   "https://goeasycab.info/RentalUpload.xls"
                 }
               >
             <button
               style={{
                 padding: "2px 8px 7px 8px",
                 color: "black",
                 backgroundColor: "#fdd12d",
                 border: "none",
                 fontWeight: "600",
                 fontSize: "14px",
                 borderRadius: "20px",
                 margin: "0px 10px 0px 0px",
               }}
               // onClick={() => {
               //   setEnterfilenameModal(true);
               // }}
             >
               <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
               Download Excel
             </button></a>
             <input
                 accept=".xlsx,.xls"
                 style={{
                   padding: "2px 8px 7px 8px",
                   color: "black",
                   backgroundColor: "#fdd12d",
                   border: "none",
                   fontWeight: "600",
                   fontSize: "14px",
                   borderRadius: "20px",
                   margin: "0px 10px 0px 0px",
                  display:"none"

                 }}
                 id="icon-button-file"
                 type="file"
                 onChange={(e) => setexcel(e.target.files[0])}
               />
               <label className="btn-c btn-orange " htmlFor="icon-button-file" style={{
                     padding: "7px 8px 7px 8px",
                     color: "black",
                     backgroundColor: "#fdd12d",
                     border: "none",
                     fontWeight: "600",
                     fontSize: "14px",
                     borderRadius: "20px",
                     margin: "0px 10px 0px 0px",
               }} >
                 {" "}
                 Import Excel
               </label>
             <button
               style={{
                 padding: "2px 8px 7px 8px",
                 color: "black",
                 backgroundColor: "#fdd12d",
                 border: "none",
                 fontWeight: "600",
                 fontSize: "14px",
                 borderRadius: "20px",
                 margin: "0px 10px 0px 0px",
               }}
               onClick={postRentalBulk}
             >
               <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
               Upload Excel
             </button>
            
      
            
              <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                  margin: "0px 10px 0px 0px",
                }}
                onClick={() => {
                  setEnterfilenameModal(true);
                }}
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                EXPORT TO EXCEL
              </button>
              <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                }}
                onClick={() => setShow1(true)}
              >
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  +
                </span>
                Packages
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <div class="react-bootstrap-table">
              <table
                class="table table align-middle table-nowrap"
                style={{ width: "90%", margin: "2%" }}
              >
                <thead
                  style={{
                    backgroundColor: "#fdd12d",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <tr style={{ padding: "10px" }}>
                    <th
                      tabindex="0"
                      aria-label="Username sortable"
                      class="sortable"
                    >
                      S.No
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      City
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Package Km
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Package Hour
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Extra Hour Limit
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Allowance
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Package Price
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Total Price
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Sold Start Date
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Sold End Date
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Action
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {search.length > 0 ? (
                    <>
                      {tableFilter
                        ?.slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((data, index) => {
                          return (
                            <tr style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td>{data?.city}</td>
                            <td>{data.PackageKm}</td>
                            <td>{data.PackageHr}</td>
                            {/* <td>{data.extraHrLimit}</td>
                            <td>{data.extraHrLimetPrice}</td> */}
                             <td>{data?.driverBata}</td>
                            <td>{data?.packegePrice}</td>
                            <td>{data.price}</td>
                            <td>{data?.from ? (moment(data?.from).format("DD/MM/YYYY")):(<></>)}
                              </td>
                              <td>{data?.to ? (moment(data?.to).format("DD/MM/YYYY")):(<></>)}
                              </td>
                            {/* <td>{data.to}</td> */}
                            <td style={{display:"flex",gap:"2px"}}> 
                            <div>
                            {data?.isSold==true ? (
                                <button type="button" class="btn btn-success" onClick={()=>UnsoldOutRental(data?._id)}>
                                  UnSoldOut
                                </button>
                              ) : (
                                <button type="button" class="btn btn-danger" onClick={()=>{
                                  setsoldid(data?._id);
                                  setAllsold(false)
                                  handeleShowld()
                                }}>
                                  SoldOut
                                </button>
                              )}{" "}
                            </div>
                            
                              <Button
                                type="button"
                                variant="success"
                                className="mx-2"
                                onClick={() =>{ 
                                  setprice(data?.packegePrice);
                                  setdriverBata(data?.driverBata);
                                  openEditor(data)}}
                              >
                                <span>
                                  <RiEditLine />
                                </span>
                              </Button>
                              <Button
                                type="button"
                                variant="danger"
                                onClick={() => {
                                  setdeleteId(data?._id);
                                  setshowdelete(true);
                                }}
                              >
                                <span>
                                  <RiDeleteBin6Line />
                                </span>
                              </Button>
                            </td>
                          </tr>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {dataSource
                        ?.slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((data, index) => {
                          return (
                            <tr style={{ textAlign: "center" }}>
                              <td>{index + 1}</td>
                              <td>{data?.city}</td>
                              <td>{data.PackageKm}</td>
                              <td>{data.PackageHr}</td>
                              {/* <td>{data.extraHrLimit}</td>
                              <td>{data.extraHrLimetPrice}</td> */}
                                <td>{data?.driverBata}</td>
                                <td>{data?.packegePrice}</td>
                              <td>{data.price}</td>
                              <td>{data?.from ? (moment(data?.from).format("DD/MM/YYYY")):(<></>)}
                                </td>
                                <td>{data?.to ? (moment(data?.to).format("DD/MM/YYYY")):(<></>)}
                                </td>
                              {/* <td>{data.to}</td> */}
                              <td style={{display:"flex",gap:"2px"}}> 
                              <div>
                              {data?.isSold==true ? (
                                  <button type="button" class="btn btn-success" onClick={()=>UnsoldOutRental(data?._id)}>
                                    UnSoldOut
                                  </button>
                                ) : (
                                  <button type="button" class="btn btn-danger" onClick={()=>{
                                    setsoldid(data?._id);
                                    setAllsold(false)
                                    handeleShowld()
                                  }}>
                                    SoldOut
                                  </button>
                                )}{" "}
                              </div>
                              
                                <Button
                                  type="button"
                                  variant="success"
                                  className="mx-2"
                                  onClick={() => {
                                    setprice(data?.packegePrice);
                                    setdriverBata(data?.driverBata);
                                    openEditor(data)}}
                                >
                                  <span>
                                    <RiEditLine />
                                  </span>
                                </Button>
                                <Button
                                  type="button"
                                  variant="danger"
                                  onClick={() => {
                                    setdeleteId(data?._id);
                                    setshowdelete(true);
                                  }}
                                >
                                  <span>
                                    <RiDeleteBin6Line />
                                  </span>
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal show={show1} onHide={handleClose1} animation={true} size="lg">
        <Modal.Header style={{ color: "black" }}>Add Packages</Modal.Header>
        <Modal.Body>
          <div style={{ margin: "1% 2%" }}>
            <label
              style={{
                textAlign: "left",
                paddingBottom: "3px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              City
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              onChange={(e) => setcity(e.target.value)}
            >
              <option>Select City</option>
              {allCity?.map((ele) => {
                return <option value={ele?.cit}>{ele?.city}</option>;
              })}
            </Form.Select>
            <label
              style={{
                textAlign: "left",
                paddingBottom: "3px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Package Km
            </label>
            <br />
            <textarea
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder="40"
              //   value={basePrice}
              onChange={(e) => {
                setPackageKm(e.target.value);
              }}
            />

            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Package Hour
            </label>
            <br />
            <textarea
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder="4"
              //   value={basePrice}
              onChange={(e) => {
                setPackageHr(e.target.value);
              }}
            />
            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Driver Allowance
            </label>
            <br />
            <textarea
              placeholder="1"
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
                value={driverBata}
              onChange={(e) => {
                setdriverBata(e.target.value);
              }}
            />
            {/* <br />

            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Extra Hour Charge
            </label>
            <br />
            <textarea
              placeholder="60"
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              //   value={basePrice}
              onChange={(e) => {
                setextraHrLimetPrice(e.target.value);
              }}
            /> */}
            <br />

            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Package Price
            </label>
            <br />
            <textarea
              placeholder="1000"
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              //   value={basePrice}
              onChange={(e) => {
                setprice(e.target.value);
              }}
            />
           <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Total Price: - {Number(price )+Number(driverBata)}
            </label>
            <br/>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ justifyContent: "space-between", padding: "10px 20px" }}
        >
          <Button
            style={{
              backgroundColor: "#ffcc33",
              border: "none",
              width: "120px",
            }}
            onClick={handleClose1}
          >
            CANCEL
          </Button>

          <Button
            style={{
              backgroundColor: "#fdd12d",
              border: "none",
              width: "180px",
            }}
            onClick={Addpackages}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={editorPopup} onHide={CloseEditor}>
        <Modal.Header closeButton>
          <h4>Edit Packages</h4>
        </Modal.Header>
        <Modal.Body>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}>City: </lable>{" "}
            {!iscity ? (
              <>
                <span className="edit-hov" style={{}}>
                  {editorShow.city}{" "}
                  <i
                    class="fa fa-pencil"
                    style={{ color: "rgb(119 118 118)", cursor: "pointer" }}
                    aria-hidden="true"
                    onClick={() => setisCity(true)}
                    onMouseEnter={() => setisCity1(true)}
                    onMouseLeave={(e) => {
                      setisCity1(false);
                    }}
                  ></i>
                </span>{" "}
                {iscity1 ? (
                  <span style={{ color: "#dc3545", fontSize: "15px" }}>
                    Edit
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span>
                  <Form.Select
                    aria-label="Default select example"
                    style={{
                      padding: "10px",
                      width: "100%",
                      border: "1px solid #fdd12d",
                      height: "50px",
                      borderRadius: "20px",
                    }}
                    onChange={(e) => setcity(e.target.value)}
                  >
                    <option>Select City</option>
                    {allCity?.map((ele) => {
                      return <option value={ele?.cit}>{ele?.city}</option>;
                    })}
                  </Form.Select>
                </span>
              </>
            )}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}>Package Km: </lable>{" "}
            {!editvehiclename ? (
              <>
                <span className="edit-hov" style={{}}>
                  {editorShow.PackageKm}{" "}
                  <i
                    class="fa fa-pencil"
                    style={{ color: "rgb(119 118 118)", cursor: "pointer" }}
                    aria-hidden="true"
                    onClick={() => seteditvehiclename(true)}
                    onMouseEnter={() => setIsShowneditvehiclename(true)}
                    onMouseLeave={(e) => {
                      setIsShowneditvehiclename(false);
                    }}
                  ></i>
                </span>{" "}
                {isShowneditvehiclename ? (
                  <span style={{ color: "#dc3545", fontSize: "15px" }}>
                    Edit
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span>
                  <input
                    type="text"
                    style={{
                      padding: "10px",
                      width: "100%",
                      border: "1px solid #fdd12d",
                      height: "50px",
                      borderRadius: "20px",
                    }}
                    placeholder={editorShow.PackageKm}
                    onChange={(e) => setPackageKm(e.target.value)}
                  />
                </span>
              </>
            )}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}> Package Hour: </lable>{" "}
            {!editvehicletype ? (
              <>
                <span className="edit-hov" style={{ cursor: "pointer" }}>
                  {editorShow.PackageHr}{" "}
                  <i
                    class="fa fa-pencil"
                    style={{ color: "rgb(119 118 118)" }}
                    aria-hidden="true"
                    onClick={() => seteditvehicletype(true)}
                    onMouseEnter={() => setIsShownvehicletype(true)}
                    onMouseLeave={(e) => {
                      setIsShownvehicletype(false);
                    }}
                  ></i>
                </span>{" "}
                {isShownvehicletype ? (
                  <span style={{ color: "#dc3545", fontSize: "15px" }}>
                    Edit
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span>
                  <textarea
                    placeholder={editorShow.PackageHr}
                    style={{
                      padding: "10px",
                      width: "100%",
                      border: "1px solid #fdd12d",
                      height: "50px",
                      borderRadius: "20px",
                    }}
                    onChange={(e) => setPackageHr(e.target.value)}
                  />
                </span>
              </>
            )}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}> Driver Allowance : </lable>{" "}
            {!editbaseprice ? (
              <>
                <span className="edit-hov" style={{ cursor: "pointer" }}>
                  {editorShow.driverBata}{" "}
                  <i
                    class="fa fa-pencil"
                    style={{ color: "rgb(119 118 118)" }}
                    aria-hidden="true"
                    onClick={() => seteditbaseprice(true)}
                    onMouseEnter={() => setisbaseprice(true)}
                    onMouseLeave={(e) => {
                      setisbaseprice(false);
                    }}
                  ></i>
                </span>{" "}
                {isbaseprice ? (
                  <span style={{ color: "#dc3545", fontSize: "15px" }}>
                    Edit
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span>
                  <input
                    type="number"
                    placeholder={editorShow.driverBata}
                    style={{
                      padding: "10px",
                      width: "100%",
                      border: "1px solid #fdd12d",
                      height: "50px",
                      borderRadius: "20px",
                    }}
                    onChange={(e) => setdriverBata(e.target.value)}
                  />
                </span>
              </>
            )}
          </div>
     
          {/* <div>
            <lable style={{ fontWeight: "bold" }}> Extra Hour Charge : </lable>{" "}
            {!editextraHrLimit ? (
              <>
                <span className="edit-hov" style={{ cursor: "pointer" }}>
                  {editorShow.extraHrLimetPrice}{" "}
                  <i
                    class="fa fa-pencil"
                    style={{ color: "rgb(119 118 118)" }}
                    aria-hidden="true"
                    onClick={() => seteditextraHrLimit(true)}
                    onMouseEnter={() => setIsShownextraHrLimit(true)}
                    onMouseLeave={(e) => {
                      setIsShownextraHrLimit(false);
                    }}
                  ></i>
                </span>{" "}
                {isShownextraHrLimit ? (
                  <span style={{ color: "#dc3545", fontSize: "15px" }}>
                    Edit
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span>
                  <input
                    placeholder={editorShow.extraHrLimit}
                    style={{
                      padding: "10px",
                      width: "100%",
                      border: "1px solid #fdd12d",
                      height: "50px",
                      borderRadius: "20px",
                    }}
                    onChange={(e) => setextraHrLimetPrice(e.target.value)}
                  />
                </span>
              </>
            )}
          </div> */}
        <br/>
          <div>
            <lable style={{ fontWeight: "bold" }}>Package Price: </lable>{" "}
            {!editluagge ? (
              <>
                <span className="edit-hov" style={{ cursor: "pointer" }}>
                  {editorShow.packegePrice}{" "}
                  <i
                    class="fa fa-pencil"
                    style={{ color: "rgb(119 118 118)" }}
                    aria-hidden="true"
                    onClick={() => seteditluagge(true)}
                    onMouseEnter={() => setisshownluagge(true)}
                    onMouseLeave={(e) => {
                      setisshownluagge(false);
                    }}
                  ></i>
                </span>{" "}
                {isshownluagge ? (
                  <span style={{ color: "#dc3545", fontSize: "15px" }}>
                    Edit
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span>
                  <textarea
                    placeholder={editorShow.packegePrice}
                    style={{
                      padding: "10px",
                      width: "100%",
                      border: "1px solid #fdd12d",
                      height: "50px",
                      borderRadius: "20px",
                    }}
                    onChange={(e) => setprice(e.target.value)}
                  />
                </span>
              </>
            )}
            <br/> <br/>
            Total Price :- {Number(price)+Number(driverBata)}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="commonupload text-black"
            variant="primary"
            onClick={() => Updatepackages(editorShow)}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={EnterfilenameModal}
        onHide={() => setEnterfilenameModal(false)}
        style={{ width: "320px", marginLeft: "40%", marginTop: "2%" }}
      >
        <Modal.Body>
          <div>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Enter Excel file name ....."
                style={{
                  padding: "10px",
                  width: "82%",
                  border: "1px solid #fdd12d",
                }}
                value={fileName}
                onChange={(e) => {
                  setfileName(e.target.value);
                }}
              />
              <ImFolderDownload
                style={{
                  width: "18%",
                  color: "white",
                  backgroundColor: "#fdd12d",
                  paddingTop: "15px",
                  height: "50px",
                  paddingBottom: "15px",
                }}
                onClick={() => ExportToExcel()}
              />
            </div>
            {/* <h1 style={{ textAlign: "center", color: "#ffcc33" }}>{Count}</h1> */}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showdelete} onHide={handleClose4}>
        <Modal.Header closeButton>Are you sure delete ?</Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={() => setshowdelete(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={Removepackages}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {/* sold out */}
      <Modal
        show={showSold}
        onHide={handleCloseShold}
        animation={true}
        size="lg"
      >
        <Modal.Header style={{ color: "black" }}>Add sold out date</Modal.Header>
        <Modal.Body>
          <div style={{ margin: "1% 2%" }}>
            <label
              style={{
                textAlign: "left",
                paddingBottom: "3px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Start Date
            </label>
            <br />
            <input
              type="date"
              min={Date.now()}
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder="40"
              //   value={basePrice}
              onChange={(e) => {
                setfrom(e.target.value);
              }}
            />

            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              End Date
            </label>
            <br />
            <input
              type="date"
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder="4"
              //   value={basePrice}
              onChange={(e) => {
                setto(e.target.value);
              }}
            />
            <br />
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ justifyContent: "space-between", padding: "10px 20px" }}
        >
          <Button
            style={{
              backgroundColor: "#ffcc33",
              border: "none",
              width: "120px",
            }}
            onClick={handleCloseShold}
          >
            CANCEL
          </Button>
            {allSold==true ? ( <Button
            style={{
              backgroundColor: "#fdd12d",
              border: "none",
              width: "180px",
            }}
            onClick={()=>allSoldOutAndUnsoldOut(true)}
          >
          All Sold Now
          </Button>):( <Button
            style={{
              backgroundColor: "#fdd12d",
              border: "none",
              width: "180px",
            }}
            onClick={soldOutRental}
          >
            Sold Now
          </Button>)}
         
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Packages;
