import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useNavigate, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
function Invoice() {
  const { id } = useParams();
  const [edit, setedit] = useState(false);
  const [bookDetails, setbookDetails] = useState({});
  const getBookDetailsById = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getBookingDetailsById/" + id
      );
      if (res.status == 200) {
        setbookDetails(res.data?.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const history=useNavigate()
  const [gst, setgst] = useState({});
  const getgst = async () => {
    try {
      let res = await axios.get("https://goeasycab.info/api/admin/getgst");
      if (res.status == 200) {
        setgst(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getgst()
    if (id) {
      getBookDetailsById();
    }
  }, []);
  // const createPDF = async () => {
  //   const pdf = new jsPDF("portrait", "pt", "a4");
  //   const data = await html2canvas(document.querySelector("#pdf"), {
  //     useCORS: true,
  //   });
  //   let img = data.toDataURL("image/png");
  //   const imgProperties = pdf.getImageProperties(img);
  //   const pdfWidth = 630;
  //   const pdfHeight = (150 * pdfWidth) / imgProperties.width;
  //   pdf.addImage(img, "PNG", 0, 0, 590, 800);
  //   pdf.save(`bookdetails.pdf`);
  // };


  const createPDF =async () => {
    const input = document.getElementById('pdf');
    const options = { scrollY: -window.scrollY };
    const canvas = await html2canvas(input, options);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
    let am= pdf.save('bookingDetails.pdf')

    pdf.save('bookingDetails.pdf');
  };

  return (
    <>
      <div className="invo">
        <div className="container" style={{ marginTop: "80px" }}>
          <div
            className="05_in"
           
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="inv_0"
              id="pdf"
              style={{ width: "40%", textAlign: "center" }}
            >
              <Table striped bordered hover >
                <thead>
                  <tr>
                    <th colSpan={2}>
                      <h3 style={{ fontSize: "28px", fontWeight: "700" }}>
                        GoEasyDoCab.com
                        <br />
                        Booking Confirmation
                      </h3>
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={2}>
                      <h3></h3>
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={2}>
                      <h3></h3>
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={2}>
                      <div className="advv">
                        <h1 style={{ fontSize: "28px", fontWeight: "700" }}>
                          Dear {bookDetails?.userName}
                        </h1>
                        <div>
                          Your Booking for {bookDetails?.picUpPlace} to{" "}
                          {bookDetails?.dropPlace} has been{" "}
                          {bookDetails?.status} . Please refer below
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h6>Booking Id</h6>
                    </td>
                    <td>
                      <b>{bookDetails?.bookid}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Booking Date and time</h6>
                    </td>
                    <td>
                      {bookDetails?.picUpDate} ,{" "}
                      {moment(bookDetails?.picUpTime, ["h:mm a"]).format(
                        "hh:mm a"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Trip Type</h6>
                    </td>
                    <td>{bookDetails?.tripType}</td>
                  </tr>
                  {bookDetails?.tripType =="Local" || bookDetails?.tripType=="Airport" ? ( <tr>
                    <td>
                      <h6>City</h6>
                    </td>
                    <td>{bookDetails?.city}</td>
                  </tr>):(<></>)}
                  <tr>
                    <td>
                      <h6>From</h6>
                    </td>
                    <td>{bookDetails?.picUpPlace}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>To</h6>
                    </td>
                    <td>{bookDetails?.dropPlace}</td>
                  </tr>
                  {bookDetails?.tripType == "Round Trip" || bookDetails.tripType=="oneway" ? (<>
                    <tr>
                    <td>
                      <h6>PickUp Place</h6>
                    </td>
                    <td>{bookDetails?.picPlace}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Drop Place</h6>
                    </td>
                    <td>{bookDetails?.DropLocation}</td>
                  </tr>
                  </>):(<></>) }
                 
                  {bookDetails?.tripType == "Round Trip" ? (
                    <tr>
                      <td>
                        <h6>Return Date and time</h6>
                      </td>
                      <td>
                        {bookDetails?.returnDate} ,
                        {moment(bookDetails?.returnTime, ["h:mm a"]).format(
                          "hh:mm a"
                        )}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                   <tr>
                    <td>
                      <h6>GST Amount</h6>
                    </td>
                    <td>
                    Rs.{bookDetails?.gst}
                    </td>
                   
                  </tr>
                  <tr>
                  <td>
                      <h6>Discount</h6>
                    </td>
                    <td>
                    Rs.{bookDetails?.discount?.toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                  <td>
                      <h6>Parking Charge</h6>
                    </td>
                    <td>
                    Rs.{bookDetails?.parkingCharge?.toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Bill Amount</h6>
                    </td>
                    <td>Rs.{bookDetails?.totalAmount}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>PayId</h6>
                    </td>
                    <td>{bookDetails?.payId}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Amount Advance</h6>
                    </td>
                    <td>Rs. {bookDetails?.payAmount?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Pay Mode</h6>
                    </td>
                    <td> {bookDetails?.paymentMethod}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Remaining Amount</h6>
                    </td>
                    <td> Rs. {(Number(bookDetails?.totalAmount)-Number(bookDetails?.payAmount))?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Payment</h6>
                    </td>
                    <td> {bookDetails?.PaymetStatus}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>FARE Inclusions</h6>
                      {/* <p style={{fontSize:"15px"}}>Night charges?(10pm to 6am),344km state tax,to all charges,driver allowance,only pickup and drop</p> */}
                      {bookDetails?.tripType == "Local" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          {/* <li style={{ fontSize: "15px" }}>
                            Night charges ({bookDetails?.vechicleId?.nightTime})
                          </li> */}
                          <li style={{ fontSize: "15px" }}>
                            {bookDetails?.totalKm} Kms  (booked package) 
                          </li>
                          <li style={{ fontSize: "15px" }}>Driver Allowance</li>
                          {/* <li style={{ fontSize: "15px" }}>
                            Only One Pickup and Drop
                          </li> */}
                        </ul>
                      ) : (
                        <></>
                      )}
                      {bookDetails?.tripType == "oneway" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          <li style={{ fontSize: "15px" }}>
                            Night charges ({bookDetails?.vechicleId?.nightTime})
                          </li>
                          <li style={{ fontSize: "15px" }}>
                          Parking Charges
                          </li>
                          <li style={{ fontSize: "15px" }}>Toll Charges</li>
                          <li style={{ fontSize: "15px" }}>State Tax</li>
                          <li style={{ fontSize: "15px" }}>
                            {bookDetails?.totalKm} Kms
                          </li>
                          <li>Driver Allowance</li>
                          <li style={{ fontSize: "15px" }}>
                            Only One Pickup and Drop
                          </li>
                        </ul>
                      ) : (
                        <></>
                      )}
                      {bookDetails?.tripType == "Round Trip" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          <li style={{ fontSize: "15px" }}>
                            {bookDetails?.totalKm} Kms
                          </li>
                          <li style={{ fontSize: "15px" }}>Driver Allowance</li>
                        </ul>
                      ) : (
                        <></>
                      )}
                      {bookDetails?.tripType == "Airport" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          <li style={{ fontSize: "15px" }}>
                            Night charges ({bookDetails?.vechicleId?.nightTime})
                          </li>
                          <li style={{ fontSize: "15px" }}>Toll Charges</li>
                          <li style={{ fontSize: "15px" }}>State Tax</li>
                          <li style={{ fontSize: "15px" }}>Airport entry charges</li>
                          <li style={{ fontSize: "15px" }}>
                            {bookDetails?.totalKm} Kms
                          </li>
                          <li style={{ fontSize: "15px" }}>Driver Allowance</li>
                          <li style={{ fontSize: "15px" }}>
                            Only One Pickup and Drop
                          </li>
                        </ul>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      <h6>FARE Exclusions</h6>
                      {/* <p style={{fontSize:"15px"}}>EXTRA Kms Rs. 15/km</p> */}
                      {bookDetails?.tripType == "Local" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          <li style={{ fontSize: "15px" }}>
                            Toll Charges As applicable
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            State Tax As applicable
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Waiting Charges After {bookDetails?.waitingTime} , ₹{" "}
                            {bookDetails?.waitingCharge}
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Fare beyond {bookDetails?.totalKm} Kms ₹{" "}
                            {bookDetails?.extraKmPrice}/Km
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Fare beyond {bookDetails?.beyondHour} Hrs ₹{" "}
                            {bookDetails?.extraHrPrice}/Minutes
                           
                          </li>
                        </ul>
                      ) : (
                        <></>
                      )}
                      {bookDetails.tripType == "oneway" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          <li style={{ fontSize: "15px" }}>
                            Waiting Charges After {bookDetails?.waitingTime} ₹{" "}
                            {bookDetails?.waitingCharge}
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Fare beyond {bookDetails?.totalKm} Kms ₹{" "}
                            {bookDetails?.extraKmPrice}/Km
                          </li>
                        </ul>
                      ) : (
                        <></>
                      )}
                      {bookDetails.tripType == "Round Trip" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          <li style={{ fontSize: "15px" }}>
                            Night charges({bookDetails?.vechicleId?.nightTime})
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Toll Charges As applicable
                          </li>
                          <li style={{ fontSize: "15px" }}>
                          Parking Charges As applicable
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            State Tax As applicable
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Waiting Charges After {bookDetails?.waitingTime} ₹{" "}
                            {bookDetails?.waitingCharge}
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Fare beyond {bookDetails?.totalKm} Kms ₹{" "}
                            {bookDetails?.extraKmPrice}/Km
                          </li>
                        </ul>
                      ) : (
                        <></>
                      )}
                      {bookDetails.tripType == "Airport" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          <li style={{ fontSize: "15px" }}>
                            Waiting Charges After {bookDetails?.waitingTime} ₹{" "}
                            {bookDetails?.waitingCharge}
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Fare beyond {bookDetails?.totalKm} Kms ₹{" "}
                            {bookDetails?.extraKmPrice}/Km
                          </li>
                        </ul>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={2}>
                      <a href="https://goeasydocab.com">goeasydocab.com</a>
                      <h6>
                        Reach us on +919538880063 or{" "}
                        <span>
                          <a href="">Support@goeasydocab.com</a>
                        </span>
                      </h6>
                    </th>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="d-flex" style={{margin:"0% 0% 2% 33%",gap:"5px"}}>
        <button type="button" class="btn btn-danger" onClick={()=>history(-1)}>Back</button>

        <button type="button" class="btn btn-warning" onClick={createPDF}>Download</button>


        </div>
      </div>
    </>
  );
}

export default Invoice;
