import axios from "axios";
import React, { useEffect, useState } from "react";
import "../Admin/Admin.css";
import Modal from "react-bootstrap/Modal";
import exportFromJSON from "export-from-json";
import { ImFolderDownload } from "react-icons/im";
import { AiOutlineCar } from "react-icons/ai";
import { BsEyeFill, BsFillRocketTakeoffFill } from "react-icons/bs";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { BsCheckCircleFill } from "react-icons/bs";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Bookingdetails() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));

  const [dataSource2, setdataSource2] = useState([]);
  useEffect(() => {
    getBooking();
  }, []);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(dataSource2.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const history = useNavigate();
  const [nochangedata, setnochangedata] = useState([]);
  const getBooking = async () => {
    const config = {
      url: "/admin/getallOrder/" + admin1?._id,
      method: "get",
      baseURL: "https://goeasycab.info/api",
      hearder: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setdataSource2(result.data.success);
        setdata(result.data.success);
        setnochangedata(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fillterdata = (abc) => {
    if (abc == "Upcoming") {
      const alldata = nochangedata?.filter((ele) => ele?.isUpcome == true);
      setdata(alldata);
      setdataSource2(alldata);
    } else if (abc == "Accepted") {
      const alldata = nochangedata?.filter(
        (ele) => ele?.driverStatus == "Accepted"
      );
      setdata(alldata);
      setdataSource2(alldata);
    } else {
      const alldata = nochangedata?.filter((ele) => ele?.status == abc);
      setdata(alldata);
      setdataSource2(alldata);
    }
  };
  const [dataSource1, setdataSource1] = useState([]);

  // console.log("dataSource1", dataSource2);
  const [nochanDrive, setNocahgeD] = useState([]);
  const Alldrivers = async () => {
    try {
      const config = {
        url: "/driver/getAllDriverAndVehicle",
        method: "get",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setdataSource1(res.data.success);
        setNocahgeD(res.data.success);
      }
    } catch (error) {
      console.log("errror==>", error);
    }
  };
  let [AssignD, setAssignD] = useState([]);

  const getAllassignList = async () => {
    try {
      const config = {
        url: "/admin/getAllassignList/" + admin1?._id,
        method: "get",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setAssignD(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(AssignD);
  useEffect(() => {
    Alldrivers();
    getAllassignList();
  }, []);
  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = dataSource2.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setdataSource2([...dataSource2]);
    }
  };
  // Export to Excel dataSource1

  const abcHandel = (e) => {
    if (e.target.value != "") {
      const filterTable = nochanDrive.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setdataSource1([...filterTable]);
    } else {
      setdataSource1([...nochanDrive]);
    }
  };
  const [data, setdata] = useState();
  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [EnterfilenameModal1, setEnterfilenameModal1] = useState(false);
  const [Enterfile, setEnterfile] = useState({});
  const [fileName, setfileName] = useState();

  // console.log("Enterfile=====>", Enterfile);

  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
    } else {
      alert("Enter file name to export");
    }
  };
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [deleteId, setdeleteId] = useState("");

  const [vehicleNum, setvehicleNum] = useState("");
  const [AnotherAss, setAnotherAss] = useState(false);
  const AssignDriver = async (data1) => {
    //  console.log("dfs====>",data._id);
    try {
      const config = {
        url: "/admin/driverAssignBook",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          bookId: Enterfile._id,
          driverId: data1._id,
          driverName: data1.driverName,
          driverMobile: data1.dMobile,
          driverProfile: data1.dProfileImg,
          driverStatus: "Assigned",
          authId: admin1._id,
          vehicleNum: vehicleNum ? vehicleNum : data1?.dVehicleRegNum,
          category: Enterfile?.vechicleId?.category,
          AnotherAss: AnotherAss,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          getAllassignList();
          getBooking();
          setvehicleNum("");
        }
      });
    } catch (error) {
      console.log(error.res);
      alert(error.response.data.error);
    }
  };
  // console.log("Enterfile?.vechicleId",Enterfile?.vechicleId);
  const [fromdate, setfromdate] = useState("");
  const [ttodate, setttodate] = useState("");
  const searchdate = () => {
    try {
      if (!fromdate) return alert("From date required");
      if (!ttodate) return alert("To date required");

      let std = fromdate?.split("-");
      let entd = ttodate?.split("-");
      let startD = parseInt(std?.join(""));
      let endD = parseInt(entd?.join(""));
      let abc = nochangedata?.filter((ele) => {
        // console.log("amit check it",moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
        let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
        let amd = parseInt(abd?.join(""));
        return startD <= amd && endD >= amd;
      });
      setdataSource2(abc);
      setdata(abc);
    } catch (error) {
      console.log(error);
    }
  };
  const [flasbitAmount, setflashbidAmount] = useState("");
  const [allCheck, setallcheck] = useState(false);
  const [payReq, setPayReq] = useState(false);
  function getTimeDifference(time1, time2) {
    // Parse the time strings into Date objects with a common date.
    const date = new Date("2000-01-01");
    const date1 = new Date(date.toDateString() + " " + time1);
    const date2 = new Date(date.toDateString() + " " + time2);

    // Calculate the time difference in milliseconds.
    const timeDifference = date2 - date1;

    // Convert the time difference to hours and minutes.
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);

    return hours * 60 + minutes;
  }

  function checkBook(data1) {
    let am = ListOfAssgined?.filter((ele) => {
      const pickupTime = ele?.bookId?.picUpTime;
      let pickupDate = ele?.bookId?.picUpDate;
      if (
        ele?.driverId?._id === data1?._id &&
        Enterfile?.picUpDate == pickupDate
      ) {
        if ( getTimeDifference(Enterfile?.picUpTime,pickupTime) <= 60) {
          return (
            ele?.status !== "Complete" &&
            ele?.bookId?.status !== "Cancel" &&
            ele?.status !== "Reject"
          );
        }
      }
    });
    if (am?.length == 0) return true;
    return false;
  }

  const flashBitAssign = async (data1) => {
    try {
      if (!flasbitAmount) return alert("Please enter amount");
      if (
        Number(Enterfile?.totalAmount) < Number(flasbitAmount) ||
        Number(Enterfile?.totalAmount) == Number(flasbitAmount)
      )
        return alert("Flash bid amount should be less then booking Amount");
      let array = dataSource1.filter(
        (item) => item.isDBlocked === false && item?.status == "Approved"
      );

      if (allCheck == true) {
        array = dataSource1?.filter((ele) => {
          if (checkBook(ele)) {
            return ele;
          }
        });
        for (let i = 0; i < array.length; i++) {
          const config = {
            url: "/admin/FlashBitdriverAssignBook",
            method: "put",
            baseURL: "https://goeasycab.info/api",
            headers: { "content-type": "application/json" },
            headers: { Authorization: `Bearer ${admin}` },
            data: {
              bookId: Enterfile._id,
              driverId: array[i]._id,
              driverName: array[i].driverName,
              driverMobile: array[i].dMobile,
              driverProfile: array[i].dProfileImg,
              driverStatus: "Assigned",
              authId: admin1._id,
              Amount: flasbitAmount,
              flashBid: "Flash bid",
              vehicleNum: array[i].dVehicleRegNum,
              PayReq: payReq,
              category: Enterfile?.vechicleId?.category,
            },
          };
          await axios(config);
        }

        getAllassignList();
        getAsseniedData();
        getBooking();
        setallcheck(false);
        alert("Successfully send notification to driver");
        return;
      } else {
        const config = {
          url: "/admin/FlashBitdriverAssignBook",
          method: "put",
          baseURL: "https://goeasycab.info/api",
          headers: { "content-type": "application/json" },
          headers: { Authorization: `Bearer ${admin}` },
          data: {
            bookId: Enterfile._id,
            driverId: data1._id,
            driverName: data1.driverName,
            driverMobile: data1.dMobile,
            driverProfile: data1.dProfileImg,
            driverStatus: "Assigned",
            authId: admin1._id,
            Amount: flasbitAmount,
            flashBid: "Flash bid",
            vehicleNum: data1.dVehicleRegNum,
            PayReq: payReq,
            category: Enterfile?.vechicleId?.category,
          },
        };
        await axios(config).then(function (res) {
          if (res.status === 200) {
            getAllassignList();
            getAsseniedData();
            getBooking();
            alert("Successfully send notification to driver");
            return;
          }
        });
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const deleteAssginDriver = async (data1, Another) => {
    try {
      const config = {
        url: "/admin/DeleteAssignBook",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          bookId: Enterfile._id,
          driverId: data1._id,
          driverName: data1.driverName,
          driverMobile: data1.dMobile,
          driverProfile: data1.dProfileImg,
          driverStatus: "Assigned",
          authId: admin1._id,
          Another: Another,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          getAllassignList();
          getAsseniedData();
          getBooking();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const [showList, setshowList] = useState(false);

  const handeleShowList = () => setshowList(true);
  const closeShowList = () => setshowList(false);
  const [ListOfAssgined, setListOfAssigne] = useState([]);
  const [Dno, setDno] = useState([]);
  const getAsseniedData = async () => {
    try {
      const config = {
        url: "/admin/getAllassignListAcceptedDriver/" + admin1?._id,
        method: "get",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setListOfAssigne(res.data.success);
        setDno(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAsseniedData();
  }, []);
  const makeCancelTrip = async (id) => {
    try {
      const config = {
        url: "/admin/AdminCancelTrip/" + id + "/" + admin1?._id,
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully cancel Trip");
        closeShowList();
        getBooking();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [cancelId, setcancelId] = useState("");
  const bookCancelAdmin = async (id) => {
    try {
      const config = {
        url: "/admin/bookCancelAdmin",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: {
          Authorization: `Bearer ${admin}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin1?._id,
          bookId: cancelId,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully cancel Trip");
        handleClose1();
        getBooking();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  // console.log("list am",ListOfAssgined?.filter((ele)=>ele?.bookId?.picUpDate==Enterfile?.picUpDate&&ele?.status!=="Complete"));
  return (
    <div style={{ paddingLeft: "0px" }}>
      <Button
        style={{
          color: "white",
          backgroundColor: "#fdd12d",
          border: "#fdd12d",
        }}
      >
        {" "}
        Bookings details
      </Button>

      <br></br>
      <div className="accountt">
        <form>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-md-2">
              <div className="jjr mb-2">
                <label>Date From</label>
                <br />
                <input
                  type="date"
                  className="new-cab"
                  onChange={(e) => setfromdate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="jjr mb-2">
                <label>Date To</label>
                <br />
                <input
                  type="date"
                  className="new-cab"
                  onChange={(e) => setttodate(e.target.value)}
                />
              </div>
            </div>

            <div className="col-md-2">
              <button
                type="button"
                class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
                onClick={() => searchdate()}
              >
                <span>Search</span>
              </button>
            </div>
            <div className="col-md-2">
              <button
                type="button"
                class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
                onClick={() => window.location.assign("/admin/Flash-bid-list")}
              >
                <span>Assigned Driver</span>
              </button>
            </div>
            {/* <div className="col-md-2">
              <button
                type="button"
                class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
                // onClick={()=>searchdate()}
              >
                <span>flashBid Driver</span>
              </button>
            </div> */}
            <div className="col-md-2">
              <button
                type="button"
                onClick={() => window.location.assign("/admin/trip-list")}
                class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
              >
                {/* <a href="/admin/trip-list" style={{ color: "white" }}> */}
                <span>TripList</span>
                {/* </a> */}
              </button>
            </div>
            <div className="col-md-2">
              <Form.Select
                aria-label="Default select example"
                style={{ width: "auto" }}
                onChange={(e) => fillterdata(e.target.value)}
              >
                <option>Select menu</option>
                <option value="Upcoming">Upcoming</option>
                <option value="Accepted">Accepted</option>
                <option value="Trip Start">Ongoing</option>
                <option value="Complete">Completed</option>
                <option value="Cancel">Cancelled</option>
              </Form.Select>
            </div>
          </div>
        </form>
      </div>
      <br />
      <div style={{ backgroundColor: "#f0f0f0" }}>
        <div class="row" style={{ padding: "10px 20px" }}>
          <div class="col-sm-4">
            <div class="search-box  d-inline-block">
              <div class="position-relative" style={{ display: "flex" }}>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  onChange={handleFilter}
                  placeholder="Search Bookings details"
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                />
              </div>
            </div>
          </div>

          <div class="col-sm-8">
            <div class="text-sm-end">
              {" "}
              <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                  margin: "0px 10px 0px 0px",
                }}
                onClick={() => {
                  setEnterfilenameModal(true);
                }}
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                EXPORT TO EXCEL
              </button>
            </div>
          </div>

          <div class="table-responsive">
            <div class="react-bootstrap-table">
              <table
                class="table table align-middle table-nowrap"
                style={{ width: "90%", margin: "2%" }}
              >
                <thead
                  style={{
                    backgroundColor: "#fdd12d",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <tr style={{ padding: "10px" }}>
                    <th
                      tabindex="0"
                      aria-label="Username sortable"
                      class="sortable"
                    >
                      S.No
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Booking ID
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Name
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>

                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Number
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Email
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      From
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      To
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Vehicle Category
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Total km
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      PickUp/ Return Date
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Time
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      TripType
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Seat capacity
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>

                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      VehicleId
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Name
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Mobile
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      GST
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Total Amount
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Payment Method
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Payment Amount
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Payment ID
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Pick up place
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>

                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Extra Charge
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      ExtraHr Price
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      ExtraKm Price
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Toll Charge
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}

                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Holiday Price
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Cancel Request
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Trip Status
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Status
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>

                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Action
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Assign Driver
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {search ? (
                    <>
                      {tableFilter
                        ?.slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((data, index) => {
                          return (
                            <tr style={{ textAlign: "center" }}>
                              <td>{index + 1}</td>
                              <td>{data?.bookid}</td>
                              <td>{data?.userName}</td>

                              <td>{data?.number}</td>
                              <td>{data?.email}</td>
                              <td>
                                <p
                                  style={{
                                    overflowX: "hidden",
                                    overflowY: "scroll",
                                    height: "80px",
                                    fontSize: "small",
                                  }}
                                >
                                  {data?.picUpPlace}
                                </p>
                              </td>
                              <td>
                                {" "}
                                <p
                                  style={{
                                    overflowX: "hidden",
                                    overflowY: "scroll",
                                    height: "80px",
                                    fontSize: "small",
                                  }}
                                >
                                  {data?.dropPlace}
                                </p>
                              </td>
                              <td>{data?.vechicleId?.category}</td>
                              <td>{data?.totalKm} km</td>
                              <td>
                                {" "}
                                {/* {data?.picUpDate?.slice(0,10)} */}
                                {data?.returnDate ? (
                                  <div>
                                    <p>
                                      PickUpDate:-
                                      {data?.picUpDate}
                                    </p>
                                    <p>ReturnDate:-{data?.returnDate}</p>
                                  </div>
                                ) : (
                                  <>{data?.picUpDate}</>
                                )}
                              </td>
                              <td>
                                {" "}
                                {/* {data?.picUpDate?.slice(0,10)} */}
                                {data?.returnTime ? (
                                  <div>
                                    <p>
                                      PickUpTime:-
                                      {moment(data?.picUpTime, [
                                        "h:mm a",
                                      ]).format("hh:mm a")}
                                    </p>
                                    <p>
                                      ReturnTime:-
                                      {moment(data?.returnTime, [
                                        "h:mm a",
                                      ]).format("hh:mm a")}
                                    </p>
                                  </div>
                                ) : (
                                  <>
                                    {moment(data?.picUpTime, ["h:mm a"]).format(
                                      "hh:mm a"
                                    )}
                                  </>
                                )}
                              </td>
                              {/* <td> {moment(data?.picUpTime, ["h:mm a"]).format(
          "hh:mm a"
        )}</td> */}
                              <td>{data?.tripType}</td>
                              <td>{data?.vechicleId?.seatAv}</td>
                              {/* <td>{data?.vechicleId?.isAc}</td> */}
                              {/* <td>{data.way}</td> */}

                              <td>{data?.vechicleId?._id}</td>
                              <td>{data?.driverName}</td>
                              <td>{data?.driverMobile}</td>
                              {/* <td>{data?.gst}</td> */}
                              <td>{data?.totalAmount}</td>
                              <td>{data?.paymentMethod}</td>
                              <td>{data?.payAmount}</td>
                              {/* <td>{data?.payId}</td> */}
                              <td>
                                <p
                                  style={{
                                    overflowX: "hidden",
                                    overflowY: "scroll",
                                    height: "80px",
                                    fontSize: "small",
                                  }}
                                >
                                  {data?.picPlace}
                                </p>
                              </td>
                              {/* <td>{data?.extraCharge}</td> */}
                              {/* <td>{data?.extraHrPrice}</td> */}
                              {/* <td>{data?.extraKmPrice}</td> */}
                              {/* <td>{data?.tollCharge}</td> */}

                              <td>
                                {data?.cancelReques} {data?.cancelTime}
                              </td>
                              <td>
                                {data?.status == "Cancel" ? (
                                  <p style={{ color: "red" }}>{data?.status}</p>
                                ) : (
                                  <>
                                    {data?.status == "Pending" ? (
                                      <p style={{ color: "#000080" }}>
                                        {data?.status}
                                      </p>
                                    ) : (
                                      <p style={{ color: "green" }}>
                                        {data?.status}
                                      </p>
                                    )}
                                  </>
                                )}
                              </td>
                              <td>
                                {data?.driverStatus == "Assigned" ? (
                                  <p
                                    style={{ color: "red" }}
                                    onClick={() => {
                                      getAsseniedData();
                                      setEnterfile(data);
                                      handeleShowList();
                                    }}
                                  >
                                    {data?.driverStatus} <BsEyeFill />
                                    {data?.cancelReques &&
                                    data?.status !== "Cancel" ? (
                                      <Button variant="danger">
                                        CancelRequest
                                      </Button>
                                    ) : (
                                      <></>
                                    )}
                                  </p>
                                ) : (
                                  <>
                                    {data?.driverStatus == "Pending" ? (
                                      <p style={{ color: "#000080" }}>
                                        {data?.driverStatus}
                                      </p>
                                    ) : (
                                      <p
                                        style={{ color: "green" }}
                                        onClick={() => {
                                          getAsseniedData();
                                          setEnterfile(data);
                                          handeleShowList();
                                        }}
                                      >
                                        {data?.driverStatus} <BsEyeFill />
                                        {data?.cancelReques &&
                                        data?.status !== "Cancel" ? (
                                          <Button variant="danger">
                                            CancelRequest
                                          </Button>
                                        ) : (
                                          <></>
                                        )}
                                      </p>
                                    )}
                                  </>
                                )}
                              </td>
                              {/* <td>{data?.driverStatus}</td> */}
                              <td className="" style={{ textAlign: "center" }}>
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  onClick={() => {
                                    if (data?.status == "Complete") {
                                      return history(`/voucher/${data?._id}`);
                                    } else {
                                      return history("/admin/Invoice", {
                                        state: data?._id,
                                      });
                                    }
                                  }}
                                >
                                  View
                                </button>

                                {data?.status == "Cancel" ||
                                data?.status == "Complete" ? (
                                  <></>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-danger"
                                    style={{ marginTop: "5px" }}
                                    onClick={() => {
                                      setcancelId(data?._id);
                                      handleShow1();
                                    }}
                                  >
                                    {" "}
                                    CancelTrip
                                  </button>
                                )}
                              </td>
                              <td>
                                {data?.status == "Cancel" ||
                                data?.status == "Complete" ? (
                                  <></>
                                ) : (
                                  <>
                                    {data?.driverStatus == "Accepted" ||
                                    data?.driverStatus == "Complete" ? (
                                      <></>
                                    ) : (
                                      <>
                                        {" "}
                                        <i
                                          class="fa fa-trash-ow"
                                          aria-hidden="true"
                                          title="Assign Driver"
                                          style={{
                                            cursor: "pointer",
                                            color: "green",
                                          }}
                                          onClick={() => {
                                            setAnotherAss(false);
                                            setEnterfilenameModal1(true);
                                            setEnterfile(data);
                                          }}
                                        >
                                          <Button variant="info">
                                            AssignDriver
                                          </Button>
                                        </i>
                                      </>
                                    )}
                                  </>
                                )}
                                {data?.status == "Cancel" ? (
                                  <></>
                                ) : (
                                  <>
                                    {data?.status == "Complete" ||
                                    data?.driverStatus == "Accepted" ? (
                                      <></>
                                    ) : (
                                      <>
                                        <Button
                                          variant="danger"
                                          className="mt-2"
                                          onClick={() => {
                                            setallcheck(false);
                                            setflashbidAmount("");
                                            setEnterfile(data);
                                            handleShow();
                                          }}
                                        >
                                          FlashBid
                                        </Button>
                                      </>
                                    )}
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {dataSource2
                        ?.slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((data, index) => {
                          return (
                            <tr style={{ textAlign: "center" }}>
                              <td>{index + 1}</td>
                              <td>{data?.bookid}</td>
                              <td>{data?.userName}</td>

                              <td>{data?.number}</td>
                              <td>{data?.email}</td>
                              <td>
                                <p
                                  style={{
                                    overflowX: "hidden",
                                    overflowY: "scroll",
                                    height: "80px",
                                    fontSize: "small",
                                  }}
                                >
                                  {data?.picUpPlace}
                                </p>
                              </td>
                              <td>
                                {" "}
                                <p
                                  style={{
                                    overflowX: "hidden",
                                    overflowY: "scroll",
                                    height: "80px",
                                    fontSize: "small",
                                  }}
                                >
                                  {data?.dropPlace}
                                </p>
                              </td>
                              <td>{data?.vechicleId?.category}</td>
                              <td>{data?.totalKm} km</td>
                              <td>
                                {" "}
                                {/* {data?.picUpDate?.slice(0,10)} */}
                                {data?.returnDate ? (
                                  <div>
                                    <p>
                                      PickUpDate:-
                                      {data?.picUpDate}
                                    </p>
                                    <p>ReturnDate:-{data?.returnDate}</p>
                                  </div>
                                ) : (
                                  <>{data?.picUpDate}</>
                                )}
                              </td>
                              <td>
                                {" "}
                                {/* {data?.picUpDate?.slice(0,10)} */}
                                {data?.returnTime ? (
                                  <div>
                                    <p>
                                      PickUpTime:-
                                      {moment(data?.picUpTime, [
                                        "h:mm a",
                                      ]).format("hh:mm a")}
                                    </p>
                                    <p>
                                      ReturnTime:-
                                      {moment(data?.returnTime, [
                                        "h:mm a",
                                      ]).format("hh:mm a")}
                                    </p>
                                  </div>
                                ) : (
                                  <>
                                    {moment(data?.picUpTime, ["h:mm a"]).format(
                                      "hh:mm a"
                                    )}
                                  </>
                                )}
                              </td>
                              {/* <td> {moment(data?.picUpTime, ["h:mm a"]).format(
          "hh:mm a"
        )}</td> */}
                              <td>{data?.tripType}</td>
                              <td>{data?.vechicleId?.seatAv}</td>
                              {/* <td>{data?.vechicleId?.isAc}</td> */}
                              {/* <td>{data.way}</td> */}

                              <td>{data?.vechicleId?._id}</td>
                              <td>{data?.driverName}</td>
                              <td>{data?.driverMobile}</td>
                              {/* <td>{data?.gst}</td> */}
                              <td>{data?.totalAmount}</td>
                              <td>{data?.paymentMethod}</td>
                              <td>{data?.payAmount}</td>
                              {/* <td>{data?.payId}</td> */}
                              <td>
                                <p
                                  style={{
                                    overflowX: "hidden",
                                    overflowY: "scroll",
                                    height: "80px",
                                    fontSize: "small",
                                  }}
                                >
                                  {data?.picPlace}
                                </p>
                              </td>
                              {/* <td>{data?.extraCharge}</td> */}
                              {/* <td>{data?.extraHrPrice}</td> */}
                              {/* <td>{data?.extraKmPrice}</td> */}
                              {/* <td>{data?.tollCharge}</td> */}

                              <td>
                                {data?.cancelReques} {data?.cancelTime}
                              </td>
                              <td>
                                {data?.status == "Cancel" ? (
                                  <p style={{ color: "red" }}>{data?.status}</p>
                                ) : (
                                  <>
                                    {data?.status == "Pending" ? (
                                      <p style={{ color: "#000080" }}>
                                        {data?.status}
                                      </p>
                                    ) : (
                                      <p style={{ color: "green" }}>
                                        {data?.status}
                                      </p>
                                    )}
                                  </>
                                )}
                              </td>
                              <td>
                                {data?.driverStatus == "Assigned" ? (
                                  <p
                                    style={{ color: "red" }}
                                    onClick={() => {
                                      getAsseniedData();
                                      setEnterfile(data);
                                      handeleShowList();
                                    }}
                                  >
                                    {data?.driverStatus} <BsEyeFill />
                                    {data?.cancelReques &&
                                    data?.status !== "Cancel" ? (
                                      <Button variant="danger">
                                        CancelRequest
                                      </Button>
                                    ) : (
                                      <></>
                                    )}
                                  </p>
                                ) : (
                                  <>
                                    {data?.driverStatus == "Pending" ? (
                                      <p style={{ color: "#000080" }}>
                                        {data?.driverStatus}
                                      </p>
                                    ) : (
                                      <p
                                        style={{ color: "green" }}
                                        onClick={() => {
                                          getAsseniedData();
                                          setEnterfile(data);
                                          handeleShowList();
                                        }}
                                      >
                                        {data?.driverStatus} <BsEyeFill />
                                        {data?.cancelReques &&
                                        data?.status !== "Cancel" ? (
                                          <Button variant="danger">
                                            CancelRequest
                                          </Button>
                                        ) : (
                                          <></>
                                        )}
                                      </p>
                                    )}
                                  </>
                                )}
                              </td>
                              {/* <td>{data?.driverStatus}</td> */}
                              <td className="" style={{ textAlign: "center" }}>
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  onClick={() => {
                                    if (data?.status == "Complete") {
                                      return history(`/voucher/${data?._id}`);
                                    } else {
                                      return history("/admin/Invoice", {
                                        state: data?._id,
                                      });
                                    }
                                  }}
                                >
                                  View
                                </button>

                                {data?.status == "Cancel" ||
                                data?.status == "Complete" ? (
                                  <></>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-danger"
                                    style={{ marginTop: "5px" }}
                                    onClick={() => {
                                      setcancelId(data?._id);
                                      handleShow1();
                                    }}
                                  >
                                    {" "}
                                    CancelTrip
                                  </button>
                                )}
                              </td>
                              <td>
                                {data?.status == "Cancel" ||
                                data?.status == "Complete" ? (
                                  <></>
                                ) : (
                                  <>
                                    {data?.driverStatus == "Accepted" ||
                                    data?.driverStatus == "Complete" ? (
                                      <></>
                                    ) : (
                                      <>
                                        {" "}
                                        <i
                                          class="fa fa-trash-ow"
                                          aria-hidden="true"
                                          title="Assign Driver"
                                          style={{
                                            cursor: "pointer",
                                            color: "green",
                                          }}
                                          onClick={() => {
                                            setAnotherAss(false);
                                            setEnterfilenameModal1(true);
                                            setEnterfile(data);
                                          }}
                                        >
                                          <Button variant="info">
                                            AssignDriver
                                          </Button>
                                        </i>
                                      </>
                                    )}
                                  </>
                                )}
                                {data?.status == "Cancel" ? (
                                  <></>
                                ) : (
                                  <>
                                    {data?.status == "Complete" ||
                                    data?.driverStatus == "Accepted" ? (
                                      <></>
                                    ) : (
                                      <>
                                        <Button
                                          variant="danger"
                                          className="mt-2"
                                          onClick={() => {
                                            setallcheck(false);
                                            setflashbidAmount("");
                                            setEnterfile(data);
                                            handleShow();
                                          }}
                                        >
                                          FlashBid
                                        </Button>
                                      </>
                                    )}
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={EnterfilenameModal}
        onHide={() => setEnterfilenameModal(false)}
        style={{ width: "320px", marginLeft: "40%", marginTop: "2%" }}
      >
        <Modal.Body>
          <div>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Enter Excel file name ....."
                style={{
                  padding: "10px",
                  width: "82%",
                  border: "1px solid #fdd12d",
                }}
                value={fileName}
                onChange={(e) => {
                  setfileName(e.target.value);
                }}
              />
              <ImFolderDownload
                style={{
                  width: "18%",
                  color: "white",
                  backgroundColor: "#fdd12d",
                  paddingTop: "15px",
                  height: "50px",
                  paddingBottom: "15px",
                }}
                onClick={() => ExportToExcel()}
              />
            </div>
            {/* <h1 style={{ textAlign: "center", color: "#ffcc33" }}>{Count}</h1> */}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={EnterfilenameModal1}
        onHide={() => setEnterfilenameModal1(false)}
        style={{ marginTop: "2%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Assign Driver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <div class="position-relative mt-2" style={{ display: "flex" }}>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  onChange={(e) => abcHandel(e)}
                  placeholder="Search Driver"
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                    marginBottom: "5px",
                  }}
                />
              </div>
              {dataSource1
                .filter(
                  (item) =>
                    item.isDBlocked === false && item?.status == "Approved"
                )
                ?.map((data1) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          margin: "10px",
                          flexWrap: "wrap",
                        }}
                      >
                        {/* <input type="checkbox"/> */}
                        {AssignD?.filter(
                          (ele) =>
                            ele?.driverId == data1?._id &&
                            ele?.bookId?._id == Enterfile?._id &&
                            ele?.Amount == 0
                        ).length ? (
                          <BsCheckCircleFill
                            style={{
                              color: "green",
                              textAlign: "center",
                              width: "25px",
                              height: "25px",
                            }}
                          />
                        ) : (
                          <></>
                        )}

                        <img
                          src={`https://goeasycab.info/Driver/${data1?.dProfileImg}`}
                          alt="#"
                          width="30px"
                          height="30px"
                          style={{ borderRadius: 10 }}
                        />
                        <span style={{ color: "black" }}>
                          {data1?.driverName}{" "}
                        </span>
                      </div>
                      <div>
                        <input
                          id="search-bar-0"
                          type="text"
                          aria-labelledby="search-bar-0-label"
                          class="form-control"
                          onChange={(e) => setvehicleNum(e.target.value)}
                          placeholder={
                            AssignD?.filter(
                              (ele) =>
                                ele?.driverId == data1?._id &&
                                ele?.bookId?._id == Enterfile?._id
                            ).length == 0
                              ? data1?.dVehicleRegNum
                              : AssignD?.filter(
                                  (ele) =>
                                    ele?.driverId == data1?._id &&
                                    ele?.bookId?._id == Enterfile?._id
                                )[0]?.vehicleNum
                          }
                          style={{
                            borderRadius: "50px",
                            border: "1px solid #fdd12d",
                            marginBottom: "5px",
                          }}
                        />
                      </div>

                      <div className="">
                        {AssignD?.filter(
                          (ele) =>
                            ele?.driverId == data1?._id &&
                            ele?.bookId?._id == Enterfile?._id &&
                            !ele.flashBid
                        ).length ? (
                          <Button
                            variant="danger"
                            onClick={() => deleteAssginDriver(data1)}
                          >
                            Cancel{" "}
                          </Button>
                        ) : (
                          <>
                            {AssignD?.filter(
                              (ele) =>
                                ele?.bookId?._id == Enterfile?._id &&
                                !ele.flashBid
                            ).length ? (
                              <>
                                <Button
                                  variant="info"
                                  onClick={() => {
                                    if (AnotherAss) {
                                      AssignDriver(data1);
                                    }
                                  }}
                                >
                                  Assign{" "}
                                </Button>
                              </>
                            ) : (
                              <>
                                {" "}
                                {checkBook(data1) ? (
                                  <Button
                                    variant="success"
                                    onClick={() => AssignDriver(data1)}
                                  >
                                    Assign{" "}
                                  </Button>
                                ) : (
                                  <div>
                                    <span style={{ color: "red" }}>
                                      Booked{"  "}
                                    </span>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Flash Bid Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Flash Bid</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              {/* <div class="position-relative mt-2" style={{ display: "flex" }}> */}
              <input
                id="search-bar-0"
                type="text"
                aria-labelledby="search-bar-0-label"
                class="form-control "
                onChange={(e) => abcHandel(e)}
                placeholder="Search Driver"
                style={{
                  borderRadius: "50px",
                  border: "1px solid #fdd12d",
                  marginBottom: "5px",
                }}
              />

              {/* </div> */}
            </div>
            <div className="col-md-6">
              <input
                type="text"
                class="form-control "
                style={{
                  borderRadius: "50px",
                  border: "1px solid #fdd12d",
                  marginBottom: "5px",
                }}
                value={flasbitAmount}
                onChange={(e) => setflashbidAmount(e.target.value)}
                placeholder=" Enter Amount"
              />
            </div>
            <div className="col-md-6">
              <span>Payment Required</span>
            </div>
            <div className="col-md-6">
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={payReq}
                  onChange={() => setPayReq(!payReq)}
                  label="Yes/No"
                />
              </Form>
            </div>
          </div>
          <div className="vvf">
            <div className="grse" style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="mt-3 mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={allCheck}
                  onChange={() => setallcheck(!allCheck)}
                />
                <label>All drivers</label>
              </div>
              <div className="mt-3 mb-2 mx-2">
                <label>Book amount</label>
                {": "}
                <span>
                  {"₹ "}
                  {Enterfile?.totalAmount}
                </span>
              </div>
              {allCheck == true ? (
                <div className="mt-3 mb-1 mx-2">
                  <Button variant="primary" onClick={flashBitAssign}>
                    All Flash Now
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="fre">
              {dataSource1
                .filter(
                  (item) =>
                    item.isDBlocked === false && item?.status == "Approved"
                )
                ?.map((data1) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          margin: "10px",
                          flexWrap: "wrap",
                        }}
                      >
                        {allCheck == true ? (
                          <input type="checkbox" checked={allCheck} />
                        ) : (
                          <></>
                        )}

                        <img
                          src={`https://goeasycab.info/Driver/${data1?.dProfileImg}`}
                          alt="#"
                          width="30px"
                          height="30px"
                          style={{ borderRadius: 10 }}
                        />
                        <span style={{ color: "black" }}>
                          {data1?.driverName}{" "}
                        </span>
                      </div>
                      <div>
                        {" "}
                        <p>
                          {" "}
                          {
                            AssignD?.filter(
                              (ele) =>
                                ele?.driverId == data1?._id &&
                                ele?.bookId?._id == Enterfile?._id
                            )[0]?.Amount
                          }
                        </p>
                      </div>
                      <div>
                        {" "}
                        <p>
                          {" "}
                          {AssignD?.filter(
                            (ele) =>
                              ele?.driverId == data1?._id &&
                              ele?.bookId?._id == Enterfile?._id
                          ).length == 0 ? (
                            <></>
                          ) : (
                            <Form>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                checked={
                                  AssignD?.filter(
                                    (ele) =>
                                      ele?.driverId == data1?._id &&
                                      ele?.bookId?._id == Enterfile?._id
                                  )[0]?.PayReq
                                }
                                // onChange={()=>setPayReq(!payReq)}
                              />
                            </Form>
                          )}
                        </p>
                      </div>
                      <div className="">
                        {ListOfAssgined?.filter(
                          (ele) =>
                            ele?.driverId?._id == data1?._id &&
                            ele?.bookId?._id == Enterfile?._id &&
                            ele.Amount !== 0
                        ).length ? (
                          <>
                            <BsCheckCircleFill
                              style={{
                                color: "green",
                                textAlign: "center",
                                width: "25px",
                                height: "25px",
                                margin: "2px 26px 0px 0px",
                              }}
                            />{" "}
                            <Button
                              variant="danger"
                              onClick={() => deleteAssginDriver(data1)}
                            >
                              Cancel{" "}
                            </Button>
                          </>
                        ) : (
                          <>
                            {allCheck ? (
                              <></>
                            ) : (
                              <>
                                {checkBook(data1) ? (
                                  <Button
                                    variant="success"
                                    onClick={() => flashBitAssign(data1)}
                                  >
                                    Flash Now{" "}
                                  </Button>
                                ) : (
                                  <div>
                                    <span style={{ color: "red" }}>
                                      Booked{"  "}
                                    </span>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {/* Show Assign Driver Modal */}
      <Modal
        show={showList}
        onHide={closeShowList}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Assigned Drivers List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="vvf">
            <div className="grse" style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="mt-3 mb-2 mx-2" style={{ fontWeight: "bold" }}>
                <label>Book Id</label>
                {": "}
                <span>{Enterfile?.bookid}</span>
              </div>
              <div className="mt-3 mb-2 mx-2" style={{ fontWeight: "bold" }}>
                <label> Book PickUp Time</label>
                {": "}
                <span>
                  {moment(Enterfile?.picUpTime, ["h:mm a"]).format("hh:mm a")}
                </span>
              </div>
            </div>
            <div className="fre">
              {ListOfAssgined.filter(
                (item) => item?.bookId?._id == Enterfile?._id
              )?.map((data1) => {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontWeight: "bold",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          margin: "10px",
                          flexWrap: "wrap",
                        }}
                      >
                        <img
                          src={`https://goeasycab.info/Driver/${data1?.driverId?.dProfileImg}`}
                          alt="#"
                          width="30px"
                          height="30px"
                          style={{ borderRadius: 10 }}
                        />
                        <span style={{ color: "black" }}>
                          {data1?.driverId?.driverName}{" "}
                        </span>
                        <span style={{ color: "black" }}>
                          {data1?.driverId?.dMobile}{" "}
                        </span>
                        <span style={{ color: "black" }}>
                          {data1?.vehicleNum}{" "}
                        </span>
                        <span style={{ color: "black" }}>
                          {data1?.Amount == 0 ? <></> : data1?.Amount}{" "}
                        </span>
                        <span style={{ color: "black" }}>
                          {data1?.Amount == 0 ? (
                            <></>
                          ) : (
                            <Form>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                checked={data1?.PayReq}
                                // onChange={()=>setPayReq(!payReq)}
                              />
                            </Form>
                          )}{" "}
                        </span>
                      </div>
                      {/* <div> <p>{data1?.Amount}</p></div> */}

                      <div className="">
                        {data1?.status !== "Assigned" &&
                        data1?.status !== "Cancel"&&data1?.status !== "Reject" ? (
                          <span style={{ color: "green" }}>
                            {data1?.flashBid}{" "}
                            <BsCheckCircleFill
                              style={{
                                color: "green",
                                textAlign: "center",
                                width: "25px",
                                height: "25px",
                                margin: "2px 26px 0px 0px",
                              }}
                            />
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "red",
                              textAlign: "center",
                              margin: "2px 26px 0px 0px",
                            }}
                          >
                            {data1?.flashBid} {data1?.status} {data1?.reason}
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ color: "green", fontSize: "15px" }}
                    >
                      <div className="col-mb-6">
                        <span style={{ color: "red" }}>
                          Driver Assigned Time: -
                        </span>
                        {moment(data1?.createdAt).format("LLL")}
                      </div>
                      {data1?.status !== "Assigned" &&
                      data1?.status !== "Cancel" ? (
                        <>
                          <div className="col-mb-6">
                            <span style={{ color: "red" }}>Trip Status: -</span>
                            {Enterfile?.message}
                          </div>
                          <div className="col-mb-6">
                            <span style={{ color: "red" }}>
                              Start From Garage Time: -
                            </span>
                            {Enterfile?.startTime}
                          </div>
                          <div className="col-mb-6">
                            <span style={{ color: "red" }}>
                              Reach Location: -
                            </span>
                            {Enterfile?.reachAddress}
                          </div>
                          <div className="col-mb-6">
                            <span style={{ color: "red" }}>
                              Reached Time: -
                            </span>
                            {Enterfile?.reachTime}
                          </div>
                          <div className="col-mb-6">
                            <span style={{ color: "red" }}>
                              Trip Start Time: -
                            </span>
                            {Enterfile?.tripstarttime}
                          </div>
                          <div className="col-mb-6">
                            <span style={{ color: "red" }}>Drop Place: -</span>
                            {Enterfile?.dropaddress}
                          </div>
                          <div className="col-mb-6">
                            <span style={{ color: "red" }}>Drop Time: -</span>
                            {Enterfile?.endtriptime}
                          </div>
                          {Enterfile?.cancelReques ? (
                            <>
                              <div className="col-mb-6">
                                <span style={{ color: "red" }}>
                                  Cancel Request: -
                                </span>
                                {Enterfile?.cancelReques}
                              </div>
                              <div className="col-mb-6">
                                <span style={{ color: "red" }}>
                                  Cancel Request Time: -
                                </span>
                                {Enterfile?.cancelTime}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeShowList} variant="danger">
            Close
          </Button>
          {Enterfile?.driverStatus !== "Pending" &&
          Enterfile?.status !== "Cancel" &&
          Enterfile?.driverStatus !== "Assigned" &&
          Enterfile?.status !== "Complete" ? (
            <Button
              onClick={() =>
                window.location.assign("/admin/track-driver/" + Enterfile?._id)
              }
              variant="success"
            >
              Live Track
            </Button>
          ) : (
            <></>
          )}
          {Enterfile?.status !== "Cancel" &&
          Enterfile?.driverStatus !== "Assigned" &&
          Enterfile?.cancelReques ? (
            <Button
              onClick={() => makeCancelTrip(Enterfile?._id)}
              variant="danger"
            >
              Cancel Trip
            </Button>
          ) : (
            <></>
          )}
          {Enterfile?.status !== "Cancel" &&
          Enterfile?.status !== "Complete" &&
          Enterfile?.status !== "Pending" ? (
            <Button
              onClick={() => {
                setAnotherAss(true);
                setEnterfilenameModal1(true);
              }}
              variant="info"
            >
              Assign Driver
            </Button>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Modal>

      {/* delete */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <h5>Are you sure cancel trip ?</h5>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="success" onClick={bookCancelAdmin}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Bookingdetails;
