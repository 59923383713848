import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";

function Adminheader() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signout =() =>{
    sessionStorage.removeItem("admin");
    window.location.assign("/admin");
  }
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        //   backgroundImage: "url('/yell1.jpg')",
          backgroundColor: "#fdd12d",
          padding: "12px",
        }}
      >
        <div style={{ marginLeft: "20px", display: "flex" }}>
        <img
              src="/GOLOGO.jpg"
              className="roy"
              alt="logo"
              style={{
                width: "160px",
                height: "80px",
              }}
            />
        </div>

        {/* {user ? ( */}
        <div style={{ display: "flex" }}>
          <img
            src="http://cdn.vectorstock.com/i/1000x1000/11/10/admin-icon-male-person-profile-avatar-with-gear-vector-25811110.webp"
            alt=""
            width={30}
            height={30}
            onClick={handleClick}
            style={{ borderRadius: "50px",marginTop:15 }}
          />
          <div style={{ paddingTop: "10px" }}>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{ color: "black",fontSize:15,fontWeight:"bold" }}
            >
              {admin1.name}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={signout}>Logout</MenuItem>
            </Menu>
          </div>
        </div>
        {/* ) : (
          ""
        )} */}
      </div>
    </>
  );
}

export default Adminheader;