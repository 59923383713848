import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import exportFromJSON from "export-from-json";
import { Button } from "react-bootstrap";
function AccountHistory() {
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  const [alldata, setalldata] = useState([]);
  const [data,setdata]=useState()
  const [nocangedata, setnocahangedata] = useState([]);
  const getAcount = async () => {
    try {
      const config = {
        url: "/admin/getAccount/" + admin1?._id,
        method: "get",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setalldata(res.data.success);
        setnocahangedata(res.data.success);
        setdata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAcount();
  }, []);
  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [EnterfilenameModal1, setEnterfilenameModal1] = useState(false);
  const [Enterfile, setEnterfile] = useState({});
  const [fileName, setfileName] = useState("AcountHistory");

  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  const [fromdate,setfromdate]=useState("");
  const [ttodate,setttodate]=useState("")
  const searchdate=()=>{
    try {
      if(!fromdate) return alert("From date required")
      if(!ttodate) return alert("To date required")
    
      let std=fromdate?.split("-");
      let entd=ttodate?.split("-")
      let startD=parseInt(std?.join(""));
      let endD=parseInt(entd?.join(""))
      let abc=nocangedata?.filter((ele)=>{
        console.log("amit check it",moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
        let abd=moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
        let amd=parseInt(abd?.join(""));
        return startD<=amd && endD>=amd
      })
      setalldata(abc);
      setdata(abc)
      setfileName(`AcountHistory_${moment(fromdate).format("DD/MM/YYYY")} to ${moment(ttodate).format("DD/MM/YYYY")}`)
    } catch (error) {
      console.log(error);
    }
  }
  const abcHandel = (e) => {
    if (e.target.value != "") {
      const filterTable = nocangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setalldata([...filterTable]);
    } else {
      setalldata([...nocangedata]);
    }
  };
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(alldata.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };


  return (
    <>
         <Button
            style={{
              color: "white",
              backgroundColor: "#fdd12d",
              border: "#fdd12d",
            }}
          >
            {" "}
            Account History
          </Button>
    
      <div className="accountt">
        <form>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-md-3">
              <div className="jjr mb-2">
                <label>Date From</label>
                <br />
                <input type="date" className="new-cab" onChange={(e)=>setfromdate(e.target.value)} />
              </div>
            </div>
            <div className="col-md-3">
              <div className="jjr mb-2">
                <label>Date To</label>
                <br />
                <input type="date" className="new-cab" onChange={(e)=>setttodate(e.target.value)}/>
              </div>
            </div>
            <div className="col-md-3">
              <div class="search-box  d-inline-block">
                <div class="position-relative" style={{ display: "flex" }}>
                  <input
                    id="search-bar-0"
                    type="text"
                    aria-labelledby="search-bar-0-label"
                    class="form-control "
                    placeholder="Search"
                    onChange={(e)=>abcHandel(e)}
                    style={{
                      borderRadius: "50px",
                      border: "1px solid #fdd12d",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <button
                type="button"
                class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
                onClick={searchdate}
              >
                <span>Search</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="vwv">
        <div style={{ paddingLeft: "0px" }}>
          <div style={{ backgroundColor: "#f0f0f0" }}>
            <div class="row" style={{ padding: "10px 20px" }}>
              <div class="col-md-8">
                <div class="text">
                  {" "}
                  <button
                    style={{
                      padding: "2px 8px 7px 8px",
                      color: "black",
                      backgroundColor: "#fdd12d",
                      border: "none",
                      fontWeight: "600",
                      fontSize: "14px",
                      borderRadius: "20px",
                      margin: "0px 10px 0px 0px",
                    }}
                    onClick={ExportToExcel}
                  >
                    <span
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    ></span>{" "}
                   Export Excel
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <div class="react-bootstrap-table">
                  <table
                    class="table table align-middle table-nowrap"
                    style={{ width: "90%", margin: "2%" }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#fdd12d",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      <tr style={{ padding: "10px" }}>
                        <th
                          tabindex="0"
                          aria-label="Username sortable"
                          class="sortable"
                        >
                          S.No
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Booking Id
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Date
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Payment Id
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Driver Id
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Amount
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Status
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {alldata?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((ele,i) => {
                        return (
                          <tr style={{ textAlign: "center" }}>
                            <td>{i+1}</td>

                            <td>{ele?.bookingId}</td>
                            <td>{moment(ele?.creatAt).format("DD/MM/YYYY")}</td>
                            <td>{ele?.paymentId}</td>
                            <td>{ele?.driverId}</td>
                            <td>{"₹ "}{ele?.amount}</td>
                            <td>
                              {ele?.status=="Cr" ? (<span style={{color:"green"}}>Credit</span>):(<span style={{color:"red"}}>Debit</span>)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountHistory;
