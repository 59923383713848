import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

function Expense() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
const [expens,setexpance]=useState("");
const [allExpanse,setallExpanse]=useState([]);

const admin = sessionStorage.getItem("token");
const admin1 = JSON.parse(sessionStorage.getItem("admin"));
const getAllExpanse=async()=>{
  try {
    let res=await axios.get("https://goeasycab.info/api/admin/getExpenseType");
    if(res.status==200){
      setallExpanse(res.data.success)
    }
  } catch (error) {
    console.log(error);
  }
}
const AddExpanse=async ()=>{
  try {
    if(!expens) return alert("Please enter expanse")
    const config = {
      url: "/admin/AddExpenseType",
      method: "post",
      baseURL: "https://goeasycab.info/api",
      headers: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
      data: { authId: admin1._id,expanseT:expens },
    };
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully added")
      getAllExpanse()
      handleClose()
      setexpance("")
    }
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}
useEffect(()=>{
  getAllExpanse()
},[])
const [editData,seteditData]=useState({})
const deleteData=async()=>{
  try {
    const config = {
      url: "/admin/deleteExpenseType/"+editData?._id+"/"+ admin1?._id,
      method: "delete",
      baseURL: "https://goeasycab.info/api",
      headers: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
    };
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully deleted");
      handleClose2()
      getAllExpanse();
    }
  } catch (error) {
    console.log(error);
  }
}

const updateExpanse=async()=>{
  try {
    const config = {
      url: "/admin/updateExpenseType",
      method: "put",
      baseURL: "https://goeasycab.info/api",
      headers: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
      data: { authId: admin1._id,expanseT:expens,id:editData?._id},
    };
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully Updated")
      getAllExpanse()
      handleClose1()
      setexpance("")
    }
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}
  return (
    <>
    
   
      <div className="accountt mb-3" style={{display:"flex",justifyContent:"space-between"}}>
      <Button
          style={{
            color: "white",
            backgroundColor: "#fdd12d",
            border: "#fdd12d",
          }}
        >
          {" "}
          Expense
        </Button>
        <Button
          style={{
            borderRadius: "50px",
            border: "1px solid #fdd12d",
          }}
          onClick={handleShow}
        >
          Create
        </Button>
      </div>

      <div className="vwv">
        <div style={{ paddingLeft: "0px" }}>
          <div style={{ backgroundColor: "#f0f0f0" }}>
            <div class="row" style={{ padding: "10px 20px" }}>
              <div class="table-responsive">
                <div class="react-bootstrap-table">
                  <table
                    class="table table align-middle table-nowrap"
                    style={{ width: "90%", margin: "2%" }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#fdd12d",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      <tr style={{ padding: "10px" }}>
                        <th
                          tabindex="0"
                          aria-label="Username sortable"
                          class="sortable"
                        >
                          S.No
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Espense Type Name
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Action
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allExpanse?.map((ele,i)=>{
                        return (
                           <tr style={{ textAlign: "center" }}>
                        <td>{i+1}</td>

                        <td>{ele?.expanseT}</td>

                        <td>
                        {/* <Button
                            type="button"
                            variant="danger"
                            onClick={()=>{
                              seteditData(ele);
                              handleShow2()
                            }}
                          >
                            <span>
                              <RiDeleteBin6Line />
                            </span>
                          </Button> */}
                          <Button
                            type="button"
                            variant="success"
                            className="mx-2"
                            onClick={()=>{
                              seteditData(ele);
                            handleShow1()}}
                          >
                            <span >
                              <RiEditLine />
                            </span>
                          </Button>
                        </td>
                      </tr>
                        )
                      })}
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div class="position-relative" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="text"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder="Enter Espense Type"
              onChange={(e)=>setexpance(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={AddExpanse}>
         Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div class="position-relative" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="text"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              onChange={(e)=>setexpance(e.target.value)}
              placeholder={editData?.expanseT}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={updateExpanse}>
         Submit
          </Button>
        </Modal.Footer>
      </Modal>
       {/* delete */}
       <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>Are you sure delete ?</Modal.Header>
        
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose2}>
            Cancel
          </Button>
          <Button variant="success" onClick={deleteData}>
         Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Expense;
