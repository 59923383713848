import axios from "axios";
import exportFromJSON from "export-from-json";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

function FlashBid() {
  const history=useNavigate()
  const [show, setShow] = useState(false);
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let [AssignD,setAssignD]=useState([]);
const [nochangedata,setnochangedata]=useState([])

  const getAllassignList=async()=>{
    try {
      const config = {
        url: "/admin/getAllassignListAcceptedDriver/"+admin1?._id,
        method: "get",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setAssignD(res.data.success);
        setnochangedata(res.data.success);
        setdata(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getAllassignList();
  }, []);
// console.log("list of data",AssignD)
const [pageNumber, setPageNumber] = useState(0);
const usersPerPage = 10;
const pagesVisited = pageNumber * usersPerPage;
const pageCount = Math.ceil(AssignD.length / usersPerPage);
const changePage = ({ selected }) => {
  setPageNumber(selected);
};
const [Enterfile,setEnterfile]=useState({})

const deleteAssginDriver=async ()=>{
  try {
       const config = {
      url: "/admin/deleteAssignDeleteData/"+Enterfile?._id+"/"+admin1._id,
      method: "delete",
      baseURL: "https://goeasycab.info/api",
      headers: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
    };
      await axios(config).then(function (res) {
      if (res.status === 200) {
        alert("Successfully deleted");
        handleClose1()
        getAllassignList();
      }
    });
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}
const [fromdate,setfromdate]=useState("");
const [ttodate,setttodate]=useState("")
const searchdate=()=>{
  try {
    if(!fromdate) return alert("From date required")
    if(!ttodate) return alert("To date required")
  
    let std=fromdate?.split("-");
    let entd=ttodate?.split("-")
    let startD=parseInt(std?.join(""));
    let endD=parseInt(entd?.join(""))
    let abc=nochangedata?.filter((ele)=>{
      // console.log("amit check it",moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
      let abd=(ele?.bookId?.picUpDate).split("/").reverse()
      let amd=parseInt(abd?.join(""));
      return startD<=amd && endD>=amd
    })
    setAssignD(abc);
    setdata(abc)
  } catch (error) {
    console.log(error);
  }
}
const abcHandel = (e) => {
  if (e.target.value !== "") {
    const filterTable = nochangedata?.filter((o) =>
      o && o.bookId && Object.keys(o.bookId).some((k) =>
        String(o.bookId[k]).toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setAssignD([...filterTable]);
    setdata([...filterTable]);
  } else {
    setAssignD([...nochangedata]);
    setdata([...nochangedata]);
  }
};

const [data, setdata] = useState();
const exportType = "xls";
const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
const [EnterfilenameModal1, setEnterfilenameModal1] = useState(false);
const [fileName, setfileName] = useState("AssignDriver");
const ExportToExcel = () => {
  if (fileName) {
    exportFromJSON({ data, fileName, exportType });
  } else {
    alert("Enter file name to export");
  }
};
  return (
    <>
      <div style={{ paddingLeft: "0px" }}>
      <Button
        style={{
          color: "white",
          backgroundColor: "#fdd12d",
          border: "#fdd12d",
        }}
      >
        {" "}
        Assigned Driver List
      </Button>
      
        <br></br>
        <div className="accountt">
          <form>
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-3">
                <div className="jjr mb-2">
                  <label>Date From</label>
                  <br />
                  <input type="date" className="new-cab" onChange={(e)=>setfromdate(e.target.value)} />
                </div>
              </div>
              <div className="col-md-3">
                <div className="jjr mb-2">
                  <label>Date To</label>
                  <br />
                  <input type="date" className="new-cab"  onChange={(e)=>setttodate(e.target.value)}/>
                </div>
              </div>

              <div className="col-md-3">
                <button
                  type="button"
                  onClick={searchdate}
                  class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
                >
                  <span>Search</span>
                </button>
              </div>
              <div className="col-md-3">
              <button type="button" class="btn btn-danger" onClick={()=>history(-1)}>Back</button>
              </div>
              {/* <div className="col-md-3">
              <button
                type="button"
                class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
              >
                <a href="/admin/trip-list" style={{color:"white"}}><span>TripList</span></a>
              </button>
            </div> */}
            </div>
          </form>
        </div>
        <br />

        <div style={{ backgroundColor: "#f0f0f0" }}>
          <div class="row" style={{ padding: "10px 20px" }}>
            <div class="col-sm-4">
              <div class="search-box  d-inline-block">
                <div class="position-relative" style={{ display: "flex" }}>
                  <input
                    id="search-bar-0"
                    type="text"
                    aria-labelledby="search-bar-0-label"
                    class="form-control "
                    onChange={(e)=>abcHandel(e)}
                    placeholder="Search assgined ......"
                    style={{
                      borderRadius: "50px",
                      border: "1px solid #fdd12d",
                    }}
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="text-sm-end">
                {" "}
                <button
                  style={{
                    padding: "2px 8px 7px 8px",
                    color: "black",
                    backgroundColor: "#fdd12d",
                    border: "none",
                    fontWeight: "600",
                    fontSize: "14px",
                    borderRadius: "20px",
                    margin: "0px 10px 0px 0px",
                  }}
                  onClick={ExportToExcel}
                >
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                  EXPORT TO EXCEL
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <div class="react-bootstrap-table">
              <table
                class="table table align-middle table-nowrap"
                style={{ width: "90%", margin: "2%" }}
              >
                <thead
                  style={{
                    backgroundColor: "#fdd12d",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <tr style={{ padding: "10px" }}>
                    <th
                      tabindex="0"
                      aria-label="Username sortable"
                      class="sortable"
                    >
                      S.No
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Booking ID
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Trip Type
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Name
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>

                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      From
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      To
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Date From
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Time 
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                    Book  Amount
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Id
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      book Amount
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Flash bid Amount
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Pay Amount
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                     Pay Id
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                    Driver Status
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                    Reason 
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                    Trip Status
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Action
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                    {AssignD?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((data,i)=>{
                        return (
                            <tr style={{ textAlign: "center" }}>
                            <td>{i+1}</td>
                            <td> {data?.bookId?.bookid}</td>
                            <td> {data?.bookId?.tripType}</td>
                            <td>{data?.driverId?.driverName}</td>
                            <td ><p style={{overflowX:"hidden",overflowY:"scroll",height:"80px",fontSize:"small"}}>{data?.bookId?.picPlace}</p></td>
                            <td ><p style={{overflowX:"hidden",overflowY:"scroll",height:"80px",fontSize:"small"}}>{data?.bookId?.dropPlace}</p></td>
                            <td>
                                {" "}
                                {/* {data?.picUpDate?.slice(0,10)} */}
                                {data?.bookId?.returnDate ? (
                                  <div>
                                    <p>
                                      PickUpDate:-
                                      {data?.bookId?.picUpDate}
                                    </p>
                                    <p>ReturnDate:-{data?.bookId?.returnDate}</p>
                                  </div>
                                ) : (
                                  <>{data?.bookId?.picUpDate}</>
                                )}
                              </td>
                              <td>
                                {" "}
                                {/* {data?.picUpDate?.slice(0,10)} */}
                                {data?.bookId?.returnTime ? (
                                  <div>
                                    <p>
                                      PickUpTime:-
                                      {moment(data?.bookId?.picUpTime, [
                                        "h:mm a",
                                      ]).format("hh:mm a")}
                                    </p>
                                    <p>
                                      ReturnTime:-
                                      {moment(data?.bookId?.returnTime, [
                                        "h:mm a",
                                      ]).format("hh:mm a")}
                                    </p>
                                  </div>
                                ) : (
                                  <>
                                    {moment(data?.bookId?.picUpTime, ["h:mm a"]).format(
                                      "hh:mm a"
                                    )}
                                  </>
                                )}
                              </td>
                              <td>{data?.bookId?.totalAmount}</td>
                            <td>{data?.driverId?._id}</td>
                            <td>{data?.Amount}</td>
                            <td>{data?.driverAmount}</td>
                            <td>{data?.payid}</td>
                            <td>{data?.status=="Cancel" ? (<p style={{color:"red"}}>{data?.flashBid}{" "}{data?.status}</p>):(<>{data?.status=="Assigned" ? (<p style={{color:"#000080"}}>{data?.flashBid}{" "}{data?.status}</p>):(<p style={{color:"green"}}>{data?.flashBid}{" "}{data?.status}</p>) }</>) }</td>
                            <td>{data?.reason}</td>
                            <td>{data?.bookId?.status=="Cancel" ? (<p style={{color:"red"}}>{data?.bookId?.status}</p>):(<>{data?.bookId?.status=="Pending" ? (<p style={{color:"#000080"}}>{data?.bookId?.status}</p>):(<p style={{color:"green"}}>{" "}{data?.bookId?.status}</p>) }</>) }</td>
                            {/* <td>     <button type="button"> <i
                                  class="fa fa-trash-o"
                                  aria-hidden="true"
                                  title="Remove"
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                    height:"20px",
                                width:"20px",
                                fontSize:"25px"
                                  }}
                                  onClick={() => {
                                    setEnterfile(data);
                                    handleShow1()
                                  }}
                                ></i></button></td> */}
                          
                            {/* <td>
                              <span className="mb-2">
                                <Button variant="success" className="mb-2">
                                  <i
                                    class="fa fa-pencil-square-o"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                              </span>
                              <span className="mx-2 ">
                                <Button
                                  variant="success"
                                  className="mb-2"
                                  onClick={handleShow}
                                >
                                  Track
                                </Button>
                              </span>
                            </td> */}
                          </tr>
                        )
                    })}
             
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Track Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="grieht">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7772.739421229605!2d77.53040988833003!3d13.075739599695376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae22eaa796c853%3A0x8ebe4a9d8b5cad16!2sSingapura%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1685100380080!5m2!1sen!2sin"
             
              height="450"
              style={{width:"100%"}}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
        {/* delete */}
     <Modal show={show1} onHide={handleClose1}>
     <Modal.Header closeButton>Are you sure delete ?</Modal.Header>
    
     <Modal.Footer>
       <Button variant="danger" onClick={handleClose1}>
         Cancel
       </Button>
       <Button variant="success" onClick={deleteAssginDriver}>
           Ok
       </Button>
     </Modal.Footer>
   </Modal>
    </>
   
  );
}

export default FlashBid;
