import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { AiFillEye } from "react-icons/ai";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import moment from "moment";
import { MdDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import ReactPaginate from "react-paginate";
function Subadmin() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [password, setpassword] = useState("");
  const [subadmin, setsubadmin] = useState(false);
  const [bookDetails, setbookDetails] = useState(false);
  const [airportDetail, setairportDetail] = useState(false);
  const [airportTarif, setairportTarif] = useState(false);
  const [outstationTarif, setoutstationTarif] = useState(false);
  const [rental, setrental] = useState(false);
  const [driver, setdriver] = useState(false);
  const [homeDriver, sethomeDriver] = useState(false);
  const [vehiclecat, setvehiclecat] = useState(false);
  const [vehicleName, setvehicleName] = useState(false);
  const [vehicleModel, setvehicleModel] = useState(false);
  const [vehicle, setvehicle] = useState(false);
  const [user, setuser] = useState(false);
  const [specialday, setspecialday] = useState(false);
  const [gst, setgst] = useState(false);
  const [account, setaccount] = useState(false);
  const [expense, setexpense] = useState(false);
  const [Coupon, setCoupon] = useState(false);
  const [banner, setbanner] = useState(false);
  const [wallet, setwallet] = useState(false);
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  const [AllsubAdmin, setAllSubAdmin] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAllSubadmin = async () => {
    try {
      const config = {
        url: "/admin/getallsubadmin/" + admin1?._id,
        method: "get",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllSubAdmin(res.data.subAdminList);
        setnochangedata(res.data.subAdminList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSubadmin();
  }, []);
  console.log("subadmin", AllsubAdmin);

  const addSubadmin = async () => {
    try {
      if (!name) return alert("Please enter subadmin name");
      if (!mobile) return alert("Please enter mobile number");
      if (!email) return alert("Please enter email id");
      if (!password) return alert("Please enter password");
      const config = {
        url: "/admin/signup",
        method: "post",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          authId: admin1?._id,
          name: name,
          email: email,
          password: password,
          mobile: mobile,
          AirportTriff: airportTarif,
          OutstationTariff: outstationTarif,
          payments: account,
          bookCab: bookDetails,
          Rentel: rental,
          Driver: driver,
          subadmin: subadmin,
          category: vehiclecat,
          user: user,
          vehical: vehicle,
          vehicalModel: vehicleModel,
          vehicleName: vehicleName,
          Banner: banner,
          expanse: expense,
          specialDay: specialday,
          homeDriver: homeDriver,
          gst: gst,
          airportRoute: airportDetail,
          Coupon:Coupon,
          wallet:wallet,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        window.location.reload();
      }
    } catch (error) {
      alert(error.responce.data.error);
      console.log(error);
    }
  };
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllSubAdmin([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAllSubAdmin([...nochangedata]);
    }
  };
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    AllsubAdmin?.filter(
      (ele) => ele?._id != admin1?._id && ele?.adminType == "SubAdmin"
    ).length / usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const [showdata, setshowdata] = useState({});
  const [show3, setshow3] = useState(false);
  const delethandel = () => setshow3(true);
  const handleClose3 = () => setshow3(false);

  const [deleteId, setdeleteId] = useState("");
  const deleteSubAdminData = async () => {
    try {
      const config = {
        url: "/admin/deletesubadmin/" + deleteId + "/" + admin1?._id,
        method: "delete",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");
        handleClose3();
        getAllSubadmin();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [editid, seteditid] = useState({});
  const [show4, setshow4] = useState(false);
  const editshow = (data) => {
    setsubadmin(data?.subadmin);
    setbookDetails(data?.bookCab);
    setairportTarif(data?.AirportTriff);
    setoutstationTarif(data?.OutstationTariff);
    setaccount(data?.payments);
    setrental(data?.Rentel);
    setdriver(data?.Driver);
    setvehiclecat(data?.category);
    setuser(data?.user);
    setvehicle(data?.vehical);
    setvehicleModel(data?.vehicalModel);
    setvehicleName(data?.vehicleName);
    setbanner(data?.Banner);
    setexpense(data?.expanse);
    setspecialday(data?.specialDay);
    sethomeDriver(data?.homeDriver);
    setgst(data?.gst);
    setairportDetail(data?.airportRoute);
    setCoupon(data?.Coupon)
    setwallet(data?.wallet)
    setshow4(true);
  };
  const handleClose4 = () => setshow4(false);

  const updateSubAdmin = async () => {
    try {
      const config = {
        url: "/admin/udateSubAdmin",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          authId: admin1?._id,
          subadminId: editid?._id,
          name: name,
          email: email,
          password: password,
          mobile: mobile,
          AirportTriff: airportTarif,
          OutstationTariff: outstationTarif,
          payments: account,
          bookCab: bookDetails,
          Rentel: rental,
          Driver: driver,
          subadmin: subadmin,
          category: vehiclecat,
          user: user,
          vehical: vehicle,
          vehicalModel: vehicleModel,
          vehicleName: vehicleName,
          Banner: banner,
          expanse: expense,
          specialDay: specialday,
          homeDriver: homeDriver,
          gst: gst,
          airportRoute: airportDetail,
          Coupon:Coupon,
          wallet:wallet,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Updated");
        window.location.reload();
      }
    } catch (error) {
      alert(error.responce.data.error);
      console.log(error);
    }
  };
  return (
    <>
      <div style={{ paddingLeft: "0px" }}>
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <Button
            style={{
              color: "white",
              backgroundColor: "#fdd12d",
              border: "#fdd12d",
            }}
          >
            {" "}
            All SubAdmin
          </Button>
          <Button
            style={{
              color: "white",
              backgroundColor: "#fdd12d",
              border: "#fdd12d",
            }}
            onClick={handleShow}
          >
            {" "}
            Create SubAdmin
          </Button>
        </div>
        <br />
        <div className="accountt">
          <div className="vwv">
            <div style={{ paddingLeft: "0px" }}>
              <div style={{ backgroundColor: "#f0f0f0" }}>
                <div class="row" style={{ padding: "10px 20px" }}>
                  <div class="col-sm-4">
                    <div class="search-box  d-inline-block">
                      <div
                        class="position-relative"
                        style={{ display: "flex" }}
                      >
                        <input
                          id="search-bar-0"
                          type="text"
                          aria-labelledby="search-bar-0-label"
                          class="form-control "
                          placeholder="Search sub admin"
                          style={{
                            borderRadius: "50px",
                            border: "1px solid #fdd12d",
                          }}
                          onChange={handleFilter}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <div class="react-bootstrap-table">
                      <table
                        class="table table align-middle table-nowrap"
                        style={{ width: "90%", margin: "2%" }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#fdd12d",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          <tr style={{ padding: "10px" }}>
                            <th
                              tabindex="0"
                              aria-label="Username sortable"
                              class="sortable"
                            >
                              S.No
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Name
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Email
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Phone
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Date
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Access Modules
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Action
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {AllsubAdmin?.filter(
                            (ele) =>
                              ele?._id != admin1?._id &&
                              ele?.adminType == "SubAdmin"
                          )
                            ?.slice(pagesVisited, pagesVisited + usersPerPage)
                            ?.map((data, i) => {
                              return (
                                <tr style={{ textAlign: "center" }}>
                                  <td>{i + 1}</td>

                                  <td>{data?.name}</td>
                                  <td>{data?.email}</td>
                                  <td>{data?.mobile}</td>
                                  <td>
                                    {moment(data?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <AiFillEye
                                      style={{
                                        fontSize: "25px",
                                        color: "blue",
                                      }}
                                      onClick={() => {
                                        setshowdata(data);
                                        handleShow1();
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      type="button"
                                      variant="success"
                                      className="mx-2"
                                      onClick={() => {
                                        seteditid(data);
                                        editshow(data);
                                      }}
                                    >
                                      <span>
                                        <RiEditLine />
                                      </span>
                                    </Button>
                                    <Button
                                      type="button"
                                      variant="danger"
                                      onClick={() => {
                                        setdeleteId(data?._id);
                                        delethandel();
                                      }}
                                    >
                                      <span>
                                        <RiDeleteBin6Line />
                                      </span>
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sub-Admin Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="fwei">
            <div className="mb-2">
              <input
                type="text"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setname(e.target.value)}
                placeholder="Sub Admin Name"
              />
            </div>
            <div className="mb-2">
              <input
                type="number"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                placeholder="Phone Number"
                onChange={(e) => setmobile(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <input
                type="email"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setemail(e.target.value)}
                placeholder="Email Id"
              />
            </div>

            <div className="mb-2">
              <input
                type="password"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setpassword(e.target.value)}
                placeholder="Password"
              />
            </div>
          </div>
          <div className="dgier mt-4">
            <h4 style={{ color: "#fdd12d" }}>Access Modules</h4>
            <div className="grse" style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={banner}
                  onChange={() => setbanner(!banner)}
                />
                <label>Banner</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={subadmin}
                  onChange={() => setsubadmin(!subadmin)}
                />
                <label>SubAdmin</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Coupon}
                  onChange={() => setCoupon(!Coupon)}
                />
                <label>Coupon</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={bookDetails}
                  onChange={() => setbookDetails(!bookDetails)}
                />
                <label>AllBooking</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={airportDetail}
                  onChange={() => setairportDetail(!airportDetail)}
                />
                <label>Airport Routes</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={airportTarif}
                  onChange={() => setairportTarif(!airportTarif)}
                />
                <label>Airport Tariffs</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={outstationTarif}
                  onChange={() => setoutstationTarif(!outstationTarif)}
                />
                <label>Out Station Tariffs</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={rental}
                  onChange={() => setrental(!rental)}
                />
                <label>Rental</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={driver}
                  onChange={() => setdriver(!driver)}
                />
                <label>Drivers</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={homeDriver}
                  onChange={() => sethomeDriver(!homeDriver)}
                />
                <label>Home Drivers</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={wallet}
                  onChange={() => setwallet(!wallet)}
                />
                <label>Driver wallet</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={vehiclecat}
                  onChange={() => setvehiclecat(!vehiclecat)}
                />
                <label>Vchicle Category</label>
              </div>

              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={vehicleName}
                  onChange={() => setvehicleName(!vehicleName)}
                />
                <label>Vehicle Model</label>
              </div>
              {/* <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={vehicleModel}
                  onChange={() => setvehicleModel(!vehicleModel)}
                />
                <label>Vehicle Model</label>
              </div> */}
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={vehicle}
                  onChange={() => setvehicle(!vehicle)}
                />
                <label>Vehicle</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={user}
                  onChange={() => setuser(!user)}
                />
                <label>Users</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={specialday}
                  onChange={() => setspecialday(!specialday)}
                />
                <label>Special Days</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={gst}
                  onChange={() => setgst(!gst)}
                />
                <label>GST</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={account}
                  onChange={() => setaccount(!account)}
                />
                <label>Account History</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={expense}
                  onChange={() => setexpense(!expense)}
                />
                <label>Expense</label>
              </div>
              {/* <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={account}
                  onChange={()=>setaccount(!account)}
                />
                <label>Account History</label>
              </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addSubadmin}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Offcanvas */}

      <Offcanvas show={show1} onHide={handleClose1}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Sub Admin All Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="efqevs mb-2">
            <div className="mt-2 mb-2">
              <h5 style={{ color: "black" }}>Name: {showdata?.name}</h5>
            </div>
            <div className="mt-2 mb-2">
              <h5 style={{ color: "black" }}>Email: {showdata?.email}</h5>
            </div>
            <div className="mt-2 mb-2">
              <h5 style={{ color: "black" }}>Mobile : {showdata?.mobile}</h5>
            </div>
          </div>
          <div style={{ fontSize: "25px", fontWeight: "800", color: "black" }}>
            AccessModules
          </div>
          <ul style={{ listStyle: "none" }}>
            <li style={{ padding: "2px" }}>
              Banner{" "}
              <span>
                {showdata?.Banner == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              SubAdmin{" "}
              <span>
                {showdata?.subadmin == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              Coupon{" "}
              <span>
                {showdata?.Coupon == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              AllBooking{" "}
              <span>
                {showdata?.bookCab == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              Airport Routes{" "}
              <span>
                {showdata?.airportRoute == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              Airport Tariffs{" "}
              <span>
                {showdata?.AirportTriff == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              Out Station Tariffs{" "}
              <span>
                {showdata?.OutstationTariff == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              Rental{" "}
              <span>
                {showdata?.Rentel == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              Drivers{" "}
              <span>
                {showdata?.Driver == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              Home Drivers{" "}
              <span>
                {showdata?.homeDriver == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
               Drivers wallet{" "}
              <span>
                {showdata?.wallet == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              Vehicle Category{" "}
              <span>
                {showdata?.category == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              Vehicle Model{" "}
              <span>
                {showdata?.vehicleName == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            {/* <li style={{ padding: "2px" }}>
              Vehicle Model{" "}
              <span>
                {showdata?.vehicalModel == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li> */}
            <li style={{ padding: "2px" }}>
              Vehicles{" "}
              <span>
                {showdata?.vehical == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              Users{" "}
              <span>
                {showdata?.user == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              Special Days{" "}
              <span>
                {showdata?.specialDay == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              GST{" "}
              <span>
                {showdata?.gst == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              Account History{" "}
              <span>
                {showdata?.payments == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
              Expense{" "}
              <span>
                {showdata?.expanse == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            {/* <li>GST{" "}<span>{showdata?.gst==true ? (<MdDone style={{color:"green",fontSize:"20px",fontWeight:"bold",marginLeft:"25px"}}/>):(<RxCross2 style={{color:"red",fontSize:"20px",fontWeight:"bold",marginLeft:"25px"}}/>)}</span> </li> */}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      {/* delete Model */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete?</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="success" onClick={deleteSubAdminData}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit SubAdmin */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Update Sub-Admin Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="fwei">
            <div className="mb-2">
              <input
                type="text"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setname(e.target.value)}
                placeholder={editid?.name}
              />
            </div>
            <div className="mb-2">
              <input
                type="number"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                placeholder={editid?.mobile}
                onChange={(e) => setmobile(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <input
                type="email"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setemail(e.target.value)}
                placeholder={editid?.email}
              />
            </div>

            <div className="mb-2">
              <input
                type="password"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setpassword(e.target.value)}
                placeholder="Password"
              />
            </div>
          </div>
          <div className="dgier mt-4">
            <h4 style={{ color: "#fdd12d" }}>Access Modules</h4>
            <div className="grse" style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={banner}
                  onChange={() => setbanner(!banner)}
                />
                <label>Banner</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={subadmin}
                  onChange={() => setsubadmin(!subadmin)}
                />
                <label>SubAdmin</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Coupon}
                  onChange={() => setCoupon(!Coupon)}
                />
                <label>Coupon</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={bookDetails}
                  onChange={() => setbookDetails(!bookDetails)}
                />
                <label>AllBooking</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={airportDetail}
                  onChange={() => setairportDetail(!airportDetail)}
                />
                <label>Airport Routes</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={airportTarif}
                  onChange={() => setairportTarif(!airportTarif)}
                />
                <label>Airport Tariffs</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={outstationTarif}
                  onChange={() => setoutstationTarif(!outstationTarif)}
                />
                <label>Out Station Tariffs</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={rental}
                  onChange={() => setrental(!rental)}
                />
                <label>Rental</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={driver}
                  onChange={() => setdriver(!driver)}
                />
                <label>Drivers</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={homeDriver}
                  onChange={() => sethomeDriver(!homeDriver)}
                />
                <label>Home Drivers</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={wallet}
                  onChange={() => setwallet(!wallet)}
                />
                <label>Drivers wallet</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={vehiclecat}
                  onChange={() => setvehiclecat(!vehiclecat)}
                />
                <label>Vchicle Category</label>
              </div>

              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={vehicleName}
                  onChange={() => setvehicleName(!vehicleName)}
                />
                <label>Vehicle Model</label>
              </div>
              {/* <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={vehicleModel}
                  onChange={() => setvehicleModel(!vehicleModel)}
                />
                <label>Vehicle Model</label>
              </div> */}
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={vehicle}
                  onChange={() => setvehicle(!vehicle)}
                />
                <label>Vehicle</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={user}
                  onChange={() => setuser(!user)}
                />
                <label>Users</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={specialday}
                  onChange={() => setspecialday(!specialday)}
                />
                <label>Special Days</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={gst}
                  onChange={() => setgst(!gst)}
                />
                <label>GST</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={account}
                  onChange={() => setaccount(!account)}
                />
                <label>Account History</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={expense}
                  onChange={() => setexpense(!expense)}
                />
                <label>Expense</label>
              </div>
              {/* <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={account}
                  onChange={()=>setaccount(!account)}
                />
                <label>Account History</label>
              </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button variant="primary" onClick={updateSubAdmin}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Subadmin;
