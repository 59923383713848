import React, { useState } from "react";
import "./Styles/Register1.css";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import {Link} from 'react-router-dom'
import axios from "axios";
import { RxCrossCircled } from "react-icons/rx";
import Buttonn from 'react-bootstrap/Button';
import { Modal } from "antd";

function Register() {
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [name,setname]=useState("");
  const [mobile,setmobile]=useState("")
  const [email,setemail]=useState("")
  const [password,setpassword]=useState("")
  const [cpassword,setcpassword]=useState("")
  const [modal2Open, setModal2Open] = useState(false);

  const [errorData,setErrordata]=useState("")
  const erorShow=(data)=>{
    setErrordata(data)
    setModal2Open(true)
  }

  function  isEmpty(str){
    if(typeof (str) == "undefined" || typeof (str) == null) {return false}
    if(typeof (str) == "string" && (str).trim().length == 0) {return false}
    if(typeof (str) == 'number' && (str).toString().trim().length == 0){return false}
    return true
}

function  isValidMobile(mobile){
    mobile=mobile?.trim();
    if(mobile?.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) ){  //&& ! (mobile.match(/0{5,}/))
        return true;
    }
    return false;
}

function isValidEmail(emailid){
    emailid=emailid?.trim();
    if(emailid?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)){
        return true;
    }
    return false;
}


  const sinup=async (e)=>{
    e.preventDefault()
    if(!isEmpty(name)) return  erorShow("Please enter full name!")
    if(!isEmpty(mobile)) return erorShow("Please enter mobile number!")
    if(!isValidMobile(mobile)) return erorShow("Invalid mobile number!")
    if(!isEmpty(email)) return erorShow("Please enter email!")
    if(!isValidEmail(email)) return erorShow("Invalid email!")
    if(!isEmpty(password)) return erorShow("Please enter password!");
    if(!isEmpty(cpassword)) return erorShow("Please enter confirm password!");
    if(password !==cpassword) return erorShow("Confirm password did not match")
    
    try{
      const config = {
        url: "/register-customer-web",
        method: "post",
        baseURL: "https://goeasycab.info/api/customer",
        headers: { "conttent-type": "application/json" },
        data:{mobile:mobile,name:name,email:email,password:password}
      };
  
      let res=await axios(config);
      if(res.status===200){
        alert("Successfully register please login");
        window.location.assign("/login")
      }
    } catch (error) {
        erorShow(error.response.data.error)
      console.log(error);
    }
  }
  return (
    <>
    <section className="common-use" style={{
     margin:"5% 0%"
    }}>
      <div className="user reg-0" >
        <div className="user-re">
          <div class="title-text">
            <h2 style={{fontWeight:"600",color:"#333333"}}>Create Account</h2>

            <p>Please register below account details</p>
          </div>
          <div className="form-re">
            <form>
              <div className="detail_hero mb-3">
                <label>
                  <i class="fa fa-user-o" aria-hidden="true"></i>
                </label>

                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="regis-pro"
                  required
                  value={name}
                  onChange={(e)=>setname(e.target.value)}
                />
              </div>
            
              <div className="detail_hero mb-3">
                <label>
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </label>

                <input
                  type="number"
                  name="mobile"
                  placeholder="Mobile No"
                  className="regis-pro"
                  required
                  value={mobile}
                  onChange={(e)=>setmobile(e.target.value)}
                />
              </div>
              <div className="detail_hero mb-3">
                <label>
                  <i class="fa fa-envelope-o" aria-hidden="true"></i>
                </label>

                <input
                  type="email"
                  name="email"
                  placeholder="example@gmail.com"
                  className="regis-pro"
                  required
                  value={email}
                  onChange={(e)=>setemail(e.target.value)}
                />
              </div>
              <div className="detail_hero mb-3">
                <label>
                  <i class="fa fa-lock" aria-hidden="true"></i>
                </label>
                <Space direction="vertical">
                <Input.Password placeholder="Password"   className="regis-pro"  value={password} onChange={(e)=>setpassword(e.target.value)}/>
              </Space>
              </div>
              <div className="detail_hero mb-3">
                <label>
                  <i class="fa fa-lock" aria-hidden="true"></i>
                </label>
               
                <input
                  type="text"
                  name="cpassword"
                  placeholder="Confirm Password"
                  className="regis-pro"
                  required
                  value={cpassword}
                  onChange={(e)=>setcpassword(e.target.value)}
                />
              </div>
              <div class="form-action-button">
                    <button type="submit" onClick={(e)=>sinup(e)}>Register</button>
                  </div>
                  
            </form>
          </div>
         
        </div>
        <div className="form-re" style={{marginTop:"16%"}}>
              <div className="already">
                  <div className="">Already an account holder?</div>
                  <div class="form-action-button-0">
                  <Link to="/login"><button type="submit" class="ant-btn css-dev-only-do-not-override-1xusghl ant-btn-default hvr-shutter-out-horizontal">Login</button></Link>  
                  </div>
              </div>
          </div>
      </div>
    </section>
    <Modal
        // title="Vertically centered modal dialog"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <div
          className=""
          style={{ display: "flex", justifyContent: "center", }}
        >
          <div className="">
            <div
              className="wrong"
              style={{
                fontSize: " 100px",
                color: "#ff000087",
                textAlign: "center",
              }}
            >
              <RxCrossCircled />
            </div>
            <div className="nf_0">{errorData}</div>
            <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30" }}
                onClick={() => setModal2Open(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
  </>
  );
}

export default Register;
