import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import CustomerDetails from "./Components/CustomerDetails";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Login from "./Components/Login";

import Home from "./Components/Home";
import SearchCav from "./Components/SearchCav";
import Adminlogin from "./Admin/Adminlogin";
import AdminPanel from "./Admin/AdminPanel";
import Admindashboard from "./Admin/Admindashboard";
import Vehiclecategories from "./Admin/Vehiclecategories";
import Bookingdetails from "./Admin/Bookingdetails";
import Routes1 from "./Admin/Routes1";
import GST from "./Admin/GST";
import Packages from "./Admin/Packages";
import Vehicle from "./Admin/Vehicle";
import Drivers from "./Admin/Drivers";
import User from "./Admin/User";
import Register from "./Components/Register";
import EditProfile from "./Components/EditProfile";
import ViewTrip from "./Components/ViewTrip";
import OutStation from "./Components/OutStation";
import OutstationRoute from "./Admin/OutstationRoute";
import SpecialDay from "./Admin/SpecialDay";
import AirpotPackage from "./Admin/AirpotPackage";
import AddAirportRates from "./Admin/AddAirportRates";
import Invoice from "./Components/Invoice";
import "aos/dist/aos.css";
import AOS from "aos";
import AccountHistory from "./Admin/AccountHistory";
import Expense from "./Admin/Expense";
import Banner from "./Admin/Banner";
import ExpenseList from "./Admin/ExpenseList";
import AirpotPackageEdit from "./Admin/AirpotPackageEdit";
import VchicleName from "./Admin/VchicleName";
import VchicleModal from "./Admin/VchicleModal";
import TripList from "./Admin/TripList";
import RouteEdit from "./Admin/RouteEdit";
import RentalEdit from "./Admin/RentalEdit";
import VechileEdit from "./Admin/VechileEdit";
import AdminInvoice from "./Admin/AdminInvice";
import Voucher from "./Components/Voucher";
import HomdeDriver from "./Admin/HomdeDriver";
import Subadmin from "./Admin/Subadmin";
import SoldOut from "./Admin/SoldOut";
import FlashBid from "./Admin/FlashBid";
import Map from "./Admin/Map";
import Coupon from "./Admin/Coupon";
import Wallet from "./Admin/Wallet";
import TermsCondition from "./Components/TermsCondition";
import UseAgreement from "./Components/UseAgreement";
import PageLoader from "./Components/Pageloader";
import Privacypolicy from "./Components/Privacypolicy";
function App() {
  AOS.init();

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Home />
                <Footer />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Header />
                <Login />
                <Footer />
              </>
            }
          />
          <Route
            path="/terms-condition"
            element={
              <>
                <Header />
                <TermsCondition />
                {/* <Footer /> */}
              </>
            }
          />
          <Route
            path="/user-agreement"
            element={
              <>
                <Header />
                <UseAgreement />
                {/* <Footer /> */}
              </>
            }
          />
           <Route
            path="/App/user-agreement"
            element={
              <>
                {/* <Header /> */}
                <UseAgreement />
                {/* <Footer /> */}
              </>
            }
          />
           <Route
            path="/privacypolicy"
            element={
              <>
                {/* <Header /> */}
                <Privacypolicy />
                {/* <Footer /> */}
              </>
            }
          />
          <Route
            path="/App/Terms-Condition"
            element={
              <>
                {/* <Header /> */}
                <TermsCondition />
                {/* <Footer /> */}
              </>
            }
          />
          <Route
            path="/invoice/:id"
            element={
              <>
                {/* <Header /> */}
                <Invoice />
                {/* <Footer /> */}
              </>
            }
          />
          <Route
            path="/voucher/:id"
            element={
              <>
                {/* <Header /> */}
                <Voucher />
                {/* <Footer /> */}
              </>
            }
          />
          <Route
            path="/Editprofile"
            element={
              <>
                <Header />
                <EditProfile />
                <Footer />
              </>
            }
          />
          <Route
            path="/View-trip"
            element={
              <>
                <Header />
                <ViewTrip />
                <Footer />
              </>
            }
          />
          <Route
            path="/register"
            element={
              <>
                <Header />
                <Register />
                <Footer />
              </>
            }
          />

          <Route
            path="/search"
            element={
              <>
                <Header />
                <PageLoader />
                <SearchCav />
              </>
            }
          />
          <Route
            path="/customerDetails"
            element={
              <>
                <Header />
                <CustomerDetails />
              </>
            }
          />

          <Route
            path="/Admin"
            element={
              <>
                <Adminlogin />
              </>
            }
          />

          <Route
            path="/AdminPanel"
            element={
              <>
                <AdminPanel />
              </>
            }
          />
          <Route
            path="/admin/User"
            element={<AdminPanel children={<User />} />}
          ></Route>
          <Route
            path="/admin/subadmin"
            element={<AdminPanel children={<Subadmin />} />}
          ></Route>
          <Route
            path="/admin/Drivers"
            element={<AdminPanel children={<Drivers />} />}
          ></Route>
          <Route
            path="/admin/Invoice"
            element={<AdminPanel children={<AdminInvoice />} />}
          ></Route>
          <Route
            path="/admin/dashbord"
            element={<AdminPanel children={<Admindashboard />} />}
          ></Route>
          <Route
            path="/admin/track-driver/:id"
            element={<AdminPanel children={<Map />} />}
          ></Route>
          <Route
            path="/admin/airport-addrates"
            element={<AdminPanel children={<AddAirportRates />} />}
          ></Route>
          <Route
            path="/admin/Home-Drivers"
            element={<AdminPanel children={<HomdeDriver />} />}
          ></Route>
          <Route
            path="/admin/Vehiclecategories"
            element={<AdminPanel children={<Vehiclecategories />} />}
          ></Route>
          <Route
            path="/admin/Bookingdetails"
            element={<AdminPanel children={<Bookingdetails />} />}
          ></Route>
          <Route
            path="/admin/Routes"
            element={<AdminPanel children={<Routes1 />} />}
          ></Route>
          <Route
            path="/admin/Coupon"
            element={<AdminPanel children={<Coupon />} />}
          ></Route>
          <Route
            path="/admin/GST"
            element={<AdminPanel children={<GST />} />}
          ></Route>
          <Route
            path="/admin/Packages"
            element={<AdminPanel children={<Packages />} />}
          ></Route>
          <Route
            path="/admin/Vehicle"
            element={<AdminPanel children={<Vehicle />} />}
          ></Route>
          <Route
            path="/admin/Flash-bid-list"
            element={<AdminPanel children={<FlashBid />} />}
          ></Route>
          <Route
            path="/admin/special-day"
            element={<AdminPanel children={<SpecialDay />} />}
          ></Route>
          <Route
            path="/admin/Airport-rates"
            element={<AdminPanel children={<AirpotPackage />} />}
          ></Route>

          <Route
            path="/admin/Airport-rates/edit"
            element={<AdminPanel children={<AirpotPackageEdit />} />}
          ></Route>
          <Route
            path="/admin/outstation-route"
            element={<AdminPanel children={<OutstationRoute />} />}
          ></Route>
          <Route
            path="/admin/account-history"
            element={<AdminPanel children={<AccountHistory />} />}
          ></Route>
          <Route
            path="/admin/driver-wallet"
            element={<AdminPanel children={<Wallet />} />}
          ></Route>
          <Route
            path="/admin/expense"
            element={<AdminPanel children={<Expense />} />}
          ></Route>
          <Route
            path="/admin/expense-list"
            element={<AdminPanel children={<ExpenseList />} />}
          ></Route>
          <Route
            path="/admin/banner"
            element={<AdminPanel children={<Banner />} />}
          ></Route>
          <Route
            path="/admin/vchicle-name"
            element={<AdminPanel children={<VchicleName />} />}
          ></Route>
          <Route
            path="/admin/vchicle-modal"
            element={<AdminPanel children={<VchicleModal />} />}
          ></Route>
          <Route
            path="/admin/trip-list"
            element={<AdminPanel children={<TripList />} />}
          ></Route>
          <Route
            path="/admin/route-edit"
            element={<AdminPanel children={<RouteEdit />} />}
          ></Route>
          <Route
            path="/admin/rental-edit"
            element={<AdminPanel children={<RentalEdit />} />}
          ></Route>
          <Route
            path="/admin/vechile-edit"
            element={<AdminPanel children={<VechileEdit />} />}
          ></Route>
          <Route
            path="/admin/sold-out"
            element={<AdminPanel children={<SoldOut />} />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
