import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Card } from "react-bootstrap";
import * as XLSX from "xlsx";
import "../Admin/Admin.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaImages } from "react-icons/fa";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { GiCancel } from "react-icons/gi";
import exportFromJSON from "export-from-json";
import { ImFolderDownload } from "react-icons/im";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useNavigate } from "react-router-dom";
function Routes1() {
  const history = useNavigate();
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const formdata = new FormData();
  const [from, setfrom] = useState();
  const [to, setto] = useState("");
  const [roundTrip, setroundTrip] = useState("");
  const [totalKm, settotalKm] = useState("");
  const [price, setprice] = useState("");
  const [Discount, setDiscount] = useState("");
  const [numberoftoll, setnumberoftoll] = useState("");
  const [toteltollcharge, settoteltollcharge] = useState("");
  const [totalprice, settotalprice] = useState(0);

  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  console.log("admin", admin1._id);
  let obj = {
    authId: admin1._id,
    to: to,
    from: from,
    roundTrip: roundTrip === "true" ? true : roundTrip === "false" ? false : "",
    totalKm: totalKm,
    BasePrice: price,
    discoont: Discount,
    totalprice: totalprice,
    numberOftoll: numberoftoll,
    totalTol: toteltollcharge,
    isActive: true,
  };

  const [data, setdata] = useState();
  const [nochangedata, setnochangedata] = useState([]);
  const [dataSource, setdataSource] = useState([]);

  const Allroutes = async () => {
    try {
      let res = await axios.get("https://goeasycab.info/api/admin/getRoute");
      if (res.status === 200) {
        setdataSource(res.data.success);
        setdata(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Allroutes();
  }, []);
  // Export to Excel

  const makeActiveAndDectiveRoute = async (routeId, isActive) => {
    try {
      let status;
      if (isActive) {
        status = "Activated";
      } else {
        status = "Deactivated";
      }
      const config = {
        url: "/admin/makeActiveAndDectiveRoute",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: { authId: admin1._id, routeId: routeId, isActive: isActive },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(`Successfully ${status}`);
        Allroutes();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(dataSource.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };
  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value != "") {

      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setdataSource([...filterTable]);
    } else {
      setdataSource([...nochangedata]);
    }
  };
  const [deleteId, setdeleteId] = useState("");
  const removeroutes = async (data) => {
    try {
      const config = {
        url: "/admin/deleteRoute/" + deleteId + "/" + admin1?._id,
        method: "delete",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);

          alert("Deleted Successfully");
          handleClose1();
          Allroutes();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const [excel, setexcel] = useState();
  const [exceldata, setexceldata] = useState([]);
  console.log(excel);

  function readFile() {
    var name = excel.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      console.log("Data>>>" + data); // shows that excel data is read
      console.log(convertToJson(data)); // shows data in json format
      setexceldata(JSON.parse(convertToJson(data)));
    };
    reader.readAsBinaryString(excel);
  }
  function convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        // console.log(currentline[j]?.replace(/_/g, ","));
        obj[headers[j]] = currentline[j]?.replace(/_/g, ",");
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }
  const [fromdate, setfromdate] = useState("");
  const [ttodate, setttodate] = useState("");
  const searchdate = () => {
    try {
      if (!fromdate) return alert("From date required");
      if (!ttodate) return alert("To date required");

      let std = fromdate?.split("-");
      let entd = ttodate?.split("-");
      let startD = parseInt(std?.join(""));
      let endD = parseInt(entd?.join(""));
      let abc = nochangedata?.filter((ele) => {
        let abd = ele?.ToDate?.split("/")?.reverse();
        let amd = parseInt(abd?.join(""));
        return startD <= amd && endD >= amd;
      });
      setdataSource(abc);
      setdata(abc);
    } catch (error) {
      console.log(error);
    }
  };
  const postRoutes = async (e) => {
    e.preventDefault();

    if (excel) {
      try {
        const config = {
          url: "/admin/uploadexcelroute",
          method: "post",
          baseURL: "https://goeasycab.info/api",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${admin}`,
          },
          data: {
            authId: admin1._id,
            route: exceldata
              ?.filter((ele) => ele.to)
              .map((datas) => ({
                to: datas.to,
                from: datas.from,
                roundTrip: datas.TripType,
                totalKm: datas.totalKm,
                BasePrice: datas.TotalPrice,
                totalTol: datas.totalTollCharge,
                perkmprice: datas.Base_Fair,
                waitingCharge: datas.waitingCharge,
                waitingTime: datas.waitingTime,
                vehicaleCategory: datas.vehicaleCategory,
                vehicleModel: datas.vehicleModel,
                ratePlane: datas.RatePlane,
                fromDate: datas.FromDate,
                ToDate: datas.EndDate,
                extraFair: datas.ExtraFair,
                fuel: datas.FuelType,
                vechicleId: datas.vechicleId,
                isActive: true,
                discoont: datas?.discount,
                stateTax:datas?.State_Tax,
                driverBata:datas?.Driver_Allowance,
                parkingCharge:datas?.Parking_Charge
              })),
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Successfully uploaded");
          setexceldata([]);
          setexcel();
          Allroutes();
          return res;
        }
      } catch (error) {
        alert("Please don't write any special characters Eg. , @ $ % & * etc)");
        console.log(error);
      }
    } else {
      alert("Please import excel  file");
    }
  };
  // console.log("amit",exceldata.filter((ele)=>ele.to))
  const [noAllCity, setnoAllcity] = useState([]);
  const [allCity, setAllcity] = useState([]);
  const getAllCity = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllAirportCity"
      );
      if (res.status === 200) {
        setAllcity(res.data.success);
        setnoAllcity(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCity();
  }, []);

  if (excel && exceldata.length === 0) {
    readFile();
  }
  const [soldStart, setsoldStart] = useState("");
  const [soldEnd, setsoldEnd] = useState("");
  const [allSold, setAllsold] = useState(false);
  const [slodid, setsoldid] = useState("");
  const [showSold, setShowSold] = useState(false);
  const handeleShowld = () => setShowSold(true);
  const handleCloseShold = () => setShowSold(false);

  const allSoldOutUnsoldOut = async (isSold) => {
    try {
      let start = "";
      let end = "";
      if (isSold == true) {
        if (!soldStart) return alert("Please select start date");
        if (!soldEnd) return alert("Please select end date");
        start = soldStart;
        end = soldEnd;
      }
      const config = {
        url: "/admin/AllsoudAndunSoldOutOutsataionRoute",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          authId: admin1?._id,
          isSold: isSold,
          soldStart: start,
          soldEnd: end,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success);
        setAllsold(false);
        handleCloseShold();
        setsoldStart("");
        setsoldEnd("");
        Allroutes();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const SoldUnsoldRoutesDetails = async (id) => {
    try {
      if (!soldStart) return alert("Please select start date");
      if (!soldEnd) return alert("Please select end date");
      const config = {
        url: "/admin/SoldAndUnsoldRoutes",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          authId: admin1?._id,
          id: slodid,
          isSold: true,
          soldStart: soldStart,
          soldEnd: soldEnd,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success);
        handleCloseShold();
        setsoldStart("");
        setsoldEnd("");
        Allroutes();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const UnsoldRoutesDetails = async (id, isSold) => {
    try {
      const config = {
        url: "/admin/SoldAndUnsoldRoutes",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          authId: admin1?._id,
          id: id,
          isSold: false,
          soldEnd: "",
          soldStart: "",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success);
        Allroutes();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [fromCity, setfromCity] = useState("");
  const [AllDeleteData, setAllDeleteData] = useState([]);
  const searchCityData = (e) => {
    try {
      if (!fromCity) return alert("Please select from city");
      let amData = nochangedata?.filter(
        (ele) => ele?.from == fromCity && ele?.to == e.target.value
      );
      setdataSource(amData);
      return setAllDeleteData(amData);
    } catch (error) {
      console.log(error);
    }
  };
  const [showAl,setshowAl]=useState(false);
  const handeleShoAl=()=>setshowAl(true);
  const handleCloseAl=()=>setshowAl(false)
  const AllDeleteFunction = async () => {
    try {
      for (let i = 0; i < AllDeleteData.length; i++) {
        const config = {
          url:
            "/admin/deleteRoute/" + AllDeleteData[i]?._id + "/" + admin1?._id,
          method: "delete",
          baseURL: "https://goeasycab.info/api",
          headers: { Authorization: `Bearer ${admin}` },
        };
        await axios(config);
      }
      alert("Successfully all deleted");
      window.location.reload()
      setAllDeleteData([]);
      Allroutes();
    } catch (error) {
      console.log(error);
    }
  };
  const [showDrop,setshoWDrop]=useState(false);
  const handDropShow=()=>setshoWDrop(true);
  const handCloseDrop=()=>setshoWDrop(false);

  const deleteAllRoute=async()=>{
    try {
      const config = {
        url:"/admin/deleteAllRoute/" + admin1?._id,
        method: "delete",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
     let res= await axios(config);
     if(res.status==200){
      alert("Successfully droped data")
      Allroutes();
     }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div style={{ paddingLeft: "0px" }}>
       <Button
        style={{
          color: "white",
          backgroundColor: "#fdd12d",
          border: "#fdd12d",
        }}
      >
        {" "}
        Routes
      </Button> 
    
      <br />
      <div className="accountt">
        <form>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-md-3">
              <div className="jjr mb-2">
                <label>From City</label>
                <br />
                <select
                  className="new-cab"
                  onChange={(e) => setfromCity(e.target.value)}
                >
                  <option>Select City</option>
                  {[
                    ...new Map(
                      nochangedata.map((item) => [item?.from, item])
                    ).values(),
                  ]?.map((ele) => {
                    return <option value={ele?.from}>{ele?.from}</option>;
                  })}
                </select>
                {/* <input type="date" className="new-cab" onChange={(e)=>setfromdate(e.target.value)}/> */}
              </div>
            </div>
            <div className="col-md-3">
              <div className="jjr mb-2">
                <label>To City</label>
                <br />
                <select className="new-cab" onChange={(e) => searchCityData(e)}>
                  <option>Select City</option>
                  {[
                    ...new Map(
                      nochangedata.map((item) => [item?.to, item])
                    ).values(),
                  ]?.map((ele) => {
                    return <option value={ele?.to}>{ele?.to}</option>;
                  })}
                </select>
              </div>
            </div>
            {AllDeleteData.length != 0 ? (
              <div className="col-md-3">
                <button
                  type="button"
                  class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
                  onClick={handeleShoAl}
                  style={{ color: "red" }}
                >
                  <span>All Delete</span>
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-md-3">
              <div className="jjr mb-2">
                <label>Date From</label>
                <br />
                <input
                  type="date"
                  className="new-cab"
                  onChange={(e) => setfromdate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="jjr mb-2">
                <label>Date To</label>
                <br />
                <input
                  type="date"
                  className="new-cab"
                  onChange={(e) => setttodate(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="col-md-3">
            <div class="search-box  d-inline-block">
              <div class="position-relative" style={{ display: "flex" }}>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  placeholder="Search"
               
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                />
              </div>
            </div>
            </div> */}
            <div className="col-md-3">
              <button
                type="button"
                class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
                onClick={searchdate}
              >
                <span>Search</span>
              </button>
            </div>
            <div className="col-md-3">
              {nochangedata?.filter((ele) => ele?.isSold == false).length ==
              0 ? (
                <button
                  type="button"
                  class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
                  onClick={() => allSoldOutUnsoldOut(false)}
                >
                  <span style={{ color: "green" }}>All Un-Sold Out</span>
                </button>
              ) : (
                <button
                  type="button"
                  class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
                  onClick={() => {
                    setAllsold(true);
                    handeleShowld();
                  }}
                >
                  <span style={{ color: "red" }}>All Sold Out</span>
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <br />
      <div style={{ backgroundColor: "#f0f0f0" }}>
        <div class="row" style={{ padding: "10px 20px" }}>
          <div class="col-sm-4">
            <div class="search-box  d-inline-block">
              <div class="position-relative" style={{ display: "flex" }}>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  onChange={handleFilter}
                  placeholder="Search  Routes"
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div class="col-sm-2">
            <div class="search-box  d-inline-block">
              <div class="position-relative" style={{ display: "flex" }}>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  onChange={handleFilter}
                  placeholder="Search  Routes"
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                />
              </div>
            </div>
          </div> */}

          <div class="col-sm-8">
            
            <div class="text-sm-end">
            <button
                  style={{
                    padding: "2px 8px 7px 8px",
                    color: "black",
                    backgroundColor: "#fdd12d",
                    border: "none",
                    fontWeight: "600",
                    fontSize: "14px",
                    borderRadius: "20px",
                    margin: "0px 10px 0px 0px",
                  }}
                  onClick={() => {
                    handDropShow();
                  }}
                >
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                Drop All
                </button>
              {" "}
              <a href={"https://goeasycab.info/Routesupload.xls"}>
                <button
                  style={{
                    padding: "2px 8px 7px 8px",
                    color: "black",
                    backgroundColor: "#fdd12d",
                    border: "none",
                    fontWeight: "600",
                    fontSize: "14px",
                    borderRadius: "20px",
                    margin: "0px 10px 0px 0px",
                  }}
                  // onClick={() => {
                  //   setEnterfilenameModal(true);
                  // }}
                >
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                  Download Excel
                </button>
              </a>
              <input
                accept=".xlsx,.xls"
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                  margin: "0px 10px 0px 0px",
                  display: "none",
                }}
                id="icon-button-file"
                type="file"
                onChange={(e) => setexcel(e.target.files[0])}
              />
              <label
                className="btn-c btn-orange "
                htmlFor="icon-button-file"
                style={{
                  padding: "7px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                  margin: "0px 10px 0px 0px",
                }}
              >
                {" "}
                Import Excel
              </label>
              <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                  margin: "0px 10px 0px 0px",
                }}
                onClick={postRoutes}
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                Upload Excel
              </button>
              <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                  margin: "0px 10px 0px 0px",
                }}
                onClick={() => {
                  setEnterfilenameModal(true);
                }}
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                EXPORT TO EXCEL
              </button>
              <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                }}
                onClick={() =>
                  window.location.assign("/admin/outstation-route")
                }
              >
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  +
                </span>
                Routes
              </button>
            </div>
          </div>
          {/* <div style={{display:"flex"}}>
          <a
                  className="btn-c btn-orange"
                  href={
                    "https://goeasycab.info/Routesupload.xls"
                  }
                >
                  Download Excel
                </a>
                <input
                  accept=".xlsx,.xls"
                  style={{ display: "none" }}
                  id="icon-button-file"
                  type="file"
                  onChange={(e) => setexcel(e.target.files[0])}
                />
                <label className="btn-c btn-orange " htmlFor="icon-button-file">
                  {" "}
                  Import Excel
                </label>

                <button style={{color:"white",backgroundColor:"yellow"}}></button>
          </div> */}
          <div class="table-responsive">
            <div class="react-bootstrap-table">
              <table
                class="table table align-middle table-nowrap"
                style={{ width: "90%", margin: "2%" }}
              >
                <thead
                  style={{
                    backgroundColor: "#fdd12d",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <tr style={{ padding: "10px" }}>
                    <th
                      tabindex="0"
                      aria-label="Username sortable"
                      class="sortable"
                    >
                      S.No
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      From
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      To
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Trip
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      TotalKm
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Discount
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      State Tax
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Vehicle Category
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Vehicle Model
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Fuel Type
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Rate Plan
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      From Date
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      To Date
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Base Fare/km
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Extra fare/km
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Waiting Charges
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Free Waiting Minutes
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      total toll Charge
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Parking Charge
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Allowance
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Price
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>

                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Sold Start Date
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Sold End Date
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      isActive
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Action
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
             
                      {" "}
                      {dataSource
                        ?.slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((data, index) => {
                          return (
                            <tr style={{ textAlign: "center" }}>
                              <td>{index + 1}</td>
                              <td>{data?.from}</td>
                              <td>{data?.to}</td>
                              <td>{data?.roundTrip}</td>
                              <td>{data?.totalKm}</td>
                              <td>{data?.discoont}%</td>
                              <td>{data?.stateTax}</td>
                              <td>{data?.vehicaleCategory}</td>
                              <td>{data?.vehicleModel}</td>
                              <td>{data?.fuel}</td>
                              <td>{data?.ratePlane}</td>
                              <td>{data?.fromDate}</td>
                              <td>{data?.ToDate}</td>
                              <td>{data?.perkmprice}</td>
                              <td>{data?.extraFair}</td>
                              <td>{data?.waitingCharge}</td>
                              <td>{data?.waitingTime}</td>
                              {/* <td>{data?.waitingTime}</td> */}
                              <td>{data?.totalTol}</td>
                              <td>{data?.parkingCharge}</td>
                              <td>{data?.driverBata}</td>
                              <td>{data?.BasePrice}</td>
                              <td>
                                {data?.soldStart ? (
                                  moment(data?.soldStart).format("DD/MM/YYYY")
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>
                                {data?.soldEnd ? (
                                  moment(data?.soldEnd).format("DD/MM/YYYY")
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>
                                {data.isActive ? (
                                  <span style={{ color: "green" }}>
                                    Activated
                                  </span>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    Deactivated
                                  </span>
                                )}
                              </td>

                              <td style={{ display: "flex", gap: "5px" }}>
                                <i
                                  class="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                  title="Edit"
                                  style={{
                                    cursor: "pointer",
                                    color: "darkorange",
                                    fontSize: "23px",
                                  }}
                                  onClick={() =>
                                    history("/admin/route-edit", {
                                      state: data,
                                    })
                                  }
                                ></i>
                                /{" "}
                                <i
                                  class="fa fa-trash-o"
                                  aria-hidden="true"
                                  title="Remove"
                                  style={{
                                    cursor: "pointer",
                                    color: "green",
                                    fontSize: "23px",
                                  }}
                                  onClick={() => {
                                    setdeleteId(data?._id);
                                    setShow1(true);
                                  }}
                                ></i>
                                /
                                {data.isActive ? (
                                  <button
                                    type="button"
                                    class="btn btn-outline-danger"
                                    onClick={() =>
                                      makeActiveAndDectiveRoute(
                                        data?._id,
                                        false
                                      )
                                    }
                                  >
                                    Deactive
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-outline-success"
                                    onClick={() =>
                                      makeActiveAndDectiveRoute(data?._id, true)
                                    }
                                  >
                                    Active
                                  </button>
                                )}
                                {data?.isSold == false ? (
                                  <button
                                    class="btn btn-outline-success"
                                    onClick={() => {
                                      setsoldid(data?._id);
                                      setAllsold(false);
                                      handeleShowld();
                                    }}
                                  >
                                    SoldOut
                                  </button>
                                ) : (
                                  <button
                                    class="btn btn-outline-danger"
                                    onClick={() =>
                                      UnsoldRoutesDetails(data?._id, false)
                                    }
                                  >
                                    UnSoldOut
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                 
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={EnterfilenameModal}
        onHide={() => setEnterfilenameModal(false)}
        style={{ width: "320px", marginLeft: "40%", marginTop: "2%" }}
      >
        <Modal.Body>
          <div>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Enter Excel file name ....."
                style={{
                  padding: "10px",
                  width: "82%",
                  border: "1px solid #fdd12d",
                }}
                value={fileName}
                onChange={(e) => {
                  setfileName(e.target.value);
                }}
              />
              <ImFolderDownload
                style={{
                  width: "18%",
                  color: "white",
                  backgroundColor: "#fdd12d",
                  paddingTop: "15px",
                  height: "50px",
                  paddingBottom: "15px",
                }}
                onClick={() => ExportToExcel()}
              />
            </div>
            {/* <h1 style={{ textAlign: "center", color: "#ffcc33" }}>{Count}</h1> */}
          </div>
        </Modal.Body>
      </Modal>
      {/* delete */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>Are you sure delete ?</Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="success" onClick={removeroutes}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
  {/*All delete */}
  <Modal show={showAl} onHide={handleCloseAl}>
        <Modal.Header closeButton>Are you sure delete ?</Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseAl}>
            Cancel
          </Button>
          <Button variant="success" onClick={AllDeleteFunction}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
        {/*All Drop delete */}
  <Modal show={showDrop} onHide={handCloseDrop}>
        <Modal.Header closeButton>Are you sure all delete data ?</Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handCloseDrop}>
            Cancel
          </Button>
          <Button variant="success" onClick={deleteAllRoute}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {/* sold out */}
      <Modal
        show={showSold}
        onHide={handleCloseShold}
        animation={true}
        size="lg"
      >
        <Modal.Header style={{ color: "black" }}>
          Add sold out date
        </Modal.Header>
        <Modal.Body>
          <div style={{ margin: "1% 2%" }}>
            <label
              style={{
                textAlign: "left",
                paddingBottom: "3px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Start Date
            </label>
            <br />
            <input
              type="date"
              min={Date.now()}
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder="40"
              //   value={basePrice}
              onChange={(e) => {
                setsoldStart(e.target.value);
              }}
            />

            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              End Date
            </label>
            <br />
            <input
              type="date"
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder="4"
              //   value={basePrice}
              onChange={(e) => {
                setsoldEnd(e.target.value);
              }}
            />
            <br />
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ justifyContent: "space-between", padding: "10px 20px" }}
        >
          <Button
            style={{
              backgroundColor: "#ffcc33",
              border: "none",
              width: "120px",
            }}
            onClick={handleCloseShold}
          >
            CANCEL
          </Button>
          {allSold == true ? (
            <Button
              style={{
                backgroundColor: "#fdd12d",
                border: "none",
                width: "180px",
              }}
              onClick={() => allSoldOutUnsoldOut(true)}
            >
              All Sold Now
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#fdd12d",
                border: "none",
                width: "180px",
              }}
              onClick={SoldUnsoldRoutesDetails}
            >
              Sold Now
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Routes1;
