import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Slider from "@mui/material/Slider";
import axios from "axios";
import DatePicker from "react-multi-date-picker";
import { DistanceMatrixService } from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";
import moment from "moment";
import { RxCrossCircled } from "react-icons/rx";
import { Modal } from "antd";
function OutstationRoute() {
  const [from, setfrom] = useState();
  const [to, setto] = useState("");
  const [roundTrip, setroundTrip] = useState("");
  const [totalKm, settotalKm] = useState("");
  const [price, setprice] = useState("");
  const [Discount, setDiscount] = useState(0);
  const [numberoftoll, setnumberoftoll] = useState("");
  const [toteltollcharge, settoteltollcharge] = useState(0);
  const [totalprice, settotalprice] = useState(0);
  const [lat1, setlat1] = useState();
  const [lat2, setlat2] = useState();
  const [lng1, setlng1] = useState();
  const [lng2, setlng2] = useState();
  const [vehModel, setvehModel] = useState("");
  const [retplan, setretplan] = useState("General");
  const [kmprice, setkmprice] = useState(10);
  const [extraprice, setextraprice] = useState(0);
  const [waitcharge, setwaitcharge] = useState("");
  const [waittime, setwaittime] = useState("");
  const [ststeTax,setstateTax]=useState(0);

  const [date, setdate] = useState(moment().format("DD/MM/YYYY"));
  const [todate, settodate] = useState(moment().format("DD/MM/YYYY"));
  const [driverBata,setdriverBata]=useState(0);
  const [parkingCharge,setparkingCharge]=useState(0);
  const [fuel, setfuel] = useState({});
  const [distance, setdistance] = useState(0);
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  console.log("admin", admin1._id);
  const [modal2Open, setModal2Open] = useState(false);

  const [errorData,setErrordata]=useState("")
  const erorShow=(data)=>{
    setErrordata(data)
    setModal2Open(true)
  }
  const Addroutes = async () => {
    let start=""
    let end=""
    if(retplan !=="General"){
      start=date
      end=todate
    }
    if (!from) {
      erorShow("Source missing");
    } else if (!to) {
      erorShow("Destination missing");
    } else if (!roundTrip) {
      erorShow("RoundTrip missing");
    } else if (!distance) {
      erorShow("TotalKm missing");
    } else if (!category) {
      erorShow("Vehicle Category missing");
    }else if (!vehModel) {
      erorShow("Vehicle model missing");
    } else if(!Object.keys(fuel).length){
      erorShow("Please select fuel type");
    }else if (!retplan) {
      erorShow("Rate Plan missing");
    }  else if (!waitcharge) {
      erorShow("Wating Charges/Minutes missing");
    }  else if (!waittime) {
      erorShow("Wating Charges/Minutes missing");
    } else {
      let obj = {
        authId: admin1._id,
        to: to,
        from: from,
        roundTrip:  roundTrip ,
        totalKm: distance,
        BasePrice: (distance * kmprice) +Number(toteltollcharge)+Number(parkingCharge)+Number(driverBata)+Number(ststeTax),
        discoont: Discount,
        totalprice: distance * kmprice,
        driverBata:driverBata,
        parkingCharge:parkingCharge,
        totalTol: toteltollcharge,
        isActive: true,
        perkmprice:kmprice,
        waitingCharge:waitcharge,
        waitingTime:waittime,
        vehicaleCategory:category,
        vehicleModel:vehModel,
        ratePlane:retplan,
        fromDate:start,
        ToDate:end,
        extraFair:Number(extraprice),
        fuel:fuel?.fueltype,
        vechicleId:fuel?._id,
        stateTax:ststeTax,
      };
      try {
        const config = {
          url: "/admin/addRoute",
          method: "post",
          baseURL: "https://goeasycab.info/api",
          headers: { "content-type": "application/json" },
          headers: { Authorization: `Bearer ${admin}` },
          data: obj,
        };
        await axios(config).then(function (res) {
          if (res.status === 200) {
            alert("Successfully added");
            window.location.assign("/admin/Routes");
            setfrom("");
            setto("");
            setDiscount("");
            settotalKm("");
            setnumberoftoll("");
            settotalprice("");
            settoteltollcharge("");
            setroundTrip("");
          }
        });
      } catch (error) {
        console.log(error.res);
        alert(error.response.data.error);
      }
    }
  };
  const handlePlaceChanged = (place) => {
    if (place) {
      //   console.log("check it", place.name);
      //   console.log("Lat", place.geometry.location.lat());
      //   console.log("Long", place.geometry.location.lng());
      setlat1(place.geometry.location.lat());
      setlng1(place.geometry.location.lng());
      setfrom(place.name);
    }
  };
  const handlePlaceChanged2 = (place) => {
    if (place) {
      setlat2(place.geometry.location.lat());
      setlng2(place.geometry.location.lng());
      setto(place.name);
    }
  };
  const [dataSource, setdataSource] = useState([]);
  const [category, setcategory] = useState("");

  const AllCategory = async () => {
    let res = await axios.get("https://goeasycab.info/api/admin/getAllCategory");
    if (res.status === 200) {
      console.log(res);
      setdataSource(res.data.carList);
      //   setdata(res.data.carList);
    }
  };
  const [allvehicle, setAllvehicle] = useState([]);
  const Allvehicles = async () => {
    let res = await axios.get("https://goeasycab.info/api/admin/getAllVehicle");
    if (res.status === 200) {
      setAllvehicle(res.data.success);
      // setdata(res.data.success);
    }
  };

  const [allSpecial, setSpecial] = useState([]);
  const getAllSpecial = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllSpecial"
      );
      if (res.status === 200) {
        console.log(res);
        setSpecial(res.data.carList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    AllCategory();
    Allvehicles();
    getAllSpecial();
  }, []);
  console.log("check from==>", from, lat1, lng1);
  console.log("check to==>", allvehicle);
  return (
    <>
      <div className="feewe">
        <div className="container">
          <div className="efwef">
            <div className="row">
              <div className="col-md-6">
                <div className="outs">
                <Button
          style={{
            color: "white",
            backgroundColor: "#fdd12d",
            border: "#fdd12d",
          }}
        >
          {" "}
          Route Details
        </Button>
                 
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="feild">
                        <Form.Label>Source</Form.Label>
                        <Autocomplete
                          apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
                          placeholder={"Enter your source"}
                          className="Googlebaba"
                          onPlaceSelected={(place) => {
                            handlePlaceChanged(place);
                          }}
                          options={{
                            types: ["(cities)"],
                            componentRestrictions: { country: "in" },
                            fields: [
                              //   "address_components",
                              "geometry",
                              "icon",
                              "name",
                            ],
                            // types: ["resoins"],
                          }}
                        />
                       
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="feild">
                        <Form.Label>Destination</Form.Label>
                        <Autocomplete
                          apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
                          placeholder={"Enter your destination"}
                          className="Googlebaba"
                          onPlaceSelected={(place) => {
                            handlePlaceChanged2(place);
                          }}
                          options={{
                            types: ["(cities)"],
                            componentRestrictions: { country: "in" },
                            fields: [
                              //   "address_components",
                              "geometry",
                              "icon",
                              "name",
                            ],
                          }}
                        />
                        {to ? (
                          <DistanceMatrixService
                            options={{
                              destinations: [
                                { lat: Number(lat2), lng: Number(lng2) },
                              ],
                              origins: [
                                { lng: Number(lng1), lat: Number(lat1) },
                              ],
                              travelMode: "DRIVING",
                            }}
                            // options={{
                            //   destinations: [{lat:Number(13.0773806), lng:77.53820429999999}],
                            //   origins: [{lng:77.7068, lat:13.1989}],
                            //   travelMode: "DRIVING",
                            // }}
                            callback={(response) => {
                              // console.log("desti dta", distlat,distlon);
                              //   console.log("Amit sir", parseInt(response.rows[0].elements[0].distance?.text),response);
                              setdistance(
                                parseInt(
                                  response.rows[0].elements[0].distance?.text
                                )
                              );
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="feild">
                        <Form.Label>Trip Type</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setroundTrip(e.target.value)}
                        >
                          <option>select menu</option>
                          <option value="Round Trip">Round Trip</option>
                          <option value="oneway">oneway</option>
                        </Form.Select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="feild">
                        <Form.Label>Vehicle Category</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setcategory(e.target.value)}
                        >
                          <option>select menu</option>
                          {dataSource?.map((item) => {
                            return (
                              <option value={item?.category}>
                                {item?.category}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="feild">
                        <Form.Label>Vehicle Model</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setvehModel(e.target.value)}
                        >
                          <option>select menu</option>
                          {allvehicle
                            ?.filter(
                              (ele) =>
                                ele.tripType === roundTrip &&
                                ele.category == category
                            )
                            .map((item) => {
                              return (
                                <option value={item?.vehModel}>
                                  {item?.vehModel}
                                </option>
                              );
                            })}
                          {/* <option value="1">Diser</option>
                          <option value="2">Two</option> */}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="feild">
                        <Form.Label>Fuel Type</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setfuel(JSON.parse(e.target.value))}
                        >
                          <option>select Fuel Type</option>
                          {allvehicle
                            ?.filter(
                              (ele) =>
                                ele.tripType === roundTrip &&
                                ele.category == category &&
                                ele.vehModel == vehModel
                            )
                            .map((item) => {
                              return (
                                <option value={JSON.stringify(item)}>
                                  {item?.fueltype}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="feild">
                          <Form.Label>Driver Allowance</Form.Label>
                          <div className="rg45h4">
                            <input
                              type="text"
                              placeholder="100"
                              className="srgje"
                              value={driverBata}
                              onChange={(e) =>
                                setdriverBata(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    {roundTrip == "oneway" ? (<>
                      <div className="col-md-6 mb-3">
                        <div className="feild">
                          <Form.Label>Total Toll Charge</Form.Label>
                          <div className="rg45h4">
                            <input
                              type="text"
                              placeholder="100"
                              className="srgje"
                              value={toteltollcharge}
                              onChange={(e) =>
                                settoteltollcharge(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="feild">
                          <Form.Label>Parking Charge</Form.Label>
                          <div className="rg45h4">
                            <input
                              type="text"
                              placeholder="100"
                              className="srgje"
                              value={parkingCharge}
                              onChange={(e) =>
                                setparkingCharge(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="feild">
                          <Form.Label>State Tax</Form.Label>
                          <div className="rg45h4">
                            <input
                              type="number"
                              min="0"
                              placeholder="Eg:-1000"
                              className="srgje"  
                              value={ststeTax}           
                              onChange={(e) =>
                                setstateTax(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="col-md-6 mb-3">
                      <div className="feild">
                        <Form.Label>Rate Plan</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setretplan(e.target.value)}
                        >
                          <option>select menu</option>
                          {allSpecial?.map((item) => {
                            return (
                              <option value={item?.specialday}>
                                {item.specialday}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                    </div>
                    {retplan=="General" ? (<></>):(<div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="feild">
                        <Form.Label>From Date</Form.Label>
                        <DatePicker
                          style={{ width: "60%", height: "38px" }}
                          format="DD/MM/YYYY"
                          type="input-icon"
                          minDate={new Date()}
                          value={date}
                          onChange={(date) =>
                            setdate(date.format("DD/MM/YYYY"))
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="feild">
                        <Form.Label>To Date</Form.Label>
                        <DatePicker
                          style={{ width: "60%", height: "38px" }}
                          format="DD/MM/YYYY"
                          type="input-icon"
                          minDate={new Date()}
                          value={todate}
                          onChange={(date) =>
                            settodate(date.format("DD/MM/YYYY"))
                          }
                        />
                      </div>
                    </div>
                    </div>)}
                  
                  </div>
                </form>
              </div>
              <div className="col-md-6">
                <div className="fare-det">
                  <div className="fare-b pt-3 pb-2" style={{ float: "right" }}>
                    <button
                      className="ant-btn css-dev-only-do-not-override-1xusghl ant-btn-default hvr-shutter-out-horizontal"
                      onClick={Addroutes}
                    >
                      Save
                    </button>
                  </div>
                  <div className="fate-detailss">
                    <h4>Fare Details</h4>
                    <div className="dmf">
                      <p style={{ fontSize: "25px", fontWeight: "600" }}>
                        Fixed Fare/km
                      </p>
                    </div>
                    <div className="etsdd">
                      <p>Select Base Fare/km</p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="wwe">
                            <Slider
                              defaultValue={10}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              min={10}
                              onChange={(e) => {setkmprice(e.target.value)
                                setextraprice(e.target.value)
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className="ecww"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                border: "1px solid #c0c4d6",
                                padding: "7px 6px",
                              }}
                            >
                              ₹
                            </span>
                            <input
                              type="text"
                              className="srgje"
                              placeholder="12.5"
                              value={kmprice}
                              onChange={(e)=>setkmprice(e.target.value)}
                              style={{
                                borderRadius: "none",
                                outline: "none",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="afea">
                          <Form.Label>Extra fare/km</Form.Label>
                          <br />
                          <input
                            type="number"
                            placeholder="Enter extra fare"
                            className="srgje"
                            value={extraprice}
                            onChange={(e) => setextraprice(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="rg45h4">
                          <Form.Label>Discount%</Form.Label>
                          <br />
                          <input
                            type="number"
                            placeholder="3"
                            className="srgje"
                            onChange={(e) => setDiscount(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fadw mt-3">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="rg45h4">
                            <Form.Label>Wating Charges</Form.Label>
                            <br />
                            <input
                              type="text"
                              placeholder="Eg. 100"
                              className="srgje"
                              onChange={(e) => setwaitcharge(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="feild">
                            <Form.Label>Free Waiting Minutes</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={(e) => setwaittime(e.target.value)}
                            >
                              <option>select menu</option>
                              <option value="20 Minutes">20 Minutes</option>
                              <option value="30 Minutes">30 Minutes</option>
                              <option value="45 Minutes">45 Minutes</option>
                              <option value="1 Hour">1 Hour</option>
                              <option value="1 Hour 30 Minutes">
                                1 Hour 30 Minutes
                              </option>
                              <option value="2 Hour">2 Hour</option>
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="wqds mt-2 mb-2"
                      style={{
                        backgroundColor: "gainsboro",
                        padding: "0px 54px 0px 0px",
                      }}
                    >
                      <div className="fwv pt-4 pb-4">
                        <ul
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <li>Base Distance</li>
                          <li>250km/day</li>
                        </ul>
                        {distance ? (
                          <ul
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <li>Distance</li>
                            <li>{distance} km</li>
                          </ul>
                        ) : (
                          <></>
                        )}
                        <ul
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <li>Extra fare/km</li>
                          <li>₹{extraprice}</li>
                        </ul>
                        {Object.keys(fuel).length ? (
                          <ul
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <li>Night Charges</li>
                            <li>₹{fuel?.nightCharge}</li>
                          </ul>
                        ) : (
                          <></>
                        )}
                        {Object.keys(fuel).length ? (
                          <ul
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <li>Vehicle base Price</li>
                            <li>₹{fuel?.basePrice}</li>
                          </ul>
                        ) : (
                          <></>
                        )}

                        {distance ? (
                          <ul
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <li> Distance price</li>
                            <li>₹{distance * kmprice}</li>
                          </ul>
                        ) : (
                          <></>
                        )}
                         {ststeTax ? (
                          <ul
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <li>State Tax</li>
                            <li>₹{ststeTax}</li>
                          </ul>
                        ) : (
                          <></>
                        )}
                        {Object.keys(fuel).length ? (
                          <ul
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <li>Total Price</li>
                            <li>
                              ₹
                              {Number(ststeTax)+
                               Number(distance * kmprice) +
                                Number(toteltollcharge)+Number(parkingCharge)+Number(driverBata)}
                            </li>
                          </ul>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <div
          className=""
          style={{ display: "flex", justifyContent: "center", }}
        >
          <div className="">
            <div
              className="wrong"
              style={{
                fontSize: " 100px",
                color: "#ff000087",
                textAlign: "center",
              }}
            >
              <RxCrossCircled />
            </div>
            <div className="nf_0">{errorData}</div>
            <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30" }}
                onClick={() => setModal2Open(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default OutstationRoute;
