import axios from "axios";
import { RxCrossCircled } from "react-icons/rx";
import React, { useEffect, useState } from "react";
import { Button, Input, Space,Modal } from "antd";
function EditProfile() {
  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [modal2Open, setModal2Open] = useState(false);

  const [act,setact]=useState(true)
  const [errorData, setErrordata] = useState("");
  const erorShow = (data) => {
    setErrordata(data);
    setModal2Open(true);
  };

  function isEmpty(str) {
    if (typeof str == "undefined" || typeof str == null) {
      return false;
    }
    if (typeof str == "string" && str.trim().length == 0) {
      return false;
    }
    if (typeof str == "number" && str.toString().trim().length == 0) {
      return false;
    }
    return true;
  }

  function isValidMobile(mobile) {
    mobile = mobile?.trim();
    if (mobile?.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
      //&& ! (mobile.match(/0{5,}/))
      return true;
    }
    return false;
  }

  function isValidEmail(emailid) {
    emailid = emailid?.trim();
    if (emailid?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      return true;
    }
    return false;
  }
  let customer = JSON.parse(sessionStorage.getItem("customer"));

useEffect(()=>{
    if(customer){
        setname(customer?.name);
        // setemail(customer?.email);
     
    }
},[])
let formdata=new FormData()
let [profile,setprofile]=useState("")

const UpdateUser=async (e)=>{

  e.preventDefault()
  if(email){
  if(!isValidEmail(email)) return erorShow("Invalid email!")
  }

  if(mobile){
    if(!isValidMobile(mobile)) return erorShow("Invalid mobile number!")
  }
  formdata.append("userId",customer?._id)
  formdata.append("mobile",mobile)
  formdata.append("name",name)
  formdata.append("email",email)
  formdata.append("profile",profile)

  try{
    const config = {
      url: "/updateUser",
      method: "put",
      baseURL: "https://goeasycab.info/api/customer",
      headers: { "conttent-type": "multipart/form-data" },
      data:formdata
    };

    let res=await axios(config);
    if(res.status===200){
      alert("Successfully updated");
      sessionStorage.setItem("customer",JSON.stringify(res.data.success))
      window.location.reload("")
    }
  } catch (error) {
      erorShow(error.response.data.error)
    console.log(error);
  }
}
const changepwd=async(e)=>{
  e.preventDefault()
  try {
      if(!isEmpty(password)) return erorShow("Please enter password!");
  if(!isEmpty(cpassword)) return erorShow("Please enter confirm password!");
  if(password !==cpassword) return erorShow("Confirm password did not match")
  
    const config = {
      url: "/changePassword",
      method: "put",
      baseURL: "https://goeasycab.info/api/customer",
      headers: { "conttent-type": "multipart/form-data" },
      data:{userId:customer?._id,Password:password}
    };
    let res=await axios(config)
    if(res.status==200){
      alert("Successfully changed")
      sessionStorage.removeItem("customer")
      window.location.assign("/login")
    }
  } catch (error) {
    console.log(error);
    erorShow(error.response.data.error)
  }
}
  return (
    <>
      <section
        id="updatesearch"
        class="updatesearch d-flex align-items-center"
        style={{ margin: "5% 0%" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="side-bar-menu mt-4">
                {customer?.profile ? ( <center><img src={`https://goeasycab.info/Customer/${customer?.profile}`} width="100px" height="100px" style={{borderRadius:"100%" , margin:"30px",marginLeft:"-8%"}}/></center>):(<></>)}
             
                {act ? ( <ul class="edit-left-sidebar">
              <li class="lefttag" onClick={()=>{
                setact(true)
              }}>
                Personal Info{" "}
                <span class="pull-right">
                  <i class="fa fa-angle-right" style={{color:"blue"}}></i>
                </span>
              </li>
              <br/>
              <li class="lefttag" onClick={()=>{
                setact(false)
              }}>
                Change Password{" "}
                {/* <span class="pull-right">
                  <i class="fa fa-angle-right"></i>
                </span> */}
              </li>
          
          </ul>):( <ul class="edit-left-sidebar">
              
              <li class="lefttag" onClick={()=>{
                setact(true)
              }}>
                Personal Info{" "}
                <span class="pull-right">
                  {/* <i class="fa fa-angle-right"></i> */}
                </span>
              </li>
              <br/>
              <li class="lefttag" onClick={()=>{
                setact(false)
              }}>
                Change Password{" "}
                <span class="pull-right">
                  <i class="fa fa-angle-right"  style={{color:"blue"}}></i>
                </span>
              </li>
          
          </ul>)}
                
              </div>
            </div>
            <div class="col-md-8">
                {act ? (     <form id="editProfile" method="post">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"

                    value={name}
                    onChange={(e)=>setname(e.target.value)}
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="exampleInputPassword1" class="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    value={email}
                    placeholder={customer?.email}
                    onChange={(e)=>setemail(e.target.value)}
                    readonly=""
                  />
                </div>

                <div class="form-group mt-2">
                  <label for="exampleInputPassword1" class="form-label">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="mobile_number"
                    placeholder={customer?.mobile}
                    value={mobile}
                    onChange={(e)=>setmobile(e.target.value)}
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="exampleInputPassword1" class="form-label" htmlFor="upload1">
                    Profile
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="upload1"
                    name="file"
                    accept="image/*"
                    onChange={(e)=>setprofile(e.target.files[0])}
                  />
                </div>
                <div class="form-group mt-4">
                  <button
                    type="submit"
                    class="ant-btn css-dev-only-do-not-override-1xusghl ant-btn-default hvr-shutter-out-horizontal"
                    style={{ width: "25%" }}
                    onClick={UpdateUser}
                  >
                    Update
                  </button>
                </div>
              </form>):(     <form id="editProfile" method="post">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id="name"
              placeholder="Enter new password"
                    value={password}
                    onChange={(e)=>setpassword(e.target.value)}
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="exampleInputPassword1" class="form-label">
                    Confirm Password
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name="cpassword"
                    id="cpassword"
                    placeholder="Confirm Password"
                    value={cpassword}
                    onChange={(e)=>setcpassword(e.target.value)}
                 
                  />
                </div>

                {/* <div class="form-group mt-2">
                  <label for="exampleInputPassword1" class="form-label">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="mobile_number"
                    value={mobile}
                    onChange={(e)=>setmobile(e.target.value)}
                  />
                </div> */}
                <div class="form-group mt-4">
                  <button
                    type="submit"
                    class="ant-btn css-dev-only-do-not-override-1xusghl ant-btn-default hvr-shutter-out-horizontal"
                    style={{ width: "25%" }} onClick={changepwd}
                  >
                    Save
                  </button>
                </div>
              </form>)}
         
            </div>
          </div>
        </div>
      </section>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <div
          className=""
          style={{ display: "flex", justifyContent: "center", }}
        >
          <div className="">
            <div
              className="wrong"
              style={{
                fontSize: " 100px",
                color: "#ff000087",
                textAlign: "center",
              }}
            >
              <RxCrossCircled />
            </div>
            <div className="nf_0">{errorData}</div>
            <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30" }}
                onClick={() => setModal2Open(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditProfile;
