import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "../Admin/Admin.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaImages } from "react-icons/fa";
import exportFromJSON from "export-from-json";
import { ImFolderDownload } from "react-icons/im";
import { BiUserCircle } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { RxCrossCircled } from "react-icons/rx";

function User() {
  const admin = sessionStorage.getItem("token");
  const [dataSource, setdataSource] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(dataSource.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const AllUsers = async () => {
    try {
      const config = {
        url: "/customer/getAllCustomer",
        method: "get",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      console.log("check===>", res);
      if (res.status === 200) {
        console.log("check===>", res);
        setdataSource(res.data.success);
        setdata(res.data.success);
      }
    } catch (error) {
      console.log("errror==>", error);
    }
  };
  useEffect(() => {
    AllUsers();
  }, []);
  // Export to Excel

  const [data, setdata] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState();

  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };
  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = dataSource.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setdataSource([...dataSource]);
    }
  };

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  // const admin = sessionStorage.getItem("token");
  const [blockId,setblockId]=useState("")
  const [block,setblock]=useState()
  const [remark,setremark]=useState("")

  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  const makeblockUnblock=async()=>{
    if(!remark) return alert("Please enter remark");
    try {
      const config = {
        url: "/customer/MakeBlockAndUnblockCustome",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {authId:admin1?._id,id:blockId,isBlocked:block,reason:remark}
      };
      let res=await axios(config);
      if(res.status==200){
        alert(res.data.success);
        handleClose1();
        AllUsers()
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }

  return (
    <div style={{ paddingLeft: "0px" }}>
       <Button
        style={{
          color: "white",
          backgroundColor: "#fdd12d",
          border: "#fdd12d",
        }}
      >
        {" "}
      Users
      </Button> 
      <br></br>
      <div style={{ backgroundColor: "#f0f0f0" }}>
        <div class="row" style={{ padding: "10px 20px" }}>
          <div class="col-sm-4">
            <div class="search-box  d-inline-block">
              <div class="position-relative" style={{ display: "flex" }}>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  onChange={handleFilter}
                  placeholder="Search User"
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                />
              </div>
            </div>
          </div>

          <div class="col-sm-8">
            <div class="text-sm-end">
              {" "}
              <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                  margin: "0px 10px 0px 0px",
                }}
                onClick={() => {
                  setEnterfilenameModal(true);
                }}
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                EXPORT TO EXCEL
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <div class="react-bootstrap-table">
              <table
                class="table table align-middle table-nowrap"
                style={{ width: "90%", margin: "2%" }}
              >
                <thead
                  style={{
                    backgroundColor: "#fdd12d",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <tr style={{ padding: "10px" }}>
                    <th
                      tabindex="0"
                      aria-label="Username sortable"
                      class="sortable"
                    >
                      S.No
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      User Image
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      User Name
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Mobile Number
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Email Id
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Remark
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Status
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Action
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {search.length > 0 ? (
                    <>
                      {tableFilter
                        ?.slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((data, index) => {
                          return (
                            <tr style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td>
                              {data?.profile ? (
                                <img
                                  src={`https://goeasycab.info/Customer/${data?.profile}`}
                                  alt="Customer"
                                  width="50px"
                                  height="50px"
                                  style={{ borderRadius: "100%" }}
                                />
                              ) : (
                                <BiUserCircle
                                  style={{
                                    width: "45px",
                                    height: "45px",
                                    color: "#fdd12d",
                                  }}
                                />
                              )}
                            </td>
                            <td>{data?.name}</td>
                            <td>{data?.mobile}</td>
                            <td>{data?.email}</td>
                            <td>{data?.reason}</td>
                            <td>{data?.isBlocked==false ? (<span style={{color:"green"}}>Un-Blocked</span>):(<span style={{color:"red"}}>Blocked</span>)}</td>
                            <td>
                              {data?.isBlocked==false ? ( <Button className="mx-2" variant="danger" onClick={()=>{
                                setblockId(data?._id);
                                setblock(true);
                                handleShow1()
                              }}>
                                Block
                              </Button>):( <Button className="mx-2" variant="success" onClick={()=>{
                                 setblockId(data?._id);
                                 setblock(false);
                                 handleShow1()
                              }}>
                                Unblock
                              </Button>)}
                             
                             
                             
                            </td>
                          </tr>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {dataSource
                        ?.slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((data, index) => {
                          return (
                            <tr style={{ textAlign: "center" }}>
                              <td>{index + 1}</td>
                              <td>
                                {data?.profile ? (
                                  <img
                                    src={`https://goeasycab.info/Customer/${data?.profile}`}
                                    alt="Customer"
                                    width="50px"
                                    height="50px"
                                    style={{ borderRadius: "100%" }}
                                  />
                                ) : (
                                  <BiUserCircle
                                    style={{
                                      width: "45px",
                                      height: "45px",
                                      color: "#fdd12d",
                                    }}
                                  />
                                )}
                              </td>
                              <td>{data?.name}</td>
                              <td>{data?.mobile}</td>
                              <td>{data?.email}</td>
                              <td>{data?.reason}</td>
                              <td>{data?.isBlocked==false ? (<span style={{color:"green"}}>Un-Blocked</span>):(<span style={{color:"red"}}>Blocked</span>)}</td>
                              <td>
                                {data?.isBlocked==false ? ( <Button className="mx-2" variant="danger" onClick={()=>{
                                  setblockId(data?._id);
                                  setblock(true);
                                  handleShow1()
                                }}>
                                  Block
                                </Button>):( <Button className="mx-2" variant="success" onClick={()=>{
                                   setblockId(data?._id);
                                   setblock(false);
                                   handleShow1()
                                }}>
                                  Unblock
                                </Button>)}
                               
                               
                               
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={EnterfilenameModal}
        onHide={() => setEnterfilenameModal(false)}
        style={{ width: "320px", marginLeft: "40%", marginTop: "2%" }}
      >
        <Modal.Body>
          <div>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Enter Excel file name ....."
                style={{
                  padding: "10px",
                  width: "82%",
                  border: "1px solid #fdd12d",
                }}
                value={fileName}
                onChange={(e) => {
                  setfileName(e.target.value);
                }}
              />
              <ImFolderDownload
                style={{
                  width: "18%",
                  color: "white",
                  backgroundColor: "#fdd12d",
                  paddingTop: "15px",
                  height: "50px",
                  paddingBottom: "15px",
                }}
                onClick={() => ExportToExcel()}
              />
            </div>
            {/* <h1 style={{ textAlign: "center", color: "#ffcc33" }}>{Count}</h1> */}
          </div>
        </Modal.Body>
      </Modal>

      {/* Remarks */}
      <Modal show={show1} onHide={handleClose1}>
        <a closeButton></a>
        <Modal.Body>
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "center",
              // padding: "25px",
            }}
          >
            <div className="">
              <div
                className="wrong"
                style={{
                  fontSize: " 80px",
                  color: "#ff000087",
                  textAlign: "center",
                }}
              >
                <RxCrossCircled />
              </div>
              <input
                type="text"
                placeholder="Please enter remark "
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                }}
                value={remark}
                onChange={(e) => {
                  setremark(e.target.value);
                }}
              />
              <div className="mt-3 mb-2" style={{ textAlign: "center"}}>
                <Button
                  type="success"
                  style={{
                    padding: " 7px 27px",
                    backgroundColor: "red",
                    borderColor: "red",
                    marginRight:"10px"
                  }}
                  onClick={handleClose1}
                >
                  Cancel
                </Button>
                <Button
                  type="success"
                  style={{
                    padding: " 7px 27px",
                    backgroundColor: "green",
                    borderColor: "green",
                    marginLeft:"10px"
                  }}
                  onClick={makeblockUnblock}
                >
                  OK
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary" onClick={handleClose1}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}

export default User;
