import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "../Admin/Admin.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaImages } from "react-icons/fa";
import exportFromJSON from "export-from-json";
import { ImFolderDownload } from "react-icons/im";
import ReactPaginate from "react-paginate";
import { RxCrossCircled } from "react-icons/rx";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
function Drivers() {
  const [show, setShow] = useState(false);
  const [deletid,setdeleteId]=useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  console.log("admin", admin1._id);

  const [Data, setData] = useState([]);

const [dataSource, setdataSource] = useState([]);
const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
   const pagesVisited = pageNumber * usersPerPage;
   const pageCount = Math.ceil(dataSource.length / usersPerPage);
   const changePage = ({ selected }) => {
     setPageNumber(selected);
   };

  

  const Alldrivers = async () => {
    try {
        const config={
            url:"/driver/getAllDriverAndVehicle",
            method:"get",
            baseURL:"https://goeasycab.info/api",
            headers: {"Authorization" : `Bearer ${admin}`},
         
        }
        let res = await axios(config);
        console.log("check===>",res)
        if (res.status === 200) {
            console.log("check===>",res)
          setdataSource(res.data.success);
          setdata(res.data.success)
        }
    } catch (error) {
        console.log("errror==>",error);
    }
   
  };
  useEffect(() => {
    Alldrivers();
  }, []);
   // Export to Excel

 const [data, setdata] = useState([]);

 const exportType = "xls";
 const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
 const [fileName, setfileName] = useState();

 const ExportToExcel = () => {
   if (fileName) {
     exportFromJSON({ data, fileName, exportType });
     setEnterfilenameModal(false);
     setfileName("");
   } else {
     alert("Enter file name to export");
   }
 };
 const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = dataSource.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setdataSource([...dataSource]);
    }
  };
// console.log("sfsdgv",dataSource);
  
  const RemoveDriver = async () => {
    try {
      const config = {
        url: "/driver/getDeleteDriverById/" + deletid + "/" + admin1?._id,
        method: "delete",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert("Deleted Successfully");
          handleClose();
          Alldrivers();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  // const admin = sessionStorage.getItem("token");
  const [blockId,setblockId]=useState("")
  const [block,setblock]=useState();
  const [remark,setremark]=useState("");

const Blockdriver = async (data) => {
//  console.log("dfs====>",data._id);
  try {
   
   const config = {
     url: "/driver/MakeBlockUnblockDriver",
     method: "put",
     baseURL: "https://goeasycab.info/api",
     headers: { "content-type": "application/json" },
     headers: { Authorization: `Bearer ${admin}` },
     data:{
      driverId : blockId,
      isDBlocked:block,
      reason:remark,
      authId:admin1._id
    } 
   };
   await axios(config).then(function (res) {
     if (res.status === 200) {
       alert(res.data.success);
      handleClose1()
       Alldrivers()
     }
   });
 } catch (error) {
   console.log(error.res);
   alert(error.response.data.error);
 }
};
const Blockdriver1 = async (data) => {
  //  console.log("dfs====>",data._id);
    try {
     const config = {
       url: "/driver/MakeBlockUnblockDriver",
       method: "put",
       baseURL: "https://goeasycab.info/api",
       headers: { "content-type": "application/json" },
       headers: { Authorization: `Bearer ${admin}` },
       data:{
        driverId : data._id,  
        isDBlocked:false,
        authId:admin1._id
      } 
     };
     await axios(config).then(function (res) {
       if (res.status === 200) {
         console.log(res.data);
         alert("UnBlocked");
         Alldrivers()
       }
     });
   } catch (error) {
     console.log(error.res);
     alert(error.response.data.error);
   }
  };

const [ap,setap]=useState(false)
  const ApproveAndHoldDriver = async (data,status,reasion) => {
    //  console.log("dfs====>",data._id);
      try {
        if(status=="Hold"){
          if(!reasion) return alert("Please enter remark")
        }
       const config = {
         url: "/driver/makeAprrovedAdnHold",
         method: "put",
         baseURL: "https://goeasycab.info/api",
         headers: { "content-type": "application/json" },
         headers: { Authorization: `Bearer ${admin}` },
         data:{
          driverId : data,  
          reason:reasion,
          authId:admin1._id,
          status:status
        } 
       };
       await axios(config).then(function (res) {
         if (res.status === 200) {
           console.log(res.data);
           alert(`Successfully ${status}`);
           handleClose1()
           Alldrivers()
         }
       });
     } catch (error) {
       console.log(error.res);
       alert(error.response.data.error);
     }
    };
  return (
    <div style={{ paddingLeft: "0px" }}>
     <Button
          style={{
            color: "white",
            backgroundColor: "#fdd12d",
            border: "#fdd12d",
          }}
        >
          {" "}
          Partners
        </Button>
    
      <br></br>
      <div style={{ backgroundColor: "#f0f0f0" }}>
        <div class="row" style={{ padding: "10px 20px" }}>
          <div class="col-sm-4">
            <div class="search-box  d-inline-block">
              <div class="position-relative" style={{ display: "flex" }}>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  placeholder="Search Partners"
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                  onChange={handleFilter}
                />
              </div>
            </div>
          </div>

          <div class="col-sm-8">
            <div class="text-sm-end">
              {" "}
              <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                  margin: "0px 10px 0px 0px",
                }}
                onClick={() => {
                  setEnterfilenameModal(true);
                }}
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                EXPORT TO EXCEL
              </button>
             
            </div>
          </div>
          <div class="table-responsive">
            <div class="react-bootstrap-table">
              <table
                class="table table align-middle table-nowrap"
                style={{ width: "90%", margin: "2%" }}
              >
                <thead style={{ backgroundColor: "#fdd12d", color: "white",textAlign:"center" }}>
                  <tr style={{ padding: "10px" }}>
                    <th
                      tabindex="0"
                      aria-label="Username sortable"
                      class="sortable"
                    >
                      S.No
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    
                    <th
                      tabindex="0"
                      aria-label="Username sortable"
                      class="sortable"
                    >
                  Driver Id
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Image
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Name
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                  Mobile Number 
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      DOB
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Licence No
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Aadhar No
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Licence Image
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Aadhar FrontImg
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Aadhar BackImg
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Police VerifyImg
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      PerKmPrice
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      PerHrsPrice
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      BasePrice
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Luggage
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Seatcapacity
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Ac/Non-Ac
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      VehicleType
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Latitute
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Longtitute
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      AvRating
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                       VehicleInsuranceDoc
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      VehicleInsuranceNum
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      VehiclePermit
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                       VehicleRCcard
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      PartnersTripType
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      VehicleRegNum
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      VehiclefrontImg
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      VehicleModal
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Status
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Remark
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Action
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {search.length>0?(<>
                    {tableFilter?.slice(pagesVisited, pagesVisited + usersPerPage).map((data, index) => {
                    return (
                      <tr style={{textAlign:"center"}}>
                      <td>{index + 1}</td>
                      <td>{data?._id}</td>
                      <td><a href={`https://goeasycab.info/Driver/${data?.dProfileImg}`} target="_blank">
                        <img
                          src={`https://goeasycab.info/Driver/${data?.dProfileImg}`}
                          alt="#"
                          width="50px"
                          height="50px"
                        /></a>
                      </td>
                      <td>{data?.driverName}</td>
                      <td>{data?.dMobile}</td>
                      <td>{data?.dDob}</td>
                      <td>{data?.dLicenceNum}</td>
                      <td>{data?.dAadharNum}</td>
                      <td><a href={`https://goeasycab.info/Driver/${data?.dLicencefrontImg}`} target="_blank">
                        <img
                          src={`https://goeasycab.info/Driver/${data?.dLicencefrontImg}`}
                          alt="#"
                         width="50px"
                          height="50px"
                        /></a>
                      </td>
                      <td><a href={`https://goeasycab.info/Driver/${data?.dAadharfrontImg}`} target="_blank">
                        <img
                          src={`https://goeasycab.info/Driver/${data?.dAadharfrontImg}`}
                          alt="#"
                         width="50px"
                          height="50px"
                        /></a>
                      </td>
                      <td><a href={`https://goeasycab.info/Driver/${data?.dAadharBackImg}`} target="_blank">
                        <img
                          src={`https://goeasycab.info/Driver/${data?.dAadharBackImg}`}
                          alt="#"
                         width="50px"
                          height="50px"
                        /></a>
                      </td>
                      <td><a href={`https://goeasycab.info/Driver/${data?.dPoliceVerificationDoc}`} target="_blank">
                        <img
                          src={`https://goeasycab.info/Driver/${data?.dPoliceVerificationDoc}`}
                          alt="#"
                         width="50px"
                          height="50px"
                        /></a>
                      </td>
                      {/* <td>{data?.perKmPrice}</td> */}
                      {/* <td>{data?.perHrsPrice}</td> */}
                      {/* <td>{data?.basePrice}</td> */}
                      {/* <td>{data?.luggage}</td> */}
                      {/* <td>{data?.dSeat}</td> */}
                      {/* <td>{data?.SelectAc}</td> */}
                      <td>{data?.dVehicleType}</td>
                      {/* <td>{data?.latitute}</td>
                      <td>{data?.longtitute}</td>
                      <td>{data?.AvRating}</td> */}
                      <td><a href={`https://goeasycab.info/Driver/${data?.dInsuranceDoc}`} target="_blank">
                        <img
                          src={`https://goeasycab.info/Driver/${data?.dInsuranceDoc}`}
                          alt="#"
                         width="50px"
                          height="50px"
                        /></a>
                      </td>
                      {/* <td>{data.dInsuranceNum}</td> */}
                      <td><a href={`https://goeasycab.info/Driver/${data?.dPermit}`} target="_blank">
                        <img
                          src={`https://goeasycab.info/Driver/${data?.dPermit}`}
                          alt="#"
                         width="50px"
                          height="50px"
                        /></a>
                      </td>
                      <td><a href={`https://goeasycab.info/Driver/${data?.dRCcard}`} target="_blank">
                        <img
                          src={`https://goeasycab.info/Driver/${data?.dRCcard}`}
                          alt="#"
                         width="50px"
                          height="50px"
                        /></a>
                      </td>
                      {/* <td>{data?.dTripType}</td> */}
                      <td>{data?.dVehicleRegNum}</td>
                      <td><a href={`https://goeasycab.info/Driver/${data?.dVehiclefrontImg}`} target="_blank">
                        <img
                          src={`https://goeasycab.info/Driver/${data?.dVehiclefrontImg}`}
                          alt="#"
                         width="50px"
                          height="50px"
                        /></a>
                      </td>
                     {/* <td>{data?.vehicleName}</td> */}
                     <td>{data?.status =="Approved" ? (<p style={{color:"green"}}>{data?.status}</p>):(<p style={{color:"red"}}>{data?.status}</p>)}</td>
                     <td>{data?.reason}</td>
                     <td style={{display:"flex",gap:"2px"}}>
                        {/* <Button
                          type="button"
                          variant="danger"
                          className="mb-2"
                          onClick={()=>{
                            setdeleteId(data?._id);
                            handleShow()
                          }}
                        >
                          <span>
                            <RiDeleteBin6Line />
                          </span>
                        </Button>{" "} */}
                        {data?.status =="Pending" ||data?.status =="Hold"  ? (<div style={{display:"flex",gap:"2px"}} >
                          <Button
                          type="button"
                          variant="success"
                          className="mx-2 mb-2"
                          onClick={()=>ApproveAndHoldDriver(data?._id,"Approved","")}
                        >
                          <span>
                           Approve
                          </span>
                        </Button> 
                        <Button
                          type="button"
                          variant="danger"
                          className="mx-2 mb-2"
                          onClick={()=> { setblockId(data?._id);
                        
                            setap(true)
                            handleShow1()}}
                        >
                          <span>
                           Hold
                          </span>
                        </Button> 
                        {/* <Button
                          type="button"
                          variant="danger"
                          className="mx-2 mb-2"
                          onClick={()=>ApproveAndHoldDriver(data?._id,"Hold","")}
                        >
                          <span>
                           Hold
                          </span>
                        </Button> */}
                        </div>):(<></>)}
                       
                     
                        <div style={{marginLeft:"2px"}}>
                      {data?.isDBlocked==false ? ( <Button className="mx-2" variant="danger" onClick={()=>{
                              setblockId(data?._id);
                              setblock(true);
                              setap(false)
                              handleShow1()
                            }}>
                              Block
                            </Button>):( <Button className="mx-2" variant="success" onClick={()=>{
                               setblockId(data?._id);
                               setblock(false);
                               setap(false)
                               handleShow1()
                            }}>
                              Unblock
                            </Button>)}</div>
                      </td>
                    </tr>
                    );
                  })}
                  </>):(<>
                    {dataSource?.slice(pagesVisited, pagesVisited + usersPerPage).map((data, index) => {
                    return (
                      <tr style={{textAlign:"center"}}>
                        <td>{index + 1}</td>
                        <td>{data?._id}</td>
                        <td><a href={`https://goeasycab.info/Driver/${data?.dProfileImg}`} target="_blank">
                          <img
                            src={`https://goeasycab.info/Driver/${data?.dProfileImg}`}
                            alt="#"
                            width="50px"
                            height="50px"
                          /></a>
                        </td>
                        <td>{data?.driverName}</td>
                        <td>{data?.dMobile}</td>
                        <td>{data?.dDob}</td>
                        <td>{data?.dLicenceNum}</td>
                        <td>{data?.dAadharNum}</td>
                        <td><a href={`https://goeasycab.info/Driver/${data?.dLicencefrontImg}`} target="_blank">
                          <img
                            src={`https://goeasycab.info/Driver/${data?.dLicencefrontImg}`}
                            alt="#"
                           width="50px"
                            height="50px"
                          /></a>
                        </td>
                        <td><a href={`https://goeasycab.info/Driver/${data?.dAadharfrontImg}`} target="_blank">
                          <img
                            src={`https://goeasycab.info/Driver/${data?.dAadharfrontImg}`}
                            alt="#"
                           width="50px"
                            height="50px"
                          /></a>
                        </td>
                        <td><a href={`https://goeasycab.info/Driver/${data?.dAadharBackImg}`} target="_blank">
                          <img
                            src={`https://goeasycab.info/Driver/${data?.dAadharBackImg}`}
                            alt="#"
                           width="50px"
                            height="50px"
                          /></a>
                        </td>
                        <td><a href={`https://goeasycab.info/Driver/${data?.dPoliceVerificationDoc}`} target="_blank">
                          <img
                            src={`https://goeasycab.info/Driver/${data?.dPoliceVerificationDoc}`}
                            alt="#"
                           width="50px"
                            height="50px"
                          /></a>
                        </td>
                        {/* <td>{data?.perKmPrice}</td> */}
                        {/* <td>{data?.perHrsPrice}</td> */}
                        {/* <td>{data?.basePrice}</td> */}
                        {/* <td>{data?.luggage}</td> */}
                        {/* <td>{data?.dSeat}</td> */}
                        {/* <td>{data?.SelectAc}</td> */}
                        <td>{data?.dVehicleType}</td>
                        {/* <td>{data?.latitute}</td>
                        <td>{data?.longtitute}</td>
                        <td>{data?.AvRating}</td> */}
                        <td><a href={`https://goeasycab.info/Driver/${data?.dInsuranceDoc}`} target="_blank">
                          <img
                            src={`https://goeasycab.info/Driver/${data?.dInsuranceDoc}`}
                            alt="#"
                           width="50px"
                            height="50px"
                          /></a>
                        </td>
                        {/* <td>{data.dInsuranceNum}</td> */}
                        <td><a href={`https://goeasycab.info/Driver/${data?.dPermit}`} target="_blank">
                          <img
                            src={`https://goeasycab.info/Driver/${data?.dPermit}`}
                            alt="#"
                           width="50px"
                            height="50px"
                          /></a>
                        </td>
                        <td><a href={`https://goeasycab.info/Driver/${data?.dRCcard}`} target="_blank">
                          <img
                            src={`https://goeasycab.info/Driver/${data?.dRCcard}`}
                            alt="#"
                           width="50px"
                            height="50px"
                          /></a>
                        </td>
                        {/* <td>{data?.dTripType}</td> */}
                        <td>{data?.dVehicleRegNum}</td>
                        <td><a href={`https://goeasycab.info/Driver/${data?.dVehiclefrontImg}`} target="_blank">
                          <img
                            src={`https://goeasycab.info/Driver/${data?.dVehiclefrontImg}`}
                            alt="#"
                           width="50px"
                            height="50px"
                          /></a>
                        </td>
                       {/* <td>{data?.vehicleName}</td> */}
                       <td>{data?.status =="Approved" ? (<p style={{color:"green"}}>{data?.status}</p>):(<p style={{color:"red"}}>{data?.status}</p>)}</td>
                       <td>{data?.reason}</td>
                       <td style={{display:"flex",gap:"2px"}}>
                          {/* <Button
                            type="button"
                            variant="danger"
                            className="mb-2"
                            onClick={()=>{
                              setdeleteId(data?._id);
                              handleShow()
                            }}
                          >
                            <span>
                              <RiDeleteBin6Line />
                            </span>
                          </Button>{" "} */}
                          {data?.status =="Pending" ||data?.status =="Hold"  ? (<div style={{display:"flex",gap:"2px"}} >
                            <Button
                            type="button"
                            variant="success"
                            className="mx-2 mb-2"
                            onClick={()=>ApproveAndHoldDriver(data?._id,"Approved","")}
                          >
                            <span>
                             Approve
                            </span>
                          </Button> 
                          <Button
                            type="button"
                            variant="danger"
                            className="mx-2 mb-2"
                            onClick={()=> { setblockId(data?._id);
                          
                              setap(true)
                              handleShow1()}}
                          >
                            <span>
                             Hold
                            </span>
                          </Button> 
                          {/* <Button
                            type="button"
                            variant="danger"
                            className="mx-2 mb-2"
                            onClick={()=>ApproveAndHoldDriver(data?._id,"Hold","")}
                          >
                            <span>
                             Hold
                            </span>
                          </Button> */}
                          </div>):(<></>)}
                         
                       
                          <div style={{marginLeft:"2px"}}>
                        {data?.isDBlocked==false ? ( <Button className="mx-2" variant="danger" onClick={()=>{
                                setblockId(data?._id);
                                setblock(true);
                                setap(false)
                                handleShow1()
                              }}>
                                Block
                              </Button>):( <Button className="mx-2" variant="success" onClick={()=>{
                                 setblockId(data?._id);
                                 setblock(false);
                                 setap(false)
                                 handleShow1()
                              }}>
                                Unblock
                              </Button>)}</div>
                        </td>
                      </tr>
                    );
                  })}
                  </>)}
                 
                </tbody>
              </table>
              <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={EnterfilenameModal}
        onHide={() => setEnterfilenameModal(false)}
        style={{ width: "320px", marginLeft: "40%", marginTop: "2%" }}
      >
        <Modal.Body>
          <div>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Enter Excel file name ....."
                style={{
                  padding: "10px",
                  width: "82%",
                  border: "1px solid #fdd12d",
                }}
                value={fileName}
                onChange={(e) => {
                  setfileName(e.target.value);
                }}
              />
              <ImFolderDownload
                style={{
                  width: "18%",
                  color: "white",
                  backgroundColor: "#fdd12d",
                  paddingTop: "15px",
                  height: "50px",
                  paddingBottom: "15px",
                }}
                onClick={() => ExportToExcel()}
              />
            </div>
            {/* <h1 style={{ textAlign: "center", color: "#ffcc33" }}>{Count}</h1> */}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete !</Modal.Title>
        </Modal.Header>
       
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={RemoveDriver}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
       {/* Remarks */}
      <Modal show={show1} onHide={handleClose1}>
        <a closeButton></a>
        <Modal.Body>
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "center",
              // padding: "25px",
            }}
          >
            <div className="">
              <div
                className="wrong"
                style={{
                  fontSize: " 80px",
                  color: "#ff000087",
                  textAlign: "center",
                }}
              >
                <RxCrossCircled />
              </div>
              <input
                type="text"
                placeholder="Please enter remark "
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                }}
                value={remark}
                onChange={(e) => {
                  setremark(e.target.value);
                }}
              />
              <div className="mt-3 mb-2" style={{ textAlign: "center"}}>
                <Button
                  type="success"
                  style={{
                    padding: " 7px 27px",
                    backgroundColor: "red",
                    borderColor: "red",
                    marginRight:"10px"
                  }}
                  onClick={handleClose1}
                >
                  Cancel
                </Button>
                {ap ==true ? (  <Button
                  type="success"
                  style={{
                    padding: " 7px 27px",
                    backgroundColor: "green",
                    borderColor: "green",
                    marginLeft:"10px"
                  }}
                  onClick={()=>ApproveAndHoldDriver(blockId,"Hold",remark)}
                >
                  OK
                </Button>):(<Button
                  type="success"
                  style={{
                    padding: " 7px 27px",
                    backgroundColor: "green",
                    borderColor: "green",
                    marginLeft:"10px"
                  }}
                  onClick={Blockdriver}
                >
                  OK
                </Button>)}
              
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary" onClick={handleClose1}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}

export default Drivers;
