import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "../Admin/Admin.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaImages } from "react-icons/fa";
import exportFromJSON from "export-from-json";
import { ImFolderDownload } from "react-icons/im";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
function HomdeDriver() {
  const [show, setShow] = useState(false);
  const [deletid,setdeleteId]=useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  console.log("admin", admin1._id);

  const [Data, setData] = useState([]);
 
//   useEffect(() => {
//     fetch('http://192.168.1.33:9000/api/driver/getAllDriverAndVehicle', {
//       method: 'GET',
//       headers: {"Authorization" : `Bearer ${admin}`},
//     })
//       .then(response => response.json())
//       .then(json => {
//         // console.log('token auth: ' + token);
//         setData(json);
//         console.log(json);
//       })
//       .catch(error => console.error(error));
//   }, [admin]);
const [dataSource, setdataSource] = useState([]);
const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
   const pagesVisited = pageNumber * usersPerPage;
   const pageCount = Math.ceil(dataSource.length / usersPerPage);
   const changePage = ({ selected }) => {
     setPageNumber(selected);
   };

  

  const Alldrivers = async () => {
    try {
        const config={
            url:"/driver/getAllDriverAndVehicle",
            method:"get",
            baseURL:"https://goeasycab.info/api",
            headers: {"Authorization" : `Bearer ${admin}`},
         
        }
        let res = await axios(config);
        console.log("check===>",res)
        if (res.status === 200) {
            console.log("check===>",res)
          setdataSource(res.data.success?.filter((ele)=>ele.isHome==true));
          setdata(res.data.success?.filter((ele)=>ele.isHome==true))
        }
    } catch (error) {
        console.log("errror==>",error);
    }
   
  };
  useEffect(() => {
    Alldrivers();
  }, []);
   // Export to Excel

 const [data, setdata] = useState([]);

 const exportType = "xls";
 const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
 const [fileName, setfileName] = useState();

 const ExportToExcel = () => {
   if (fileName) {
     exportFromJSON({ data, fileName, exportType });
     setEnterfilenameModal(false);
     setfileName("");
   } else {
     alert("Enter file name to export");
   }
 };
 const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = dataSource.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setdataSource([...dataSource]);
    }
  };
// console.log("sfsdgv",dataSource);
  
  const RemoveDriver = async () => {
    try {
      const config = {
        url: "/driver/getDeleteDriverById/" + deletid + "/" + admin1?._id,
        method: "delete",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert("Deleted Successfully");
          handleClose();
          Alldrivers();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

const [reason, setreason] = useState(""); 

const Blockdriver = async (data) => {
//  console.log("dfs====>",data._id);
  try {
   const config = {
     url: "/driver/MakeBlockUnblockDriver",
     method: "put",
     baseURL: "https://goeasycab.info/api",
     headers: { "content-type": "application/json" },
     headers: { Authorization: `Bearer ${admin}` },
     data:{
      driverId : data._id,
      isDBlocked:true,
      authId:admin1._id
    } 
   };
   await axios(config).then(function (res) {
     if (res.status === 200) {
       console.log(res.data);
       alert("Blocked");

       Alldrivers()
     
     }
   });
 } catch (error) {
   console.log(error.res);
   alert(error.response.data.error);
 }
};
const Blockdriver1 = async (data) => {
  //  console.log("dfs====>",data._id);
    try {
     const config = {
       url: "/driver/MakeBlockUnblockDriver",
       method: "put",
       baseURL: "https://goeasycab.info/api",
       headers: { "content-type": "application/json" },
       headers: { Authorization: `Bearer ${admin}` },
       data:{
        driverId : data._id,  
        isDBlocked:false,
        authId:admin1._id
      } 
     };
     await axios(config).then(function (res) {
       if (res.status === 200) {
         console.log(res.data);
         alert("UnBlocked");
         Alldrivers()
       }
     });
   } catch (error) {
     console.log(error.res);
     alert(error.response.data.error);
   }
  };


  const ApproveAndHoldDriver = async (data,status,reasion) => {
    //  console.log("dfs====>",data._id);
      try {
       const config = {
         url: "/driver/makeAprrovedAdnHold",
         method: "put",
         baseURL: "https://goeasycab.info/api",
         headers: { "content-type": "application/json" },
         headers: { Authorization: `Bearer ${admin}` },
         data:{
          driverId : data,  
          reason:reasion,
          authId:admin1._id,
          status:status
        } 
       };
       await axios(config).then(function (res) {
         if (res.status === 200) {
           console.log(res.data);
           alert(`Successfully ${status}`);
           Alldrivers()
         }
       });
     } catch (error) {
       console.log(error.res);
       alert(error.response.data.error);
     }
    };
    const [fromdate,setfromdate]=useState("");
    const [ttodate,setttodate]=useState("")
    const searchdate=()=>{
      try {
        if(!fromdate) return alert("From date required")
        if(!ttodate) return alert("To date required")
      
        let std=fromdate?.split("-");
        let entd=ttodate?.split("-")
        let startD=parseInt(std?.join(""));
        let endD=parseInt(entd?.join(""))
        let abc=dataSource?.filter((ele)=>{
      
          let abd=(ele?.ToDate)?.split("/")?.reverse() 
          let amd=parseInt(abd?.join(""));
          return startD<=amd && endD>=amd
        })
        setdataSource(abc);
        setdata(abc);
      } catch (error) {
        console.log(error);
      }
    }
  return (
    <div style={{ paddingLeft: "0px" }}>
        <Button
        style={{
          color: "white",
          backgroundColor: "#fdd12d",
          border: "#fdd12d",
        }}
      >
        {" "}
        Home Drivers
      </Button>
   
      <br></br>
      <div style={{ backgroundColor: "#f0f0f0" }}>
        <div class="row" style={{ alignItems:"center"}}>
        <div className="col-md-2">
              <div className="jjr mb-2">
                <label>Date From</label>
                <br />
                <input type="date" className="new-cab" onChange={(e)=>setfromdate(e.target.value)}/>
              </div>
            </div>
            <div className="col-md-2">
              <div className="jjr mb-2">
                <label>Date To</label>
                <br />
                <input type="date"  className="new-cab" onChange={(e)=>setttodate(e.target.value)}/>
              </div>
            </div>
            <div className="col-md-2">
              <button
                type="button"
                class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
                onClick={searchdate}
              >
                <span>Search</span>
              </button>
            </div>
          <div class="col-sm-4">
            <div class="search-box  d-inline-block">
              <div class="position-relative" style={{ display: "flex" }}>
                <input
                  id="search-bar-3"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  placeholder="Search Partners"
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                  onChange={handleFilter}
                />
              </div>
            </div>
          </div>

          <div class="col-sm-2">
            <div class="text-sm-end">
              {" "}
              <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                  margin: "0px 10px 0px 0px",
                }}
                onClick={() => {
                  setEnterfilenameModal(true);
                }}
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                EXPORT TO EXCEL
              </button>
             
            </div>
          </div>
          <div class="table-responsive">
            <div class="react-bootstrap-table">
              <table
                class="table table align-middle table-nowrap"
                style={{ width: "90%", margin: "2%" }}
              >
                <thead style={{ backgroundColor: "#fdd12d", color: "white",textAlign:"center" }}>
                  <tr style={{ padding: "10px" }}>
                    <th
                      tabindex="0"
                      aria-label="Username sortable"
                      class="sortable"
                    >
                      S.No
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Username sortable"
                      class="sortable"
                    >
                   Driver Id
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Image
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Name
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                  Mobile Number 
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      From Location
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      To Location
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      From Date
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      To Date
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Trip Required
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                  {search.length>0?(<>
                    {tableFilter?.slice(pagesVisited, pagesVisited + usersPerPage).map((data, index) => {
                  return (
                    <tr style={{textAlign:"center"}}>
                    <td>{index + 1}</td>
                    <td>{data?._id}</td>
                    <td><a href={`https://goeasycab.info/Driver/${data?.dProfileImg}`} target="_blank">
                      <img
                        src={`https://goeasycab.info/Driver/${data?.dProfileImg}`}
                        alt="#"
                        width="50px"
                        height="50px"
                      /></a>
                    </td>
                    <td>{data?.driverName}</td>
                    <td>{data?.dMobile}</td>
                 
                    {/* <td>{data?.dLicenceNum}</td> */}
                    <td>{data?.fromLocation}</td>
                    <td>{data?.toLocation}</td>
                    <td>{data?.fromDate}</td>
                    <td>{data?.ToDate}</td>
                    <td>{data?.Assigntripornot}</td>
                   {/* <td>{data?.status =="Approved" ? (<p style={{color:"green"}}>{data?.status}</p>):(<p style={{color:"red"}}>{data?.status}</p>)}</td> */}
                   {/* <td style={{display:"flex",gap:"2px"}}>
                      <Button
                        type="button"
                        variant="danger"
                        className="mb-2"
                        onClick={()=>{
                          setdeleteId(data?._id);
                          handleShow()
                        }}
                      >
                        <span>
                          <RiDeleteBin6Line />
                        </span>
                      </Button>{" "}
                      {data?.status =="Pending" ? (<div >
                        <Button
                        type="button"
                        variant="success"
                        className="mx-2 mb-2"
                        onClick={()=>ApproveAndHoldDriver(data?._id,"Approved","")}
                      >
                        <span>
                         Approve
                        </span>
                      </Button> 
                    
                      </div>):(<></>)}
                     
                   
                      <div style={{marginLeft:"2px"}}>
                    {data?.isDBlocked===true?(<>
                       {" "} <i
                      class="fa fa-stop"
                        aria-hidden="true"
                        title="UnBlock"
                        style={{
                          cursor: "pointer",
                          color: "red",
                          borderRadius:50
                        }}
                        onClick={() => Blockdriver1(data)}
                      ></i>
                      </>):(<></>)}
                      {data?.isDBlocked===false?(<>
                       {" "} <i
                      class="fa fa-stop"
                        aria-hidden="true"
                        title="Block"
                        style={{
                          cursor: "pointer",
                          color: "black",
                          borderRadius:50
                        }}
                        onClick={() => Blockdriver(data)}
                      ></i>
                      </>):(<></>)}</div>
                    </td> */}
                  </tr>
                  );
                  })}
                  </>):(<>
                    {dataSource?.slice(pagesVisited, pagesVisited + usersPerPage).map((data, index) => {
                    return (
                      <tr style={{textAlign:"center"}}>
                      <td>{index + 1}</td>
                      <td>{data?._id}</td>
                      <td><a href={`https://goeasycab.info/Driver/${data?.dProfileImg}`} target="_blank">
                        <img
                          src={`https://goeasycab.info/Driver/${data?.dProfileImg}`}
                          alt="#"
                          width="50px"
                          height="50px"
                        /></a>
                      </td>
                      <td>{data?.driverName}</td>
                      <td>{data?.dMobile}</td>
                   
                      {/* <td>{data?.dLicenceNum}</td> */}
                      <td>{data?.fromLocation}</td>
                      <td>{data?.toLocation}</td>
                      <td>{data?.fromDate}</td>
                      <td>{data?.ToDate}</td>
                      <td>{data?.Assigntripornot}</td>
                     {/* <td>{data?.status =="Approved" ? (<p style={{color:"green"}}>{data?.status}</p>):(<p style={{color:"red"}}>{data?.status}</p>)}</td> */}
                     {/* <td style={{display:"flex",gap:"2px"}}>
                        <Button
                          type="button"
                          variant="danger"
                          className="mb-2"
                          onClick={()=>{
                            setdeleteId(data?._id);
                            handleShow()
                          }}
                        >
                          <span>
                            <RiDeleteBin6Line />
                          </span>
                        </Button>{" "}
                        {data?.status =="Pending" ? (<div >
                          <Button
                          type="button"
                          variant="success"
                          className="mx-2 mb-2"
                          onClick={()=>ApproveAndHoldDriver(data?._id,"Approved","")}
                        >
                          <span>
                           Approve
                          </span>
                        </Button> 
                      
                        </div>):(<></>)}
                       
                     
                        <div style={{marginLeft:"2px"}}>
                      {data?.isDBlocked===true?(<>
                         {" "} <i
                        class="fa fa-stop"
                          aria-hidden="true"
                          title="UnBlock"
                          style={{
                            cursor: "pointer",
                            color: "red",
                            borderRadius:50
                          }}
                          onClick={() => Blockdriver1(data)}
                        ></i>
                        </>):(<></>)}
                        {data?.isDBlocked===false?(<>
                         {" "} <i
                        class="fa fa-stop"
                          aria-hidden="true"
                          title="Block"
                          style={{
                            cursor: "pointer",
                            color: "black",
                            borderRadius:50
                          }}
                          onClick={() => Blockdriver(data)}
                        ></i>
                        </>):(<></>)}</div>
                      </td> */}
                    </tr>
                    );
                  })}
                  </>)}
                 
                </tbody>
              </table>
              <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={EnterfilenameModal}
        onHide={() => setEnterfilenameModal(false)}
        style={{ width: "320px", marginLeft: "40%", marginTop: "2%" }}
      >
        <Modal.Body>
          <div>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Enter Excel file name ....."
                style={{
                  padding: "10px",
                  width: "82%",
                  border: "1px solid #fdd12d",
                }}
                value={fileName}
                onChange={(e) => {
                  setfileName(e.target.value);
                }}
              />
              <ImFolderDownload
                style={{
                  width: "18%",
                  color: "white",
                  backgroundColor: "#fdd12d",
                  paddingTop: "15px",
                  height: "50px",
                  paddingBottom: "15px",
                }}
                onClick={() => ExportToExcel()}
              />
            </div>
            {/* <h1 style={{ textAlign: "center", color: "#ffcc33" }}>{Count}</h1> */}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete !</Modal.Title>
        </Modal.Header>
       
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={RemoveDriver}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default HomdeDriver;
