import React from "react";
import { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import exportFromJSON from "export-from-json";
import { ImFolderDownload } from "react-icons/im";
import { AiOutlineCar } from "react-icons/ai";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import axios from "axios";
import shadows from "@mui/material/styles/shadows";
export default function ViewTrip() {
  const [check, setcheck] = useState("");
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("customer"));
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("");

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setModalText("Are you sure cancel booking ?");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const [dataSource2, setdataSource2] = useState([]);
  useEffect(() => {
    getBooking();
  }, []);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 3;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(dataSource2.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const [nochangedata, setnochangedata] = useState([]);
  const getBooking = async () => {
    const config = {
      url: "/admin/getOrderByUser/" + admin1?._id,
      method: "get",
      baseURL: "https://goeasycab.info/api",
      hearder: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setdataSource2(result.data.success);
        setdata(result.data.success);
        setnochangedata(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [bookId, setbookId] = useState("");
  const [reason, setreason] = useState("");
  const makecancel = async () => {
    try {
      if (!reason) return alert("Please select your reason!");
      const config = {
        url: "/admin/makeCancelTripBycustomer",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        hearder: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: { authId: admin1?._id, bookId: bookId, reason: reason },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully cancel your booking");
        handleCancel();
        getBooking();
        setreason("");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const fillterdata = (abc) => {
    if (abc == "Upcoming") {
      const alldata = nochangedata?.filter((ele) => ele?.isUpcome == true);
      setdata(alldata);
      setdataSource2(alldata);
    } else {
      const alldata = nochangedata?.filter((ele) => ele?.status == abc);
      setdata(alldata);
      setdataSource2(alldata);
    }
  };
  console.log("dataSource1", dataSource2);

  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = dataSource2.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setdataSource2([...dataSource2]);
    }
  };
  // Export to Excel

  const [data, setdata] = useState();
  let today = new Date();
  let todaydate = moment().format("DD/MM/YYYY");
  todaydate = todaydate?.split("/").reverse()?.join("");

  //check make cancel or not
  function checkTime(data) {
    try {
      if (
        parseInt(data?.picUpDate?.split("/")?.reverse()?.join("")) ==
        parseInt(todaydate)
      ) {
        if (data?.vechicleId?.cancelTime == "20 Minutes") {
          const later = new Date(today.getTime() + 20 * 60 * 1000);
          let mint = 0;
          if (later.getMinutes() < 10) {
            mint = `0${later.getMinutes()}`;
          } else {
            mint = later.getMinutes();
          }
          let abc = `${later.getHours()}${mint}`;
          if (parseInt(data?.picUpTime?.split(":")?.join("")) > parseInt(abc))
            return true;
          else return false;
        } else if (data?.vechicleId?.cancelTime == "30 Minutes") {
          const later = new Date(today.getTime() + 30 * 60 * 1000);
          let mint = 0;
          if (later.getMinutes() < 10) {
            mint = `0${later.getMinutes()}`;
          } else {
            mint = later.getMinutes();
          }
          let abc = `${later.getHours()}${mint}`;
          if (parseInt(data?.picUpTime?.split(":")?.join("")) > parseInt(abc))
            return true;
          else return false;
        } else if (data?.vechicleId?.cancelTime == "45 Minutes") {
          const later = new Date(today.getTime() + 45 * 60 * 1000);
          let mint = 0;

          if (later.getMinutes() < 10) {
            mint = `0${later.getMinutes()}`;
          } else {
            mint = later.getMinutes();
          }
          let abc = `${later.getHours()}${mint}`;
          if (parseInt(data?.picUpTime?.split(":")?.join("")) > parseInt(abc))
            return true;
          else return false;
        } else if (data?.vechicleId?.cancelTime == "1 Hour") {
          const later = new Date(today.getTime() + 1 * 60 * 60 * 1000);
          let mint = 0;
          if (later.getMinutes() < 10) {
            mint = `0${later.getMinutes()}`;
          } else {
            mint = later.getMinutes();
          }
          let abc = `${later.getHours()}${mint}`;
          if (parseInt(data?.picUpTime?.split(":")?.join("")) > parseInt(abc))
            return true;
          else return false;
        } else if (data?.vechicleId?.cancelTime == "1 Hour 30 Minutes") {
          const later = new Date(today.getTime() + 90 * 60 * 1000);
          let mint = 0;
          if (later.getMinutes() < 10) {
            mint = `0${later.getMinutes()}`;
          } else {
            mint = later.getMinutes();
          }
          let abc = `${later.getHours()}${mint}`;
          if (parseInt(data?.picUpTime?.split(":")?.join("")) > parseInt(abc))
            return true;
          else return false;
        } else if (data?.vechicleId?.cancelTime == "2 Hour") {
          const later = new Date(today.getTime() + 2 * 60 * 60 * 1000);
          let mint = 0;
          if (later.getMinutes() < 10) {
            mint = `0${later.getMinutes()}`;
          } else {
            mint = later.getMinutes();
          }
          let abc = `${later.getHours()}${mint}`;
          if (parseInt(data?.picUpTime?.split(":")?.join("")) > parseInt(abc))
            return true;
          else return false;
        } else return false;
      } else if (
        parseInt(data?.picUpDate?.split("/")?.reverse()?.join("")) >
        parseInt(todaydate)
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div class="go-0" style={{ margin: "5% 3% 5% 1%" }}>
      <div class="row"></div>
      <div class="row me-0">
        <div class="col-md-3" style={{ marginTop: "-1%" }}>
          <div class="side-bar-menu mt-3">
            <ul class="edit-left-sidebar" style={{ width: "100%" }}>
              <li
                class="lefttag"
                onClick={() => {
                  setcheck("Booked Trips");
                  fillterdata("Upcoming");
                }}
              >
                Booked Trips{" "}
                <span class="pull-right">
                  {check == "Booked Trips" ? (
                    <i class="fa fa-angle-right" style={{ color: "blue" }}></i>
                  ) : (
                    <></>
                  )}
                </span>
              </li>
              <li
                class="lefttag"
                onClick={() => {
                  setcheck("Ongoing");
                  fillterdata("Trip Start");
                }}
              >
                Ongoing{" "}
                <span class="pull-right">
                  {check == "Ongoing" ? (
                    <i class="fa fa-angle-right" style={{ color: "blue" }}></i>
                  ) : (
                    <></>
                  )}
                </span>
              </li>
              <li
                class="lefttag"
                onClick={() => {
                  setcheck("Cancel");
                  fillterdata("Cancel");
                }}
              >
                Cancel{" "}
                <span class="pull-right">
                  {check == "Cancel" ? (
                    <i class="fa fa-angle-right" style={{ color: "blue" }}></i>
                  ) : (
                    <></>
                  )}
                </span>
              </li>
              <li
                class="lefttag"
                onClick={() => {
                  setcheck("Complete");
                  fillterdata("Complete");
                }}
              >
                Complete{" "}
                <span class="pull-right">
                  {check == "Complete" ? (
                    <i class="fa fa-angle-right" style={{ color: "blue" }}></i>
                  ) : (
                    <></>
                  )}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-9">
          <div class="trip_result" style={{ width: "100%" }}>
            <div class="">
              <div style={{ backgroundColor: "#f0f0f0" }}>
                <div class="row" style={{ padding: "10px 20px" }}>
                  <div class="col-sm-4">
                    <div class="search-box  d-inline-block">
                      <div
                        class="position-relative"
                        style={{ display: "flex" }}
                      >
                        <input
                          id="search-bar-0"
                          type="text"
                          aria-labelledby="search-bar-0-label"
                          class="form-control "
                          onChange={handleFilter}
                          placeholder="Search Booked Trips"
                          style={{
                            borderRadius: "50px",
                            border: "1px solid #fdd12d",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" style={{ textAlign: "center" }}>
                    <div class="search-box  d-inline-block">
                      <div
                        class="position-relative"
                        style={{ display: "flex" }}
                      >
                        <h5 class="text-center">
                          {check ? check : "All Trips details"}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <div class="react-bootstrap-table">
                      <table
                        class="table table align-middle table-nowrap"
                        style={{ width: "90%", margin: "2%" }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#fdd12d",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          <tr style={{ padding: "10px" }}>
                            <th
                              tabindex="0"
                              aria-label="Username sortable"
                              class="sortable"
                            >
                              S.No
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>

                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Vehicle
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Vehicle Category
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Name
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>

                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Number
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Email
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              From
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              To
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>

                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              PickUp Date
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Time
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              TripType
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Seats
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>

                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Status
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              bookId
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Total Distance
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Total Amount
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>

                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              Action
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {search ? (
                            <>
                              {tableFilter
                                ?.slice(
                                  pagesVisited,
                                  pagesVisited + usersPerPage
                                )
                                .map((data, index) => {
                                  return (
                                    <tr style={{ textAlign: "center" }}>
                                      <td>{index + 1}</td>
                                      <td>
                                        {" "}
                                        {data?.vechicleId?.vehicleImage ? (
                                          <img
                                            src={`https://goeasycab.info/Vehicle/${data?.vechicleId?.vehicleImage}`}
                                            alt="#"
                                            width="120px"
                                            height="70px"
                                            // style={{ borderRadius: "100%" }}
                                          />
                                        ) : (
                                          <img
                                            src="../Image/car-1.png"
                                            alt="#"
                                            width="120px"
                                            height="70px"
                                            // style={{ borderRadius: "100%" }}
                                          />
                                        )}
                                      </td>
                                      <td>{data?.vechicleId?.category}</td>
                                      <td>{data?.userName}</td>

                                      <td>{data?.number}</td>
                                      <td>{data?.email}</td>
                                      <td>{data?.picUpPlace}</td>
                                      <td>{data?.dropPlace}</td>
                                      <td> {data?.picUpDate}</td>
                                      <td>
                                        {moment(data?.picUpTime, [
                                          "h:mm a",
                                        ]).format("hh:mm a")}
                                      </td>
                                      <td>{data?.tripType}</td>
                                      <td>{data?.vechicleId?.seatAv}</td>

                                      <td>
                                        {data?.status == "Cancel" ? (
                                          <p style={{ color: "red" }}>
                                            {data?.status}
                                          </p>
                                        ) : (
                                          <>
                                            {data?.status == "Pending" ? (
                                              <p style={{ color: "#000080" }}>
                                                {data?.status}
                                              </p>
                                            ) : (
                                              <p style={{ color: "green" }}>
                                                {data?.status}
                                              </p>
                                            )}
                                          </>
                                        )}
                                      </td>
                                      <td>{data?.bookid}</td>

                                      <td>{data?.totalKm} km</td>

                                      <td>{data?.totalAmount}</td>

                                      <td
                                        style={{ display: "flex", gap: "5px" }}
                                      >
                                        {data?.status == "Complete" ? (
                                          <a href={`/voucher/${data?._id}`}>
                                            <button
                                              type="button"
                                              class="btn btn-outline-success"
                                            >
                                              View
                                            </button>
                                          </a>
                                        ) : (
                                          <a href={`/invoice/${data?._id}`}>
                                            <button
                                              type="button"
                                              class="btn btn-outline-success"
                                            >
                                              View
                                            </button>
                                          </a>
                                        )}

                                        {data?.status == "Complete" ||
                                        data?.status == "Trip Start" ||
                                        data?.status == "Cancel" ? (
                                          <></>
                                        ) : (
                                          <>
                                            {checkTime(data) ? (
                                              <button
                                                type="button"
                                                class="btn btn-outline-danger"
                                                onClick={() => {
                                                  showModal();
                                                  setbookId(data?._id);
                                                }}
                                              >
                                                Cancel
                                              </button>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              {dataSource2
                                ?.slice(
                                  pagesVisited,
                                  pagesVisited + usersPerPage
                                )
                                .map((data, index) => {
                                  return (
                                    <tr style={{ textAlign: "center" }}>
                                      <td>{index + 1}</td>
                                      <td>
                                        {" "}
                                        {data?.vechicleId?.vehicleImage ? (
                                          <img
                                            src={`https://goeasycab.info/Vehicle/${data?.vechicleId?.vehicleImage}`}
                                            alt="#"
                                            width="120px"
                                            height="70px"
                                            // style={{ borderRadius: "100%" }}
                                          />
                                        ) : (
                                          <img
                                            src="../Image/car-1.png"
                                            alt="#"
                                            width="120px"
                                            height="70px"
                                            // style={{ borderRadius: "100%" }}
                                          />
                                        )}
                                      </td>
                                      <td>{data?.vechicleId?.category}</td>
                                      <td>{data?.userName}</td>

                                      <td>{data?.number}</td>
                                      <td>{data?.email}</td>
                                      <td>{data?.picUpPlace}</td>
                                      <td>{data?.dropPlace}</td>
                                      <td> {data?.picUpDate}</td>
                                      <td>
                                        {moment(data?.picUpTime, [
                                          "h:mm a",
                                        ]).format("hh:mm a")}
                                      </td>
                                      <td>{data?.tripType}</td>
                                      <td>{data?.vechicleId?.seatAv}</td>

                                      <td>
                                        {data?.status == "Cancel" ? (
                                          <p style={{ color: "red" }}>
                                            {data?.status}
                                          </p>
                                        ) : (
                                          <>
                                            {data?.status == "Pending" ? (
                                              <p style={{ color: "#000080" }}>
                                                {data?.status}
                                              </p>
                                            ) : (
                                              <p style={{ color: "green" }}>
                                                {data?.status}
                                              </p>
                                            )}
                                          </>
                                        )}
                                      </td>
                                      <td>{data?.bookid}</td>

                                      <td>{data?.totalKm} km</td>

                                      <td>{data?.totalAmount}</td>

                                      <td
                                        style={{ display: "flex", gap: "5px" }}
                                      >
                                        {data?.status == "Complete" ? (
                                          <a href={`/voucher/${data?._id}`}>
                                            <button
                                              type="button"
                                              class="btn btn-outline-success"
                                            >
                                              View
                                            </button>
                                          </a>
                                        ) : (
                                          <a href={`/invoice/${data?._id}`}>
                                            <button
                                              type="button"
                                              class="btn btn-outline-success"
                                            >
                                              View
                                            </button>
                                          </a>
                                        )}

                                        {data?.status == "Complete" ||
                                        data?.status == "Trip Start" ||
                                        data?.status == "Cancel" ? (
                                          <></>
                                        ) : (
                                          <>
                                            {checkTime(data) ? (
                                              <button
                                                type="button"
                                                class="btn btn-outline-danger"
                                                onClick={() => {
                                                  showModal();
                                                  setbookId(data?._id);
                                                }}
                                              >
                                                Cancel
                                              </button>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </>
                          )}
                        </tbody>
                      </table>
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="trip_pagination"></div>
        </div>
      </div>
      <Modal
        title="Are you sure cancel your booking ?"
        centered
        open={open}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
      >
        <div className="" style={{ display: "flex", justifyContent: "center" }}>
          <div className="">
            <div
              className="wrong"
              style={{
                fontSize: " 100px",
                color: "#ff000087",
                textAlign: "center",
              }}
            ></div>
            <div className="nf_0">
              <select
                style={{
                  border: "2px solid red",

                  borderRadius: "10px",
                  padding: "1rem 0px 0px",
                  fontSize: "1.2rem",
                  width: "100%",
                }}
                onChange={(e) => setreason(e.target.value)}
              >
                <option value="">Select Reason</option>
                <option value="Got better price">Got better price</option>
                <option value="Driver or vehicle is not as per my booking">
                  Driver or vehicle is not as per my booking
                </option>
                <option value="Driver asked me to cancel">
                  Driver asked me to cancel
                </option>
                <option value="Booked for wrong date/time">
                  Booked for wrong date/time
                </option>
                <option value="Flight/Train delayed/cancelled">
                  Flight/Train delayed/cancelled
                </option>
                <option value="Booked by mistake">Booked by mistake</option>
                <option value="Wanted to change my pickup/drop location">
                  Wanted to change my pickup/drop location
                </option>
                <option value="Change in plan">Change in plan</option>
              </select>
            </div>
            <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: "Red" }}
                onClick={() => handleCancel()}
              >
                Back
              </Button>{" "}
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: "green" }}
                onClick={() => makecancel()}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
