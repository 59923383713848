import React, { useEffect, useState } from "react";
import "./invoice.css";
import Table from "react-bootstrap/Table";
import axios from "axios";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

function Voucher() {
  const { id } = useParams();
  const [edit, setedit] = useState(false);
  const [bookDetails, setbookDetails] = useState({});
  const getBookDetailsById = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getBookingDetailsById/" + id
      );
      if (res.status == 200) {
        setbookDetails(res.data?.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const history = useNavigate();

  // const createPDF = async () => {
  //   const pdf = new jsPDF("portrait", "pt", "a4");
  //   const data = await html2canvas(document.querySelector("#pdf"), {
  //     useCORS: true,
  //   });
  //   let img = data.toDataURL("image/png");
  //   const imgProperties = pdf.getImageProperties(img);
  //   const pdfWidth = 630;
  //   const pdfHeight = (150 * pdfWidth) / imgProperties.width;
  //   pdf.addImage(img, "PNG", 0, 0, 590, 800);
  //   pdf.save(`bookdetails.pdf`);
  // };
  const [gst, setgst] = useState({});
  const getgst = async () => {
    try {
      let res = await axios.get("https://goeasycab.info/api/admin/getgst");
      if (res.status == 200) {
        setgst(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (id) {
      getBookDetailsById();
    }
    getgst();
  }, []);
  const createPDF = async () => {
    const input = document.getElementById("pdf");
    const options = { scrollY: -window.scrollY };
    const canvas = await html2canvas(input, options);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save("voucher.pdf");
  };
  console.log("book data", bookDetails);
  return (
    <>
      <div className="voucher">
        <div className="container" id="pdf" style={{ padding: "10%" }}>
          <div className="vo_0">
            {bookDetails?.PaymetStatus == "Full Payment" ? (
              <h3>TAX INVOICE</h3>
            ) : (
              <h3>ADVANCE RECEIPT VOUCHER</h3>
            )}
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6">
                  <div className="bool">
                    <p>BOOKING ID :</p>
                    <div className="sirial">{bookDetails?.bookid}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="bool">
                    <p>ADVANCED RECEIPT VOUCHER NO. </p>
                    <div className="sirial">{bookDetails?.invoice}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="bool">
                    <p>DATE</p>
                    <div className="sirial">{bookDetails?.picUpDate}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="bool">
                    <p>TRANSACTIONAL TYPE/CATEGORY:</p>
                    <div className="sirial">REG/B2C</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="bool">
                    <p>DOCUMENT TYPE:</p>
                    <div className="sirial">ADVANCE RECEIPT VOUCHER</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="bool">
                    <p>TRANSACTION DETAIL:</p>
                    <div className="sirial">RG</div>
                  </div>
                </div>

                {/* <div className="col-md-6">
                  <div className="bool">
                    <p>PLACE OF SUPPLY</p>
                    <div className="sirial">UP</div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="voucher-img"
                style={{ marginTop: "-40px", marginLeft: "10px" }}
              >
                <img
                  src="/GOLOGO.jpg"
                  alt=""
                  style={{ width: "80%", height: "200px" }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              border: "2px solid black",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>
          <div className="pick_0">
            <div className="row">
              <div className="col-md-6">
                <div className="pick-up">
                  <div className="drop_0">PICKUP</div>
                  <p className="m-n-m">{bookDetails?.picUpPlace}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pick-up">
                  <div className="drop_0">DROP</div>
                  <p className="m-n-m">{bookDetails?.dropPlace}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pick-up">
                  <div className="drop_0">CUSTOMER NAME</div>
                  <p className="m-n-m">{bookDetails?.userName}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pick-up">
                  <div className="drop_0">CUSTOMER GSTIN</div>
                  <p className="m-n-m">Registered</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pick-up">
                  <div className="drop_0">COMPANY NAME</div>
                  <p className="m-n-m">Goeasydo private limited</p>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-recieve">
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th colSpan={2}>PAYMENT BREAKUP</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ fontWeight: "500" }}>
                    Car Hire Charges
                    <br />
                    <span>(including applicable taxes)</span>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    INR{" "}
                    {(
                      Number(bookDetails?.totalAmount) -
                      Number(bookDetails?.discount) -
                      Number(bookDetails?.gst) -
                      Number(bookDetails?.extraCharge) -
                      Number(bookDetails?.serviceFee) -
                      Number(bookDetails?.ReversalFee) -
                      Number(bookDetails?.tollCharge) -
                      Number(bookDetails?.SgstPrice)
                    )?.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "500" }}>Toll Charges</td>
                  <td style={{ textAlign: "end" }}>
                    INR {bookDetails?.tollCharge?.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "500" }}>
                    Internet Handling Charges
                  </td>
                  <td style={{ textAlign: "end" }}>INR 0.0</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "500" }}>GEDC Service Fees</td>
                  <td style={{ textAlign: "end" }}>
                    INR {bookDetails?.serviceFee?.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "600", color: "red" }}>
                    Reversal of GEDC Service Fee
                  </td>
                  <td style={{ textAlign: "end", color: "red" }}>
                    INR {bookDetails?.ReversalFee?.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "600", color: "red" }}>
                    Effective Discount
                  </td>
                  <td style={{ textAlign: "end", color: "red" }}>
                    INR {bookDetails?.discount?.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "500" }}>Extra Charge</td>
                  <td style={{ textAlign: "end" }}>
                    INR {bookDetails?.extraCharge?.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "500" }}>GST Amount</td>
                  <td style={{ textAlign: "end" }}>INR {bookDetails?.gst}</td>
                </tr>
                {/* <tr>
                  <td style={{ fontWeight: "500" }}>SGST @ {bookDetails?.Sgst}%</td>
                  <td style={{ textAlign: "end" }}>INR {bookDetails?.SgstPrice}</td>
                </tr> */}
                {/* <tr>
                  <td style={{ fontWeight: "500" }}>IGST @ 0%</td>
                  <td style={{ textAlign: "end" }}>INR 0.00</td>
                </tr> */}
                <tr>
                  <td style={{ fontWeight: "800", fontSize: "18px" }}>
                    Grand Total
                  </td>
                  <td style={{ textAlign: "end", fontWeight: "800" }}>
                    INR {Number(bookDetails?.totalAmount)?.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "800", fontSize: "18px" }}>
                    Amount Paid
                  </td>
                  <td style={{ textAlign: "end", fontWeight: "800" }}>
                    INR {bookDetails?.payAmount?.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "800", fontSize: "18px" }}>
                    Outstanding Amount
                  </td>
                  <td style={{ textAlign: "end", fontWeight: "800" }}>
                    INR{" "}
                    {(
                      Number(bookDetails?.totalAmount) -
                      Number(bookDetails?.payAmount)
                    )?.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "800", fontSize: "18px" }}>
                    Payment
                  </td>
                  <td style={{ textAlign: "end", fontWeight: "800" }}>
                    {bookDetails?.PaymetStatus}
                  </td>
                </tr>
              </tbody>
            </Table>
            <div style={{ fontSize: "14px" }}>
              This is not a valid travel document
            </div>
          </div>
          <div className="make-0">
            <div className="issu">
              Invoice issued by Go Easy Do Cab Pvt. Ltd.
            </div>
            <div className="row pt-2 pb-2">
              <div className="col-md-3">
                <div className="pick-up">
                  <div className="drop_0">PAN</div>
                  <p className="m-n-m-0">AAICG1690F</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="pick-up">
                  <div className="drop_0">HSN/SAC</div>
                  <p className="m-n-m-0">996412</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="pick-up">
                  <div className="drop_0">SERVICE DESCRIPTION</div>
                  <p className="m-n-m-0">
                    Reservation service for transportation
                  </p>
                </div>
              </div>
              <div className="row  mb-2">
                <div className="col-md-3">
                  <div className="pick-up">
                    <div className="drop_0">GSTN</div>
                    <p className="m-n-m-0">29AAICG1690F1ZG</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="pick-up">
                    <div className="drop_0">CIN</div>
                    <p className="m-n-m-0">U63030KA2020PTC131393</p>
                  </div>
                </div>
              </div>
              <div className="row  mb-2">
                <div className="col-md-4">
                  <div className="pick-up">
                    <div className="drop_0">
                      Go Easy Do Cabs PRIVATE LIMITED
                    </div>
                    <p className="m-n-m-0">
                      2693, Nagarabhavi Main Rd, New Income Tax Layout, Jyothi
                      Nagar, Maruthi Nagar, Attiguppe, Bengaluru, Karnataka
                      560072
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="pick-up">
                    <div className="drop_0">REGISTERED OFFICE</div>
                    <p className="m-n-m-0">
                      2693, Nagarabhavi Main Rd, New Income Tax Layout, Jyothi
                      Nagar, Maruthi Nagar, Attiguppe, Bengaluru, Karnataka
                      560072
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex" style={{ margin: "0% -5% 0% 70%", gap: "5px" }}>
          <button
            type="button"
            class="btn btn-danger"
            onClick={() => history(-1)}
          >
            Back
          </button>

          <button type="button" class="btn btn-warning" onClick={createPDF}>
            Download
          </button>
        </div>
        {/* <button type="button" style={{margin:"0% -5% 0% 80%"}} class="btn btn-warning" onClick={createPDF}>Download</button> */}
        <br />
        <br />
      </div>
    </>
  );
}

export default Voucher;
