import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GoogleMap,Marker , LoadScript, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
// import { Loader } from 'react-google-maps-loader';
// import { backgroundClip } from 'html2canvas/dist/types/css/property-descriptors/background-clip';

// import { Map } from '@googlemaps/react-wrapper'
function Map() {
    const {id}=useParams();
    const [directions, setDirections] = useState(null);
    const [bookDetails, setbookDetails] = useState({});
    const getBookDetailsById = async () => {
      try {
        let res = await axios.get(
          "https://goeasycab.info/api/admin/getBookingDetailsById/" + id
        );
        if (res.status == 200) {
          setbookDetails(res.data?.success);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const history=useNavigate()
    useEffect(() => {
      if (id) {
        getBookDetailsById();
      }
    }, []);
    console.log("bookdata",bookDetails);

    const directionsOptions = {
        destination: { lat: Number(bookDetails?.Latitude2), lng: Number(bookDetails?.Longitude2)},
        origin: { lat: Number(bookDetails?.Latitude1), lng: Number(bookDetails?.Longitude1) },
        travelMode: 'DRIVING' // Can be DRIVING, WALKING, BICYCLING, or TRANSIT
      };
    
      const directionsCallback = (response) => {
        if (response !== null) {
          if (response.status === 'OK') {
            // console.log("Success",response);
            if(!directions){
             setDirections(response)
            }
       
            // return response
          } else {
            // Handle error response
            console.log("Error",response);
          }
        }
      };
      console.log("window", window.google);
      // useEffect(() => {
      //   const directionsService =  window?.google?.maps?.DirectionsService();
      //   directionsService?.route(directionsOptions, directionsCallback);
      // }, []);
    const containerStyle = {
        width: '100%',
        height: '600px'
      };
    
      const center = {
        lat: Number(bookDetails?.driverId?.latitute),
        lng:Number(bookDetails?.driverId?.longtitute) 
      };
     
    
   const [amit,setamit]=useState(false);

  useEffect(() => {
  setTimeout(() => {
        setamit(true)
  }, 1000);

  }, []);
  console.log("Amit check it",amit);
  
    return (<>
        {Object.keys(bookDetails).length!==0 ? (
            <div className='container'>
              <div style={{display:"flex",justifyContent:"space-between"}}>
             <h4 style={{ color: "#fdd12d" }}> Track Driver</h4>
             <div>
             <button type="button" class="btn btn-success" onClick={()=>window.location.reload()}>Refresh</button>
             {/* <div>{" "}</div> */}
             {" "} <button type="button" class="btn btn-danger" onClick={()=>history(-1)}>Back</button></div>
              
              </div>
        
      <LoadScript googleMapsApiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk">
      <GoogleMap
         mapContainerStyle={containerStyle}
         center={center}
         zoom={10}
     
      >
       {amit ? ( <Marker
          key={0}
            position={ { lat: Number(bookDetails?.driverId?.latitute), lng: Number(bookDetails?.driverId.longtitute) }}

            icon={{  url:"../../Image/car-1.png",
            scaledSize: new window.google.maps.Size(40, 40),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(30, 60),
           
          }}
          animation={window?.google?.maps?.Animation?.DROP}
          />):(<></>)}
         
       
          {directions && <DirectionsRenderer directions={directions} />}
        <DirectionsService options={directionsOptions} callback={directionsCallback} />
        <DirectionsRenderer />
      </GoogleMap>
    </LoadScript>
    

      </div>):(<></>)}
    </>
     
    );
  }
  
  export default Map;
  