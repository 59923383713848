import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Slider from "@mui/material/Slider";
import axios from "axios";
import DatePicker from "react-multi-date-picker";
import { DistanceMatrixService } from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";
import moment from "moment";
import { RxCrossCircled } from "react-icons/rx";
import { Modal } from "antd";
function AddAirportRates() {
  const [from, setfrom] = useState();
  const [to, setto] = useState("");
  const [roundTrip, setroundTrip] = useState("");
  const [totalKm, settotalKm] = useState("");
  const [price, setprice] = useState("");
  const [Discount, setDiscount] = useState(0);
  const [baseKm, setbaseKm] = useState(10);
  const [toteltollcharge, settoteltollcharge] = useState("");
  const [parkingCharge, setparkingCharge] = useState("");
  const [totalprice, settotalprice] = useState(0);


  const [retplan, setretplan] = useState("General");
  const [kmprice, setkmprice] = useState(0);
  const [extraprice, setextraprice] = useState(0);
  const [waitcharge, setwaitcharge] = useState("");
  const [waittime, setwaittime] = useState("");
  const [date, setdate] = useState(moment().format("DD/MM/YYYY"));
  const [todate, settodate] = useState(moment().format("DD/MM/YYYY"));
  const [noAllCity, setnoAllcity] = useState([]);
  const [allCity, setAllcity] = useState([]);
  const getAllCity = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllAirportCity"
      );
      if (res.status === 200) {
        setAllcity(res.data.success);
        setnoAllcity(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCity();
  }, []);
  const [city,setcty]=useState("");
  const [arr, setarr] = useState([]);
const [DropPerkmPrice,setDropPerkmPrice]=useState(0)
const [DropPerkmExtra,setDropPerkmExtra]=useState(0)
  const addDataVe = () => {
    try {
      if (!category) {
        return erorShow("Vehicle Category missing");
      }
      if (!waitcharge) {
        return erorShow("Waiting charge missing");
      }
      if (!waittime) {
        return erorShow("Waiting time missing");
      }
      let am=arr?.filter((ele)=>ele?.category==category);
      if(am.length!==0)  return erorShow("Category already exits");
      arr.push({
        category: category,
        PickPerkmPrice: Number(kmprice),
        PickPerkmExtra: Number(extraprice),
        DropPerkmPrice: Number(DropPerkmPrice),
        DropPerkmExtra: Number(DropPerkmExtra),
        discount: Discount,
        waitCharge: waitcharge,
        waittime: waittime,
      });
      setcategory("");
      setextraprice(0);
      setDiscount(0);
      // setwaitcharge(0);
      // setwaittime("");
      setDropPerkmExtra(0);
      setDropPerkmPrice(0);
      return alert("success");
    } catch (error) {
      console.log(error);
    }
  };
  console.log("Array data", arr);
  const [fuel, setfuel] = useState({});
  const [distance, setdistance] = useState(0);
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  console.log("admin", admin1._id);
  const [modal2Open, setModal2Open] = useState(false);
  const [startTime, setstartTime] = useState("");
  const [endTime, setendTime] = useState("");

  const [errorData, setErrordata] = useState("");
  const erorShow = (data) => {
    setErrordata(data);
    setModal2Open(true);
  };
  const Addroutes = async () => {
    let obj = {
      authId: admin1._id,
      vaehicleCate: arr,
      ratePlane: retplan,
      city:city,
      parkingCharge:parkingCharge,
      tollCharge:toteltollcharge,
      baseKm:baseKm
    };
 if(!city){
      return erorShow("City missing")
     }
         if(!baseKm){
      return erorShow("Base Km missing")
     }
    if(retplan !=="General"){
      if (!startTime) {
      return  erorShow("Start time missing");
     } else if (!endTime) {
      return  erorShow("End time missing");
     } else if (!retplan) {
      return erorShow("Rate Plan missing");
     }
      obj["startTime"]=startTime;
      obj["startDate"]=date;
      obj["ToTime"]=endTime;
      obj["ToDate"]=todate;
     
    }
   
     if (arr.length == 0) {
      return erorShow("Rate Plan missing");
     }
      try {
        const config = {
          url: "/admin/addairportrate",
          method: "post",
          baseURL: "https://goeasycab.info/api",
          headers: { "content-type": "application/json" },
          headers: { Authorization: `Bearer ${admin}` },
          data: obj,
        };
        await axios(config).then(function (res) {
          if (res.status === 200) {
            alert("Successfully added");
            window.location.assign("/admin/Airport-rates");
            setfrom("");
            setto("");
            setDiscount("");
            settotalKm("");
            setbaseKm("");
            settotalprice("");
            settoteltollcharge("");
            setroundTrip("");
          }
        });
      } catch (error) {
        console.log(error.res);
        alert(error.response.data.error);
      }
   
  };

  const [dataSource, setdataSource] = useState([]);
  const [category, setcategory] = useState("");

  const AllCategory = async () => {
    let res = await axios.get("https://goeasycab.info/api/admin/getAllCategory");
    if (res.status === 200) {
      console.log(res);
      setdataSource(res.data.carList);
      //   setdata(res.data.carList);
    }
  };
  const [allvehicle, setAllvehicle] = useState([]);
  const Allvehicles = async () => {
    let res = await axios.get("https://goeasycab.info/api/admin/getAllVehicle");
    if (res.status === 200) {
      setAllvehicle(res.data.success);
      // setdata(res.data.success);
    }
  };

  const [allSpecial, setSpecial] = useState([]);
  const getAllSpecial = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllSpecial"
      );
      if (res.status === 200) {
        console.log(res);
        setSpecial(res.data.carList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    AllCategory();
    Allvehicles();
    getAllSpecial();
  }, []);
  const alldate = [
    { id: 0, time: "00:00" },
    { id: 15, time: "00:15" },
    { id: 30, time: "00:30" },
    { id: 45, time: "00:45" },
    { id: 100, time: "01:00" },
    { id: 115, time: "01:15" },
    { id: 130, time: "01:30" },
    { id: 145, time: "01:45" },
    { id: 200, time: "02:00" },
    { id: 215, time: "02:15" },
    { id: 230, time: "02:30" },
    { id: 245, time: "02:45" },
    { id: 300, time: "03:00" },
    { id: 315, time: "03:15" },
    { id: 330, time: "03:30" },
    { id: 345, time: "03:45" },
    { id: 400, time: "04:00" },
    { id: 415, time: "04:15" },
    { id: 430, time: "04:30" },
    { id: 445, time: "04:45" },
    { id: 500, time: "05:00" },
    { id: 515, time: "05:15" },
    { id: 530, time: "05:30" },
    { id: 545, time: "05:45" },
    { id: 600, time: "06:00" },
    { id: 615, time: "06:15" },
    { id: 630, time: "06:30" },
    { id: 645, time: "06:45" },
    { id: 700, time: "07:00" },
    { id: 715, time: "07:15" },
    { id: 730, time: "07:30" },
    { id: 745, time: "07:45" },
    { id: 800, time: "08:00" },
    { id: 815, time: "08:15" },
    { id: 830, time: "08:30" },
    { id: 845, time: "08:45" },
    { id: 900, time: "09:00" },
    { id: 915, time: "09:15" },
    { id: 930, time: "09:30" },
    { id: 945, time: "09:45" },
    { id: 1000, time: "10:00" },
    { id: 1015, time: "10:15" },
    { id: 1030, time: "10:30" },
    { id: 1045, time: "10:45" },
    { id: 1100, time: "11:00" },
    { id: 1115, time: "11:15" },
    { id: 1130, time: "11:30" },
    { id: 1145, time: "11:45" },
    { id: 1200, time: "12:00" },
    { id: 1215, time: "12:15" },
    { id: 1230, time: "12:30" },
    { id: 1245, time: "12:45" },
    { id: 1300, time: "13:00" },
    { id: 1315, time: "13:15" },
    { id: 1330, time: "13:30" },
    { id: 1345, time: "13:45" },
    { id: 1400, time: "14:00" },
    { id: 1415, time: "14:15" },
    { id: 1430, time: "14:30" },
    { id: 1445, time: "14:45" },
    { id: 1500, time: "15:00" },
    { id: 1515, time: "15:15" },
    { id: 1530, time: "15:30" },
    { id: 1545, time: "15:45" },
    { id: 1600, time: "16:00" },
    { id: 1615, time: "16:15" },
    { id: 1630, time: "16:30" },
    { id: 1645, time: "16:45" },
    { id: 1700, time: "17:00" },
    { id: 1715, time: "17:15" },
    { id: 1730, time: "17:30" },
    { id: 1745, time: "17:45" },
    { id: 1800, time: "18:00" },
    { id: 1815, time: "18:15" },
    { id: 1830, time: "18:30" },
    { id: 1845, time: "18:45" },
    { id: 1900, time: "19:00" },
    { id: 1915, time: "19:15" },
    { id: 1930, time: "19:30" },
    { id: 1945, time: "19:45" },
    { id: 2000, time: "20:00" },
    { id: 2015, time: "20:15" },
    { id: 2030, time: "20:30" },
    { id: 2045, time: "20:45" },
    { id: 2100, time: "21:00" },
    { id: 2115, time: "21:15" },
    { id: 2130, time: "21:30" },
    { id: 2145, time: "21:45" },
    { id: 2200, time: "22:00" },
    { id: 2215, time: "22:15" },
    { id: 2230, time: "22:30" },
    { id: 2245, time: "22:45" },
    { id: 2300, time: "23:00" },
    { id: 2315, time: "23:15" },
    { id: 2330, time: "23:30" },
    { id: 2345, time: "23:45" },
  ];

  return (
    <>
      <div className="feewe">
        <div className="container">
          <div className="efwef">
            <div className="row" style={{ marginBottom: "5%" }}>
              <div className="col-md-12">
                <div className="outs">
                <Button
            style={{
              color: "white",
              backgroundColor: "#fdd12d",
              border: "#fdd12d",
            }}
          >
            {" "}
            Airport Tariffs
          </Button>
                 
                </div>
                 
                <div className="row">
                  
                  <div className="col-md-6">
                    <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="feild">
                          <Form.Label>Rate Plan</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => setretplan(e.target.value)}
                          >
                            <option>select menu</option>
                            {allSpecial?.map((item) => {
                              return (
                                <option value={item?.specialday}>
                                  {item.specialday}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="feild">
                          <Form.Label>Select City</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => setcty(e.target.value)}
                          >
                            <option>select menu</option>
                            {allCity?.map((item) => {
                              return (
                                <option value={item?.city}>
                                  {item?.city}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </div>
                      </div>
                       <div className="col-md-6">
                        <div className="afea">
                          <Form.Label>Base Km</Form.Label>
                          <br />
                          <input
                            type="number"
                            value={baseKm}
                            placeholder="Enter base km"
                            className="srgje"
                            onChange={(e) => setbaseKm(e.target.value)}
                          />
                        </div>
                      </div>
                        <div className="col-md-6">
                        <div className="afea">
                          <Form.Label>Toll Charge</Form.Label>
                          <br />
                          <input
                            type="number"
                            value={toteltollcharge}
                            placeholder="Enter toll charge"
                            className="srgje"
                            onChange={(e) => settoteltollcharge(e.target.value)}
                          />
                        </div>
                      </div>
                       <div className="col-md-6">
                        <div className="afea">
                          <Form.Label>Parking Charge</Form.Label>
                          <br />
                          <input
                            type="number"
                            value={parkingCharge}
                            placeholder="Enter parking charge"
                            className="srgje"
                            onChange={(e) => setparkingCharge(e.target.value)}
                          />
                        </div>
                      </div>
                      {retplan=="General" ? (<></>):(<div className="row">

                      <div className="col-md-6 mb-3">
                        <div className="feild">
                          <Form.Label>Start Date</Form.Label>
                          <br />
                          <DatePicker
                            style={{ width: "60%", height: "38px" }}
                            format="DD/MM/YYYY"
                            type="input-icon"
                            minDate={new Date()}
                            value={date}
                            onChange={(date) =>
                              setdate(date.format("DD/MM/YYYY"))
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="eiggyI pb-2">Start Time</label>
                        <div>
                          <select
                            name="txtStartTime"
                            style={{ width: "60%", height: "38px" }}
                            onChange={(e) => setstartTime(e.target.value)}
                          >
                            <option>Select Time</option>
                            {alldate?.map((item, i) => {
                              return (
                                <option value={item?.time}>
                                  {moment(item?.time, ["h:mm A"]).format(
                                    "hh:mm a"
                                  )}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="feild">
                          <Form.Label>End Date</Form.Label>
                          <br />
                          <DatePicker
                            style={{ width: "60%", height: "38px" }}
                            format="DD/MM/YYYY"
                            type="input-icon"
                            minDate={new Date()}
                            value={todate}
                            onChange={(date) =>
                              settodate(date.format("DD/MM/YYYY"))
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="eiggyI pb-2">End Time</label>
                        <div>
                          <select
                            name="txtStartTime"
                            style={{ width: "60%", height: "38px" }}
                            onChange={(e) => setendTime(e.target.value)}
                          >
                            <option>Select Time</option>
                            {alldate?.map((item, i) => {
                              return (
                                <option value={item?.time}>
                                  {moment(item?.time, ["h:mm A"]).format(
                                    "hh:mm a"
                                  )}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      </div>)}
                     
                   
                    </div>

                    <div className="dmf">
                      <p style={{ fontSize: "25px", fontWeight: "600" }}>
                        Add one by one vehicle Category Rates
                      </p>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="feild">
                        <Form.Label>Vehicle Category</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setcategory(e.target.value)}
                        >
                          <option>select menu</option>
                          {dataSource?.map((item) => {
                            return (
                              <option value={item?.category}>
                                {item?.category}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="etsdd">
                      <p>Select PickUp Base Fare/km</p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="wwe">
                            <Slider
                              defaultValue={10}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              min={10}
                              onChange={(e) => {
                                setkmprice(e.target.value);
                                setextraprice(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className="ecww"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                border: "1px solid #c0c4d6",
                                padding: "7px 6px",
                              }}
                            >
                              ₹
                            </span>
                            <input
                              type="text"
                              className="srgje"
                              placeholder="12.5"
                              value={kmprice}
                              onChange={(e)=>  setkmprice(e.target.value)}
                              style={{
                                borderRadius: "none",
                                outline: "none",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="etsdd">
                      <p>Select Drop Base Fare/km</p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="wwe">
                            <Slider
                              defaultValue={10}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              min={10}
                              onChange={(e) => {
                                setDropPerkmPrice(e.target.value);
                                setDropPerkmExtra(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className="ecww"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                border: "1px solid #c0c4d6",
                                padding: "7px 6px",
                              }}
                            >
                              ₹
                            </span>
                            <input
                              type="text"
                              className="srgje"
                              placeholder="12.5"
                              value={DropPerkmPrice}
                              onChange={(e)=>  setDropPerkmPrice(e.target.value)}
                              style={{
                                borderRadius: "none",
                                outline: "none",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="afea">
                          <Form.Label>PickUp Extra/km price</Form.Label>
                          <br />
                          <input
                            type="number"
                            value={extraprice}
                            placeholder="Enter extra fare"
                            className="srgje"
                            onChange={(e) => setextraprice(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="afea">
                          <Form.Label>Drop Extra/km price</Form.Label>
                          <br />
                          <input
                            type="number"
                            value={DropPerkmExtra}
                            placeholder="Enter extra fare"
                            className="srgje"
                            onChange={(e) => setDropPerkmExtra(e.target.value)}
                          />
                        </div>
                      </div>
                    
                      <div className="fadw mt-3">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="rg45h4">
                              <Form.Label>Wating Charges</Form.Label>
                              <br />
                              <input
                                type="text"
                                value={waitcharge}
                                placeholder="Eg. 100"
                                className="srgje"
                                onChange={(e) => setwaitcharge(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="feild">
                              <Form.Label>Free Waiting Minutes</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                onChange={(e) => setwaittime(e.target.value)}
                              >
                                <option>select menu</option>
                                <option value="20 Minutes">20 Minutes</option>
                                <option value="30 Minutes">30 Minutes</option>
                                <option value="45 Minutes">45 Minutes</option>
                                <option value="1 Hour">1 Hour</option>
                                <option value="1 Hour 30 Minutes">
                                  1 Hour 30 Minutes
                                </option>
                                <option value="2 Hour">2 Hour</option>
                              </Form.Select>
                            </div>
                          </div>
                          <div className="col-md-6">
                        <div className="rg45h4">
                          <Form.Label>Discount%</Form.Label>
                          <br />
                          <input
                            type="number"
                            placeholder="3"
                            value={Discount}
                            className="srgje"
                            onChange={(e) => setDiscount(e.target.value)}
                          />
                        </div>
                      </div>
                        </div>
                      </div>
                      <div
                        className="fare-b pt-3 pb-2"
                        style={{ float: "right", textAlign: "center" }}
                      >
                        <button
                          className="ant-btn css-dev-only-do-not-override-1xusghl ant-btn-default hvr-shutter-out-horizontal"
                          onClick={addDataVe}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fare-det">
                      <div
                        className="fare-b pt-3 pb-2"
                        style={{ float: "right" }}
                      >
                        <button
                          className="ant-btn css-dev-only-do-not-override-1xusghl ant-btn-default hvr-shutter-out-horizontal"
                          onClick={Addroutes}
                        >
                          Save Details
                        </button>
                      </div>
                      <br />
                      <br />
                      <br />
                      <div className="fate-detailss">
                        <div
                          className="wqds mt-2 mb-2"
                          style={{
                            backgroundColor: "gainsboro",
                            padding: "0px 54px 0px 0px",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "25px",
                              fontWeight: "600",
                              textAlign: "center",
                            }}
                          >
                            {"  "} Vehicle Category Rates Details
                          </p>
                          <div className="fwv pt-4 pb-4">
                            {arr.map((item) => {
                              return (
                                <ul
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <li>{item?.category}</li>
                                  <li>{item?.PickPerkmPrice}</li>
                                  <li>{item?.PickPerkmExtra}</li>
                                  <li>{item?.DropPerkmPrice}</li>
                                  <li>{item?.DropPerkmExtra}</li>
                                  <li>{item?.discount}%</li>
                                  <li>{item?.waitCharge}</li>
                                  <li>{item?.waittime}</li>
                                </ul>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <div className="" style={{ display: "flex", justifyContent: "center" }}>
          <div className="">
            <div
              className="wrong"
              style={{
                fontSize: " 100px",
                color: "#ff000087",
                textAlign: "center",
              }}
            >
              <RxCrossCircled />
            </div>
            <div className="nf_0">{errorData}</div>
            <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30" }}
                onClick={() => setModal2Open(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AddAirportRates;
