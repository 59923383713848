import axios from "axios";
import exportFromJSON from "export-from-json";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import { BsCheckCircleFill, BsEyeFill } from "react-icons/bs";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
function FlashBid() {
  const [show, setShow] = useState(false);
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let [AssignD, setAssignD] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);

  const getAllassignList = async () => {
    try {
      const config = {
        url: "/admin/getallOrder/" + admin1?._id,
        method: "get",
        baseURL: "https://goeasycab.info/api",
        hearder: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setAssignD(
          res.data.success?.filter(
            (am) => am?.status !== "Pending" && am?.status !== "Assigned"
          )
        );
        setnochangedata(
          res.data.success?.filter(
            (am) => am?.status !== "Pending" && am?.status !== "Assigned"
          )
        );
        setdata(
          res.data.success?.filter(
            (am) => am?.status !== "Pending" && am?.status !== "Assigned"
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllassignList();
  }, []);
  console.log("list of data", AssignD);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AssignD.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const [Enterfile, setEnterfile] = useState({});

  const deleteAssginDriver = async () => {
    try {
      const config = {
        url:
          "/admin/deleteAssignDeleteData/" + Enterfile?._id + "/" + admin1._id,
        method: "delete",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert("Successfully deleted");
          handleClose1();
          getAllassignList();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const [fromdate, setfromdate] = useState("");
  const [ttodate, setttodate] = useState("");
  const searchdate = () => {
    try {
      if (!fromdate) return alert("From date required");
      if (!ttodate) return alert("To date required");

      let std = fromdate?.split("-");
      let entd = ttodate?.split("-");
      let startD = parseInt(std?.join(""));
      let endD = parseInt(entd?.join(""));
      let abc = nochangedata?.filter((ele) => {
        // console.log("amit check it",moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
        let abd = (ele?.picUpDate).split("/").reverse();
        let amd = parseInt(abd?.join(""));
        return startD <= amd && endD >= amd;
      });
      setAssignD(abc);
      setdata(abc);
    } catch (error) {
      console.log(error);
    }
  };
  const abcHandel = (e) => {
    if (e.target.value != "") {
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAssignD([...filterTable]);
      setdata([...filterTable]);
    } else {
      setAssignD([...nochangedata]);
      setdata([...nochangedata]);
    }
  };
  const [data, setdata] = useState();
  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [EnterfilenameModal1, setEnterfilenameModal1] = useState(false);
  const [fileName, setfileName] = useState("trpList");
  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };
  const [orginlat, setorgin] = useState(0);
  const [orginlong, setorginlng] = useState(0);
  const history = useNavigate();
  const fillterdata = (abc) => {
    if (abc == "Upcoming") {
      const alldata = nochangedata?.filter((ele) => ele?.isUpcome == true);
      setdata(alldata);
      setAssignD(alldata);
    } else if (abc == "Accepted") {
      const alldata = nochangedata?.filter(
        (ele) => ele?.driverStatus == "Accepted"
      );
      setdata(alldata);
      setAssignD(alldata);
    } else {
      const alldata = nochangedata?.filter((ele) => ele?.status == abc);
      setdata(alldata);
      setAssignD(alldata);
    }
  };
  const [showList, setshowList] = useState(false);

  const handeleShowList = () => setshowList(true);
  const closeShowList = () => setshowList(false);
  const [ListOfAssgined, setListOfAssigne] = useState([]);
  const [Dno, setDno] = useState([]);
  const getAsseniedData = async () => {
    try {
      const config = {
        url: "/admin/getAllassignListAcceptedDriver/" + admin1?._id,
        method: "get",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setListOfAssigne(res.data.success);
        setDno(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAsseniedData();
  }, []);

  const makeCancelTrip = async (id) => {
    try {
      const config = {
        url: "/admin/AdminCancelTrip/" + id + "/" + admin1?._id,
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully cancel Trip");
        closeShowList();
        getAsseniedData();
      }
    } catch (error) {
      console.log(error);
    }
  };


const [CloseD,setCloseD]=useState(false);
const [deleId,setdelId]=useState("")
  const tripDelete=async()=>{
      try {
      const config = {
        url: "/admin/deleteOrder/" + deleId + "/" + admin1?._id,
        method: "delete",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");
        setCloseD(false);
        getAllassignList();
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div style={{ paddingLeft: "0px" }}>
        <Button
          style={{
            color: "white",
            backgroundColor: "#fdd12d",
            border: "#fdd12d",
          }}
        >
          {" "}
          Trip List
        </Button>

        <br></br>
        <div className="accountt">
          <form>
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-2">
                <div className="jjr mb-2">
                  <label>Date From</label>
                  <br />
                  <input
                    type="date"
                    className="new-cab"
                    onChange={(e) => setfromdate(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="jjr mb-2">
                  <label>Date To</label>
                  <br />
                  <input
                    type="date"
                    className="new-cab"
                    onChange={(e) => setttodate(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <button
                  type="button"
                  onClick={searchdate}
                  class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
                >
                  <span>Search</span>
                </button>
              </div>
              <div className="col-md-2">
                <Form.Select
                  aria-label="Default select example"
                  style={{ width: "auto" }}
                  onChange={(e) => fillterdata(e.target.value)}
                >
                  <option>Select menu</option>
                  {/* <option value="Upcoming">Upcoming</option> */}
                  <option value="Accepted">Accepted</option>
                  <option value="Trip Start">Ongoing</option>
                  <option value="Complete">Completed</option>
                  <option value="Cancel">Canceled</option>
                </Form.Select>
              </div>
              <div className="col-md-3">
                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={() => history(-1)}
                >
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
        <br />

        <div style={{ backgroundColor: "#f0f0f0" }}>
          <div class="row" style={{ padding: "10px 20px" }}>
            <div class="col-sm-4">
              <div class="search-box  d-inline-block">
                <div class="position-relative" style={{ display: "flex" }}>
                  <input
                    id="search-bar-0"
                    type="text"
                    aria-labelledby="search-bar-0-label"
                    class="form-control "
                    onChange={(e) => abcHandel(e)}
                    placeholder="Search trip ......"
                    style={{
                      borderRadius: "50px",
                      border: "1px solid #fdd12d",
                    }}
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="text-sm-end">
                {" "}
                <button
                  style={{
                    padding: "2px 8px 7px 8px",
                    color: "black",
                    backgroundColor: "#fdd12d",
                    border: "none",
                    fontWeight: "600",
                    fontSize: "14px",
                    borderRadius: "20px",
                    margin: "0px 10px 0px 0px",
                  }}
                  onClick={ExportToExcel}
                >
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                  EXPORT TO EXCEL
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <div class="react-bootstrap-table">
              <table
                class="table table align-middle table-nowrap"
                style={{ width: "90%", margin: "2%" }}
              >
                <thead
                  style={{
                    backgroundColor: "#fdd12d",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <tr style={{ padding: "10px" }}>
                    <th
                      tabindex="0"
                      aria-label="Username sortable"
                      class="sortable"
                    >
                      S.No
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Booking ID
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Trip Type
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Vehicle Category
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Name
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>

                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      From
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      To
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Date From
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Time
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Book Amount
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Id
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      book Amount
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Flash bid Amount
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Pay Amount
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Pay Id
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Cancel Request
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Driver Status
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                    Reason 
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Trip Status
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Action
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {AssignD?.slice(
                    pagesVisited,
                    pagesVisited + usersPerPage
                  )?.map((data, i) => {
                    return (
                      <tr style={{ textAlign: "center" }}>
                        <td>{i + 1}</td>
                        <td> {data?.bookid}</td>
                        <td> {data?.tripType}</td>
                        <td>{data?.vechicleId?.category}</td>
                        <td>{data?.driverName}</td>
                        <td>
                          <p
                            style={{
                              overflowX: "hidden",
                              overflowY: "scroll",
                              height: "80px",
                              fontSize: "small",
                            }}
                          >
                            {data?.picPlace}
                          </p>
                        </td>
                        <td>
                          <p
                            style={{
                              overflowX: "hidden",
                              overflowY: "scroll",
                              height: "80px",
                              fontSize: "small",
                            }}
                          >
                            {data?.dropPlace}
                          </p>
                        </td>
                        <td>
                          {" "}
                          {/* {data?.picUpDate?.slice(0,10)} */}
                          {data?.returnDate ? (
                            <div>
                              <p>
                                PickUpDate:-
                                {data?.picUpDate}
                              </p>
                              <p>ReturnDate:-{data?.returnDate}</p>
                            </div>
                          ) : (
                            <>{data?.picUpDate}</>
                          )}
                        </td>
                        <td>
                          {" "}
                          {/* {data?.picUpDate?.slice(0,10)} */}
                          {data?.returnTime ? (
                            <div>
                              <p>
                                PickUpTime:-
                                {moment(data?.picUpTime, ["h:mm a"]).format(
                                  "hh:mm a"
                                )}
                              </p>
                              <p>
                                ReturnTime:-
                                {moment(data?.returnTime, ["h:mm a"]).format(
                                  "hh:mm a"
                                )}
                              </p>
                            </div>
                          ) : (
                            <>
                              {moment(data?.picUpTime, ["h:mm a"]).format(
                                "hh:mm a"
                              )}
                            </>
                          )}
                        </td>
                        <td>{data?.totalAmount}</td>
                        <td>{data?.driverId}</td>
                        {/* <td>{data?.Amount}</td> */}
                        {/* s<td>{data?.driverAmount}</td> */}
                        <td>{data?.payId}</td>
                        <td>
                          {data?.cancelReques} {data?.cancelTime}
                        </td>
                        <td>
                          {data?.driverStatus == "Assigned" ? (
                            <p
                              style={{ color: "red" }}
                              onClick={() => {
                                getAsseniedData();
                                setEnterfile(data);
                                handeleShowList();
                              }}
                            >
                              {data?.driverStatus} <BsEyeFill />
                              {data?.cancelReques &&
                              data?.status !== "Cancel" ? (
                                <Button variant="danger">CancelRequest</Button>
                              ) : (
                                <></>
                              )}
                            </p>
                          ) : (
                            <>
                              {data?.driverStatus == "Pending" ? (
                                <p style={{ color: "#000080" }}>
                                  {data?.driverStatus}
                                </p>
                              ) : (
                                <p
                                  style={{ color: "green" }}
                                  onClick={() => {
                                    getAsseniedData();
                                    setEnterfile(data);
                                    handeleShowList();
                                  }}
                                >
                                  {data?.driverStatus} <BsEyeFill />
                                  {data?.cancelReques &&
                                  data?.status !== "Cancel" ? (
                                    <Button variant="danger">
                                      CancelRequest
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </p>
                              )}
                            </>
                          )}
                        </td>

                        <td>
                          {data?.status == "Cancel" ? (
                            <p style={{ color: "red" }}>{data?.status}</p>
                          ) : (
                            <>
                              {data?.status == "Pending" ? (
                                <p style={{ color: "#000080" }}>
                                  {data?.status}
                                </p>
                              ) : (
                                <p style={{ color: "green" }}>
                                  {" "}
                                  {data?.status}
                                </p>
                              )}
                            </>
                          )}
                        </td>
                        {/* <td>    </td> */}

                        <td style={{ display: "flex", gap: "5px" }}>
                          <div>
                            {data?.status == "Cancel" ||
                            data?.status == "Complete" ? (
                              <></>
                            ) : (
                              <span className="mx-2 ">
                                <Button
                                  variant="success"
                                  className="mb-2"
                                  onClick={() =>
                                    window.location.assign(
                                      `/admin/track-driver/${data?._id}`
                                    )
                                  }
                                >
                                  Track
                                </Button>
                              </span>
                            )}
                          </div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-info"
                              onClick={() => {
                                if (data?.status == "Complete") {
                                  return history(`/voucher/${data?._id}`);
                                } else {
                                  return history("/admin/Invoice", {
                                    state: data?._id,
                                  });
                                }
                              }}
                            >
                              View
                            </button>
                          </div>
                          <div>
                            {data?.status == "Cancel" ? (
                              <span className="mx-2 ">
                              <Button
                                variant="danger"
                                className="mb-2"
                                onClick={() =>{
                                  setdelId(data?._id)
                                  setCloseD(true)}
                                }
                              >
                                Delete
                              </Button>
                            </span>
                            ) : (
                             <></>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Track Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="grieht">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7772.739421229605!2d77.53040988833003!3d13.075739599695376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae22eaa796c853%3A0x8ebe4a9d8b5cad16!2sSingapura%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1685100380080!5m2!1sen!2sin"
             
              height="450"
              style={{width:"100%"}}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div> */}
          <LoadScript googleMapsApiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk">
            <GoogleMap center={{ lat: orginlat, lng: orginlong }} zoom={20}>
              {/* Additional components and markers */}
            </GoogleMap>
          </LoadScript>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>Are you sure delete ?</Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="success" onClick={deleteAssginDriver}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Show Assign Driver Modal */}
      <Modal
        show={showList}
        onHide={closeShowList}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Assigned Drivers List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="vvf">
            <div className="grse" style={{ display: "flex", flexWrap: "wrap" }}>
              {/* <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  onChange={(e)=>abcHandel(e)}
                  placeholder="Search Driver"
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                    marginBottom:"5px"
                  }}
                /> */}

              {/* <div className="mt-3 mb-2 mx-2" >
              <input
                type="checkbox"
                style={{
                  border: "1px solid #fdd12d",
                  borderRadius: "20px",
                }}
                checked={allCheck}
               onChange={()=>setallcheck(!allCheck)}
              />
              <label>All drivers</label>
            </div> */}
              <div className="mt-3 mb-2 mx-2" style={{ fontWeight: "bold" }}>
                <label>Book Id</label>
                {": "}
                <span>{Enterfile?.bookid}</span>
              </div>
              <div className="mt-3 mb-2 mx-2" style={{ fontWeight: "bold" }}>
                <label> Book PickUp Time</label>
                {": "}
                <span>
                  {moment(Enterfile?.picUpTime, ["h:mm a"]).format("hh:mm a")}
                </span>
              </div>
            </div>
            <div className="fre">
              {ListOfAssgined.filter(
                (item) => item?.bookId?._id == Enterfile?._id
              )?.map((data1) => {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontWeight: "bold",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          margin: "10px",
                          flexWrap: "wrap",
                        }}
                      >
                        <img
                          src={`https://goeasycab.info/Driver/${data1?.driverId?.dProfileImg}`}
                          alt="#"
                          width="30px"
                          height="30px"
                          style={{ borderRadius: 10 }}
                        />
                        <span style={{ color: "black" }}>
                          {data1?.driverId?.driverName}{" "}
                        </span>
                        <span style={{ color: "black" }}>
                          {data1?.driverId?.dMobile}{" "}
                        </span>
                        <span style={{ color: "black" }}>
                          {data1?.vehicleNum}{" "}
                        </span>
                        <span style={{ color: "black" }}>
                          {data1?.Amount == 0 ? <></> : data1?.Amount}{" "}
                        </span>
                        <span style={{ color: "black" }}>
                          {data1?.Amount == 0 ? (
                            <></>
                          ) : (
                            <Form>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                checked={data1?.PayReq}
                                // onChange={()=>setPayReq(!payReq)}
                              />
                            </Form>
                          )}{" "}
                        </span>
                      </div>
                      {/* <div> <p>{data1?.Amount}</p></div> */}

                      <div className="">
                        {data1?.status !== "Assigned" ? (
                          <span style={{ color: "green" }}>
                            {data1?.flashBid}{" "}
                            <BsCheckCircleFill
                              style={{
                                color: "green",
                                textAlign: "center",
                                width: "25px",
                                height: "25px",
                                margin: "2px 26px 0px 0px",
                              }}
                            />
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "red",
                              textAlign: "center",
                              margin: "2px 26px 0px 0px",
                            }}
                          >
                            {data1?.flashBid} {data1?.status}
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ color: "green", fontSize: "15px" }}
                    >
                      <div className="col-mb-6">
                        <span style={{ color: "red" }}>
                          Driver Assigned Time: -
                        </span>
                        {moment(data1?.createdAt).format("LLL")}
                      </div>
                      {Enterfile?.driverStatus !== "Assigned" ? (
                        <>
                          <div className="col-mb-6">
                            <span style={{ color: "red" }}>Trip Status: -</span>
                            {Enterfile?.message}
                          </div>
                          <div className="col-mb-6">
                            <span style={{ color: "red" }}>
                              Start From Garage Time: -
                            </span>
                            {Enterfile?.startTime}
                          </div>
                          <div className="col-mb-6">
                            <span style={{ color: "red" }}>
                              Reach Location: -
                            </span>
                            {Enterfile?.reachAddress}
                          </div>
                          <div className="col-mb-6">
                            <span style={{ color: "red" }}>
                              Reached Time: -
                            </span>
                            {Enterfile?.reachTime}
                          </div>
                          <div className="col-mb-6">
                            <span style={{ color: "red" }}>
                              Trip Start Time: -
                            </span>
                            {Enterfile?.tripstarttime}
                          </div>
                          <div className="col-mb-6">
                            <span style={{ color: "red" }}>Drop Place: -</span>
                            {Enterfile?.dropaddress}
                          </div>
                          <div className="col-mb-6">
                            <span style={{ color: "red" }}>Drop Time: -</span>
                            {Enterfile?.endtriptime}
                          </div>
                          {Enterfile?.cancelReques ? (
                            <>
                              <div className="col-mb-6">
                                <span style={{ color: "red" }}>
                                  Cancel Request: -
                                </span>
                                {Enterfile?.cancelReques}
                              </div>
                              <div className="col-mb-6">
                                <span style={{ color: "red" }}>
                                  Cancel Request Time: -
                                </span>
                                {Enterfile?.cancelTime}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeShowList} variant="danger">
            Close
          </Button>
          {Enterfile?.status !== "Cancel" &&
          Enterfile?.driverStatus !== "Pending" &&
          Enterfile?.driverStatus !== "Assigned" &&
          Enterfile?.status !== "Complete" ? (
            <Button
              onClick={() =>
                window.location.assign("/admin/track-driver/" + Enterfile?._id)
              }
              variant="success"
            >
              Live Track
            </Button>
          ) : (
            <></>
          )}
          {Enterfile?.status !== "Cancel" &&
          Enterfile?.driverStatus !== "Assigned" &&
          Enterfile?.cancelReques ? (
            <Button
              onClick={() => makeCancelTrip(Enterfile?._id)}
              variant="danger"
            >
              Cancel Trip
            </Button>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Modal>

          {/* delete Trip*/}
          <Modal show={CloseD} onHide={()=>setCloseD(false)}>
        <Modal.Header closeButton>Are you sure delete ?</Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={()=>setCloseD(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={tripDelete}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FlashBid;
