import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { FaImages } from "react-icons/fa";
import Button from "react-bootstrap/Button";
function VechileEdit() {
  const [dataSource1, setdataSource1] = useState([]);
  const AllCategory = async () => {
    let res = await axios.get(
      "https://goeasycab.info/api/admin/getAllCategory"
    );
    if (res.status === 200) {
      console.log(res);
      setdataSource1(res.data.carList);
    }
  };
  const [allData, setAllData] = useState([]);
  const allVehicleName = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllVehicleName"
      );
      if (res.status == 200) {
        setAllData(res.data.carList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AllBrandData, setAllBrand] = useState([]);
  const getAllbrand = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllVehicleBrand"
      );
      if (res.status == 200) {
        setAllBrand(res?.data?.carList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(dataSource1,"category");
  useEffect(() => {
    AllCategory();
    allVehicleName();
    getAllbrand();
  }, []);
  const formdata = new FormData();
  const [basePrices, setbasePrices] = useState("");
  const [Category, setCategory] = useState("");
  const [perKmPrice, setperKmPrice] = useState("");
  const [tripType, settripType] = useState("");
  const [luggage, setluggage] = useState("");
  const [isAc, setisAc] = useState("");
  const [seatAv, setseatAv] = useState();
  const [holidayPerkmPrice, setholidayPerkmPrice] = useState("");
  const [Holiday, setHoliday] = useState("");
  const [waitingtime, setwaitingtime] = useState("");
  const [waitingPerMinCharge, setwaitingPerMinCharge] = useState("");
  const [nightTime, setnightTime] = useState("");
  const [nightCharge, setnightCharge] = useState("");
  const [VehicleName, setVehicleName] = useState("");
  const [perMinutesPrice,setperMinutesPrice]=useState("")
  const [image, setimage] = useState();
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  // console.log("admin", admin1._id);
  const [cancelTime, setcancelTime] = useState("");
  const [discount, setdiscount] = useState("");
  const [vehModel, setvehModel] = useState("");
  const [fueltype, setfueltype] = useState("");
  const [color, setcolor] = useState("");
  const { state } = useLocation();
  console.log("state data", state);
  useEffect(() => {
    if (state) {
      settripType(state?.tripType);
      setCategory(state?.category);
      if (state?.perKmPrice) {
        setperMinutesPrice(state?.extraHrLimetPrice)
        setbasePrices(state?.basePrice);
        setperKmPrice(state?.perKmPrice);
        setwaitingtime(state?.waitingtime);
        setwaitingPerMinCharge(state?.waitingPerMinCharge);
      }
      setluggage(state?.luggage);
      setseatAv(state?.seatAv);
      setisAc(state?.isAc)
      setnightTime(state?.nightTime);
      setnightCharge(state?.nightCharge);
    }
  }, []);
  const updateVehicle = async () => {
    try {
      formdata.set("authId", admin1?._id);
      formdata.set("vehicleId", state?._id);
      formdata.append("vehicleImage", image);
      formdata.append("category", Category);
      // formdata.append("basePrice", basePrices);
      // formdata.append("vehicleName", VehicleName);
      if(tripType=="Local"){
        formdata.append("basePrice", basePrices);
        formdata.append("perKmPrice", perKmPrice);
        formdata.append("extraHrLimetPrice",perMinutesPrice)
        formdata.append("waitingtime", waitingtime);
        formdata.append("waitingPerMinCharge", waitingPerMinCharge);
       }

      formdata.append("tripType", tripType);
      formdata.append("luggage", luggage);
      formdata.append("isAc", isAc);
      formdata.append("seatAv", seatAv);
      // formdata.append("holidayPerkmPrice", holidayPerkmPrice);
      formdata.append("cancelTime", cancelTime);

      
      
   

      formdata.append("nightTime", nightTime);
      formdata.append("nightCharge", nightCharge);
     

      formdata.append("vehModel", vehModel);
      formdata.append("fueltype", fueltype);
      formdata.append("color", color);
      const config = {
        url: "/admin/updateVehicle",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        hearder: { "content-type": "multipart/form-data" },
        headers: { Authorization: `Bearer ${admin}` },
        data: formdata,
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert("updated Success");
          window.location.assign("/admin/Vehicle");
        }
      });
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  return (
    <>
      <Button
        style={{
          color: "white",
          backgroundColor: "#fdd12d",
          border: "#fdd12d",
        }}
      >
        {" "}
        Vechile Edit
      </Button> 
 

      <div style={{ margin: "1% 2%" }}>
        <label
          style={{
            textAlign: "left",
            paddingBottom: "10px",
            fontSize: "22px",
            color: "#fdd12d",
            fontWeight: "500",
          }}
        >
          Select Vehicle Image
        </label>
        <div class="add-list-media-wrap">
          <div class="listsearch-textarea-item fl-wrap">
            <div class="fuzone">
              <form>
                <div class="fu-text">
                  <span>
                    {!image ? (
                      <FaImages
                        style={{ fontSize: "70px", color: "#ffcc33" }}
                      />
                    ) : (
                      <img
                        src={URL.createObjectURL(image)}
                        alt="Thumb"
                        style={{
                          width: "25%",
                          height: "76px",
                          paddingTop: "1%",
                          paddingBottom: "1%",
                        }}
                      ></img>
                    )}
                    Click here or drop files to upload
                  </span>
                  <div class="photoUpload-files fl-wrap"></div>
                </div>
                <input
                  type="file"
                  name="file"
                  id="upload2"
                  accept="image/*"
                  onChange={(e) => setimage(e.target.files[0])}
                />
              </form>
            </div>
            <p>970 px × 386 px</p>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-4">
            <label
              style={{
                textAlign: "left",
                paddingBottom: "3px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Vehicle Category{" "}
            </label>
            <br />

            <div className="listsearch-input-item">
              <Form.Select
                aria-label="Default select example"
                style={{
                  borderRadius: "50px",
                  border: "1px solid #fdd12d",
                }}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                {state?.category ? (
                  <option>{state?.category}</option>
                ) : (
                  <option>Vehicle Category</option>
                )}

                {dataSource1.map((item) => {
                  return <option value={item.category}>{item.category}</option>;
                })}
              </Form.Select>
            </div>
          </div>
       
          <div className="col-md-4">
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Vehicle Model{" "}
            </label>
            <br />

            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e) => setvehModel(e.target.value)}
            >
              {state?.vehModel ? (
                <option>{state?.vehModel}</option>
              ) : (
                <option>Vehicle Model</option>
              )}
              {allData
                ?.filter((ele) => ele?.category == Category)
                ?.map((item) => {
                  return (
                    <option value={item?.vehicleName}>
                      {item?.vehicleName}
                    </option>
                  );
                })}
            </Form.Select>
          </div>
             <div className='col-md-4'>
                <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
           Ac/Non-Ac{" "}
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e) => setisAc(e.target.value)}
            >
              {isAc? ( <option>{isAc}</option>):( <option>Select menu</option>)}
              <option value="Ac">
                      Ac
                    </option>
                    <option value="Non-Ac">
                    Non-Ac
                    </option>
            </Form.Select>
                </div>
          <div className="col-md-4">
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Vehicle Color{" "}
            </label>
            <br />
            <textarea
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder={state?.color}
              onChange={(e) => {
                setcolor(e.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Fuel Type{" "}
            </label>
            <br />
            <div className="listsearch-input-item">
              <Form.Select
                aria-label="Default select example"
                style={{
                  borderRadius: "50px",
                  border: "1px solid #fdd12d",
                }}
                onChange={(e) => setfueltype(e.target.value)}
              >
                {state?.fueltype ? (
                  <option> {state?.fueltype}</option>
                ) : (
                  <option> Fuel Type</option>
                )}
                <option value="Petrol">Petrol</option>
                <option value="Diesel">Diesel</option>
                <option value="CNG">CNG</option>
                <option value="Electric">Electric</option>
              </Form.Select>
            </div>
          </div>
        
          <div className="col-md-4">
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Luggage{" "}
            </label>
            <br />

            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e) => {
                setluggage(e.target.value);
              }}
            >
              {state?.luggage ? (
                <option> {state?.luggage}</option>
              ) : (
                <option> Luggage</option>
              )}
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </Form.Select>
          </div>
          <div className="col-md-4">
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Seat Av{" "}
            </label>
            <br />

            <textarea
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder={state?.seatAv}
              onChange={(e) => {
                setseatAv(e.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Trip Type{" "}
            </label>
            <br />

            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e) => {
                settripType(e.target.value);
              }}
            >
              {state?.tripType ? (
                <option>{state?.tripType}</option>
              ) : (
                <option> Trip Type</option>
              )}
              <option value="oneway">oneway</option>
              <option value="Round Trip">Round Trip</option>
              <option value="Airport">Airport</option>
              <option value="Local">Local</option>
            </Form.Select>
          </div>
          {tripType == "Local" ? (
            <>
              <div className="col-md-4">
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "green",
                fontWeight: "500",
              }}
            >
              Base Price{" "}
            </label>
            <br />

            <textarea
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder={state?.basePrice}
              value={basePrices}
              onChange={(e) => {
                setbasePrices(e.target.value);
              }}
            />
          </div>
              <div className="col-md-4">
                <label
                  style={{
                    textAlign: "left",
                    paddingBottom: "10px",
                    fontSize: "22px",
                    color: "green",
                    fontWeight: "500",
                  }}
                >
                 Extra Per km rate{" "}
                </label>
                <br />
                <textarea
                  style={{
                    padding: "10px",
                    width: "100%",
                    border: "1px solid #fdd12d",
                    height: "50px",
                    borderRadius: "20px",
                  }}
                  placeholder={state?.perKmPrice}
                  onChange={(e) => {
                    setperKmPrice(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-4">
                <label
                  style={{
                    textAlign: "left",
                    paddingBottom: "10px",
                    fontSize: "22px",
                    color: "green",
                    fontWeight: "500",
                  }}
                >
                 Extra Per Minutes rate{" "}
                </label>
                <br />
                <textarea
                  style={{
                    padding: "10px",
                    width: "100%",
                    border: "1px solid #fdd12d",
                    height: "50px",
                    borderRadius: "20px",
                  }}
                  placeholder={state?.extraHrLimetPrice}
                  onChange={(e) => {
                    setperMinutesPrice(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-4">
                <label
                  style={{
                    textAlign: "left",
                    paddingBottom: "10px",
                    fontSize: "22px",
                    color: "green",
                    fontWeight: "500",
                  }}
                >
                  Discount{" "}
                </label>
                <br />
                <textarea
                  placeholder={state?.discount}
                  style={{
                    padding: "10px",
                    width: "100%",
                    border: "1px solid #fdd12d",
                    height: "50px",
                    borderRadius: "20px",
                  }}
                  onChange={(e) => {
                    setdiscount(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-4">
                <label
                  style={{
                    textAlign: "left",
                    paddingBottom: "10px",
                    fontSize: "22px",
                    color: "green",
                    fontWeight: "500",
                  }}
                >
                  Waiting Time{" "}
                </label>
                <br />
                <Form.Select
                  aria-label="Default select example"
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                  onChange={(e) => {
                    setwaitingtime(e.target.value);
                  }}
                >
                  {state?.waitingtime ? (
                    <option>{state?.waitingtime}</option>
                  ) : (
                    <option>Select</option>
                  )}
                  <option value="20 Minutes">20 Minutes</option>
                  <option value="30 Minutes">30 Minutes</option>
                  <option value="45 Minutes">45 Minutes</option>
                  <option value="1 Hour">1 Hour</option>
                  <option value="1 Hour 30 Minutes">1 Hour 30 Minutes</option>
                  <option value="2 Hour">2 Hour</option>
                </Form.Select>
              </div>
              <div className="col-md-4">
                <label
                  style={{
                    textAlign: "left",
                    paddingBottom: "10px",
                    fontSize: "22px",
                    color: "green",
                    fontWeight: "500",
                  }}
                >
                  Waiting Charge{" "}
                </label>
                <br />
                <textarea
                  style={{
                    padding: "10px",
                    width: "100%",
                    border: "1px solid #fdd12d",
                    height: "50px",
                    borderRadius: "20px",
                  }}
                  placeholder={state?.waitingPerMinCharge}
                  onChange={(e) => {
                    setwaitingPerMinCharge(e.target.value);
                  }}
                />
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="col-md-4">
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Cancel Time{" "}
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e) => {
                setcancelTime(e.target.value);
              }}
            >
              {state?.cancelTime ? (
                <option>{state?.cancelTime}</option>
              ) : (
                <option>Select cancel Time</option>
              )}
              <option value="20 Minutes">20 Minutes</option>
              <option value="30 Minutes">30 Minutes</option>
              <option value="45 Minutes">45 Minutes</option>
              <option value="1 Hour">1 Hour</option>
              <option value="1 Hour 30 Minutes">1 Hour 30 Minutes</option>
              <option value="2 Hour">2 Hour</option>
            </Form.Select>
          </div>
          <div className="col-md-4">
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Night Time{" "}
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e) => {
                setnightTime(e.target.value);
              }}
            >
              {state?.nightTime ? (
                <option> {state?.nightTime}</option>
              ) : (
                <option> Night Time</option>
              )}
              <option value="10 pm to 6 am">10 pm to 6 am</option>
              {/* <option value="8 pm to 5 am">8 pm to 5 am</option>
              <option value="9 pm to 6 am">Three</option>
              <option value="11 pm to 7 am">Three</option> */}
            </Form.Select>
          </div>
          <div className="col-md-4">
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Night Charge{" "}
            </label>
            <br />

            <textarea
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder={state?.nightCharge}
              onChange={(e) => {
                setnightCharge(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="fare-b pt-3 pb-2" style={{ textAlign: "center" }}>
          <button
            className="ant-btn css-dev-only-do-not-override-1xusghl ant-btn-default hvr-shutter-out-horizontal"
            onClick={updateVehicle}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default VechileEdit;
