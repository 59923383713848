import React from 'react'
import {Button} from "react-bootstrap"

function RentalEdit() {
  return (
   <>
   
    <div> <Button
        style={{
          color: "white",
          backgroundColor: "#fdd12d",
          border: "#fdd12d",
        }}
      >
        {" "}
        Edit Packages
      </Button> </div>
    <div className='rent'>
       <form>
       <div className="row">
            <div className='col-md-4'>
                <div className='ghjt mb-2'>
                    <label>PackageKm</label><br/>
                    <input type='text' placeholder='PackageKm' className='wrrg'/>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='ghjt mb-2'>
                    <label>PackageHr</label><br/>
                    <input type='text' placeholder='PackageHr' className='wrrg'/>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='ghjt mb-2'>
                    <label>ExtraHrLimit</label><br/>
                    <input type='text' placeholder='ExtraHrLimit' className='wrrg'/>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='ghjt mb-2'>
                    <label>ExtraHrLimetPrice</label><br/>
                    <input type='text' placeholder='ExtraHrLimetPrice' className='wrrg'/>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='ghjt mb-2'>
                    <label>Price</label><br/>
                    <input type='text' placeholder='Price' className='wrrg'/>
                </div>
            </div>
            <div className="col-md-3">
              <button
                type="button"
                class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
              >
                <span>Update</span>
              </button>
            </div>
        </div>
       </form>
    </div>
   </>
  )
}

export default RentalEdit