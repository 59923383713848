import axios from "axios";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "../Admin/Admin.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaImages } from "react-icons/fa";
import exportFromJSON from "export-from-json";
import { ImFolderDownload } from "react-icons/im";
import ReactPaginate from "react-paginate";
import {FaCar} from "react-icons/fa"
import { useNavigate } from "react-router-dom";
function Vehicle() {
  const history=useNavigate();
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [show, setshow] = useState(false);
  const handleClose = () => setshow(false);
  const handShow=()=>setshow(true);
  const [deleteId,setdeletId]=useState("")

  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  const formdata = new FormData();
  const [basePrices, setbasePrices] = useState("");
  const [Category, setCategory] = useState("");
  const [perKmPrice, setperKmPrice] = useState("");
  const [perMinutesPrice,setperMinutesPrice]=useState("")
  const [tripType, settripType] = useState("");
  const [luggage, setluggage] = useState("");
  const [isAc, setisAc] = useState("Ac");
  const [seatAv, setseatAv] = useState();
  const [holidayPerkmPrice, setholidayPerkmPrice] = useState("");
  const [Holiday, setHoliday] = useState("");
  const [waitingtime, setwaitingtime] = useState("");
  const [waitingPerMinCharge, setwaitingPerMinCharge] = useState("");
  const [nightTime, setnightTime] = useState("");
  const [nightCharge, setnightCharge] = useState("");
  const [VehicleName, setVehicleName] = useState("");
  // const [basePrice, setbasePrice] = useState("");
  const [image, setimage] = useState();
  const [cancelTime, setcancelTime] = useState("");
  const [discount, setdiscount] = useState("");
  const [vehModel, setvehModel] = useState("");
  const [fueltype, setfueltype] = useState("");
  const [color, setcolor] = useState("");
  const AddVehicle = async (e) => {
    if (!Category) {
    return alert("Category missing");
   } 
    if (!vehModel) {
      return  alert("VehicleModel missing");
   }
    if (!fueltype) {
      return  alert("FuelType missing");
  } 
 
    if (!luggage) {
      return alert("Luggage missing");
   } 
    if (!seatAv) {
      return  alert("SeatAv missing");
   }
   if (!tripType) {
    return  alert("TripType missing");
   } 
   if(tripType=="Local"){
    if (!basePrices) {
      return  alert("BasePrices missing");
    } 
    if (!perKmPrice) {
      return  alert("Extra per km missing");
    } 
    if (!perMinutesPrice) {
      return  alert("Extra per Minutes missing");
    } 
    if (!waitingtime) {
      return  alert("Waiting Time missing");
    } 
    if (!waitingPerMinCharge) {
      return  alert("Waiting Per Min Charge missing");
    } 
   }
    if (!nightTime) {
      return alert("NightTime missing");
   }
    if (!nightCharge) {
      return alert("NightCharge missing");
   } 

     e.preventDefault();
     formdata.set("authId", admin1?._id);
     formdata.append("vehicleImage", image);
     formdata.append("category", Category);

     formdata.append("tripType", tripType);
     formdata.append("luggage", luggage);
     formdata.append("isAc", isAc);
     formdata.append("seatAv", seatAv);
     formdata.append("holidayPerkmPrice", holidayPerkmPrice);
     formdata.append("cancelTime", cancelTime);
   
     formdata.append("nightTime", nightTime);
     formdata.append("nightCharge", nightCharge);
     formdata.append("discount", discount);
     formdata.append("vehModel", vehModel);
     formdata.append("fueltype", fueltype);
     formdata.append("color", color);
     if(tripType=="Local"){
      formdata.append("basePrice", basePrices);
      formdata.append("perKmPrice", perKmPrice);
      formdata.append("extraHrLimetPrice",perMinutesPrice)
      formdata.append("waitingtime", waitingtime);
      formdata.append("waitingPerMinCharge", waitingPerMinCharge);
     }
     try {
       const config = {
         url: "/admin/addVehicle",
         method: "post",
         baseURL: "https://goeasycab.info/api",
         headers: { Authorization: `Bearer ${admin}` },
         data: formdata,
       };
       await axios(config).then(function (res) {
         if (res.status === 200) {
           alert("Successfully added")
           handleClose1()
          window.location.reload()
         }
       });
     } catch (error) {
       console.log(error.res);
       alert(error.response.data.error);
     }
   
 };

  const [dataSource, setdataSource] = useState([]);
  useEffect(() => {
    Allvehicles();
  }, []);

  const Allvehicles = async () => {
    let res = await axios.get("https://goeasycab.info/api/admin/getAllVehicle");
    if (res.status === 200) {
      setdataSource(res.data.success);
      setdata(res.data.success);
    }
  };
  // Export to Excel

  const [data, setdata] = useState();

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(dataSource.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };
  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = dataSource.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setdataSource([...dataSource]);
    }
  };
  // const dataSource1=dataSource.map((item)=>item._id)
  // console.log("dataSource1",dataSource1);
  const Removevehicle = async () => {
    try {
      const config = {
        url: "/admin/DeleteVehicleById/" + deleteId + "/" + admin1?._id,
        method: "delete",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert("Deleted Successfully");
          handleClose()
          Allvehicles();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };
  const [dataSource1, setdataSource1] = useState([]);
  const AllCategory = async () => {
    let res = await axios.get(
      "https://goeasycab.info/api/admin/getAllCategory"
    );
    if (res.status === 200) {
      console.log(res);
      setdataSource1(res.data.carList);
    }
  };
  // console.log(dataSource1,"category");
  useEffect(() => {
    AllCategory();
  }, []);
  // console.log("Allvehicles===>", dataSource);
  const [editorPopup, seteditorPopup] = useState(false);
  const [editorShow, seteditorShow] = useState({});

  const openEditor = (data) => {
    seteditorShow(data);
    seteditorPopup(true);
  };
  const CloseEditor = () => seteditorPopup(false);

  const [blogPopup, setblogPopup] = useState(false);
  const [blogShow, setblogShow] = useState();

  const openBlog = (data) => {
    // setFullscreen(breakpoint);
    setblogShow(data);
    setblogPopup(true);
  };
  const CloseBlog = () => setblogPopup(false);

  
  const [allData, setAllData] = useState([]);
  const allVehicleName = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllVehicleName"
      );
      if (res.status == 200) {
        setAllData(res.data.carList);
      }
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    allVehicleName();
 
  }, []);
  return (
    <div style={{ paddingLeft: "0px" }}>
      <Button
        style={{
          color: "white",
          backgroundColor: "#fdd12d",
          border: "#fdd12d",
        }}
      >
        {" "}
        Vehicle 
      </Button> 
    
      <br></br>
      <div style={{ backgroundColor: "#f0f0f0" }}>
        <div class="row" style={{ padding: "10px 20px" }}>
          <div class="col-sm-4">
            <div class="search-box  d-inline-block">
              <div class="position-relative" style={{ display: "flex" }}>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  placeholder="Search Vehicle"
                  onChange={handleFilter}
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                />
              </div>
            </div>
          </div>

          <div class="col-sm-8">
            <div class="text-sm-end">
              {" "}
              <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                  margin: "0px 10px 0px 0px",
                }}
                onClick={() => {
                  setEnterfilenameModal(true);
                }}
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                EXPORT TO EXCEL
              </button>
              <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                }}
                onClick={() => setShow1(true)}
              >
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  +
                </span>
                Vehicle
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <div class="react-bootstrap-table">
              <table
                class="table table align-middle table-nowrap"
                style={{ width: "90%", margin: "2%" }}
              >
                <thead
                  style={{
                    backgroundColor: "#fdd12d",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <tr style={{ padding: "10px" }}>
                    <th
                      tabindex="0"
                      aria-label="Username sortable"
                      class="sortable"
                    >
                      S.No
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Vehicle Image
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Vehicle Name
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Vehicle Category
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Vehicle Model
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Ac/Non-Ac
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Vehicle Color
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Fuel Type
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Vehicle basePrice
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Trip Type
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
             
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                    Extra  PerKm Price
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                    Extra  Per Minutes Price
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Luggage
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      VehicleId
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      SeatAv
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
          

                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Waitingtime
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Waiting Charge
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Night Time
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Night Charge
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Cancel Time Limit
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Action
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {search.length > 0 ? (
                    <>
                      {tableFilter
                        ?.slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((data, index) => {
                          return (
                            <tr style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td>
                               {data?.vehicleImage ? (  <img
                                src={`https://goeasycab.info/Vehicle/${data.vehicleImage}`}
                                alt="#"
                                // width="100%"
                                // height="100%"
                                style={{
                                  borderRadius:"20%",
                                  width:"100%",
                                  height:"100%"
                                }}
                                
                              />):(<img src="../Image/car-1.png" style={{
                                borderRadius:"20%",
                                width:"100%",
                                height:"100%",
                                color:"red"
                              }}/>)}
                            </td>
                            {/* <td>{data.vehicleName}</td> */}
                            <td>{data.category}</td>

                            <td>{data?.vehModel}</td>
                            <td>{data?.isAc}</td>
                            <td>{data?.color}</td>
                            <td>{data?.fueltype}</td>
                            <td>
                              {"₹ "}
                              {data.basePrice}
                            </td>
                            <td>{data.tripType}</td>
                            <td>{data.perKmPrice}</td>
                            <td>{data.extraHrLimetPrice}</td>
                            <td>{data.luggage}</td>
                            <td>{data._id}</td>
                            <td>{data.seatAv}</td>
                            {/* <td>{data.holidayPerkmPrice}</td> */}
                            <td>{data.waitingtime}</td>
                            <td>{data.waitingPerMinCharge}</td>
                            <td>{data.nightTime}</td>
                            <td>{data.nightCharge}</td>
                            <td>{data.cancelTime}</td>
                            <td>
                              <Button variant="danger" className="mb-2"   onClick={() => {
                                  setdeletId(data?._id)
                                  handShow()
                                }}>
                                {" "}
                                <i
                                  class="fa fa-trash-o"
                                  aria-hidden="true"
                                  title="Remove"
                                 
                                ></i>
                              </Button>
                            
                                <Button variant="success" className="mb-2" onClick={()=>history("/admin/vechile-edit",{state:data})}>
                                  <i
                                    class="fa fa-pencil-square-o"
                                    aria-hidden="true"
                                    title="Edit"

                                    // onClick={() => openEditor(data)}
                                  ></i>
                                </Button>
                             
                            </td>
                          </tr>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {dataSource
                        ?.slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((data, index) => {
                          return (
                            <tr style={{ textAlign: "center" }}>
                              <td>{index + 1}</td>
                              <td>
                                 {data?.vehicleImage ? (  <img
                                  src={`https://goeasycab.info/Vehicle/${data.vehicleImage}`}
                                  alt="#"
                                  // width="100%"
                                  // height="100%"
                                  style={{
                                    borderRadius:"20%",
                                    width:"100%",
                                    height:"100%"
                                  }}
                                  
                                />):(<img src="../Image/car-1.png" style={{
                                  borderRadius:"20%",
                                  width:"100%",
                                  height:"100%",
                                  color:"red"
                                }}/>)}
                              </td>
                              {/* <td>{data.vehicleName}</td> */}
                              <td>{data.category}</td>

                              <td>{data?.vehModel}</td>
                              <td>{data?.isAc}</td>
                              <td>{data?.color}</td>
                              <td>{data?.fueltype}</td>
                              <td>
                                {"₹ "}
                                {data.basePrice}
                              </td>
                              <td>{data.tripType}</td>
                              <td>{data.perKmPrice}</td>
                              <td>{data.extraHrLimetPrice}</td>
                              <td>{data.luggage}</td>
                              <td>{data._id}</td>
                              <td>{data.seatAv}</td>
                              {/* <td>{data.holidayPerkmPrice}</td> */}
                              <td>{data.waitingtime}</td>
                              <td>{data.waitingPerMinCharge}</td>
                              <td>{data.nightTime}</td>
                              <td>{data.nightCharge}</td>
                              <td>{data.cancelTime}</td>
                              <td>
                                <Button variant="danger" className="mb-2"   onClick={() => {
                                    setdeletId(data?._id)
                                    handShow()
                                  }}>
                                  {" "}
                                  <i
                                    class="fa fa-trash-o"
                                    aria-hidden="true"
                                    title="Remove"
                                   
                                  ></i>
                                </Button>
                              
                                  <Button variant="success" className="mb-2" onClick={()=>history("/admin/vechile-edit",{state:data})}>
                                    <i
                                      class="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                      title="Edit"

                                      // onClick={() => openEditor(data)}
                                    ></i>
                                  </Button>
                               
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
      </div>
     {/* add vehicle */}
     <Modal
        show={show1}
        onHide={handleClose1}
        animation={true}
        size="lg"
        // style={{ marginTop: "10%" }}
      >
        <Modal.Header style={{ color: "black" }}>Add Vehicle</Modal.Header>
        <Modal.Body>
          <div style={{ margin: "1% 2%" }}>
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
               Vehicle Image 
            </label>
            <div class="add-list-media-wrap">
              <div class="listsearch-textarea-item fl-wrap">
                <div class="fuzone">
                  <form>
                    <div class="fu-text">
                      <span>
                        {!image ? (
                          <FaImages
                            style={{ fontSize: "70px", color: "#ffcc33" }}
                          />
                        ) : (
                          <img
                            src={URL.createObjectURL(image)}
                            alt="Thumb"
                            style={{
                              width: "25%",
                              height: "76px",
                              paddingTop: "1%",
                              paddingBottom: "1%",
                            }}
                          ></img>
                        )}
                        Click here or drop files to upload
                      </span>
                      <div class="photoUpload-files fl-wrap"></div>
                    </div>
                    <input
                      type="file"
                      name="file"
                      id="upload2"
                      accept="image/*"
                      onChange={(e) => setimage(e.target.files[0])}
                    />
                  </form>
                </div>
              </div>
            </div><p>970 px × 386 px</p>
            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "3px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Vehicle Category{" "}
            </label>
            <br />

            <div className="listsearch-input-item">
              <Form.Select
                aria-label="Default select example"
                style={{
                  borderRadius: "50px",
                  border: "1px solid #fdd12d",
                }}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <option>Vehicle Category</option>
                {dataSource1.map((item) => {
                  return <option value={item.category}>{item.category}</option>;
                })}
              </Form.Select>
           
            </div>
          
            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Vehicle Model{" "}
            </label>
            <br />
         
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e) => setvehModel(e.target.value)}
            >
              <option>Vehicle Model</option>
              {allData
                ?.filter((ele) => ele?.category == Category)?.map((item) => {
                return (
                  <option value={item?.vehicleName}>
                    {item?.vehicleName}
                  </option>
                );
              })}
            </Form.Select>
              <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Ac/Non-Ac{" "}
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e) => setisAc(e.target.value)}
            >
              <option>Select menu</option>
            
                    <option value="Ac">
                      Ac
                    </option>
                    <option value="Non-Ac">
                    Non-Ac
                    </option>
             
            </Form.Select>
            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Vehicle Color{" "}
            </label>
            <br />
            <textarea
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder="white"
              //   value={basePrice}
              onChange={(e) => {
                setcolor(e.target.value);
              }}
            />
            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Fuel Type{" "}
            </label>
            <br />
            <div className="listsearch-input-item">
              <Form.Select
                aria-label="Default select example"
                style={{
                  borderRadius: "50px",
                  border: "1px solid #fdd12d",
                }}
                onChange={(e) => setfueltype(e.target.value)}
              >
                <option> Fuel Type</option>
                <option value="Petrol">Petrol</option>
                <option value="Diesel">Diesel</option>
                <option value="CNG">CNG</option>
                <option value="Electric">Electric</option>
              </Form.Select>
            </div>
            
            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Luggage{" "}
            </label>
            <br />
      
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e) => {
                setluggage(e.target.value);
              }}
            >
              <option> Luggage</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </Form.Select>

           
            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Seat Av{" "}
            </label>
            <br />
            <textarea
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder="4"
              onChange={(e) => {
                setseatAv(e.target.value);
              }}
            />
           
            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Trip Type{" "}
            </label>
            <br />

       
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e) => {
                settripType(e.target.value);
              }}
            >
              <option> Trip Type</option>
              <option value="oneway">oneway</option>
              <option value="Round Trip">Round Trip</option>
              <option value="Airport">Airport</option>
              <option value="Local">Local</option>
            </Form.Select>
            {tripType == "Local" ? (
              <div style={{border:"2px solid red",marginTop:"5px",padding:"5px",borderRadius:"10px"}}><br/>
              <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "green",
                fontWeight: "500",
              }}
            >
              Base Price{" "}
            </label>
            <br />
            <textarea
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder="300"
              value={basePrices}
              onChange={(e) => {
                setbasePrices(e.target.value);
              }}
            />
                <br />
                <label
                 style={{
                  textAlign: "left",
                  paddingBottom: "10px",
                  fontSize: "22px",
                  color: "green",
                  fontWeight: "500",
                }}
                >
                Extra per km rate{" "}
                </label>
                <br />
                <textarea
                  style={{
                    padding: "10px",
                    width: "100%",
                    border: "1px solid #fdd12d",
                    height: "50px",
                    borderRadius: "20px",
                   
                  }}
                  placeholder="12"
                  onChange={(e) => {
                    setperKmPrice(e.target.value);
                  }}
                />
                <br />
                <label
                 style={{
                  textAlign: "left",
                  paddingBottom: "10px",
                  fontSize: "22px",
                  color: "green",
                  fontWeight: "500",
                }}
                >
                Extra per Minutes rate{" "}
                </label>
                <br />
                <textarea
                  style={{
                    padding: "10px",
                    width: "100%",
                    border: "1px solid #fdd12d",
                    height: "50px",
                    borderRadius: "20px",
                   
                  }}
                  placeholder="12"
                  onChange={(e) => {
                    setperMinutesPrice(e.target.value);
                  }}
                />
                <br />
                <label
                  style={{
                    textAlign: "left",
                    paddingBottom: "10px",
                    fontSize: "22px",
                    color: "#fdd12d",
                    fontWeight: "500",
                    color: "green",
                  }}
                >
                  Discount{" "}
                </label>
                <br />
                <textarea
                  placeholder="2"
                  style={{
                    padding: "10px",
                    width: "100%",
                    border: "1px solid #fdd12d",
                    height: "50px",
                    borderRadius: "20px",
                  }}
                  onChange={(e) => {
                    setdiscount(e.target.value);
                  }}
                />
                <br />
                <label
                  style={{
                    textAlign: "left",
                    paddingBottom: "10px",
                    fontSize: "22px",
                    fontWeight: "500",
                    color: "green",
                  }}
                >
                  Waiting Time{" "}
                </label>
                <br />
                <Form.Select
                  aria-label="Default select example"
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                  onChange={(e) => {
                    setwaitingtime(e.target.value);
                  }}
                >
                  <option>select</option>
                  <option value="20 Minutes">20 Minutes</option>
                  <option value="30 Minutes">30 Minutes</option>
                  <option value="45 Minutes">45 Minutes</option>
                  <option value="1 Hour">1 Hour</option>
                  <option value="1 Hour 30 Minutes">1 Hour 30 Minutes</option>
                  <option value="2 Hour">2 Hour</option>
                </Form.Select>

                <br />
                <label
                  style={{
                    textAlign: "left",
                    paddingBottom: "10px",
                    fontSize: "22px",
                    color: "green",
                    fontWeight: "500",
                  }}
                >
                  Waiting Charge{" "}
                </label>
                <br />
                <textarea
                  style={{
                    padding: "10px",
                    width: "100%",
                    border: "1px solid #fdd12d",
                    height: "50px",
                    borderRadius: "20px",
                  }}
                  placeholder="50"
                  onChange={(e) => {
                    setwaitingPerMinCharge(e.target.value);
                  }}
                />
              </div>
            ) : (
              <></>
            )}

            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Cancel Time{" "}
            </label>
            <br />
          
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              
              onChange={(e) => {
                setcancelTime(e.target.value);
              }}
            >
              <option>Select cancel Time</option>
              <option value="20 Minutes">20 Minutes</option>
                  <option value="30 Minutes">30 Minutes</option>
                  <option value="45 Minutes">45 Minutes</option>
                  <option value="1 Hour">1 Hour</option>
                  <option value="1 Hour 30 Minutes">1 Hour 30 Minutes</option>
                  <option value="2 Hour">2 Hour</option>
            </Form.Select>
            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Night Time{" "}
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e) => {
                setnightTime(e.target.value);
              }}
            >
              <option> Night Time</option>
              <option value="10 pm to 6 am">10 pm to 6 am</option>
              {/* <option value="8 pm to 5 am">8 pm to 5 am</option>
              <option value="9 pm to 6 am">9 pm to 6 am</option>
              <option value="11 pm to 7 am">11 pm to 7 am</option> */}
            </Form.Select>
          
            <br />
            <label
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontSize: "22px",
                color: "#fdd12d",
                fontWeight: "500",
              }}
            >
              Night Charge{" "}
            </label>
            <br />
            <textarea
              style={{
                padding: "10px",
                width: "100%",
                border: "1px solid #fdd12d",
                height: "50px",
                borderRadius: "20px",
              }}
              placeholder="250"
              onChange={(e) => {
                setnightCharge(e.target.value);
              }}
            />
          
            <br />
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ justifyContent: "space-between", padding: "10px 20px" }}
        >
          <Button
            style={{
              backgroundColor: "#ffcc33",
              border: "none",
              width: "120px",
            }}
            onClick={handleClose1}
          >
            CANCEL
          </Button>

          <Button
            style={{
              backgroundColor: "#fdd12d",
              border: "none",
              width: "180px",
            }}
            onClick={AddVehicle}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={EnterfilenameModal}
        onHide={() => setEnterfilenameModal(false)}
        style={{ width: "320px", marginLeft: "40%", marginTop: "2%" }}
      >
        <Modal.Body>
          <div>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Enter Excel file name ....."
                style={{
                  padding: "10px",
                  width: "82%",
                  border: "1px solid #fdd12d",
                }}
                value={fileName}
                onChange={(e) => {
                  setfileName(e.target.value);
                }}
              />
              <ImFolderDownload
                style={{
                  width: "18%",
                  color: "white",
                  backgroundColor: "#fdd12d",
                  paddingTop: "15px",
                  height: "50px",
                  paddingBottom: "15px",
                }}
                onClick={() => ExportToExcel()}
              />
            </div>
            {/* <h1 style={{ textAlign: "center", color: "#ffcc33" }}>{Count}</h1> */}
          </div>
        </Modal.Body>
      </Modal>
      {/* delete */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>Are you sure delete ?</Modal.Header>
       
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={Removevehicle}>
              Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Vehicle;
