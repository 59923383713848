import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "../Admin/Admin.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaImages } from "react-icons/fa";
import { BsFillCheckCircleFill, BsFillEyeFill } from "react-icons/bs";
import { GiCancel } from "react-icons/gi";
import exportFromJSON from "export-from-json";
import { ImFolderDownload } from "react-icons/im";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useNavigate } from "react-router-dom";
function AirpotPackage() {

  const history = useNavigate();
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  
  const formdata = new FormData();
  const [from, setfrom] = useState();
  const [to, setto] = useState("");
  const [roundTrip, setroundTrip] = useState("");
  const [totalKm, settotalKm] = useState("");
  const [price, setprice] = useState("");
  const [Discount, setDiscount] = useState("");
  const [numberoftoll, setnumberoftoll] = useState("");
  const [toteltollcharge, settoteltollcharge] = useState("");
  const [totalprice, settotalprice] = useState(0);

  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  console.log("admin", admin1._id);
  let obj = {
    authId: admin1._id,
    to: to,
    from: from,
    roundTrip: roundTrip === "true" ? true : roundTrip === "false" ? false : "",
    totalKm: totalKm,
    BasePrice: price,
    discoont: Discount,
    totalprice: totalprice,
    numberOftoll: numberoftoll,
    totalTol: toteltollcharge,
    isActive: true,
  };

 
  const [data, setdata] = useState();
  const [nochangedata,setnochangedata]=useState([])
  const [dataSource, setdataSource] = useState([]);
  useEffect(() => {
    Allroutes();
  }, []);

  const Allroutes = async () => {
    let res = await axios.get(
      "https://goeasycab.info/api/admin/getAllairportPlane"
    );
    if (res.status === 200) {
      setdataSource(res.data.success);
      setdata(res.data.success);
      setnochangedata(res.data.success);
    }
  };
  // Export to Excel

  const makeActiveAndDectiveRoute = async (routeId, isActive) => {
    try {
      let status;
      if (isActive) {
        status = "Activated";
      } else {
        status = "Deactivated";
      }
      const config = {
        url: "/admin/makeActiveAndDectiveAirportRates",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: { authId: admin1._id, id: routeId, isActive: isActive },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(`Successfully ${status}`);
        Allroutes();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(dataSource.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };
  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = dataSource.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setdataSource([...dataSource]);
    }
  };
  const removeroutes = async (data) => {
    try {
      const config = {
        url: "/admin/deleteAirportRate/" + data?._id + "/" + admin1?._id,
        method: "delete",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);

          alert("Deleted Successfully");
          Allroutes();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const [editorPopup, seteditorPopup] = useState(false);
  const [editorShow, seteditorShow] = useState({});

  const openEditor = (data) => {
    seteditorShow(data);
    seteditorPopup(true);
  };
  const CloseEditor = () => seteditorPopup(false);

  const [blogPopup, setblogPopup] = useState(false);
  const [blogShow, setblogShow] = useState({});

  const openBlog = (data) => {
    // setFullscreen(breakpoint);
    // setblogShow(data);
    setblogPopup(true);
  };
  const CloseBlog = () => setblogPopup(false);

  const [editvehiclename, seteditvehiclename] = useState(false);
  const [isShowneditvehiclename, setIsShowneditvehiclename] = useState(false);
  const [editbaseprice, seteditbaseprice] = useState(false);
  const [isbaseprice, setisbaseprice] = useState(false);
  const [editvehicletype, seteditvehicletype] = useState(false);
  const [isShownvehicletype, setIsShownvehicletype] = useState(false);

  const [editroundTrip, seteditroundTrip] = useState(false);
  const [isShownroundTrip, setIsShownroundTrip] = useState(false);
  const [editluagge, seteditluagge] = useState(false);
  const [isshownluagge, setisshownluagge] = useState(false);
  const [editnumberoftoll, seteditnumberoftoll] = useState(false);
  const [edidiscount, setedidiscount] = useState(false);
  const [edittotaltollcharge, setedittotaltollcharge] = useState(false);

  let obj1 = {
    authId: admin1._id,
    routeId: editorShow._id,
    to: to ? to : editorShow?.to,
    from: from ? from : editorShow?.from,
    roundTrip: roundTrip ? roundTrip : editorShow?.roundTrip,
    totalKm: totalKm ? totalKm : editorShow?.totalKm,
    BasePrice: price ? price : editorShow?.price,
    discoont: Discount,
    totalprice: totalprice,
    numberOftoll: numberoftoll,
    totalTol: toteltollcharge,
  };
  const updateroute = async () => {
    try {
      const config = {
        url: "/admin/updateRoute",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: obj1,
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert("updated Success");
          Allroutes();
          CloseEditor();
          setfrom("");
          setto("");
          setDiscount("");
          settotalKm("");
          setnumberoftoll("");
          settotalprice("");
          settoteltollcharge("");
          setroundTrip("");
        }
      });
    } catch (error) {
      console.log(error.res);
      alert("Something went wrong!!! try again");
    }
  };
  const [fromdate,setfromdate]=useState("");
  const [ttodate,setttodate]=useState("")
  const searchdate=()=>{
    try {
      if(!fromdate) return alert("From date required")
      if(!ttodate) return alert("To date required")
    
      let std=fromdate?.split("-");
      let entd=ttodate?.split("-")
      let startD=parseInt(std?.join(""));
      let endD=parseInt(entd?.join(""))
      let abc=nochangedata?.filter((ele)=>{
    
        let abd=(ele?.ToDate)?.split("/")?.reverse() 
        let amd=parseInt(abd?.join(""));
        return startD<=amd && endD>=amd
      })
      setdataSource(abc);
      setdata(abc);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div style={{ paddingLeft: "0px" }}>
       <Button
          style={{
            color: "white",
            backgroundColor: "#fdd12d",
            border: "#fdd12d",
          }}
        >
          {" "}
          Airport Tariffs
        </Button>
  
      <br />
      <div className="accountt">
        <form>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-md-3">
              <div className="jjr mb-2">
                <label>Date From</label>
                <br />
                <input type="date" className="new-cab" onChange={(e)=>setfromdate(e.target.value)}/>
              </div>
            </div>
            <div className="col-md-3">
              <div className="jjr mb-2">
                <label>Date To</label>
                <br />
                <input type="date" className="new-cab" onChange={(e)=>setttodate(e.target.value)} />
              </div>
            </div>

            <div className="col-md-3">
              <button
                type="button"
                class="ant-btn css-dev-only-do-not-override-ed5zg0 ant-btn-default hvr-shutter-out-horizontal"
                onClick={searchdate}
              >
                <span>Search</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <br />
      <div style={{ backgroundColor: "#f0f0f0" }}>
        <div class="row" style={{ padding: "10px 20px" }}>
          <div class="col-sm-4">
            <div class="search-box  d-inline-block">
              <div class="position-relative" style={{ display: "flex" }}>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  onChange={handleFilter}
                  placeholder="Search  Routes"
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                />
              </div>
            </div>
          </div>

          <div class="col-sm-8">
            <div class="text-sm-end">
              {" "}
              <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                  margin: "0px 10px 0px 0px",
                }}
                onClick={() => {
                  setEnterfilenameModal(true);
                }}
              >
                <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                EXPORT TO EXCEL
              </button>
              <button
                style={{
                  padding: "2px 8px 7px 8px",
                  color: "black",
                  backgroundColor: "#fdd12d",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "20px",
                }}
                onClick={() =>
                  window.location.assign("/admin/airport-addrates")
                }
              >
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  +
                </span>
                Add rates
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <div class="react-bootstrap-table">
              <table
                class="table table align-middle table-nowrap"
                style={{ width: "90%", margin: "2%" }}
              >
                <thead
                  style={{
                    backgroundColor: "#fdd12d",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <tr style={{ padding: "10px" }}>
                    <th
                      tabindex="0"
                      aria-label="Username sortable"
                      class="sortable"
                    >
                      S.No
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      City
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Rates Plan
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Base Km
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Parking Charge
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Toll Charge
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Start Date
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Start Time
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      End Date
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      End Time
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Vehicle Category
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    {/* <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                    Vehicle Model
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                   Fuel Type
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                   Rate Plan
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                  From Date
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                To Date
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                Base Fare/km
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
               Extra fare/km
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
               Wating Charges/Hour
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
              Free Waiting Minutes
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                        <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                    total toll Charge
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                       <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                    Price
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th> */}
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      isActive
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabindex="0"
                      aria-label="Phone / Email sortable"
                      class="sortable"
                    >
                      Action
                      <span class="order">
                        <span class="dropdown">
                          <span class="caret"></span>
                        </span>
                        <span class="dropup">
                          <span class="caret"></span>
                        </span>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {search.length > 0 ? (
                    <>
                      {tableFilter
                        ?.slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((data, index) => {
                          return (
                            <tr style={{ textAlign: "center" }}>
                              <td>{index + 1}</td>
                              <td>{data.city}</td>
                              <td>{data.ratePlane}</td>

                              {/* <td>{data.roundTrip == true ? <BsFillCheckCircleFill/>:<GiCancel/>}</td> */}
                              <td>{data.startDate}</td>
                              <td>
                                {" "}
                                {data?.startTime ? (
                                  moment(data?.startTime, ["h:mm A"]).format(
                                    "hh:mm a"
                                  )
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>{data?.ToDate}</td>
                              <td>
                                {data?.ToTime ? (
                                  moment(data?.ToTime, ["h:mm A"]).format(
                                    "hh:mm a"
                                  )
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>
                                <div className="fwv pt-4 pb-4">
                                  {data?.vaehicleCate?.map((item) => {
                                    return (
                                      <div
                                        style={{
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>
                                          {item?.category}{" "}
                                          <span>
                                            <BsFillEyeFill
                                              style={{
                                                color: "blue",
                                                fontSize: "20px",
                                                margin: "2px",
                                              }}
                                              onClick={() => {
                                                setblogShow(item);
                                                openBlog(item);
                                              }}
                                            />
                                          </span>
                                        </p>
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>

                              <td>
                                {data.isActive ? (
                                  <span style={{ color: "green" }}>
                                    Activated
                                  </span>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    Deactivated
                                  </span>
                                )}
                              </td>
                              <td style={{ display: "flex", gap: "5px" }}>
                                <i
                                  class="fa fa-trash-o"
                                  aria-hidden="true"
                                  title="Remove"
                                  style={{
                                    cursor: "pointer",
                                    color: "green",
                                    fontSize: "23px",
                                  }}
                                  onClick={() => removeroutes(data)}
                                ></i>
                                /
                                <i
                                  class="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                  title="Edit"
                                  style={{
                                    cursor: "pointer",
                                    color: "green",
                                    fontSize: "23px",
                                  }}
                                  onClick={() =>
                                    history("/admin/Airport-rates/edit", {
                                      state: data,
                                    })
                                  }
                                ></i>
                                /
                                {data.isActive ? (
                                  <button
                                    type="button"
                                    class="btn btn-outline-danger"
                                    onClick={() =>
                                      makeActiveAndDectiveRoute(
                                        data?._id,
                                        false
                                      )
                                    }
                                  >
                                    Deactive
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-outline-success"
                                    onClick={() =>
                                      makeActiveAndDectiveRoute(data?._id, true)
                                    }
                                  >
                                    Active
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {" "}
                      {dataSource
                        ?.slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((data, index) => {
                          return (
                            <tr style={{ textAlign: "center" }}>
                              <td>{index + 1}</td>
                              <td>{data?.city}</td>
                              <td>{data?.ratePlane}</td>
                              <td>{data?.baseKm} Km</td>
                              <td>{data?.parkingCharge}</td>
                              <td>{data?.tollCharge}</td>
                              {/* <td>{data.roundTrip == true ? <BsFillCheckCircleFill/>:<GiCancel/>}</td> */}
                              <td>{data.startDate}</td>
                              <td>
                                {" "}
                                {data?.startTime ? (
                                  moment(data?.startTime, ["h:mm A"]).format(
                                    "hh:mm a"
                                  )
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>{data?.ToDate}</td>
                              <td>
                                {data?.ToTime ? (
                                  moment(data?.ToTime, ["h:mm A"]).format(
                                    "hh:mm a"
                                  )
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>
                                <div className="fwv pt-4 pb-4">
                                  {data?.vaehicleCate?.map((item) => {
                                    return (
                                      <div
                                        style={{
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>
                                          {item?.category}{" "}
                                          <span>
                                            <BsFillEyeFill
                                              style={{
                                                color: "blue",
                                                fontSize: "20px",
                                                margin: "2px",
                                              }}
                                              onClick={() => {
                                                setblogShow(item);
                                                openBlog(item);
                                              }}
                                            />
                                          </span>
                                        </p>
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>

                              <td>
                                {data.isActive ? (
                                  <span style={{ color: "green" }}>
                                    Activated
                                  </span>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    Deactivated
                                  </span>
                                )}
                              </td>
                              <td style={{ display: "flex", gap: "5px" }}>
                                <i
                                  class="fa fa-trash-o"
                                  aria-hidden="true"
                                  title="Remove"
                                  style={{
                                    cursor: "pointer",
                                    color: "green",
                                    fontSize: "23px",
                                  }}
                                  onClick={() => removeroutes(data)}
                                ></i>
                                /
                                <i
                                  class="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                  title="Edit"
                                  style={{
                                    cursor: "pointer",
                                    color: "green",
                                    fontSize: "23px",
                                  }}
                                  onClick={() =>
                                    history("/admin/Airport-rates/edit", {
                                      state: data,
                                    })
                                  }
                                ></i>
                                /
                                {data.isActive ? (
                                  <button
                                    type="button"
                                    class="btn btn-outline-danger"
                                    onClick={() =>
                                      makeActiveAndDectiveRoute(
                                        data?._id,
                                        false
                                      )
                                    }
                                  >
                                    Deactive
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-outline-success"
                                    onClick={() =>
                                      makeActiveAndDectiveRoute(data?._id, true)
                                    }
                                  >
                                    Active
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
      </div>
      {/* edit Model */}
      <Modal show={editorPopup} onHide={CloseEditor}>
        <Modal.Header closeButton>
          <h4>Edit Packages</h4>
        </Modal.Header>
        <Modal.Body>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}>from: </lable>{" "}
            {!editvehiclename ? (
              <>
                <span className="edit-hov" style={{}}>
                  {editorShow.from}{" "}
                  <i
                    class="fa fa-pencil"
                    style={{ color: "rgb(119 118 118)", cursor: "pointer" }}
                    aria-hidden="true"
                    onClick={() => seteditvehiclename(true)}
                    onMouseEnter={() => setIsShowneditvehiclename(true)}
                    onMouseLeave={(e) => {
                      setIsShowneditvehiclename(false);
                    }}
                  ></i>
                </span>{" "}
                {isShowneditvehiclename ? (
                  <span style={{ color: "#dc3545", fontSize: "15px" }}>
                    Edit
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span>
                  <input
                    type="text"
                    placeholder={editorShow.from}
                    onChange={(e) => setfrom(e.target.value)}
                  />
                </span>
              </>
            )}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}> to: </lable>{" "}
            {!editvehicletype ? (
              <>
                <span className="edit-hov" style={{ cursor: "pointer" }}>
                  {editorShow.to}{" "}
                  <i
                    class="fa fa-pencil"
                    style={{ color: "rgb(119 118 118)" }}
                    aria-hidden="true"
                    onClick={() => seteditvehicletype(true)}
                    onMouseEnter={() => setIsShownvehicletype(true)}
                    onMouseLeave={(e) => {
                      setIsShownvehicletype(false);
                    }}
                  ></i>
                </span>{" "}
                {isShownvehicletype ? (
                  <span style={{ color: "#dc3545", fontSize: "15px" }}>
                    Edit
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span>
                  <textarea
                    placeholder={editorShow.to}
                    style={{ width: "300px" }}
                    onChange={(e) => setto(e.target.value)}
                  />
                </span>
              </>
            )}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}> roundTrip : </lable>{" "}
            {!editbaseprice ? (
              <>
                <span className="edit-hov" style={{ cursor: "pointer" }}>
                  {editorShow.roundTrip === false ? "False" : "True"}{" "}
                  <i
                    class="fa fa-pencil"
                    style={{ color: "rgb(119 118 118)" }}
                    aria-hidden="true"
                    onClick={() => seteditbaseprice(true)}
                    onMouseEnter={() => setisbaseprice(true)}
                    onMouseLeave={(e) => {
                      setisbaseprice(false);
                    }}
                  ></i>
                </span>{" "}
                {isbaseprice ? (
                  <span style={{ color: "#dc3545", fontSize: "15px" }}>
                    Edit
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <select
                  className="nice-select chosen-select no-search-select"
                  onChange={(e) => {
                    setroundTrip(e.target.value);
                  }}
                  //   style={{ display: "none" }}
                >
                  <option>Select RoundTrip</option>
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </>
            )}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}> totalKm : </lable>{" "}
            {!editroundTrip ? (
              <>
                <span className="edit-hov" style={{ cursor: "pointer" }}>
                  {editorShow.totalKm}{" "}
                  <i
                    class="fa fa-pencil"
                    style={{ color: "rgb(119 118 118)" }}
                    aria-hidden="true"
                    onClick={() => seteditroundTrip(true)}
                    onMouseEnter={() => setIsShownroundTrip(true)}
                    onMouseLeave={(e) => {
                      setIsShownroundTrip(false);
                    }}
                  ></i>
                </span>{" "}
                {isShownroundTrip ? (
                  <span style={{ color: "#dc3545", fontSize: "15px" }}>
                    Edit
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span>
                  <input
                    placeholder={editorShow.roundTrip}
                    style={{ width: "300px" }}
                    onChange={(e) => settotalKm(e.target.value)}
                  />
                </span>
              </>
            )}
          </div>{" "}
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}> Discount : </lable>{" "}
            {!edidiscount ? (
              <>
                <span className="edit-hov" style={{ cursor: "pointer" }}>
                  {editorShow.discoont}%{" "}
                  <i
                    class="fa fa-pencil"
                    style={{ color: "rgb(119 118 118)" }}
                    aria-hidden="true"
                    onClick={() => setedidiscount(true)}
                    onMouseEnter={() => setedidiscount(false)}
                    onMouseLeave={(e) => {
                      setedidiscount(false);
                    }}
                  ></i>
                </span>{" "}
                {edidiscount ? (
                  <span style={{ color: "#dc3545", fontSize: "15px" }}>
                    Edit
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span>
                  <input
                    placeholder={`${editorShow.discoont}`}
                    style={{ width: "300px" }}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </span>
              </>
            )}
          </div>{" "}
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}> Number of toll : </lable>{" "}
            {!editnumberoftoll ? (
              <>
                <span className="edit-hov" style={{ cursor: "pointer" }}>
                  {editorShow.numberOftoll}{" "}
                  <i
                    class="fa fa-pencil"
                    style={{ color: "rgb(119 118 118)" }}
                    aria-hidden="true"
                    onClick={() => seteditnumberoftoll(true)}
                    onMouseEnter={() => seteditnumberoftoll(false)}
                    onMouseLeave={(e) => {
                      seteditnumberoftoll(false);
                    }}
                  ></i>
                </span>{" "}
                {editnumberoftoll ? (
                  <span style={{ color: "#dc3545", fontSize: "15px" }}>
                    Edit
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span>
                  <input
                    placeholder={`${editorShow.numberOftoll}`}
                    style={{ width: "300px" }}
                    onChange={(e) => setnumberoftoll(e.target.value)}
                  />
                </span>
              </>
            )}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}> Total toll charge : </lable>{" "}
            {!edittotaltollcharge ? (
              <>
                <span className="edit-hov" style={{ cursor: "pointer" }}>
                  {editorShow.totalTol}{" "}
                  <i
                    class="fa fa-pencil"
                    style={{ color: "rgb(119 118 118)" }}
                    aria-hidden="true"
                    onClick={() => setedittotaltollcharge(true)}
                    onMouseEnter={() => setedittotaltollcharge(false)}
                    onMouseLeave={(e) => {
                      setedittotaltollcharge(false);
                    }}
                  ></i>
                </span>{" "}
                {editnumberoftoll ? (
                  <span style={{ color: "#dc3545", fontSize: "15px" }}>
                    Edit
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span>
                  <input
                    placeholder={`${editorShow.totalTol}`}
                    style={{ width: "300px" }}
                    onChange={(e) => settoteltollcharge(e.target.value)}
                  />
                </span>
              </>
            )}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}> Price: </lable>{" "}
            {!editluagge ? (
              <>
                <span className="edit-hov" style={{ cursor: "pointer" }}>
                  {editorShow.BasePrice}{" "}
                  <i
                    class="fa fa-pencil"
                    style={{ color: "rgb(119 118 118)" }}
                    aria-hidden="true"
                    onClick={() => seteditluagge(true)}
                    onMouseEnter={() => setisshownluagge(true)}
                    onMouseLeave={(e) => {
                      setisshownluagge(false);
                    }}
                  ></i>
                </span>{" "}
                {isshownluagge ? (
                  <span style={{ color: "#dc3545", fontSize: "15px" }}>
                    Edit
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span>
                  <textarea
                    placeholder={editorShow.price}
                    style={{ width: "300px" }}
                    onChange={(e) => setprice(e.target.value)}
                  />
                </span>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="commonupload text-black"
            variant="primary"
            onClick={() => updateroute(editorShow)}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={EnterfilenameModal}
        onHide={() => setEnterfilenameModal(false)}
        style={{ width: "320px", marginLeft: "40%", marginTop: "2%" }}
      >
        <Modal.Body>
          <div>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Enter Excel file name ....."
                style={{
                  padding: "10px",
                  width: "82%",
                  border: "1px solid #fdd12d",
                }}
                value={fileName}
                onChange={(e) => {
                  setfileName(e.target.value);
                }}
              />
              <ImFolderDownload
                style={{
                  width: "18%",
                  color: "white",
                  backgroundColor: "#fdd12d",
                  paddingTop: "15px",
                  height: "50px",
                  paddingBottom: "15px",
                }}
                onClick={() => ExportToExcel()}
              />
            </div>
            {/* <h1 style={{ textAlign: "center", color: "#ffcc33" }}>{Count}</h1> */}
          </div>
        </Modal.Body>
      </Modal>
      {/* Show Model */}
      <Modal show={blogPopup} onHide={CloseBlog}>
        <Modal.Header closeButton>
          <h4>Vehicle Category Details</h4>
        </Modal.Header>
        <Modal.Body>
          <div>
            <lable style={{ fontWeight: "bold" }}>Vehicle Category : </lable>{" "}
            <span className="edit-hov" style={{}}>
              {blogShow?.category}
            </span>{" "}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}>PickUp Base Fare/km : </lable>{" "}
            <span className="edit-hov" style={{}}>
              {"₹ "}
              {blogShow?.PickPerkmPrice}
            </span>{" "}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}>PickUp Extra fare/km : </lable>{" "}
            <span className="edit-hov" style={{}}>
              {"₹ "}
              {blogShow?.PickPerkmExtra}
            </span>{" "}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}>Drop Base fare/km : </lable>{" "}
            <span className="edit-hov" style={{}}>
              {"₹ "}
              {blogShow?.DropPerkmPrice}
            </span>{" "}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}>Drop Extra fare/km : </lable>{" "}
            <span className="edit-hov" style={{}}>
              {"₹ "}
              {blogShow?.DropPerkmExtra}
            </span>{" "}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}> Discount : </lable>{" "}
            <span className="edit-hov" style={{}}>
              {blogShow?.discount}%
            </span>{" "}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}> Waiting Charge : </lable>{" "}
            <span className="edit-hov" style={{}}>
              {"₹ "}
              {blogShow?.waitCharge}
            </span>{" "}
          </div>
          <br />
          <div>
            <lable style={{ fontWeight: "bold" }}>
              {" "}
              Free Waiting Minutes :{" "}
            </lable>{" "}
            <span className="edit-hov" style={{}}>
              {blogShow?.waittime}
            </span>{" "}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="commonupload text-black"
            variant="primary"
            onClick={() => CloseBlog(editorShow)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AirpotPackage;
