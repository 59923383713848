import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import ReactPaginate from "react-paginate";
import moment from "moment";

function Coupon() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [expens,setexpance]=useState("");

  const [allExpanse,setallExpanse]=useState([]);
  
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  const getAllExpanse=async()=>{
    try {
      let res=await axios.get("https://goeasycab.info/api/admin/getAllCouponCode");
      if(res.status==200){
        setallExpanse(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [date,setdate]=useState("");
  const [enddate,setenddate]=useState("");
  const [Discount,setDiscount]=useState("");
  const [remark,setremark]=useState("");
  const [title,settitle]=useState("");
  const [couponType,setcouponType]=useState("");
  const [couponCode,setcouponCode]=useState("");
  const [couponImage,setcouponImage]=useState("");
const AddCoupon=async ()=>{
  try {
    if(!title) return alert("Please enter title")
    if(!couponType) return alert("Please select coupon type")
    if(!couponCode) return alert("Please enter coupon code")
    if(!Discount) return alert("Please enter Discount")
    // if(!remark) return alert("Please enter remark")
    const config = {
      url: "/admin/AddCoupon",
      method: "post",
      baseURL: "https://goeasycab.info/api",
    //   headers: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}`,"content-type": "multipart/form-data"  },
      data: { authId: admin1._id,title:title,couponDiscount:Discount,couponCode:couponCode ,startDate:date,EndDate:enddate,couponImage:couponImage,couponType:couponType}
    };
    let res=await axios(config);
    if(res.status===200){
      alert("Successfully added")
      getAllCoupon()
      handleClose()
      setexpance("")
      settitle("");
      setcouponCode("")
      setcouponType("")
      setcouponImage("")
      setdate("")
      setDiscount("")
      setenddate("")
      setremark("")
 
    }
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}
const [alldata,setalldata]=useState([])
const [nochange,setnochange]=useState([]);

const getAllCoupon=async()=>{
  try {
    let res=await axios.get("https://goeasycab.info/api/admin/getAllCouponCode");
    if(res.status==200){
      setalldata(res.data.success)
      setnochange(res.data.success)
    }
  } catch (error) {
    console.log(error);
  }
}
useEffect(()=>{
  getAllExpanse();
  getAllCoupon()
},[])
const [editData,seteditData]=useState({})
const deleteData=async()=>{
  try {
    const config = {
      url: "/admin/getDeleteCouponCode/"+editData?._id+"/"+ admin1?._id,
      method: "delete",
      baseURL: "https://goeasycab.info/api",
      headers: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}`,"content-type": "multipart/form-data"  },
    };
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully deleted");
      handleClose2()
      getAllCoupon()
    }
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}

const updateExpanse=async()=>{
  try {
    console.log("check",date,enddate);
    const config = {
      url: "/admin/updateCoupon",
      method: "put",
      baseURL: "https://goeasycab.info/api",
      headers: { Authorization: `Bearer ${admin}`,"content-type": "multipart/form-data"  },
      data: { authId: admin1?._id,title:title,couponDiscount:Discount,couponCode:couponCode ,startDate:date,EndDate:enddate,couponImage:couponImage,couponType:couponType,id:editData?._id},
    };
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully Updated")
      getAllCoupon()
      handleClose1()
      settitle("");
      setcouponCode("")
      setcouponType("")
      setcouponImage("")
      setdate("")
      setDiscount("")
      setenddate("")
      setremark("")
    }
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}
// console.log("AllData",alldata);
const [pageNumber, setPageNumber] = useState(0);
const usersPerPage = 10;
const pagesVisited = pageNumber * usersPerPage;
const pageCount = Math.ceil(alldata?.length / usersPerPage);
const changePage = ({ selected }) => {
  setPageNumber(selected);
};
const [search, setSearch] = useState("");
const handleFilter = (e) => {
  if (e.target.value != "") {
    setSearch(e.target.value);
    const filterTable = nochange.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setalldata([...filterTable]);
  } else {
    setSearch(e.target.value);
    setalldata([...nochange]);
  }
};
  return (
    <>
         <Button
            style={{
              color: "white",
              backgroundColor: "#fdd12d",
              border: "#fdd12d",
            }}
          >
            {" "}
            Coupon
          </Button>
      <div className="" style={{float:"right"}}>
        <Button
          style={{
            borderRadius: "50px",
            border: "1px solid #fdd12d",
          }}
          onClick={handleShow}
        >
          Create
        </Button>
      </div>

      <div className="vwv">
        <div style={{ paddingLeft: "0px" }}>
          <div style={{ backgroundColor: "#f0f0f0" }}>
            <div class="row" style={{ padding: "10px 20px" }}>
              <div className="col-md-6">
            <div class="search-box  d-inline-block">
              <div class="position-relative" style={{ display: "flex" }}>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  onChange={handleFilter}
                  placeholder="Search coupon..."
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                  }}
                />
              </div>
            </div></div>
              <div class="table-responsive">
                <div class="react-bootstrap-table">
                  <table
                    class="table table align-middle table-nowrap"
                    style={{ width: "90%", margin: "2%" }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#fdd12d",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      <tr style={{ padding: "10px" }}>
                        <th
                          tabindex="0"
                          aria-label="Username sortable"
                          class="sortable"
                        >
                          S.No
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Title
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Coupon Image
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Coupon Type
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Coupon Code
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Discount
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Start Date
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          End Date
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Status
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Action
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {alldata?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((ele,i)=>{
                        return (
                          <tr style={{ textAlign: "center" }}>
                          <td>{i+1}</td>
                          <td>{ele?.title}</td>
                          <td>
                            {ele?.couponImage ? (<img src={`https://goeasycab.info/Coupon/${ele?.couponImage}`} width="50px" height="50px"/>):(<></>)}
                         </td>
                          <td>{ele?.couponType}</td>
                          <td>{ele?.couponCode}</td>
                          <td>{ele?.couponDiscount}%</td>
                          <td>{ele?.startDate ? (<>{moment(ele?.startDate).format("DD/MM/YYYY")}</>):(<></>)}</td>
                          <td>{ele?.EndDate ? (<>{moment(ele?.EndDate).format("DD/MM/YYYY")}</>):(<></>)}</td>
                          <td>{ele?.status=="Expired" ? (<span style={{color:"red"}}>{ele?.status}</span>):(<span style={{color:"green"}}>{ele?.status}</span>)}</td>
                          <td style={{display:"flex",gap:"2px"}}>
                            <div><Button
                              type="button"
                              variant="danger"
                              onClick={()=>{
                                seteditData(ele);
                              
                                handleShow2()
                              }}
                            >
                              <span>
                                <RiDeleteBin6Line />
                              </span>
                            </Button></div>
                          <div> <Button
                              type="button"
                              variant="success"
                              className="mx-2"
                              onClick={()=>{
                                seteditData(ele);
                                setcouponType(ele?.couponType)
                              handleShow1()}}
                            >
                              <span >
                                <RiEditLine />
                              </span>
                            </Button></div>
                           
                          </td>
                        </tr>
                        )
                      })}
                   
                    </tbody>
                  </table>
                  <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
        <label>Coupon title</label><br/>
        <div class="position-relative  mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="text"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder="Enter coupon title"
              onChange={(e)=>settitle(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
          <label htmlFor="upload1">Coupon Image</label><br/>
        <div class="position-relative  mb-2" style={{ display: "flex" }}>
            <input
              id="upload1"
              type="file"
              name="file"
              accept="image/*"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder="Enter coupon title"
              onChange={(e)=>setcouponImage(e.target.files[0])}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
          <label>Coupon type</label><br/>
          <div class="position-relative  mb-2" style={{ display: "flex" }}>
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e)=>setcouponType(e.target.value)}
            >
              <option>Select Coupon type</option>
            <option value="First Booking">First Booking</option>
            <option value="Festival">Festival</option>
            <option value="Weekend">Weekend</option>
            <option value="General">General</option>
            </Form.Select>
          </div>
          <label>Coupon code</label><br/>
          <div class="position-relative  mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="text"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder="Eg:- X4uZe5c"
              onChange={(e)=>setcouponCode(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
          <label>Discount</label><br/>
          <div class="position-relative  mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="number"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder="Discount%"
              onChange={(e)=>setDiscount(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
          {couponType=="Festival" || couponType=="Weekend" ? (<> <label>Start Date</label><br/>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
       
            <input
              id="search-bar-0"
              type="date"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder="Date"
            
              onChange={(e)=>setdate(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
          <label>End Date</label>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
       
            <input
              id="search-bar-0"
              type="date"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder="Date"
            
              onChange={(e)=>setenddate(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div></>):(<></>) }
         
      
          {/* <div class="position-relative  mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0 "
              type="text"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder="Remarks"
              onChange={(e)=>setremark(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={AddCoupon}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/*Edit  */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
        <label>Coupon title</label><br/>
        <div class="position-relative  mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="text"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder={editData?.title}
              onChange={(e)=>settitle(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
          <label htmlFor="upload1">Coupon Image</label><br/>
        <div class="position-relative  mb-2" style={{ display: "flex" }}>
            <input
              id="upload1"
              type="file"
              name="file"
              accept="image/*"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder="Enter coupon title"
              onChange={(e)=>setcouponImage(e.target.files[0])}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
          <label>Coupon type</label><br/>
          <div class="position-relative  mb-2" style={{ display: "flex" }}>
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e)=>setcouponType(e.target.value)}
            >
             {editData?.couponType ? ( <option>{editData?.couponType}</option>):( <option>Select Coupon type</option>)}
            <option value="First Booking">First Booking</option>
            <option value="Festival">Festival</option>
            <option value="Weekend">Weekend</option>
            <option value="General">General</option>
            </Form.Select>
          </div>
          <label>Coupon code</label><br/>
          <div class="position-relative  mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="text"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder={editData?.couponCode}
              onChange={(e)=>setcouponCode(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
          <label>Discount</label><br/>
          <div class="position-relative  mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="number"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder={editData?.couponDiscount}
              onChange={(e)=>setDiscount(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
          {couponType=="Festival" || couponType=="Weekend" ? (<> <label>Start Date</label><br/>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
       
            <input
              id="search-bar-0"
              type="date"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder="Date"
            
              onChange={(e)=>setdate(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
          <label>End Date</label>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
       
            <input
              id="search-bar-0"
              type="date"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder="Date"
            
              onChange={(e)=>setenddate(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div></>):(<></>) }
      
          {/* <div class="position-relative  mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0 "
              type="text"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder="Remarks"
              onChange={(e)=>setremark(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={updateExpanse}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>Are you sure delete ?</Modal.Header>
        
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose2}>
            Cancel
          </Button>
          <Button variant="success" onClick={deleteData}>
         Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Coupon;
