import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { AiFillEye } from "react-icons/ai";
import axios from "axios";
import moment from "moment";
import exportFromJSON from "export-from-json";
import useRazorpay from "react-razorpay";

function Wallet() {
  const [show, setShow] = useState(false);
  const [Transaction, setTransaction] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  const [alldata, setalldata] = useState([]);
  const [data, setdata] = useState();
  const [nocangedata, setnocahangedata] = useState([]);
  const getAcount = async () => {
    try {
      const config = {
        url: "/driver/allWalletOfdriver/" + admin1?._id,
        method: "get",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setalldata(res.data.success);
        setnocahangedata(res.data.success);
        setdata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAcount();
  }, []);
  console.log("all", alldata);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(alldata.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const [pageNumber1, setPageNumber1] = useState(0);
  const pagesVisited1 = pageNumber1 * usersPerPage;
  const pageCount1 = Math.ceil(Transaction.length / usersPerPage);
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected);
  };

  const abcHandel = (e) => {
    if (e.target.value != "") {
      const filterTable = nocangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setalldata([...filterTable]);
    } else {
      setalldata([...nocangedata]);
    }
  };

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [EnterfilenameModal1, setEnterfilenameModal1] = useState(false);
  const [Enterfile, setEnterfile] = useState({});
  const [fileName, setfileName] = useState("DriverWallet");

  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  const Razorpay = useRazorpay();

  const d = new Date();
  const [checkradio, setcheckradio] = useState("");
  const [payid, setpayid] = useState("");
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const [show1, setshow1] = useState(false);
  const handshow1 = () => setshow1(true);
  const handleClose1 = () => setshow1(false);

  const [amount, setamount] = useState(0);
  const [driverDar, setDravierDar] = useState({});
  const posttransaction = async () => {
    try {
      if (!amount) return alert("Please enter amount");
      const config = {
        data: {
          key: "rzp_test_FAe0X6xLYXaXHe",
          amount: Math.round(amount) * 100,
          currency: "INR",
          name: "Go Easy Cabs",
          description: "Pay Amount",
          image: "/Cab-Logo.png",
          customerId: driverDar?.driverId,
          handler: function (response) {
            alert(response.razorpay_payment_id);
            setpayid(response.razorpay_payment_id);
          },
          prefill: {
            name: driverDar?.driverName,

            contact: driverDar?.dMobile,
          },
        },
      };
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Fail to load");
        return;
      }
      const paymentObject = new Razorpay(config.data);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  };

  const [DeductShow, setDeductShow] = useState(false);
  const [reasion, setreasion] = useState("");
  const DeductAmount = async () => {
    try {
      if (!reasion) return alert("Please enter Title");
      if (!amount) return alert("Please enter amount");
      const config = {
        url: "/driver/addTransaction",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          authId: admin1?._id,
          driverId: driverDar?.driverId,
          TxnId: `GOEDW00${Transaction?.length + 1}`,
          status: "DR",
          amount: amount,
          name: reasion,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deducted amount");
        setDeductShow(false);
        getAcount();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addAcount = async (bookid) => {
    try {
      const config = {
        url: "/admin/addAccount",
        method: "post",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          authId: admin1?._id,
          driverId: driverDar?.driverId,
          paymentId: payid,
          status: "Dr",
          paymentStatus: "Success",
          amount: amount,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        return window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const driverAddAmount = async () => {
    try {
      const config = {
        url: "/driver/addTransaction",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          authId: admin1?._id,
          driverId: driverDar?.driverId,
          TxnId: payid,
          status: "CR",
          amount: amount,
          name: "Added By GoEasyDoCab",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        return addAcount();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  useEffect(() => {
    if (payid) {
      driverAddAmount();
    }
  }, [payid]);
  return (
    <>
      <div style={{ paddingLeft: "0px" }}>
        <Button
          style={{
            color: "white",
            backgroundColor: "#fdd12d",
            border: "#fdd12d",
          }}
        >
          {" "}
          Driver Wallet
        </Button>

        <br></br>
        <div style={{ backgroundColor: "#f0f0f0" }}>
          <div class="row" style={{ padding: "10px 20px" }}>
            <div class="col-sm-4">
              <div class="search-box  d-inline-block">
                <div class="position-relative" style={{ display: "flex" }}>
                  <input
                    id="search-bar-0"
                    type="text"
                    aria-labelledby="search-bar-0-label"
                    class="form-control "
                    placeholder="Search..."
                    onChange={abcHandel}
                    style={{
                      borderRadius: "50px",
                      border: "1px solid #fdd12d",
                    }}
                  />
                </div>
              </div>
            </div>

            <div class="col-sm-8">
              <div class="text-sm-end">
                <button
                  style={{
                    padding: "2px 8px 7px 8px",
                    color: "black",
                    backgroundColor: "#fdd12d",
                    border: "none",
                    fontWeight: "600",
                    fontSize: "14px",
                    borderRadius: "20px",
                    margin: "0px 10px 0px 0px",
                  }}
                  onClick={() => {
                    ExportToExcel();
                  }}
                >
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}></span>{" "}
                  Export CSV
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <div class="react-bootstrap-table">
                <table
                  class="table table align-middle table-nowrap"
                  style={{ width: "90%", margin: "2%" }}
                >
                  <thead
                    style={{
                      backgroundColor: "#fdd12d",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <tr style={{ padding: "10px" }}>
                      <th
                        tabindex="0"
                        aria-label="Username sortable"
                        class="sortable"
                      >
                        S.No
                        <span class="order">
                          <span class="dropdown">
                            <span class="caret"></span>
                          </span>
                          <span class="dropup">
                            <span class="caret"></span>
                          </span>
                        </span>
                      </th>
                      <th
                        tabindex="0"
                        aria-label="Phone / Email sortable"
                        class="sortable"
                      >
                        Driver Id
                        <span class="order">
                          <span class="dropdown">
                            <span class="caret"></span>
                          </span>
                          <span class="dropup">
                            <span class="caret"></span>
                          </span>
                        </span>
                      </th>
                      <th
                        tabindex="0"
                        aria-label="Phone / Email sortable"
                        class="sortable"
                      >
                        Driver Name
                        <span class="order">
                          <span class="dropdown">
                            <span class="caret"></span>
                          </span>
                          <span class="dropup">
                            <span class="caret"></span>
                          </span>
                        </span>
                      </th>
                      <th
                        tabindex="0"
                        aria-label="Phone / Email sortable"
                        class="sortable"
                      >
                        Mobile Number
                        <span class="order">
                          <span class="dropdown">
                            <span class="caret"></span>
                          </span>
                          <span class="dropup">
                            <span class="caret"></span>
                          </span>
                        </span>
                      </th>

                      {/* <th
                        tabindex="0"
                        aria-label="Phone / Email sortable"
                        class="sortable"
                      >
                        Email Id
                        <span class="order">
                          <span class="dropdown">
                            <span class="caret"></span>
                          </span>
                          <span class="dropup">
                            <span class="caret"></span>
                          </span>
                        </span>
                      </th> */}
                      <th
                        tabindex="0"
                        aria-label="Phone / Email sortable"
                        class="sortable"
                      >
                        Wallet Balance
                        <span class="order">
                          <span class="dropdown">
                            <span class="caret"></span>
                          </span>
                          <span class="dropup">
                            <span class="caret"></span>
                          </span>
                        </span>
                      </th>
                      <th
                        tabindex="0"
                        aria-label="Phone / Email sortable"
                        class="sortable"
                      >
                        Transaction
                        <span class="order">
                          <span class="dropdown">
                            <span class="caret"></span>
                          </span>
                          <span class="dropup">
                            <span class="caret"></span>
                          </span>
                        </span>
                      </th>
                      <th
                        tabindex="0"
                        aria-label="Phone / Email sortable"
                        class="sortable"
                      >
                        <span class="order">
                          <span class="dropdown">
                            <span class="caret"></span>
                          </span>
                          <span class="dropup">
                            <span class="caret"></span>
                          </span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {alldata
                      ?.slice(pagesVisited, pagesVisited + usersPerPage)
                      ?.map((ele, i) => {
                        return (
                          <tr style={{ textAlign: "center" }}>
                            <td>{i + 1}</td>
                            <td>{ele?.driverId}</td>
                            <td>{ele?.driverName}</td>
                            <td>{ele?.dMobile}</td>
                            <td>{ele?.totalAmount}</td>
                            {/* <td>6</td> */}
                            <td>
                              <Button
                                style={{
                                  color: "white",
                                  backgroundColor: "#fdd12d",
                                  border: "#fdd12d",
                                }}
                                className="mx-2"
                                onClick={() => {
                                  setTransaction(ele?.transaction?.reverse());
                                  handleShow();
                                }}
                              >
                                {" "}
                                <AiFillEye />
                              </Button>
                              <Button
                                style={{
                                  color: "white",
                                  backgroundColor: "#fdd12d",
                                  border: "#fdd12d",
                                }}
                                onClick={() => {
                                  setDravierDar(ele);
                                  handshow1();
                                }}
                              >
                                {" "}
                                +AddAmount
                              </Button>
                            </td>
                            <td>
                              <Button
                                style={{
                                  color: "white",
                                  backgroundColor: "#fdd12d",
                                  border: "#fdd12d",
                                }}
                                onClick={() => {
                                  setTransaction(ele?.transaction?.reverse());
                                  setDravierDar(ele);
                                  setDeductShow(true);
                                }}
                              >
                                {" "}
                                Deduct Amount
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Transaction History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="" style={{ padding: "2px", fontWeight: "600" }}>
            {Transaction?.slice(
              pagesVisited1,
              pagesVisited1 + usersPerPage
            )?.map((ele, i) => {
              return (
                <div class="row" style={{ paddingBottom: "5px" }}>
                  <div>
                    <div>
                      <div> </div>
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="">
                          <span>
                            {i + 1}
                            {". "}
                          </span>{" "}
                          <span
                            style={{
                              color: ele?.status == "CR" ? "green" : "red",
                            }}
                          >
                            {ele?.otherParty?.name}
                          </span>{" "}
                          <br />
                          <div className="walls">
                            PayId :-{" "}
                            <span style={{ fontSize: 13 }}>
                              {ele?.otherParty?.TxnId}
                            </span>
                          </div>
                        </div>

                        <div style={{ fontSize: 13 }}>
                          {moment(ele?.date).format("lll")}
                          <br />
                          {ele?.status == "CR" ? (
                            <div style={{ color: "green" }}>
                              + ₹{ele?.amount?.toFixed(2)}
                            </div>
                          ) : (
                            <div style={{ color: "red" }}>
                              - ₹{ele?.amount?.toFixed(2)}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount1}
            onPageChange={changePage1}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Add to Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="" style={{ padding: "2px", fontWeight: "600" }}>
            <label
              style={{
                fontSize: " 21px",
                color: "black",
              }}
            >
              Amount
            </label>
            <br />
            <input
              type="number"
              onChange={(e) => setamount(e.target.value)}
              style={{
                border: "2px solid yellow",
                padding: "10px",
                borderRadius: "4px",
              }}
              min="1"
              placeholder="Enter amount"
            ></input>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="success" onClick={posttransaction}>
            Pay Now
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={DeductShow} onHide={() => setDeductShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Deduct to Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="" style={{ padding: "2px", fontWeight: "600" }}>
            <label
              style={{
                fontSize: " 21px",
                color: "black",
              }}
            >
              Title
            </label>
            <br />
            <input
              type="text"
              onChange={(e) => setreasion(e.target.value)}
              style={{
                border: "2px solid yellow",
                padding: "10px",
                borderRadius: "4px",
                width: "100%",
              }}
              placeholder="Ex:-Dedected by Goeasydocab"
            ></input>
            <br />
            <label
              style={{
                fontSize: " 21px",
                color: "black",
              }}
            >
              Amount
            </label>
            <br />
            <input
              type="number"
              onChange={(e) => setamount(e.target.value)}
              style={{
                border: "2px solid yellow",
                padding: "10px",
                borderRadius: "4px",
                width: "100%",
              }}
              min="1"
              placeholder="Enter amount"
            ></input>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setDeductShow(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={DeductAmount}>
            Deduct Now
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Wallet;
