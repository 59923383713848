import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

function Banner() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  const [images,setImage]=useState("");

  const [discount,setdiscount]=useState("");
  const [heading,setheding]=useState("");
  const [title,settitle]=useState("");
  const [edit,setedit]=useState({})
 let formdata=new FormData()
  const addAndUpdateBanner=async()=>{
    formdata.append("bannerImage",images)
    formdata.append("authId",admin1?._id)
    formdata.append("discount",discount)
    formdata.append("heading",heading)
    formdata.append("title",title)
    try {
      const config = {
        url: "/admin/addBanner",
        method: "post",
        baseURL: "https://goeasycab.info/api",
        hearder: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}`,"content-type":"multipart/form-data"},
        data:formdata
        // {
        //   authId: admin1?._id,discount:discount,heading:heading,title:title,bannerImage:images
        // }
      };
      let res=await axios(config);
      if(res.status==200){
        alert("Success")
        handleClose()
        getAllbaner()
        setedit({})
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }

  const [allBanner,setallBanner]=useState([])
  const getAllbaner=async()=>{
    try {
      let res=await axios.get("https://goeasycab.info/api/admin/getBanner");
      if(res.status==200){
        setallBanner(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const deleteBaner=async()=>{
    try {
      const config = {
        url: "/admin/deleteBanner/"+edit?._id+"/"+admin1?._id,
        method: "delete",
        baseURL: "https://goeasycab.info/api",
        hearder: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}`,"content-type":"multipart/form-data"},
      };
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully deleted");
        handleClose1();
        getAllbaner();
        setedit({})
      }
      } catch (error) {
      console.log(error);
    }
  }
useEffect(()=>{
  getAllbaner()
},[])
  return (
    <>
      
      <div className="accountt mb-3" style={{display:"flex",justifyContent:"space-between"}}>
      <Button
            style={{
              color: "white",
              backgroundColor: "#fdd12d",
              border: "#fdd12d",
            }}
          >
            {" "}
         Banner
          </Button>
        <Button
          style={{
            borderRadius: "50px",
            border: "1px solid #fdd12d",
          }}
          onClick={handleShow}
        >
          Create
        </Button>
      </div>

      <div className="vwv">
        <div style={{ paddingLeft: "0px" }}>
          <div style={{ backgroundColor: "#f0f0f0" }}>
            <div class="row" style={{ padding: "10px 20px" }}>
              <div class="table-responsive">
                <div class="react-bootstrap-table">
                  <table
                    class="table table align-middle table-nowrap"
                    style={{ width: "90%", margin: "2%" }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#fdd12d",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      <tr style={{ padding: "10px" }}>
                        <th
                          tabindex="0"
                          aria-label="Username sortable"
                          class="sortable"
                        >
                          S.No
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                        Images
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                        Heading
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                        Title
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                        Discount 
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Action
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allBanner?.map((ele,i)=>{
                        return (
                           <tr style={{ textAlign: "center" }}>
                        <td>{i+1}</td>

                        <td><img src={`https://goeasycab.info/banner/${ele?.bannerImage}`} alt="" style={{width:"100%",height:"100px"}}/></td>
                        <td>{ele?.heading}</td>
                        <td>{ele?.title}</td>
                        <td>{ele?.discount}</td>
                        <td>
                        <Button
                            type="button"
                            variant="danger"
                            onClick={()=>{
                              setedit(ele) 
                              handleShow1()
                            }}
                          >
                            <span>
                              <RiDeleteBin6Line />
                            </span>
                          </Button>
                          <Button
                            type="button"
                            variant="success"
                            className="mx-2"
                            onClick={()=>{
                              setedit(ele) 
                              handleShow()
                            }
                             }
                          >
                            <span >
                              <RiEditLine />
                            </span>
                          </Button>
                        </td>
                      </tr>
                        )
                      })}
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="file"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              name="file"
              accept="*/image"
              onChange={(e)=>setImage(e.target.files[0])}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
           
          </div>
          <h7>Banner size 1520×300</h7><br/>
          <label>Heading</label><br/>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
         
            <input
              id="search-bar-0"
              type="text"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              onChange={(e)=>setheding(e.target.value)}
              placeholder={edit?.heading ? (edit?.heading):("Enter heading")}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
          <label>Title</label><br/>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="text"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder={edit?.title ? (edit?.title):("Title")}
              onChange={(e)=>settitle(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
          <label>Discount</label><br/>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="number"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              onChange={(e)=>setdiscount(e.target.value)}
              placeholder={edit?.discount ? (edit?.discount):("1000")}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addAndUpdateBanner}>
         Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>Are you sure delete ?</Modal.Header>
       
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="success" onClick={deleteBaner}>
              Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Banner;
