import React, { useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";

function UserAgreement() {
  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState("");
  const [acc2, setacc2] = useState("");
  const [acc3, setacc3] = useState("");
  const [acc4, setacc4] = useState("");
  const [acc5, setacc5] = useState("");
  const [acc6, setacc6] = useState("");
  const [acc7, setacc7] = useState("");
  const [acc8, setacc8] = useState("");
  const [acc9, setacc9] = useState("");
  const [acc10, setacc10] = useState("");
  const [acc11, setacc11] = useState("");
  const [acc12, setacc12] = useState("");
  const [acc13, setacc13] = useState("");
  const [acc14, setacc14] = useState("");
  return (
    <>
      <div style={{ marginTop: "100px" }}>
        <h2 className="text-center" style={{ color: "black" }}>
          User Agreement
        </h2>

        <div className="container-fluid">
          <div className="add-gr">
            <div className="container-fluid">
              <div className="ad-b mt-4 mb-3"></div>
              <div className="main-body">
                <div className="row">
                  <div className="col-lg-3">
                    <div
                      className="profile-o"
                      style={{
                        width: "251px",
                        cursor: "pointer",
                      }}
                    >
                      <ListGroup>
                        <ListGroup.Item
                          className={`${acc ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(true);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                          }}
                        >
                          <p>
                            <b>1.APPLICABILITY</b>
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${acc1 ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(true);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                          }}
                        >
                          <p>
                            <b>2.ELIGIBILITY TO USE</b>
                          </p>
                        </ListGroup.Item>

                        <ListGroup.Item
                          className={`${acc2 ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(true);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                          }}
                        >
                          <p>
                            <b>3.CONTENT</b>
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${acc3 ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(true);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                          }}
                        >
                          <p>
                            <b>4.WEBSITE</b>
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${acc4 ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(true);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                          }}
                        >
                          <p>
                            <b>5.BOOKINGS BY TRAVEL AGENTS</b>
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${acc5 ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(true);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                          }}
                        >
                          <p>
                            <b> 6.LIMITED LIABILITY OF Goeasydocab</b>
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${acc6 ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(true);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                          }}
                        >
                          <p>
                            <b>7.USER’S RESPONSIBILITY</b>
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${acc7 ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(true);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                          }}
                        >
                          <p>
                            <b>8.SECURITY AND ACCOUNT RELATED INFORMATION</b>
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${acc8 ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(true);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                          }}
                        >
                          <p>
                            <b>9.FEES AND PAYMENT</b>
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${acc9 ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(true);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                          }}
                        >
                          <p>
                            <b>
                              {" "}
                              10.USAGE OF THE MOBILE NUMBER, COMMUNICATION
                              DETAILS OF THE USER BY Goeasydocab
                            </b>
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${acc10 ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(true);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                          }}
                        >
                          <p>
                            <b>11.INSURANCE</b>
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${acc11 ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(true);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                          }}
                        >
                          <p>
                            <b>
                              12.COMPLIANCE OF LIBERALIZED REMITTANCE SCHEME
                              (LRS)
                            </b>
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${acc12 ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(true);
                            setacc13(false);
                            setacc14(false);
                          }}
                        >
                          <p>
                            <b>13.OBLIGATION TO OBTAIN VISA</b>
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${acc13 ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(true);
                            setacc14(false);
                          }}
                        >
                          <p>
                            <b>14.FORCE MAJEURE</b>
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className={`${acc14 ? "active-list" : ""}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(true);
                          }}
                        >
                          <p>
                            <b>
                              15.ADVERTISERS ON Goeasydocab, AND LINKED WEBSITES
                            </b>
                          </p>
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </div>
                  <div className="col-lg-8" style={{ textAlign: "justify" }}>
                    {acc ? (
                      <div>
                        <p>
                          <b style={{ color: "darkred", fontSize: "23px" }}>
                            1. APPLICABILITY
                          </b>
                        </p>
                        <div>
                          <ul>
                            <li>
                              This User Agreement along with Terms of Service
                              (collectively, the "User Agreement") forms the
                              terms and conditions for the use of services and
                              products of Goeasydo Private Limited
                            </li>
                            <li>
                              Any person ("User") who inquiries about or
                              purchases any products or services of Goeasydocab
                              through its websites, mobile applications, sales
                              persons, offices, call centers, branch offices,
                              franchisees, agents etc. (all the aforesaid
                              platforms collectively referred to as “Sales
                              Channels”) agree to be governed by this User
                              Agreement. The websites and the mobile
                              applications of Goeasydocab are collectively
                              referred to as ‘Website’.
                            </li>
                            <li>
                              Both User and Goeasydocab are individually
                              referred to as 'Party' and collectively referred
                              to as 'Parties' to the User Agreement.
                            </li>
                            <li>
                              “Terms of Service” available on Goeasydocab
                              website details out terms & conditions applicable
                              on various services or products facilitated by
                              Goeasydocab. The User should refer to the relevant
                              Terms of Service applicable for the given product
                              or service as booked by the User. Such Terms of
                              Service are binding on the User.
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : acc1 ? (
                      <div>
                        <p>
                          <b style={{ color: "darkred", fontSize: "23px" }}>
                            2.ELIGIBILITY TO USE:
                          </b>
                        </p>
                        <div>
                          <li>
                            The User must be atleast 18 years of age and must
                            possess the legal authority to enter into an
                            agreement so as become a User and use the services
                            of Goeasydocab. If you are a minor or are below the
                            age of 18 years, you shall not register as a User of
                            the Website and shall not transact on or use the
                            Website.
                          </li>
                          <li>
                            As a minor if you wish to use or transact on the
                            Website, such use or transaction shall only be made
                            by a person of legal contracting age (legal guardian
                            or parents). We reserve the right to terminate your
                            membership and/or block access to the Website if it
                            is discovered that you are a minor or incompetent to
                            contract according to the law or any information
                            pertaining to your age entered at the time of
                            creation of account is false.
                          </li>
                          <li>
                            Before using the Website, approaching any Sales
                            Channels or procuring the services of Goeasydocab,
                            the Users shall compulsorily read and understand
                            this User Agreement, and shall be deemed to have
                            accepted this User Agreement as a binding document
                            that governs User’s dealings and transactions with
                            Goeasydocab. If the User does not agree with any
                            part of this Agreement, then the User must not avail
                            Goeasydocab services and must not access or approach
                            the Sales Channels of Goeasydocab.
                          </li>
                          <li>
                            All rights and liabilities of the User and
                            Goeasydocab with respect to any services or product
                            facilitated by Goeasydocab shall be restricted to
                            the scope of this User Agreement.
                          </li>
                        </div>
                      </div>
                    ) : (
                      <>
                        {acc2 ? (
                          <div>
                            <p>
                              <b style={{ color: "darkred", fontSize: "23px" }}>
                                3.CONTENT:
                              </b>
                            </p>
                            <div>
                              <ul>
                                <li>
                                  All content provided through various Sales
                                  Channels, including but not limited to audio,
                                  images, software, text, icons and such similar
                                  content ("Content"), are registered by
                                  Goeasydocab and protected under applicable
                                  intellectual property laws. User cannot use
                                  this Content for any other purpose, except as
                                  specified herein.
                                </li>
                                <li>
                                  User agrees to follow all instructions
                                  provided by Goeasydocab which will prescribe
                                  the way such User may use the Content.
                                </li>
                                <li>
                                  There are a number of proprietary logos,
                                  service marks and trademarks displayed on the
                                  Website and through other Sales Channels of
                                  Goeasydocab, as may be applicable. Goeasydocab
                                  does not grant the User a license, right or
                                  authority to utilize such proprietary logos,
                                  service marks, or trademarks in any manner.
                                  Any unauthorized use of the Content, will be
                                  in violation of the applicable law.
                                </li>
                                <li>
                                  Goeasydocab respects the intellectual property
                                  rights of others. If you notice any act of
                                  infringement on the Website, you are requested
                                  to send us a written notice/ intimation which
                                  must include the following information;
                                </li>
                                <ul>
                                  <li>
                                    clear identification of such copyrighted
                                    work that you claim has been infringed;
                                  </li>
                                  <li>
                                    location of the material on the Website,
                                    including but not limited to the link of the
                                    infringing material
                                  </li>
                                  <li>
                                    {" "}
                                    The proof that the alleged copyrighted work
                                    is owned by you;
                                  </li>
                                  <li>Contact information;</li>
                                  <li>
                                    The aforesaid notices can be sent to
                                    Goeasydocab by email at{" "}
                                    <a href="#">admin@goeasydocab.com</a>
                                  </li>
                                </ul>
                              </ul>
                            </div>
                          </div>
                        ) : acc3 ? (
                          <div>
                            <p>
                              <b style={{ color: "darkred", fontSize: "23px" }}>
                                4.WEBSITE:
                              </b>
                            </p>
                            <div>
                              <ul>
                                <li>
                                  The Website is meant to be used by bonafide
                                  User(s) for a lawful use.
                                </li>
                                <li>
                                  User shall not distribute exchange, modify,
                                  sell or transmit anything from the Website,
                                  including but not limited to any text, images,
                                  audio and video, for any business, commercial
                                  or public purpose.
                                </li>
                                <li>
                                  The User Agreement grants a limited,
                                  non-exclusive, non-transferable right to use
                                  this Website as expressly permitted in this
                                  User Agreement. The User agrees not to
                                  interrupt or attempt to interrupt the
                                  operation of the Website in any manner
                                  whatsoever.
                                </li>
                                <li>
                                  Access to certain features of the Website may
                                  only be available to registered User(s). The
                                  process of registration, may require the User
                                  to answer certain questions or provide certain
                                  information that may or may not be personal in
                                  nature. Some such fields may be mandatory or
                                  optional. User represents and warrants that
                                  all information supplied to Goeasydocab is
                                  true and accurate.
                                </li>
                                <li>
                                  Access to certain features of the Website may
                                  only be available to registered User(s). The
                                  process of registration, may require the User
                                  to answer certain questions or provide certain
                                  information that may or may not be personal in
                                  nature. Some such fields may be mandatory or
                                  optional. User represents and warrants that
                                  all information supplied to Goeasydocab is
                                  true and accurate.
                                </li>
                                <li>
                                  Goeasydocab will always make its best
                                  endeavors to ensure that the content on its
                                  websites or other sales channels are free of
                                  any virus or such other malwares. However, any
                                  data or information downloaded or otherwise
                                  obtained through the use of the Website or any
                                  other Sales Channel is done entirely at the
                                  User’s own discretion and risk and they will
                                  be solely responsible for any damage to their
                                  computer systems or loss of data that may
                                  result from the download of such data or
                                  information.
                                </li>
                                <li>
                                  {" "}
                                  Goeasydocab reserves the right to periodically
                                  make improvements or changes in its Website at
                                  any time without any prior notice to the User.
                                  User(s) are requested to report any content on
                                  the Website which is deemed to be unlawful,
                                  objectionable, libelous, defamatory, obscene,
                                  harassing, invasive to privacy, abusive,
                                  fraudulent, against any religious beliefs,
                                  spam, or is violative of any applicable law to
                                  report@goeasydo.com. On receiving such report,
                                  Goeasydocab reserves the right to investigate
                                  and/or take such action as the Company may
                                  deem appropriate.
                                </li>
                              </ul>
                            </div>
                          </div>
                        ) : acc4 ? (
                          <div>
                            <p>
                              <b style={{ color: "darkred", fontSize: "23px" }}>
                                5.BOOKINGS BY TRAVEL AGENTS :
                              </b>
                            </p>
                            <div>
                              <ul>
                                <li>
                                  Except with the prior registration with
                                  Goeasydocab as B2B agents, priority partner or
                                  a franchisee, and explicit permission of
                                  Goeasydocab to use the Website, all travel
                                  agents, tour operators, consolidators or
                                  aggregators (“Travel Agents”) are barred from
                                  using this Website for any commercial or
                                  resale purpose. If any such bookings are
                                  detected, Goeasydocab reserves the right,
                                  including without limitation, to cancel all
                                  such bookings immediately without any notice
                                  to such travel agents and also to withhold
                                  payments or any refunds thereto. Goeasydocab
                                  shall not be held liable for any incidental
                                  loss or damage that may arise from the
                                  bookings made by any person through such
                                  Travel Agents. The liability in case of such
                                  cancellations shall be solely borne by such
                                  Travel Agents.
                                </li>
                                <li>
                                  All discounts and offers mentioned on the
                                  Website are applicable only to the User(s) of
                                  Goeasydocab for legitimate bookings.
                                </li>
                              </ul>
                            </div>
                          </div>
                        ) : acc5 ? (
                          <div>
                            <p>
                              <b style={{ color: "darkred", fontSize: "23px" }}>
                                6.LIMITED LIABILITY OF Goeasydocab:
                              </b>
                            </p>
                            <div>
                              <ul>
                                <li>
                                  {" "}
                                  Unless Goeasydocab explicitly acts as a
                                  reseller in certain scenarios, Goeasydocab
                                  always acts as a facilitator by connecting the
                                  User with the respective service providers
                                  like airlines, hotels, restaurants, bus
                                  operators etc. (collectively referred to as
                                  “Service Providers”). Goeasydocab liability is
                                  limited to providing the User with a confirmed
                                  booking as selected by the User.
                                </li>
                                <li>
                                  Any issues or concerns faced by the User at
                                  the time of availing any such services shall
                                  be the sole responsibility of the Service
                                  Provider. Goeasydocab will have no liability
                                  with respect to the acts, omissions, errors,
                                  representations, warranties, breaches or
                                  negligence on part of any Service Provider
                                </li>
                                <li>
                                  Unless explicitly committed by Goeasydocab as
                                  a part of any product or service:
                                </li>
                                <ul>
                                  <li>
                                    Goeasydocab assumes no liability for the
                                    standard of services as provided by the
                                    respective Service Providers.
                                  </li>
                                  <li>
                                    Goeasydocab provides no guarantee with
                                    regard to their quality or fitness as
                                    represented.
                                  </li>
                                  <li>
                                    Goeasydocab doesn’t guarantee the
                                    availability of any services as listed by a
                                    Service Provider
                                  </li>
                                </ul>
                                <li>
                                  By making a booking, User understands
                                  Goeasydocab merely provides a technology
                                  platform for booking of services and products
                                  and the ultimate liability rests on the
                                  respective Service Provider and not
                                  Goeasydocab. Thus the ultimate contract of
                                  service is between User and Service Provider.
                                </li>
                                <li>
                                  User further understands that the information
                                  displayed on the Website with respect to any
                                  service is displayed as furnished by the
                                  Service Provider. Goeasydocab, therefore
                                  cannot be held liable in case if the
                                  information provided by the Service Provider
                                  is found to be inaccurate, inadequate or
                                  obsolete or in contravention of any laws,
                                  rules, regulations or directions in force.
                                </li>
                              </ul>
                            </div>
                          </div>
                        ) : acc6 ? (
                          <div>
                            {" "}
                            <p>
                              <b style={{ color: "darkred", fontSize: "23px" }}>
                                7.USER’S RESPONSIBILITY:
                              </b>
                            </p>
                            <div>
                              <ul>
                                <li>
                                  Users are advised to check the description of
                                  the services and products carefully before
                                  making a booking. User(s) agree to be bound by
                                  all the conditions as contained in booking
                                  confirmation or as laid out in the confirmed
                                  booking voucher. These conditions are also to
                                  be read in consonance with the User Agreement.
                                </li>
                                <li>
                                  If a User intends to make a booking on behalf
                                  of another person, it shall be the
                                  responsibility of the User to inform such
                                  person about the terms of this Agreement,
                                  including all rules and restrictions
                                  applicable thereto.
                                </li>
                                <li>
                                  The User undertakes to abide by all procedures
                                  and guidelines, as modified from time to time,
                                  in connection with the use of the services
                                  available through Goeasydocab. The User
                                  further undertakes to comply with all
                                  applicable laws, regulations, orders,
                                  directions etc. issued by either the Central
                                  Government, State Government, District
                                  Authorities or any other statutory body
                                  empowered to do so w.r.t use of services or
                                  for each transaction.
                                </li>
                                <li>
                                  The services are provided on an "as is" and
                                  "as available" basis. Goeasydocab may change
                                  the features or functionality of the services
                                  being provided at any time, in its sole
                                  discretion, without any prior notice.
                                  Goeasydocab expressly disclaims all warranties
                                  of any kind, whether express or implied,
                                  including, but not limited to the implied
                                  warranties of merchantability, reasonably fit
                                  for all purposes. No advice or information,
                                  whether oral or written, which the User
                                  obtains from Goeasydocab or through the
                                  services opted shall create any warranty not
                                  expressly made herein or in the terms and
                                  conditions of the services.
                                </li>
                                <li>
                                  {" "}
                                  User also authorizes Goeasydocab
                                  representative to contact such user over
                                  phone, message and email. This consent shall
                                  supersede any preferences set by such User
                                  through national customer preference register
                                  (NCPR) or any other similar preferences.
                                </li>
                              </ul>
                            </div>
                          </div>
                        ) : acc7 ? (
                          <div>
                            <p>
                              <b style={{ color: "darkred", fontSize: "23px" }}>
                                8.SECURITY AND ACCOUNT RELATED INFORMATION :
                              </b>
                            </p>
                            <div>
                              <ul>
                                <li>
                                  While registering on the Website, the User
                                  will have to choose a password to access that
                                  User’s account and User shall be solely
                                  responsible for maintaining the
                                  confidentiality of both the password and the
                                  account as well as for all activities on the
                                  account. It is the duty of the User to notify
                                  Goeasydocab immediately in writing of any
                                  unauthorized use of their password or account
                                  or any other breach of security. Goeasydocab
                                  will not be liable for any loss that may be
                                  incurred by the User as a result of
                                  unauthorized use of the password or account,
                                  either with or without the User’s knowledge.
                                  The User shall not use anyone else's account
                                  at any time.
                                </li>
                                <li>
                                  For logging-in or sign-up on the Website
                                  and/or mobile & web applications, the User has
                                  an option to voluntarily sign-up or login
                                  through a phone number verification tool
                                  integrated with a third-party partner of
                                  Goeasydocab. For the avoidance of doubt, login
                                  or sign-up of the User via such SDK
                                  verification process will at all times be
                                  subject to the User giving its consent to
                                  Goeasydocab for engaging the third-party
                                  partner. Under this login or sign-up option,
                                  no Personal Information or Data of the User
                                  will be shared by Goeasydocab with the
                                  third-party partner.
                                </li>
                                <li>
                                  User understands that any information that is
                                  provided to this Website may be read or
                                  intercepted by others due to any breach of
                                  security at the User’s end.
                                </li>
                                <li>
                                  Goeasydocab keeps all the data in relation to
                                  credit card, debit card, bank information etc.
                                  secured and in an encrypted form in compliance
                                  with the applicable laws and regulations.
                                  However, for cases of fraud detection,
                                  offering bookings on credit (finance) etc.,
                                  Goeasydocab may at times verify certain
                                  information of its Users like their credit
                                  score, as and when required.
                                </li>
                                <li>
                                  Additionally, Goeasydocab may share your
                                  Personal Information in an anonymized and/ or
                                  aggregated form with a third party that
                                  Goeasydocab may engage to perform certain
                                  tasks on its behalf or to fulfill the desired
                                  booking, including but not limited to payment
                                  processing, data hosting, data processing ,
                                  credit score and assessing credit worthiness
                                  for offering bookings on credit in accordance
                                  with the applicable laws.
                                </li>
                                <li>
                                  Additionally, Goeasydocab may share your
                                  Personal Information in an anonymized and/ or
                                  aggregated form with a third party that
                                  Goeasydocab may engage to perform certain
                                  tasks on its behalf, including but not limited
                                  to payment processing, data hosting, data
                                  processing , credit score and assessing credit
                                  worthiness for offering bookings on credit in
                                  accordance with the applicable laws.
                                </li>
                                <li>
                                  Goeasydocab adopts the best industry standard
                                  to secure the information as provided by the
                                  User. However, Goeasydocab cannot guarantee
                                  that there will never be any security breach
                                  of its systems which may have an impact on
                                  User’s information too.
                                </li>
                                <li>
                                  The data of the User as available with
                                  Goeasydocab may be shared with concerned law
                                  enforcement agencies for any lawful or
                                  investigation purpose without the consent of
                                  the User.
                                </li>
                              </ul>
                            </div>
                          </div>
                        ) : acc8 ? (
                          <div>
                            <p>
                              <b style={{ color: "darkred", fontSize: "23px" }}>
                                9.FEES AND PAYMENT:
                              </b>
                            </p>
                            <div>
                              <ul>
                                <li>
                                  In addition to the cost of booking as charged
                                  by the Service Providers, Goeasydocab reserves
                                  the right to charge certain fees in the nature
                                  of convenience fees or service fees.
                                  Goeasydocab further reserves the right to
                                  alter any and all fees from time to time. Any
                                  such additional fees, including fee towards
                                  any modifications thereof, will be displayed
                                  to the User before confirming the booking or
                                  collecting the payment from such User.
                                </li>
                                <li>
                                  In cases of short charging of the booking
                                  amount, taxes, statutory fee, convenience fee
                                  etc., owing to any technical error or other
                                  reason, Goeasydocab shall reserve the right to
                                  deduct, charge or claim the balance amount
                                  from the User and the User shall pay such
                                  balance amount to Goeasydocab. In cases where
                                  the short charge is claimed prior to the
                                  utilization of the booking, Goeasydocab will
                                  be at liberty to cancel such bookings if the
                                  amount is not paid before the utilization
                                  date.
                                </li>
                                <li>
                                  Any increase in the price charged by
                                  Goeasydocab on account of change in rate of
                                  taxes or imposition of new taxes, levies by
                                  Government shall have to be borne by the User.
                                  Such imposition of taxes, levies may be
                                  without prior notice and could also be
                                  retrospective but will always be as per
                                  applicable law.
                                </li>
                                <li>
                                  In the rare circumstance of a booking not
                                  getting confirmed for any reason whatsoever,
                                  Goeasydocab will process the refund of the
                                  booking amount paid by the User and intimate
                                  the User about the same. Goeasydocab is not
                                  under any obligation to provide an alternate
                                  booking in lieu of or to compensate or replace
                                  the unconfirmed booking. All subsequent
                                  bookings will be treated as new transactions.
                                  Any applicable refund will be processed as per
                                  the defined policies of the service provider
                                  and Goeasydocab as the case may be.
                                </li>
                                <li>
                                  The User shall be completely responsible for
                                  all charges, fees, duties, taxes, and
                                  assessments arising out of the use of the
                                  service, as per the applicable laws
                                </li>
                                <li>
                                  {" "}
                                  The User agrees and understands that all
                                  payments shall only be made to bank accounts
                                  of Goeasydocab. Goeasydocab or its agents,
                                  representatives or employees shall never ask a
                                  customer to transfer money to any private
                                  account or to an account not held in the name
                                  of Goeasydocab. The User agrees that if that
                                  user transfers any amount against any booking
                                  or transaction to any bank account that is not
                                  legitimately held by Goeasydocab or to any
                                  personal account of any person, Goeasydocab
                                  shall not be held liable for the same. User
                                  shall not hold any right to recover from
                                  Goeasydocab any amount which is transferred by
                                  the User to any third party.
                                </li>
                                <li>
                                  The User will not share his personal sensitive
                                  information like credit/debit card number,
                                  CVV, OTP, card expiry date, user IDs,
                                  passwords etc. with any person including the
                                  agents, employees or representatives of
                                  Goeasydocab. The User shall immediately inform
                                  Goeasydocab if such details are demanded by
                                  any of its agents’ employees or
                                  representatives. Goeasydocab shall not be
                                  liable for any loss that the User incurs for
                                  sharing the aforesaid details.
                                </li>
                              </ul>
                            </div>
                          </div>
                        ) : acc9 ? (
                          <div>
                            <p>
                              <b style={{ color: "darkred", fontSize: "23px" }}>
                                10.Modification of the Service and User Terms:
                              </b>
                            </p>
                            <div>
                              The Company reserves the right, at its sole
                              discretion, to modify or replace any of these User
                              Terms, or change, suspend, or discontinue the
                              application (including without limitation, the
                              availability of any feature, database, or content)
                              at any time by posting a notice on the website or
                              by sending you notice through the Service,
                              application or via email. The Company may also
                              impose limits on certain features and services or
                              restrict your access to parts or all of the
                              Service without notice or liability.
                            </div>
                          </div>
                        ) : acc10 ? (
                          <div>
                            <p>
                              <b style={{ color: "darkred", fontSize: "23px" }}>
                                11.Notice
                              </b>
                            </p>
                            <div>
                              The Company may give notice by means of a general
                              notice on the Service or Application, or by
                              electronic mail to your email address on record in
                              the Company's account information, or by written
                              communication sent by regular mail to your address
                              on record in Company's account information.
                            </div>
                          </div>
                        ) : acc11 ? (
                          <div>
                            {" "}
                            <p>
                              <b style={{ color: "darkred", fontSize: "23px" }}>
                                12.Assignment
                              </b>
                            </p>
                            <div>
                              You may not assign your rights under these User
                              Terms without prior written approval of the
                              Company.
                            </div>
                          </div>
                        ) : acc12 ? (
                          <div>
                            <p>
                              <b style={{ color: "darkred", fontSize: "23px" }}>
                                13.Privacy and Cookie Notice:
                              </b>
                            </p>
                            <div>
                              The Company collects and processes the personal
                              data of the visitors of the website and users of
                              the application according to the Privacy and
                              Cookie Notice
                            </div>
                          </div>
                        ) : acc13 ? (
                          <div>
                            {" "}
                            <p>
                              <b style={{ color: "darkred", fontSize: "23px" }}>
                                14.Excusable Delays (Force Majeure):
                              </b>
                            </p>
                            <div>
                              Neither party hereto shall be responsible for
                              delays or failures in performance resulting from
                              acts beyond its reasonable control and without its
                              fault or negligence. Such excusable delays or
                              failures may be caused by, among other things,
                              strikes, lock-out, riots, rebellions, accidental
                              explosions, floods, storms, acts of God and
                              similar occurrences.
                            </div>
                          </div>
                        ) : acc14 ? (
                          <div>
                            <p>
                              <b style={{ color: "darkred", fontSize: "23px" }}>
                                15.Miscellaneous:
                              </b>
                            </p>
                            <div>
                              The Courts of Mumbai, India shall have the sole
                              and exclusive jurisdiction in respect of any
                              matters arising from the Use of the Services
                              offered by the Company or the agreement or
                              arrangement between the Company and the Customer.
                              All claims and disputes arising under this Terms
                              and Conditions should be notified to the Company
                              within 30 days from the event date, after which
                              You shall have no rights to raise any claim
                              against the Company.
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserAgreement;
