import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import exportFromJSON from "export-from-json";
import ReactPaginate from "react-paginate";
import { ImFolderDownload } from "react-icons/im";
import { HiRefresh } from "react-icons/hi";
import { AiFillFileWord } from "react-icons/ai";
function SpecialDay() {
    const [dataSource, setdataSource] = useState([]);
    const [category, setcategory] = useState();
  
    const AllCategory = async () => {
      let res = await axios.get("https://goeasycab.info/api/admin/getAllSpecial");
      if (res.status === 200) {
        console.log(res);
        setdataSource(res.data.carList);
        setdata(res.data.carList);
      }
    };
  
    const [show1, setShow1] = useState(false);
    // const [show2, setShow2] = useState(false);
    const handleClose1 = () => setShow1(false);
    // const handleClose2 = () => setShow2(false);
    const handleShow1 = () => setShow1(true);
    const admin = sessionStorage.getItem("token");
    const admin1 = JSON.parse(sessionStorage.getItem("admin"));
    // console.log("admin", admin1._id);
  
    const addcategory = async () => {
      if (category) {
        try {
          const config = {
            url: "/admin/addSpecialDay",
            method: "post",
            baseURL: "https://goeasycab.info/api",
            headers: { Authorization: `Bearer ${admin}` },
  
            data: {
                specialday: category,
              authId:admin1._id
            },
          };
          await axios(config).then(function (res) {
            if (res.status === 200) {
              console.log(res.data);
              alert("Successfully Added");
              // AllCategory();
              setcategory("");
              setShow1(false);
              AllCategory();
            } else {
              alert("Something Wrong!");
            }
          });
        } catch (error) {
          console.log(error.response);
        }
      } else {
        alert("please enter day name!");
      }
    };
   
    useEffect(() => {
      AllCategory();
    }, []);
  
    // Export to Excel
  
    const [data, setdata] = useState();
  
   const exportType = "xls";
   const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
   const [fileName, setfileName] = useState();
   const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;
    const pageCount = Math.ceil(dataSource.length / usersPerPage);
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };
   const ExportToExcel = () => {
     if (fileName) {
       exportFromJSON({ data, fileName, exportType });
       setEnterfilenameModal(false);
       setfileName("");
     } else {
       alert("Enter file name to export");
     }
   };
    const Removecategory = async (data) => {
      try {
        const config = {
          url: "/admin/deleteSpecialDay/" + data?._id + "/" + admin1?._id,
          method: "delete",
          baseURL: "https://goeasycab.info/api",
          headers: { Authorization: `Bearer ${admin}` },
        };
        await axios(config).then(function (res) {
          if (res.status === 200) {
            console.log(res.data);
            alert("Deleted Successfully");
            AllCategory();
          } else {
            alert("Something Wrong");
          }
        });
      } catch (error) {
        console.log(error.response);
      }
    };
   
    const [search, setSearch] = useState("");
    const [tableFilter, settableFilter] = useState([]);
  
    const handleFilter = (e) => {
      if (e.target.value != "") {
        setSearch(e.target.value);
        const filterTable = dataSource.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
          )
        );
        settableFilter([...filterTable]);
      } else {
        setSearch(e.target.value);
        setdataSource([...dataSource]);
      }
    };
  return (
    <div style={{ backgroundColor: "#f0f0f0", paddingBottom: "100px" }}>
   
    <div style={{ padding: "1% 2%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 0px",
        }}
      >
         <Button
        style={{
          color: "white",
          backgroundColor: "#fdd12d",
          border: "#fdd12d",
        }}
      >
        {" "}
        Special Days
      </Button> 
        {/* <h6
          style={{
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Special Days
        </h6> */}
      </div>

      <div class="row">
        <div class="col-12">
          <div class="EloetableOrder">
            <div class="card-body">
              <div class="row" style={{ padding: "10px 20px" }}>
                <div class="col-sm-4">
                  <div class="search-box  d-inline-block">
                    <div
                      class="position-relative"
                      style={{ display: "flex" }}
                    >
                      <input
                        id="search-bar-0"
                        type="text"
                        aria-labelledby="search-bar-0-label"
                        class="form-control "
                        placeholder="Search by day"
                        onChange={handleFilter}
                        style={{
                          borderRadius: "50px",
                          border: "1px solid #fdd12d",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div class="col-sm-8">
                  <div class="text-sm-end">
                    {" "}
                    <button
                      style={{
                        padding: "2px 8px 7px 8px",
                        color: "white",
                        backgroundColor: "#1d6f42",
                        border: "none",
                        fontWeight: "600",
                        fontSize: "14px",
                        borderRadius: "20px",
                        margin: "0px 10px 0px 0px",
                        backgroundColor:"#fdd12d"
                      }}
                      onClick={() => {
                        setEnterfilenameModal(true);
                      }}
                    >
                      <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                        <AiFillFileWord />
                      </span>{" "}
                      EXPORT TO EXCEL
                    </button>
                    <button
                      style={{
                        padding: "2px 8px 7px 8px",
                        color: "white",
                        backgroundColor: "#fdd12d",
                        border: "none",
                        fontWeight: "600",
                        fontSize: "14px",
                        borderRadius: "20px",
                      }}
                      onClick={() => {
                        setShow1(true);
                      }}
                    >
                      {" "}
                      <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                        +
                      </span>{" "}
                      Add
                    </button>
                  </div>
                </div>
              </div>
              
              <div class="table-responsive">
                <div class="react-bootstrap-table">
                  <table
                    class="table table align-middle table-nowrap"
                    style={{ width: "96%", margin: "2%" }}
                  >
                    <thead
                      style={{ backgroundColor: "#fdd12d", color: "white" }}
                    >
                      <tr style={{ padding: "10px" }}>
                        <th
                          tabindex="0"
                          aria-label="Username sortable"
                          class="sortable"
                        >
                          Sl.No
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Special day
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Action
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                     {search.length>0?(<>
                      {tableFilter?.slice(pagesVisited, pagesVisited + usersPerPage).map((data,index)=>{
                             return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{data.specialday}</td>
                                <td>
                        <i
                          class="fa fa-trash-o"
                          aria-hidden="true"
                          title="Remove"
                          style={{
                            cursor: "pointer",
                            color: "green",
                          }}
                          onClick={() => Removecategory(data)}
                        ></i>
                        
                      </td>
                              </tr>
                            )}
                            )}</>):(<>
                             {dataSource?.slice(pagesVisited, pagesVisited + usersPerPage).map((data,index)=>{
                             return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{data.specialday}</td>
                                <td>
                        <i
                          class="fa fa-trash-o"
                          aria-hidden="true"
                          title="Remove"
                          style={{
                            cursor: "pointer",
                            color: "green",
                          }}
                          onClick={() => Removecategory(data)}
                        ></i>
                        
                      </td>
                              </tr>
                            )}
                            )}</>)}
                           
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </div>

    <Modal
      show={show1}
      onHide={handleClose1}
      animation={true}
      style={{ marginTop: "5%" }}
    >
      <Modal.Header>
        <div class="col-md-12" style={{ margin: "2% 4%" }}>
          <label
            style={{
              textAlign: "left",
              paddingBottom: "10px",
              fontSize: "22px",
              color: "#fdd12d",
              fontWeight: "500",
            }}
          >
            Enter special day{" "}
          </label>
          <br></br>
          <input
            type="text"
            placeholder="Special day here..."
            style={{
              padding: "10px",
              width: "90%",
              border: "1px solid #fdd12d",
            }}
            value={category}
            onChange={(e) => {
              setcategory(e.target.value);
            }}
          />
          {/* <button
            style={{
              padding: "10px",
              color: "white",
              backgroundColor: "#ff7d26",
              border: "none",
            }}
            onClick={addcategory}
          >
            Add Category
          </button> */}
        </div>
      </Modal.Header>

      <Modal.Footer
        style={{ justifyContent: "space-between", padding: "10px 20px" }}
      >
        <Button
          style={{
            backgroundColor: "#fdd12d",
            border: "none",
            width: "120px",
          }}
          onClick={handleClose1}
        >
          CANCEL
        </Button>

        <Button
          style={{
            backgroundColor: "#fdd12d",
            border: "none",
            width: "180px",
          }}
          onClick={addcategory}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
    <Modal
      show={EnterfilenameModal}
      onHide={() => setEnterfilenameModal(false)}
      style={{ width: "320px", marginLeft: "40%", marginTop: "2%" }}
    >
      <Modal.Body>
        <div>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              placeholder="Enter Excel file name ....."
              style={{
                padding: "10px",
                width: "82%",
                border: "1px solid #fdd12d",
              }}
              value={fileName}
              onChange={(e) => {
                setfileName(e.target.value);
              }}
            />
            <ImFolderDownload
              style={{
                width: "18%",
                color: "white",
                backgroundColor: "#fdd12d",
                paddingTop: "15px",
                height: "50px",
                paddingBottom: "15px",
              }}
              onClick={() => ExportToExcel()}
            />
          </div>
          {/* <h1 style={{ textAlign: "center", color: "#ffcc33" }}>{Count}</h1> */}
        </div>
      </Modal.Body>
    </Modal>
  </div>
  )
}

export default SpecialDay