import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useLocation, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import DatePicker from "react-multi-date-picker";
function AdminInvoice() {
  const { state } = useLocation();
  const [edit, setedit] = useState(false);

  const [bookDetails, setbookDetails] = useState({});
  const getBookDetailsById = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getBookingDetailsById/" + state
      );
      if (res.status == 200) {
        setbookDetails(res.data?.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [gst, setgst] = useState({});
  const getgst = async () => {
    try {
      let res = await axios.get("https://goeasycab.info/api/admin/getgst");
      if (res.status == 200) {
        setgst(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (state) {
      getBookDetailsById();
    }
    getgst()
  }, []);
  const history = useNavigate();
  const createPDF = async () => {
    const input = document.getElementById("pdf");
    const options = { scrollY: -window.scrollY };
    const canvas = await html2canvas(input, options);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save("bookingDetails.pdf");
  };

  const alldate = [
    { id: 0, time: "00:00" },
    { id: 15, time: "00:15" },
    { id: 30, time: "00:30" },
    { id: 45, time: "00:45" },
    { id: 100, time: "01:00" },
    { id: 115, time: "01:15" },
    { id: 130, time: "01:30" },
    { id: 145, time: "01:45" },
    { id: 200, time: "02:00" },
    { id: 215, time: "02:15" },
    { id: 230, time: "02:30" },
    { id: 245, time: "02:45" },
    { id: 300, time: "03:00" },
    { id: 315, time: "03:15" },
    { id: 330, time: "03:30" },
    { id: 345, time: "03:45" },
    { id: 400, time: "04:00" },
    { id: 415, time: "04:15" },
    { id: 430, time: "04:30" },
    { id: 445, time: "04:45" },
    { id: 500, time: "05:00" },
    { id: 515, time: "05:15" },
    { id: 530, time: "05:30" },
    { id: 545, time: "05:45" },
    { id: 600, time: "06:00" },
    { id: 615, time: "06:15" },
    { id: 630, time: "06:30" },
    { id: 645, time: "06:45" },
    { id: 700, time: "07:00" },
    { id: 715, time: "07:15" },
    { id: 730, time: "07:30" },
    { id: 745, time: "07:45" },
    { id: 800, time: "08:00" },
    { id: 815, time: "08:15" },
    { id: 830, time: "08:30" },
    { id: 845, time: "08:45" },
    { id: 900, time: "09:00" },
    { id: 915, time: "09:15" },
    { id: 930, time: "09:30" },
    { id: 945, time: "09:45" },
    { id: 1000, time: "10:00" },
    { id: 1015, time: "10:15" },
    { id: 1030, time: "10:30" },
    { id: 1045, time: "10:45" },
    { id: 1100, time: "11:00" },
    { id: 1115, time: "11:15" },
    { id: 1130, time: "11:30" },
    { id: 1145, time: "11:45" },
    { id: 1200, time: "12:00" },
    { id: 1215, time: "12:15" },
    { id: 1230, time: "12:30" },
    { id: 1245, time: "12:45" },
    { id: 1300, time: "13:00" },
    { id: 1315, time: "13:15" },
    { id: 1330, time: "13:30" },
    { id: 1345, time: "13:45" },
    { id: 1400, time: "14:00" },
    { id: 1415, time: "14:15" },
    { id: 1430, time: "14:30" },
    { id: 1445, time: "14:45" },
    { id: 1500, time: "15:00" },
    { id: 1515, time: "15:15" },
    { id: 1530, time: "15:30" },
    { id: 1545, time: "15:45" },
    { id: 1600, time: "16:00" },
    { id: 1615, time: "16:15" },
    { id: 1630, time: "16:30" },
    { id: 1645, time: "16:45" },
    { id: 1700, time: "17:00" },
    { id: 1715, time: "17:15" },
    { id: 1730, time: "17:30" },
    { id: 1745, time: "17:45" },
    { id: 1800, time: "18:00" },
    { id: 1815, time: "18:15" },
    { id: 1830, time: "18:30" },
    { id: 1845, time: "18:45" },
    { id: 1900, time: "19:00" },
    { id: 1915, time: "19:15" },
    { id: 1930, time: "19:30" },
    { id: 1945, time: "19:45" },
    { id: 2000, time: "20:00" },
    { id: 2015, time: "20:15" },
    { id: 2030, time: "20:30" },
    { id: 2045, time: "20:45" },
    { id: 2100, time: "21:00" },
    { id: 2115, time: "21:15" },
    { id: 2130, time: "21:30" },
    { id: 2145, time: "21:45" },
    { id: 2200, time: "22:00" },
    { id: 2215, time: "22:15" },
    { id: 2230, time: "22:30" },
    { id: 2245, time: "22:45" },
    { id: 2300, time: "23:00" },
    { id: 2315, time: "23:15" },
    { id: 2330, time: "23:30" },
    { id: 2345, time: "23:45" },
  ];
  console.log("dslds", bookDetails);
  let [totalAmount,settotalAmount]=useState(0);
  const [paymentStatus, setpymentStatus] = useState("");
  const [payAmount,setpayAmount]=useState(0);
  const [servicefee,setservicefee]=useState(0);
  const [reservelFess,setreservelFee]=useState(0);
  const [pickuptime, setpickuptime] = useState("");
  const [returntime, setreturntime] = useState("");
  const [pickupdate, setpickupdate] = useState("");
  const [retundate, setretundate] = useState(moment().format("DD/MM/YYYY"));
  const [extraCharge,setextraCharge]=useState(0);
  const [tollCharge,settollCharge]=useState(0)
  const [sgst,setsgst]=useState(0)
  const [SgstPrice,setSgstPrice]=useState(0)
  useEffect(() => {
    if (Object.keys(bookDetails).length) {
      setpickupdate(bookDetails?.picUpDate);
      setpickuptime(bookDetails?.picUpTime);
      settotalAmount(bookDetails?.totalAmount);
      setpayAmount(bookDetails?.payAmount);
      if(bookDetails?.tripType=="Round Trip"){
        setretundate(bookDetails?.returnDate);
        setreturntime(bookDetails?.returnTime);
      }
      setservicefee(bookDetails?.serviceFee);
      setreservelFee(bookDetails?.ReversalFee);
      setextraCharge(bookDetails?.extraCharge);
      settollCharge(bookDetails?.tollCharge);
      setSgstPrice(bookDetails?.SgstPrice);
      setsgst(bookDetails?.Sgst)
    }
  }, [bookDetails]);

  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
//editOrder
  const updatedbookDetails=async ()=>{
    try {
      totalAmount=Number(totalAmount)+Number(extraCharge)+Number(tollCharge)+Number(servicefee)+Number(reservelFess)+Math.round(Number(totalAmount)*Number(sgst)/100)
    let  obj={
        authId:admin1?._id,
        picUpDate:pickupdate,
        picUpTime:pickuptime,
        payAmount:payAmount,
        extraCharge:extraCharge,
        PaymetStatus:paymentStatus,
        serviceFee:servicefee,
        Sgst:sgst,
        ReversalFee:reservelFess,
        tollCharge:tollCharge,
        totalAmount:totalAmount,
        bookId:state,
        SgstPrice:Math.round(Number(totalAmount)*Number(sgst)/100)
      }
      if(bookDetails?.tripType=="Round Trip"){
       obj["returnDate"]=retundate;
       obj["returnTime"]=returntime;
      }
      const config = {
        url: "/admin/editOrder",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data:obj
      };
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully updated");
        getBookDetailsById();
        setedit(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="invo">
        <div className="container" style={{ margin: "1% 0% 1% 0%" }}>
          <div
            className="05_in"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="inv_0"
              id="pdf"
              style={{ width: "40%", textAlign: "center" }}
            >
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan={2}>
                      <h3 style={{ fontSize: "28px", fontWeight: "700" }}>
                        GoEasyDoCab.com
                        <br />
                        Booking Confirmation
                      </h3>
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={2}>
                      <h3></h3>
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={2}>
                      <h3></h3>
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={2}>
                      <div className="advv">
                        <h1 style={{ fontSize: "28px", fontWeight: "700" }}>
                          Dear {bookDetails?.userName}
                        </h1>
                        <div>
                          Your Booking for {bookDetails?.picUpPlace} to{" "}
                          {bookDetails?.dropPlace} has been{" "}
                          {bookDetails?.status} . Please refer below
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h6>Booking Id</h6>
                    </td>
                    <td>
                      <b>{bookDetails?.bookid}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Booking Date and time</h6>
                    </td>
                    <td>
                      {edit ? (
                        <div className="d-flex">
                          <div className="">
                            <DatePicker
                              style={{ width: "80%", height: "38px" }}
                              format="DD/MM/YYYY"
                              type="input-icon"
                              minDate={new Date()}
                              value={pickupdate}
                              onChange={(date) =>
                                setpickupdate(date.format("DD/MM/YYYY"))
                              }
                            />
                          </div>
                          <select
                            name="txtStartTime"
                            className="select_hero-0"
                            style={{ width: "60%", height: "38px" }}
                            onChange={(e) => setpickuptime(e.target.value)}
                          >
                            {pickuptime ? (
                              <option>
                                {" "}
                                {moment(pickuptime, ["h:mm A"]).format(
                                  "hh:mm a"
                                )}
                              </option>
                            ) : (
                              <option>Select Time</option>
                            )}
                            {alldate?.map((item, i) => {
                              return (
                                <option value={item?.time}>
                                  {moment(item?.time, ["h:mm A"]).format(
                                    "hh:mm a"
                                  )}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : (
                        <>
                          {" "}
                          {bookDetails?.picUpDate} ,{" "}
                          {moment(bookDetails?.picUpTime, ["h:mm a"]).format(
                            "hh:mm a"
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Trip Type</h6>
                    </td>
                    <td>{bookDetails?.tripType}</td>
                  </tr>
                  {bookDetails?.tripType =="Local" || bookDetails?.tripType=="Airport" ? ( <tr>
                    <td>
                      <h6>City</h6>
                    </td>
                    <td>{bookDetails?.city}</td>
                  </tr>):(<></>)}
                  <tr>
                    <td>
                      <h6>From</h6>
                    </td>
                    <td>{bookDetails?.picUpPlace}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>To</h6>
                    </td>
                    <td>{bookDetails?.dropPlace}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>PickUp Place</h6>
                    </td>
                    <td>{bookDetails?.picPlace}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Drop Place</h6>
                    </td>
                    <td>{bookDetails?.DropLocation}</td>
                  </tr>
                  {bookDetails?.tripType == "Round Trip" ? (
                    <tr>
                      <td>
                        <h6>Return Date and time</h6>
                      </td>
                      <td>
                        {edit ? (
                          <div className="d-flex">
                            <div className="">
                              <DatePicker
                                style={{ width: "80%", height: "38px" }}
                                format="DD/MM/YYYY"
                                type="input-icon"
                                minDate={new Date()}
                                value={retundate}
                                onChange={(date) =>
                                  setretundate(date.format("DD/MM/YYYY"))
                                }
                              />
                            </div>
                            <select
                              name="txtStartTime"
                              className="select_hero-0"
                              style={{ width: "60%", height: "38px" }}
                              onChange={(e) => setreturntime(e.target.value)}
                            >
                              {returntime ? (
                                <option>
                                  {" "}
                                  {moment(returntime, ["h:mm A"]).format(
                                    "hh:mm a"
                                  )}
                                </option>
                              ) : (
                                <option>Select Time</option>
                              )}
                              {alldate?.map((item, i) => {
                                return (
                                  <option value={item?.time}>
                                    {moment(item?.time, ["h:mm A"]).format(
                                      "hh:mm a"
                                    )}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        ) : (
                          <>
                            {" "}
                            {bookDetails?.returnDate} ,
                            {moment(bookDetails?.returnTime, ["h:mm a"]).format(
                              "hh:mm a"
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                   <tr>
                    <td>
                      <h6>GST Amount</h6>
                    </td>
                    <td>
                    Rs.{bookDetails?.gst}
                    </td>
                  </tr>
                  <tr>
                  <td>
                      <h6>Discount</h6>
                    </td>
                    <td>
                    Rs.{bookDetails?.discount?.toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                  <td>
                      <h6>Parking Charge</h6>
                    </td>
                    <td>
                    Rs.{bookDetails?.parkingCharge?.toFixed(2)}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      <h6>SGST {bookDetails?.Sgst}%</h6>
                    </td>
                    <td>
                      {edit ? (
                        <div className="d-flex">
                          <input
                            type="number"
                            style={{ width: "60%", height: "38px" }}
                            placeholder="0"
                            className="select_hero-0"
                            value={sgst}
                            onChange={(e)=>setsgst(e.target.value)}
                          ></input>{" "}
                        </div>
                      ) : (
                        <>Rs.{bookDetails?.SgstPrice}</>
                      )}
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      <h6>Toll Charge</h6>
                    </td>
                    <td>
                      {edit ? (
                        <div className="d-flex">
                          <input
                            type="number"
                            style={{ width: "60%", height: "38px" }}
                            placeholder={bookDetails?.tollCharge}
                            onChange={(e)=>settollCharge(e.target.value)}
                            className="select_hero-0"
                          ></input>{" "}
                        </div>
                      ) : (
                        <>Rs.{(bookDetails?.tollCharge)?.toFixed(2)}</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Extra Charge</h6>
                    </td>
                    <td>
                      {edit ? (
                        <div className="d-flex">
                          <input
                            type="number"
                            style={{ width: "60%", height: "38px" }}
                            value={extraCharge}
                            className="select_hero-0"
                            onChange={(e)=>setextraCharge(e.target.value)}
                          ></input>{" "}
                        </div>
                      ) : (
                        <>Rs.{(bookDetails?.extraCharge)?.toFixed(2)}</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>GEDC Service Fees</h6>
                    </td>
                    
                    <td>
                      {edit ? (
                        <div className="d-flex">
                          <input
                            type="number"
                            style={{ width: "60%", height: "38px" }}
                            value={servicefee}
                            onChange={(e)=>setservicefee(e.target.value)}
                            className="select_hero-0"
                          ></input>{" "}
                        </div>
                      ) : (
                        <>Rs.{(bookDetails?.serviceFee)?.toFixed(2)}</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Reversal of GEDC Service Fee</h6>
                    </td>
                    
                    <td>
                      {edit ? (
                        <div className="d-flex">
                          <input
                            type="number"
                            style={{ width: "60%", height: "38px" }}
                            value={reservelFess}
                            onChange={(e)=>setreservelFee(e.target.value)}
                            className="select_hero-0"
                          ></input>{" "}
                        </div>
                      ) : (
                        <>Rs.{(bookDetails?.ReversalFee)?.toFixed(2)}</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Bill Amount</h6>
                    </td>
                    <td>Rs.{bookDetails?.totalAmount}</td>
                  </tr>
                  
                  <tr>
                    <td>
                      <h6>Amount Advance</h6>
                    </td>
                    <td>Rs. {bookDetails?.payAmount?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>PayId</h6>
                    </td>
                    <td>{bookDetails?.payId}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Pay Mode</h6>
                    </td>
                    <td> {bookDetails?.paymentMethod}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Remaining Amount</h6>
                    </td>
                    <td>  Rs. {(Number(bookDetails?.totalAmount)-Number(bookDetails?.payAmount))?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Payment</h6>
                    </td>
                    <td>
                      {edit ? (
                        <input
                          type="text"
                          className="select_hero-0"
                          onChange={(e) => setpymentStatus(e.target.value)}
                          placeholder={bookDetails?.PaymetStatus}
                          style={{ width: "60%", height: "38px" }}
                        ></input>
                      ) : (
                        bookDetails?.PaymetStatus
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>FARE Inclusions</h6>
                      {/* <p style={{fontSize:"15px"}}>Night charges?(10pm to 6am),344km state tax,to all charges,driver allowance,only pickup and drop</p> */}
                      {bookDetails?.tripType == "Local" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          {/* <li style={{ fontSize: "15px" }}>
                            Night charges ({bookDetails?.vechicleId?.nightTime})
                          </li> */}
                          <li style={{ fontSize: "15px" }}>
                            {bookDetails?.totalKm} Kms  (booked package) 
                          </li>
                          <li style={{ fontSize: "15px" }}>Driver Allowance</li>
                          {/* <li style={{ fontSize: "15px" }}>
                            Only One Pickup and Drop
                          </li> */}
                        </ul>
                      ) : (
                        <></>
                      )}
                      {bookDetails?.tripType == "oneway" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          <li style={{ fontSize: "15px" }}>
                            Night charges ({bookDetails?.vechicleId?.nightTime})
                          </li>
                          <li style={{ fontSize: "15px" }}>
                          Parking Charges
                          </li>
                          <li style={{ fontSize: "15px" }}>Toll Charges</li>
                          <li style={{ fontSize: "15px" }}>State Tax</li>
                          <li style={{ fontSize: "15px" }}>
                            {bookDetails?.totalKm} Kms
                          </li>
                          <li>Driver Allowance</li>
                          <li style={{ fontSize: "15px" }}>
                            Only One Pickup and Drop
                          </li>
                        </ul>
                      ) : (
                        <></>
                      )}
                      {bookDetails?.tripType == "Round Trip" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          <li style={{ fontSize: "15px" }}>
                            {bookDetails?.totalKm} Kms
                          </li>
                          <li style={{ fontSize: "15px" }}>Driver Allowance</li>
                        </ul>
                      ) : (
                        <></>
                      )}
                      {bookDetails?.tripType == "Airport" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          <li style={{ fontSize: "15px" }}>
                            Night charges ({bookDetails?.vechicleId?.nightTime})
                          </li>
                          <li style={{ fontSize: "15px" }}>Toll Charges</li>
                          <li style={{ fontSize: "15px" }}>State Tax</li>
                          <li style={{ fontSize: "15px" }}>Airport entry charges</li>
                          <li style={{ fontSize: "15px" }}>
                            {bookDetails?.totalKm} Kms
                          </li>
                          <li style={{ fontSize: "15px" }}>Driver Allowance</li>
                          <li style={{ fontSize: "15px" }}>
                            Only One Pickup and Drop
                          </li>
                        </ul>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      <h6>FARE Exclusions</h6>
                      {/* <p style={{fontSize:"15px"}}>EXTRA Kms Rs. 15/km</p> */}
                      {bookDetails?.tripType == "Local" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          <li style={{ fontSize: "15px" }}>
                            Toll Charges As applicable
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            State Tax As applicable
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Waiting Charges After {bookDetails?.waitingTime} , ₹{" "}
                            {bookDetails?.waitingCharge}
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Fare beyond {bookDetails?.totalKm} Kms ₹{" "}
                            {bookDetails?.extraKmPrice}/Km
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Fare beyond {bookDetails?.beyondHour} Hrs ₹{" "}
                            {bookDetails?.extraHrPrice}/Minutes
                           
                          </li>
                        </ul>
                      ) : (
                        <></>
                      )}
                      {bookDetails.tripType == "oneway" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          <li style={{ fontSize: "15px" }}>
                            Waiting Charges After {bookDetails?.waitingTime} ₹{" "}
                            {bookDetails?.waitingCharge}
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Fare beyond {bookDetails?.totalKm} Kms ₹{" "}
                            {bookDetails?.extraKmPrice}/Km
                          </li>
                        </ul>
                      ) : (
                        <></>
                      )}
                      {bookDetails.tripType == "Round Trip" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          <li style={{ fontSize: "15px" }}>
                            Night charges({bookDetails?.vechicleId?.nightTime})
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Toll Charges As applicable
                          </li>
                          <li style={{ fontSize: "15px" }}>
                          Parking Charges As applicable
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            State Tax As applicable
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Waiting Charges After {bookDetails?.waitingTime} ₹{" "}
                            {bookDetails?.waitingCharge}
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Fare beyond {bookDetails?.totalKm} Kms ₹{" "}
                            {bookDetails?.extraKmPrice}/Km
                          </li>
                        </ul>
                      ) : (
                        <></>
                      )}
                      {bookDetails.tripType == "Airport" ? (
                        <ul
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          <li style={{ fontSize: "15px" }}>
                            Waiting Charges After {bookDetails?.waitingTime} ₹{" "}
                            {bookDetails?.waitingCharge}
                          </li>
                          <li style={{ fontSize: "15px" }}>
                            Fare beyond {bookDetails?.totalKm} Kms ₹{" "}
                            {bookDetails?.extraKmPrice}/Km
                          </li>
                        </ul>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={2}>
                      <a href="https://goeasydocab.com">goeasydocab.com</a>
                      <h6>
                        Reach us on +919538880063 or{" "}
                        <span>
                          <a href="">Support@goeasydocab.com</a>
                        </span>
                      </h6>
                    </th>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="d-flex" style={{ margin: "0% 0% 0% 30%", gap: "5px" }}>
          <button
            type="button"
            class="btn btn-danger"
            onClick={() => history(-1)}
          >
            Back
          </button>
          {bookDetails?.status =="Complete" ? (<></>):(<>
            {edit ? (
            <button
              type="button"
              class="btn btn-secondary"
              onClick={() => setedit(false)}
            >
              Cancel
            </button>
          ) : (
            <button
              type="button"
              class="btn btn-secondary"
              onClick={() => setedit(true)}
            >
              Edit
            </button>
          )}
          {edit ? (
            <button type="button" class="btn btn-success" onClick={updatedbookDetails}>
              Save
            </button>
          ) : (
            <></>
          )}
          </>)}
         

          <button type="button" class="btn btn-warning" onClick={createPDF}>
            Download
          </button>
        </div>
        <br />
      </div>
    </>
  );
}

export default AdminInvoice;
