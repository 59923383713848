import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import Button from "react-bootstrap/Button";
import Modal1 from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { RxCrossCircled } from "react-icons/rx";
import { Modal } from "antd";
import ReactPaginate from "react-paginate";
function VchicleName() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const [etitdata, seteditdata] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [modal2Open, setModal2Open] = useState(false);

  const [errorData, setErrordata] = useState("");
  const erorShow = (data) => {
    setErrordata(data);
    setModal2Open(true);
  };

  const [dataSource, setdataSource] = useState([]);
  const [category, setcategory] = useState("");
  const [VchicleName, setVehicleName] = useState("");
  const AllCategory = async () => {
    let res = await axios.get(
      "https://goeasycab.info/api/admin/getAllCategory"
    );
    if (res.status === 200) {
      console.log(res);
      setdataSource(res.data.carList);
    }
  };
  const [allData, setAllData] = useState([]);
  const [nochangedata,setnochangedata]=useState([])
  
  const allVehicleName = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllVehicleName"
      );
      if (res.status == 200) {
        setAllData(res.data.carList);
        setnochangedata(res.data.carList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    AllCategory();
    allVehicleName();
  }, []);
  const handleFilter = (e) => {
    if (e.target.value != "") {
      // setSearch(e.target.value);
      const filterTable = nochangedata?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllData([...filterTable]);
    } else {
      // setSearch(e.target.value);
      setAllData([...nochangedata]);
    }
  };
  const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
  const addVehicleName = async () => {
    try {
      if (!category) return erorShow("Please select category");
      if (!VchicleName) return erorShow("Please enter vehicle name");
      const config = {
        url: "/admin/addVehicleName",
        method: "post",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          authId: admin1._id,
          category: category,
          vehicleName: VchicleName,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        allVehicleName();
        handleClose();
        setModal2Open(false);
        setcategory("");
        setVehicleName("");
      }
    } catch (error) {
      erorShow(error.response.data.error);
      console.log(error);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allData.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const editVehicle = async () => {
    try {
      const config = {
        url: "/admin/editVehicleName",
        method: "put",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          authId: admin1._id,
          id: etitdata?._id,
          category: category,
          vehicleName: VchicleName,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully update");
        allVehicleName();
        handleClose1();
        setModal2Open(false);
        setcategory("");
        setVehicleName("");
      }
    } catch (error) {
      erorShow(error.response.data.error);
      console.log(error);
    }
  };
  const deleteVehicleName = async (id) => {
    try {
      const config = {
        url: "/admin/deleteVehicleName/" + id + "/" + admin1._id,
        method: "delete",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");
        allVehicleName();
      }
    } catch (error) {
      erorShow(error.response.data.error);
      console.log(error);
    }
  };
  return (
    <>
     <Button
            style={{
              color: "white",
              backgroundColor: "#fdd12d",
              border: "#fdd12d",
              marginBottom:"10px"
            }}
          // onClick={handleShow}
        >
       Vehicle Model
        </Button>
     <div className="accountt mb-3" style={{display:"flex",justifyContent:"space-between"}}>
      <div class="search-box  d-inline-block">
                      <div
                        class="position-relative"
                        style={{ display: "flex" }}
                      >
                        <input
                          id="search-bar-0"
                          type="text"
                          aria-labelledby="search-bar-0-label"
                          class="form-control "
                          placeholder="Search ........."
                        onChange={(e)=>handleFilter(e)}
                          style={{
                            borderRadius: "50px",
                            border: "1px solid #fdd12d",
                          }}
                        />
                      </div>
                    </div>
        <Button
          style={{
            borderRadius: "50px",
            border: "1px solid #fdd12d",
          }}
          onClick={handleShow}
        >
          Create
        </Button>
      </div>

      <div className="vwv">
        <div style={{ paddingLeft: "0px" }}>
          <div style={{ backgroundColor: "#f0f0f0" }}>
            <div class="row" style={{ padding: "10px 20px" }}>
              <div class="table-responsive">
                <div class="react-bootstrap-table">
                  <table
                    class="table table align-middle table-nowrap"
                    style={{ width: "90%", margin: "2%" }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#fdd12d",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      <tr style={{ padding: "10px" }}>
                        <th
                          tabindex="0"
                          aria-label="Username sortable"
                          class="sortable"
                        >
                          S.No
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Vehicle Category
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Vehicle Model
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                        <th
                          tabindex="0"
                          aria-label="Phone / Email sortable"
                          class="sortable"
                        >
                          Action
                          <span class="order">
                            <span class="dropdown">
                              <span class="caret"></span>
                            </span>
                            <span class="dropup">
                              <span class="caret"></span>
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allData
                        ?.slice(pagesVisited, pagesVisited + usersPerPage)
                        ?.map((item, i) => {
                          return (
                            <tr style={{ textAlign: "center" }}>
                              <td>{i + 1}</td>

                              <td>{item?.category}</td>
                              <td>{item?.vehicleName}</td>
                              <td>
                                <Button
                                  type="button"
                                  variant="danger"
                                  onClick={() => deleteVehicleName(item?._id)}
                                >
                                  <span>
                                    <RiDeleteBin6Line />
                                  </span>
                                </Button>
                                <Button
                                  type="button"
                                  variant="success"
                                  className="mx-2"
                                  onClick={() => {
                                    seteditdata(item);
                                    handleShow1();
                                  }}
                                >
                                  <span>
                                    <RiEditLine />
                                  </span>
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal */}

      <Modal1 show={show} onHide={handleClose}>
        <Modal1.Header closeButton></Modal1.Header>
        <Modal1.Body>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e) => setcategory(e.target.value)}
            >
              <option>Select Category</option>
              {dataSource?.map((item) => {
                return <option value={item?.category}>{item?.category}</option>;
              })}
            </Form.Select>
          </div>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="text"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder="Enter model"
              // value={}
              onChange={(e) => setVehicleName(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
        </Modal1.Body>
        <Modal1.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addVehicleName}>
            Submit
          </Button>
        </Modal1.Footer>
      </Modal1>
      {/* Edit Modal1 */}
      <Modal1 show={show1} onHide={handleClose1}>
        <Modal1.Header closeButton></Modal1.Header>
        <Modal1.Body>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
              onChange={(e) => setcategory(e.target.value)}
            >
              {etitdata?.category ? (
                <option>{etitdata?.category}</option>
              ) : (
                <option>Select Category</option>
              )}
              {dataSource?.map((item) => {
                return <option value={item?.category}>{item?.category}</option>;
              })}
            </Form.Select>
          </div>
          <div class="position-relative mb-2" style={{ display: "flex" }}>
            <input
              id="search-bar-0"
              type="text"
              aria-labelledby="search-bar-0-label"
              class="form-control "
              placeholder={etitdata?.vehicleName}
              onChange={(e) => setVehicleName(e.target.value)}
              style={{
                borderRadius: "50px",
                border: "1px solid #fdd12d",
              }}
            />
          </div>
        </Modal1.Body>
        <Modal1.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editVehicle}>
            Submit
          </Button>
        </Modal1.Footer>
      </Modal1>
      {/* error handler model */}
      <Modal
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <div className="" style={{ display: "flex", justifyContent: "center" }}>
          <div className="">
            <div
              className="wrong"
              style={{
                fontSize: " 100px",
                color: "#ff000087",
                textAlign: "center",
              }}
            >
              <RxCrossCircled />
            </div>
            <div className="nf_0">{errorData}</div>
            <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30" }}
                onClick={() => setModal2Open(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default VchicleName;
